import { useContext, useState, useEffect } from 'react';
import SubmenuFilter from '../../components/SubmenuFilter';
import Navbar from "../../components/Navbar";
import { DataCountContext } from '../../components/DataCountContext';
import { useLocation } from 'react-router-dom';
import { appSettings } from '../../AppSettings';
import DataTable from 'react-data-table-component';
import { AiOutlinePlus, AiFillEdit } from 'react-icons/ai';
import { Modal, DatePicker } from 'antd';
import FloatingLabel from '../../components/FloatingLabel';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';


const VehicleEquipmentList = () => {

    const dataCount = useContext(DataCountContext) ?? 5;
    const location = useLocation();
    const vehicleId = location.state.vehicleId;
    const fleetNo = location.state.fleetNo;
    const [vehicleEquipmentData, setVehicleEquipmentData] = useState([]);
    const [IsLoading, setIsLoading] = useState(false);
    const [isUploadModalVisible, setUploadModalVisible] = useState(false);

    const [isAddModalVisible, setAddModalVisible] = useState(false);
    const [equipmentEditedFlag, setEquimentEditedFlag] = useState(false);
    const [isEquipmentAddedFlag, setEquipmentAddedFlag] = useState(false);

    const [equipmentId, setEquipmentId] = useState('');
    const [equipmentType, setEquipmentType] = useState('');
    const [equipmentSubType, setEquipmentSubType] = useState('');
    const [fleetNumber, setFleetNumber] = useState('');
    const [equipmentMake, setEquipmentMake] = useState('');
    const [equipmentModel, setEquipmentModel] = useState('');
    const [equipmentYear, setEquipmentYear] = useState('');
    const [serialNum, setSerialNum] = useState('');
    const [warranty, setWarranty] = useState('');

    const columns = [
        {
            name: 'id',
            selector: 'id',
            sortable: true,
            omit: true
        },
        {
            name: 'Equipment Type',
            selector: 'equipmentType',
            sortable: true
        },
        {
            name: 'SubType',
            selector: 'equipmentSubType',
            sortable: true
        },
        {
            name: 'Fleet Number',
            selector: 'fleetNumber',
            sortable: true
        },
        {
            name: 'Make',
            selector: 'make',
            sortable: true
        },
        {
            name: 'Model',
            selector: 'model',
            sortable: true
        },
        {
            name: 'Year',
            selector: 'year',
            sortable: true
        },
        {
            name: 'Serial',
            selector: 'serialNumber',
            sortable: true
        },
        {
            name: 'Expiry Date',
            selector: 'warrantyExpiryDate',
            sortable: true,
            cell: (row) => {
                // Parse the date string into a JavaScript Date object
                const date = new Date(row.warrantyExpiryDate);

                // Format the date as "yyyy-MM-dd"
                const formattedDate = date.toISOString().split('T')[0];

                // Render the formatted date
                return formattedDate;
            }
        },
        {
            name: 'Edit',
            cell: (row) => (
                <div>
                    <button
                        className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2"
                        onClick={() => editEquipmentData(row)}
                    >
                        <span className="mr-2">Edit</span>
                        <AiFillEdit size={20} />
                    </button>
                </div>
            )
        }
    ]

    const getVehicles = () => {
          setIsLoading(true);
        fetch(`${appSettings.apiUrl}/VehicleList/GetVehicleEquipmentByFleet/${fleetNo}`)
            .then(response => response.json())
            .then(data => {
                setVehicleEquipmentData(data);
                setIsLoading(false);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching vehicle equipment:', error);
            });

    };

    useEffect(() => {
        if (equipmentEditedFlag || isEquipmentAddedFlag) {
            getVehicles();
            setEquimentEditedFlag(false);
        }
        else {
            getVehicles();
        }


    }, [equipmentEditedFlag, isEquipmentAddedFlag]);

    const handleOK = () => {
        const equipmentModelData = {
            equipmentId,
            equipmentType,
            equipmentSubType,
            fleetNumber,
            equipmentMake,
            equipmentModel,
            equipmentYear,
            serialNum,
            warranty
        }

        fetch(appSettings.apiUrl + '/VehicleList/EditEquipment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(equipmentModelData),
        })
            .then(response => response.json())
            .then(data => {
                setEquipmentId('');
                setEquipmentType('');
                setEquipmentSubType('');
                setFleetNumber('');
                setEquipmentMake('');
                setEquipmentModel('');
                setEquipmentYear('');
                setSerialNum('');
                setWarranty('');
                setEquimentEditedFlag(true);
                setUploadModalVisible(false);
            })
            .catch(error => {
                console.error(error);
            });
        
    }

    const handleCancel = () => {
        setUploadModalVisible(false);
        setEquipmentId('');
        setEquipmentType('');
        setEquipmentSubType('');
        setFleetNumber('');
        setEquipmentMake('');
        setEquipmentModel('');
        setEquipmentYear('');
        setSerialNum('');
        setWarranty('');
    }

    function editEquipmentData(row) {
        setUploadModalVisible(true);
        setEquipmentId(row.id);
        setEquipmentType(row.equipmentType);
        setEquipmentSubType(row.equipmentSubType);
        setFleetNumber(row.fleetNumber);
        setEquipmentMake(row.make);
        setEquipmentModel(row.model);
        setEquipmentYear(row.year);
        setSerialNum(row.serialNumber);
        setWarranty(row.warrantyExpiryDate);
    }

    function handleAddOK() {
        const newEquipmentId = uuidv4();
        const formattedWarranty = warranty
            ? moment(warranty, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            : null;

        const equipmentModelData = {
            equipmentId: newEquipmentId,
            equipmentType,
            equipmentSubType,
            fleetNumber,
            equipmentMake,
            equipmentModel,
            equipmentYear,
            serialNum,
            warranty: formattedWarranty,
            vehicleId
        }

    
        fetch(appSettings.apiUrl + '/VehicleList/AddNewEquipmentByVehicle', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(equipmentModelData),
        })
            .then(response => response.json())
            .then(data => {
                setEquipmentId('');
                setEquipmentType('');
                setEquipmentSubType('');
                setFleetNumber('');
                setEquipmentMake('');
                setEquipmentModel('');
                setEquipmentYear('');
                setSerialNum('');
                setWarranty('');
                setEquipmentAddedFlag(true);
                setAddModalVisible(false);
            })
            .catch(error => {
                console.error(error);
            });
        
        setAddModalVisible(false);
    }

    function handleAddCancel() {
        setAddModalVisible(false);
        setEquipmentId('');
        setEquipmentType('');
        setEquipmentSubType('');
        setFleetNumber('');
        setEquipmentMake('');
        setEquipmentModel('');
        setEquipmentYear('');
        setSerialNum('');
        setWarranty('');
    }

    console.log(vehicleEquipmentData);

    return (
        <div>
            <Navbar />
            <SubmenuFilter dataCount={dataCount} />
            <br />
            <div className="bg-gray-200 p-2 rounded-lg shadow-md">
                <div className="flex justify-center">
                    <p className="text-sm font-semibold text-blue-600">
                        Equipment for Fleet No: {fleetNo}
                    </p>
                </div>
            </div>


            <Modal
                title={"Edit Equipment"}
                open={isUploadModalVisible}
                onOk={handleOK}
                width={800}
                centered
                onCancel={handleCancel}
                cancelButtonProps={{ style: { backgroundColor: 'red', border: 'none', color: 'white' } }}
                okButtonProps={{ style: { backgroundColor: 'green' } }}
            >
                <div className="flex flex-col">
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="equipmentType"
                        name="equipmentType"
                        type="text"
                        value={equipmentType}
                        onChange={(e) => setEquipmentType(e.target.value)}
                        placeholder="Equipment Type"
                        label="Equipment Type"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="equipmentSubType"
                        name="equipmentSubType"
                        type="text"
                        value={equipmentSubType}
                        onChange={(e) => setEquipmentSubType(e.target.value)}
                        placeholder="Equipment Sub Type"
                        label="Equipment Sub Type"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="fleetNo"
                        name="fleetNo"
                        type="text"
                        value={fleetNumber}
                        onChange={(e) => setFleetNumber(e.target.value)}
                        placeholder="Fleet Number"
                        label="Fleet Number"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="make"
                        name="make"
                        type="text"
                        value={equipmentMake}
                        onChange={(e) => setEquipmentMake(e.target.value)}
                        placeholder="Equipment Make"
                        label="Equipment Make"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="model"
                        name="model"
                        type="text"
                        value={equipmentModel}
                        onChange={(e) => setEquipmentModel(e.target.value)}
                        placeholder="Equipment Model"
                        label="Equipment Model"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="year"
                        name="year"
                        type="text"
                        value={equipmentYear}
                        onChange={(e) => setEquipmentYear(e.target.value)}
                        placeholder="Equipment Year"
                        label="Equipment Year"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="serial"
                        name="serial"
                        type="text"
                        value={serialNum}
                        onChange={(e) => setEquipmentYear(e.target.value)}
                        placeholder="Equipment Year"
                        label="Equipment Year"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    {/* <FloatingLabel
                        id="warranty"
                        name="warranty"
                        type="text"
                        value={warranty}
                        onChange={(e) => setWarranty(e.target.value)}
                        placeholder="Warranty Expiry Date"
                        label="Warranty Expiry Date"
                        className="relative ml-3"
                    /> */}
                    <DatePicker
                        id="warranty"
                        name="warranty"
                        value={warranty ? moment(warranty, 'YYYY/MM/DD') : null}
                        onChange={(date, dateString) => {
                            const formattedWarranty = dateString
                                ? moment(dateString, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                                : null;

                            setWarranty(formattedWarranty);
                        }}
                        placeholder="Warranty Expiry Date"
                        className="relative ml-3"
                    />
                </div>
            </Modal>


            <Modal
                title={"Add Equipment"}
                open={isAddModalVisible}
                onOk={handleAddOK}
                width={800}
                centered
                onCancel={handleAddCancel}
                cancelButtonProps={{ style: { backgroundColor: 'red', border: 'none', color: 'white' } }}
                okButtonProps={{ style: { backgroundColor: 'green' } }}
            >
                <div className="flex flex-col">
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="equipmentType"
                        name="equipmentType"
                        type="text"
                        value={equipmentType}
                        onChange={(e) => setEquipmentType(e.target.value)}
                        placeholder="Equipment Type"
                        label="Equipment Type"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="equipmentSubType"
                        name="equipmentSubType"
                        type="text"
                        value={equipmentSubType}
                        onChange={(e) => setEquipmentSubType(e.target.value)}
                        placeholder="Equipment Sub Type"
                        label="Equipment Sub Type"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="fleetNo"
                        name="fleetNo"
                        type="text"
                        value={fleetNumber}
                        onChange={(e) => setFleetNumber(e.target.value)}
                        placeholder="Fleet Number"
                        label="Fleet Number"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="make"
                        name="make"
                        type="text"
                        value={equipmentMake}
                        onChange={(e) => setEquipmentMake(e.target.value)}
                        placeholder="Equipment Make"
                        label="Equipment Make"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="model"
                        name="model"
                        type="text"
                        value={equipmentModel}
                        onChange={(e) => setEquipmentModel(e.target.value)}
                        placeholder="Equipment Model"
                        label="Equipment Model"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="year"
                        name="year"
                        type="text"
                        value={equipmentYear}
                        onChange={(e) => setEquipmentYear(e.target.value)}
                        placeholder="Equipment Year"
                        label="Equipment Year"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <FloatingLabel
                        id="serial"
                        name="serial"
                        type="text"
                        value={serialNum}
                        onChange={(e) => setSerialNum(e.target.value)}
                        placeholder="Serial Number"
                        label="Serial Number"
                        className="relative ml-3"
                    />
                    <div className="my-2"></div>
                    <DatePicker
                        id="warranty"
                        name="warranty"
                        value={warranty ? moment(warranty, 'YYYY/MM/DD') : null}
                        onChange={(date, dateString) => {
                            const formattedWarranty = dateString
                                ? moment(dateString, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                                : null;

                            setWarranty(formattedWarranty);
                        }}
                        placeholder="Warranty Expiry Date"
                        className="relative ml-3"
                    />

                </div>
            </Modal>
            <br />
            <div className="flex justify-center">
                <div className="w-4/5">
                    <button
                        className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2"
                        onClick={() => {
                            setAddModalVisible(true);
                            const newEquipmentId = uuidv4();
                            setEquipmentId(newEquipmentId);
                        }}>
                        <span className="mr-2">Add Equipment</span>
                        <AiOutlinePlus size={20} />
                    </button>

                    <br />
                    <DataTable
                        columns={columns}
                        data={vehicleEquipmentData}
                        pagination={true}
                        striped={true}
                        highlightOnHover={true}
                        className="bg-white border border-[#f9787c] rounded-lg"
                        cellClassName="border-b border-[#f9787c] px-4 py-2"
                        headerClassName="bg-[#f9787c] text-white font-semibold"
                    />
                </div>
            </div>

        </div>


    )
}

export default VehicleEquipmentList;