import React, { useState, useEffect } from "react";
import { Menu, Badge, Tag } from "antd";
import { observer } from "mobx-react-lite";
import {
  ClockCircleOutlined,
  BellOutlined,
  SettingOutlined,
  FlagOutlined,
  MinusCircleOutlined
} from "@ant-design/icons";
import { appSettings } from "../../AppSettings";
import { useStore } from "../../Utilities/Stores/store";
import agent from "../../Utilities/API/agent";

const items = [
  {
    label: "Active",
    key: "Active",
    icon: <BellOutlined />,
  },
  {
    label: "QA",
    key: "QA",
    icon: <FlagOutlined />,
  },
  {
    label: "Delayed",
    key: "Delayed",
    icon: <ClockCircleOutlined />,
    disabled: false,
  },
  {
    label: "Workshop",
    key: "Workshop",
    icon: <SettingOutlined />,
  },
];

const MyMenu = ({
  setDelayed,
  setActive,
  setQA,
  setWorkshop,
  setBreakdowns,
  setLockedRows,
  lockedRows,
  setCurrent,
  removeBreakdowns
}) => {
  const [countData, setCountData] = useState([]);
  const { breakdownStore } = useStore();
  const { getFilter, setFilter, removeFilter } = breakdownStore;

  useEffect(() => {
    fetchData("/Breakdowns/GetBreakdownCount", setCountData);

    const interval = setInterval(() => {
      fetchData("/Breakdowns/GetBreakdownCount", setCountData);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getCountByStatus = (status) => {
    const countEntry = countData.find((entry) => entry.status === status);
    return countEntry ? countEntry.count : 0;
  };

  const fetchData = (url, setter) => {
    fetch(appSettings.apiUrl + url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setter(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const onClick = async (e) => {
    setLockedRows({});
    const selectedItem = items.find((item) => item.key === e.key);
    let result;
    if (selectedItem) {
      switch (e.key) {
        case "Delayed":
          result = await agent.BreakdownRequest.delayed();
          break;
        case "QA":
          result = await agent.BreakdownRequest.qa();
          break;
        case "Workshop":
          result = await agent.BreakdownRequest.workshop();
          break;
        case "Active":
        default:
          result = await agent.BreakdownRequest.activeBreakdownList();
          break;
      }
      setFilter(e.key);
    }
    setBreakdowns(result);
    setCurrent(e.key);
  };

  const handleCurrentViewTagClick = (filter) => {
    removeFilter(filter);
    removeBreakdowns(filter);
  };

  return (
    <div>
      <Menu
        mode="inline"
        onClick={onClick}
        selectedKeys={[getFilter]}
        style={{ display: "flex", alignItems: "center" }}
      >
        {items.map((item) => (
          <Menu.Item
            key={item.key}
            icon={item.icon}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span style={{ flex: 1 }}>{item.label}</span>
            <Badge
              count={getCountByStatus(item.label)}
              showZero
              overflowCount={999}
              style={{ marginTop: "-3px", marginLeft: "9px" }}
            />
          </Menu.Item>
        ))}
      </Menu>
      {/* Render the current view tag */}
      <div style={{ marginTop: "10px" }}>
        Current View :&nbsp; 
        {getFilter.map((item) => (
          <Tag key={item} onClick={() => handleCurrentViewTagClick(item)}>
            <MinusCircleOutlined /> {item}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default observer(MyMenu);
