import Navbar from './Navbar';
import SecondaryNavbar from './SecondaryNavbar';

const UpdatedNavbar = () => {
    return (
        <div>
            <Navbar/>
            <SecondaryNavbar/>
        </div>
    );
};

export default UpdatedNavbar;