import React from 'react';
import BreakdownDetailsInfo from '../../components/breakdowns/BreakdownDetailsInfo';
import DriverDetailsHeader from '../../components/drivers/DriverDetailsHeader';
import { Button } from 'antd';

const BreakdownDetailsContainer = ({ breakdownDetails, handleOpenModal }) => {
  return (
    <div>
      <DriverDetailsHeader />
      <div className="rounded-lg border p-2 mb-4 relative border border-gray-300 text-gray-800 bg-white">
        <BreakdownDetailsInfo breakdownDetails={breakdownDetails} />
      </div>
    </div>
  );
};

export default BreakdownDetailsContainer;
