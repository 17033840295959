import React from 'react';

function FloatingLabel({ id, name, type, value, onChange, onClick, placeholder, label, className }) {
  return (
    <div className={className}>
      <input
        id={id}
        name={name}
        type={type}
        className="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 text-sm placeholder-transparent focus:outline-none focus:border-rose-600"
        value={value}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
      />
      <label
        htmlFor={id}
        className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:xs peer-placeholder-shown:text-gray-900 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
      >
        {label}
      </label>
    </div>
  );
}

export default FloatingLabel;
