import React, { useEffect, useState } from "react";
import { Modal, Button, Descriptions, Input, Select, Col, Row } from "antd";
import PanicNoteList from "./PanicNoteList";
import agent from "../../Utilities/API/agent";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Utilities/Stores/store";

const { Option } = Select;

const CallMeModal = ({ panicId, visible, onClose }) => {
  const [modalVisible, setModalVisible] = useState(visible);
  const { TextArea } = Input;
  const [panicNote, setPanicNote] = useState("");
  const [pleaseCallData, setPleaseCallData] = useState(); // Initialize state for PleaseCall data
  const [updatedPanicNotes, setUpdatedPanicNotes] = useState([]);
  const { signalRStore, notificationStore } = useStore();
  const [currentUser,setCurrentUSer] = useState();
  const { assignCallMe } = signalRStore;
  const { loadMyNotifications } = notificationStore;

  useEffect(() => {
    const fetchData = async () => {
      const user = await JSON.parse(sessionStorage.getItem('currentUser'));
      setCurrentUSer(user);
      const data = await agent.Notifications.getCallMeById(panicId);
      setPleaseCallData(data); // Set PleaseCall data
      setModalVisible(true);
      if (data.status == 0) {
        assignCallMe(data);
      }
    };
    fetchData();
  }, [panicId]);

  const handleClose = () => {
    setModalVisible(false);
    onClose();
  };

  const handleSaveNote = async () => {
    
    try {
    
     
      const noteResponse = await agent.Notifications.addCallMeNote({
        Id: "",
        PleaseCallId: pleaseCallData.id,
        Note: panicNote,
        CreateDateTime: new Date().toISOString(),
        UserModelId: "",
      });
      const updateResponse = await agent.Notifications.updateCallMe({
        ...pleaseCallData,
        PleaseCallStatus: pleaseCallData.pleaseCallStatus,
        AssignedUser: currentUser.loginId,
      });

      const updatedPanicNotes = await agent.Notifications.callMeNotes(
        pleaseCallData.id
      );
      setUpdatedPanicNotes(updatedPanicNotes);
      loadMyNotifications();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  useEffect(() => {
    const fetchPanicNotes = async () => {
      try {
        const initialPanicNotes = await agent.Notifications.callMeNotes(
          pleaseCallData.id
        );
        setUpdatedPanicNotes(initialPanicNotes);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (modalVisible && pleaseCallData) {
      fetchPanicNotes();
    }
  }, [modalVisible, panicId, pleaseCallData]);

  return (
    currentUser && pleaseCallData && (
      <Modal
        title="Call Me Details"
        visible={modalVisible}
        onCancel={handleClose}
        footer={[
          <Button key="close" onClick={handleClose}>
            Close
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveNote}>
            Save Note
          </Button>,
        ]}
        width={800}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Please Call Reference">
                {pleaseCallData.pleaseCallReference}
              </Descriptions.Item>
              {/* Add other fields as needed */}
              <Descriptions.Item label="Please Call Status">
                <Select
                  defaultValue={pleaseCallData.pleaseCallStatus.toString()}
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    setPleaseCallData({ ...pleaseCallData, pleaseCallStatus: value })
                  }
                >
                  <Option value="0">Open</Option>
                  <Option value="1">In Progress</Option>
                  <Option value="2">Closed</Option>
                  <Option value="3">Canceled</Option>
                  <Option value="4">Resolved</Option>
                </Select>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          {/* Add another column for other fields */}
        </Row>
        <TextArea
          placeholder="Enter call me note..."
          autoSize={{ minRows: 2, maxRows: 3 }}
          value={panicNote}
          onChange={(e) => setPanicNote(e.target.value)}
        />
        <div style={{ marginTop: "10px" }}>
          <h2>Call Me Notes</h2>
          <PanicNoteList
            panicId={pleaseCallData.id}
            notificationType={2}
            updatedPanicNotes={updatedPanicNotes}
          />
        </div>
      </Modal>
    )
  );
};

export default observer(CallMeModal);
