import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { ListGroup } from 'react-bootstrap';
import { appSettings } from '../../AppSettings';

const MechanicList = ({ 
  mechanic,
  mechanicStatusValue,
  handleMechStatusChange,
  handleButtonClick,
  companiesWithNoMechanics,
  handleRemoveButtonClick,
  rules,
  setRules
}) => {
  const MechanicStatus = {
    'Accepted': 0,
    'Rejected': 1,
    'Pending': 2,
    'No Response': 3,
    'Released': 4,
    'Remove': 5
  };

  const fetchData = (url, setter, parameter = null) => {
    const apiUrl = appSettings.apiUrl + url + (parameter ? `/${parameter}` : '');
  
    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setter(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };


  //This keeps looping - Find a better way.
  
  /*if (companiesWithNoMechanics && companiesWithNoMechanics.length > 0) {
    const id = companiesWithNoMechanics[0].supplierCompanyId;

    if(id)
    {
      fetchData('/Breakdowns/GetCompanyRules', setRules, id);
    }
  }*/

  

  return (
    <>
      {mechanic && mechanic.length > 0 || companiesWithNoMechanics && companiesWithNoMechanics.length > 0 ? (
        <ListGroup className="scrollable-list" style={{ height: 'calc(100% - 500px)', overflowY: 'auto' }} id='MechanicDetailsListID'>
          {mechanic && mechanic
            .sort((a,b) => a.status - b.status)
            .map((mechanicItem) => (
              <ListGroup.Item key={mechanicItem.id} className="rounded-lg border p-1 mb-4 rounded-lg border p-2 mb-4  relative border border-gray-300 text-gray-800 bg-white">
                <div className="flex flex-col">
                  <div className="flex mb-2">
                    <p className="mr-2">Name:</p>
                    <p className="font-bold">{mechanicItem.fullname}</p>
                  </div>
                  <div className="flex mb-2">
                    <p className="mr-2">Number:</p>
                    <p className="font-bold">{mechanicItem.mobileNum}</p>
                  </div>
                  <div className="flex mb-2">
                    <p className="mr-2">Company:</p>
                    <p className="font-bold">{mechanicItem.companyName}</p>
                  </div>
                  <div className="flex mb-2">
                    <p className="mr-2 pt-2">Status:</p>
                    <select className='pt-1' value={mechanicStatusValue !== null ? mechanicStatusValue : mechanicItem.status} onChange={(event) => handleMechStatusChange(event, mechanicItem.id)}>
                      {Object.entries(MechanicStatus).map(([statusLabel, statusValue]) => (
                        <option key={statusValue} value={statusValue}>
                          {statusLabel}
                        </option>
                      ))}
                    </select>
                    <Button
                      type="primary"
                      onClick={(event) => handleButtonClick(event, mechanicItem.id, mechanicItem.status, mechanicItem.fullname, mechanicItem.companyName)}
                      style={{ backgroundColor: '#4CAF50', borderColor: 'green', width: '100px', marginLeft: '20px' }}
                      className='pl-1'
                    >
                      <span style={{ color: 'white' }}>Update Status</span>
                    </Button>
                  </div>
                </div>
                <hr className="my-1" />
                <div className="flex mb-2">
                  <p className="mr-2">Last Location Time :</p>
                  <p className="font-bold">
                    {new Date(mechanicItem.lastTime).toLocaleString('en-ZA', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit'
                    })}
                  </p>
                </div>
              </ListGroup.Item>
            ))}
          {companiesWithNoMechanics && companiesWithNoMechanics.length > 0 && (
            <>
              {companiesWithNoMechanics.map((company) => (
                <ListGroup.Item key={company.id} className="rounded-lg border p-1 mb-4 rounded-lg border p-2 mb-4  relative border border-gray-300 text-gray-800 bg-white">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: '10px' }}>{company.companyName}</p>
                    <Button
                      type="primary"
                      onClick={(event) => handleRemoveButtonClick(event, company.id, company.companyName, company.supplierCompanyId, company.breakdownModelId)}
                      style={{ backgroundColor: '#4CAF50', borderColor: 'green', width: '140px' }}
                    >
                      <span style={{ color: 'white' }}>Remove company</span>
                    </Button>
                  </div>
                </ListGroup.Item>
              ))}
            </>
          )}

        </ListGroup>
      ) : (
        <p>No mechanic details available.</p>
      )}
    </>
  );
};

export default MechanicList;
