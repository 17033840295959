import React from 'react';
import { Tabs } from 'antd';
import { ListGroup } from 'react-bootstrap';
import Display from '../../Utilities/GoogleMaps/Display';
import { LoadingOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

function BreakdownHistoryTab({ activeTab, handleTabChange, breakdownHistory, LatestLatitude, LatestLongitude, breakdownLatitude, breakdownLongitude, rules, mechanicRules, breakdownAddress, onAddressUpdate,breakdownId }) {
  if (!Array.isArray(breakdownHistory)) {
    return <div>No breakdown history available</div>;
  }

  const onPindrop = (item) => {
    onAddressUpdate();
    
  }

  return (
    <div className="flex-grow border border-black rounded-lg border p-2 mb-0  relative border border-gray-300 text-gray-800 bg-white">
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Map" key="3">
          <div>{breakdownAddress}</div>
          <div>
            
            {breakdownLatitude && breakdownLongitude && (
              <Display
                latitude={parseFloat(LatestLatitude)}
                longitude={parseFloat(LatestLongitude)}
                breakdownLatitude={parseFloat(breakdownLatitude)}
                breakdownLongitude={parseFloat(breakdownLongitude)}
                breakdownId={breakdownId}
                onPinDrop={onPindrop}
              />
            )}
          </div>
        </TabPane>
        <TabPane tab="Breakdown History" key="1">
          {breakdownHistory &&  (
            <ListGroup className="scrollable-list" style={{ maxHeight: '300px', overflowY: 'auto' }} id='breakdownListID'>
              {breakdownHistory.map((history, index) => (
                <ListGroup.Item key={index} style={{ fontSize: '13px' }}>
                  <span>
                    <span style={{ fontWeight: 'normal' }}>Breakdown Reference:</span>{' '}
                    <span style={{ fontWeight: 'bold' }}>{history.breakdownReference}</span>
                  </span>
                  <br />
                  <span>
                    <span style={{ fontWeight: 'normal' }}>Description:</span>{' '}
                    <span style={{ fontWeight: 'bold' }}>{history.description}</span>
                  </span>
                  <br />
                  <span>
                    <span style={{ fontWeight: 'normal' }}>Correction:</span>{' '}
                    <span style={{ fontWeight: 'bold' }}>{history.correction}</span>
                  </span>
                  <br />
                  <span>
                    <span style={{ fontWeight: 'normal' }}>Date Created:</span>{' '}
                    <span style={{ fontWeight: 'bold' }}> {new Date(history.dateTimeOffset).toLocaleString('en-ZA')}</span>
                  </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </TabPane>
        <TabPane tab="Rules" key="2">
              <div>
            {rules && rules.length > 0 ? (
              <div>
                {rules.map((rule, index) => (
                  <div key={index}>
                    <div dangerouslySetInnerHTML={{ __html: rule.rules }} />
                    <br />
                    <br />
                    <br />
                  </div>
                ))}
              </div>
            ) : (
              <div>No rules available</div>
            )}
          </div>
          {/* <div>
            <div>
              <div>
                {rules && rules.length > 0  && (
                  <div dangerouslySetInnerHTML={{ __html: rules[0].rules }} />
                )}
                <br/>
                {mechanicRules && mechanicRules.length > 0  && (
                  <div dangerouslySetInnerHTML={{ __html: mechanicRules[0].rules }} />
                )}
              </div>

            </div>
          </div> */}

        </TabPane>

      </Tabs>
    </div>
  );
}

export default BreakdownHistoryTab;
