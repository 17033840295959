import React, { useState } from 'react';
import { Button, Input, Table } from 'antd';


const { Search } = Input;

const SearchBreakdownLog = () => {
  const [dataSource, setDataSource] = useState([]);
  
  // Function to handle search
  const handleSearch = (value) => {
    // Your logic to fetch breakdown data based on the search value
    // For demonstration, I'm just setting some dummy data
    const data = [
      { key: '1', breakdown: 'Breakdown 1', log: 'Log 1' },
      { key: '2', breakdown: 'Breakdown 2', log: 'Log 2' },
      { key: '3', breakdown: 'Breakdown 3', log: 'Log 3' },
    ];
    setDataSource(data);
  };
  
  // Function to export data to Excel
  const exportToExcel = () => {

  };

  const columns = [
    {
      title: 'Breakdown',
      dataIndex: 'breakdown',
      key: 'breakdown',
    },
    {
      title: 'Log',
      dataIndex: 'log',
      key: 'log',
    },
  ];

  return (
    <div>
      <Search
        placeholder="Input breakdown"
        allowClear
        enterButton="Search"
        size="large"
        onSearch={handleSearch}
      />
      <Table dataSource={dataSource} columns={columns} />
      <Button type="primary" onClick={exportToExcel}>Export to Excel</Button>
    </div>
  );
};

export default SearchBreakdownLog;
