import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Row, Col, Button, Input, Modal, Form, Select } from "antd";
import SubmenuFilter from "../../../components/SubmenuFilter";
import Navbar from "../../../components/Navbar";
import agent from "../../../Utilities/API/agent";
import { useNavigate } from "react-router";

export default function PortalSecureSupplierList() {
  const navigate = useNavigate();

  const [editorOpen, setEditorOpen] = useState(false);
  const [userEditorOpen, setUserEditorOpen] = useState(false);

  const [supplierData, setSupplierData] = useState([]);
  const [distinctCompanies, setDistinctCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  //Company Editor Variables
  const [companyId, setCompanyId] = useState("");
  const [name, setName] = useState("");
  const [isHoldingCompany, setisHoldingCompany] = useState(0);
  const [holdingCompanyId, setHoldingCompanyId] = useState("");
  const [deleted, setDeleted] = useState("");
  const [address, setAddress] = useState("");
  const [cell, setCell] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [companyType, setCompanyType] = useState(1);
  const [holdingCompanyList, setHoldingCompanyList] = useState([]);

  const [userId, setUserId] = useState("");
  const [userCompanyId, setUserCompanyId] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [userUsername, setUserUsername] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userLoginId, setUserLoginId] = useState("");

  //Define Data table columns
  const columns = [
    {
      name: "Company",
      selector: (row) => row.companyName,
      sortable: true,
      cell: (row) => (
        <Button
          type="link"
          onClick={() => {
            editCompany(row);
          }}
        >
          {row.companyName}
        </Button>
      ),
    },
    {
      name: "User",
      selector: (row) => row.supplierName,
      sortable: true,
      cell: (row) => (
        <Button type="link" onClick={() => editSupplier(row)}>
          {row.supplierName}
        </Button>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button type="primary" onClick={() => editMechanics(row)}>
            <span className="mr-2">Mechanics</span>
          </Button>
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row, rowIndex) => rowIndex % 2 === 1,
      style: {
        backgroundColor: "green",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    // You can also pass a callback to style for additional customization
    {
      when: (row) => row.calories < 400,
      style: (row) => ({ backgroundColor: row.isSpecial ? "pink" : "inerit" }),
    },
  ];

  useEffect(() => {
    const startFunction = async () => {
      const response = await agent.Supplier.getAllSuppliersWithCompany(0);
      setSupplierData(response);

      const distinctArray = response.reduce((acc, current) => {
        const found = acc.find(
          (item) => item.companyName === current.companyName
        );
        if (!found) {
          acc.push(current);
        }
        return acc;
      }, []);

      setDistinctCompanies(distinctArray);
    };

    startFunction();
  }, []);

  const newCompany = async () => {
    const result = await agent.Supplier.getSupplierHoldingCompanies();
    setHoldingCompanyList(result);

    setEditorOpen(true);
  };

  const handleCompanyEditorClose = () => {
    setEditorOpen(false);
  };

  const handleUserEditorClose = () => {
    setUserEditorOpen(false);
  };

  const handleUserSave = async () => {
    const companyRow = supplierData.find(
      (item) => item.companyId === userCompanyId
    );

    const userDetails = {
      id: userId,
      companyId: userCompanyId,
      companyName: companyRow.companyName,
      fullName: userFullName,
      email: userEmail,
      mobileNumber: userMobileNumber,
      username: userUsername,
      password: userPassword,
      loginId: userLoginId,
    };

    if (userId === "") {
      await agent.Supplier.addSupplier(userDetails);
    } else {
      await agent.Supplier.updateSupplier(userDetails);
    }

    const response = await agent.Supplier.getAllSuppliersWithCompany(0);
    setSupplierData(response);
    // const distinctArray = response.reduce((response, current) => {
    //     const found = acc.find(item => item.companyName === current.companyName);
    //     if (!found) {
    //       acc.push(current);
    //     }
    //     return acc;
    //   }, []);

    //    setDistinctCompanies(distinctArray);

    setUserEditorOpen(false);

    setUserFullName("");
    setUserId("");
    setUserCompanyId("");
    setUserEmail("");
    setUserMobileNumber("");
    setUserUsername("");
    setUserPassword("");
    setUserLoginId("");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {}, [searchTerm]);
  const handleSave = () => {
    saveCompany();
  };

  const saveCompany = async () => {
    const companyDetails = {
      id: companyId,
      name: name,
      holdingCompanyId: holdingCompanyId,
      isHoldingCompany: isHoldingCompany,
      companyType: companyType,
      address: address,
      latitude: latitude,
      longitude: longitude,
      cell: cell,
      contactName: contactName,
      contactNumber: contactNumber,
      contactEmail: contactEmail,
    };

    if (companyId === "") {
      await agent.Company.addCompany(companyDetails);
    } else {
      await agent.Company.updateCompany(companyDetails);
    }

    const response = await agent.Supplier.getAllSuppliersWithCompany(0);
    setSupplierData(response);
    const distinctArray = response.reduce((acc, current) => {
      const found = acc.find(
        (item) => item.companyName === current.companyName
      );
      if (!found) {
        acc.push(current);
      }
      return acc;
    }, []);

    setDistinctCompanies(distinctArray);

    const result = await agent.Supplier.getSupplierHoldingCompanies();
    setHoldingCompanyList(result);

    setCompanyId("");
    setName("");
    setAddress("");
    setCell("");
    setContactEmail("");
    setContactName("");
    setContactNumber("");
    setisHoldingCompany("");
    setHoldingCompanyId("");
    setLongitude("");
    setLatitude("");
    setEditorOpen(false);
  };

  function editMechanics(row) {
    navigate("/portal/secure/MechanicDetails", {
      state: {
        breakdownId: row.supplierId,
        breakdownName: row.companyName,
      },
    });
  }

  const editCompany = async (row) => {
    const result = await agent.Supplier.getSupplierHoldingCompanies();
    setHoldingCompanyList(result);
    const company = await agent.Company.getCompanyById(row.companyId);

    setCompanyId(company.id);
    setName(company.name);
    setAddress(company.address);
    setCell(company.cell);
    setContactEmail(company.contactEmail);
    setContactName(company.contactName);
    setContactNumber(company.contactNumber);
    setisHoldingCompany(company.isHoldingCompany);
    setHoldingCompanyId(company.holdingCompanyId);
    setLatitude(company.latitude);
    setLongitude(company.longitude);
    setEditorOpen(true);
  };

  const editSupplier = async (row) => {
    const supplier = await agent.Supplier.getSupplier(row.supplierId);
    setUserFullName(supplier.fullName);
    setUserId(supplier.id);
    setUserCompanyId(supplier.companyId);
    setUserEmail(supplier.email);
    setUserMobileNumber(supplier.mobileNumber);
    setUserUsername(supplier.username);
    setUserPassword(supplier.password);
    setUserLoginId(supplier.loginId);
    setLatitude(supplier.latitude);
    setLongitude(supplier.longitude);
    setUserEditorOpen(true);
  };

  const filteredSuppliers = supplierData.filter((company) => {
    if (searchTerm && searchTerm.length > 0) {
      return company.supplierName
        .toLowerCase()
        .includes(searchTerm?.toLowerCase());
    } else return supplierData;
  });

  return (
    <div>
      <div>
        <Navbar />
        <SubmenuFilter />
      </div>
      <div style={{ margin: "0 16px" }}>
        <Row style={{ marginTop: "10px" }}>
          <Col span={3}>
            <Button type="primary" onClick={newCompany}>
              New Company
            </Button>
          </Col>
          <Col span={3}>
            <Button
              type="primary"
              onClick={() => {
                setUserEditorOpen(true);
              }}
            >
              New User
            </Button>
          </Col>
          <Col>
            <input
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="flex-grow py-2 px-4 ml-2 bg-white text-gray-700 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out"
              style={{ flex: 1, marginLeft: "5%", width:1000 }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={24} style={{ border: "solid 1px #c2c2c2" }}>
            <DataTable
              columns={columns}
              //data={supplierData}
              data={filteredSuppliers}
              conditionalRowStyles={conditionalRowStyles}
              striped={true}
            />
          </Col>
        </Row>
      </div>

      <Modal
        open={editorOpen}
        onCancel={handleCompanyEditorClose}
        onOk={handleSave}
      >
        <Row style={sectionHeader}>
          <Col span="24">ADD SUPPLIER COMPANY </Col>
        </Row>
        <Row>
          <Col span={6} style={dataInputColumn}>
            <Form.Item>
              Is a Holding Company <br />
              <Select
                options={[
                  { value: 1, label: "Yes" },
                  { value: 0, label: "No" },
                ]}
                value={isHoldingCompany}
              />
            </Form.Item>
          </Col>
          <Col span={16} style={dataInputColumn}>
            <Form.Item>
              Select Holding Company <br />
              <Select
                defaultValue={holdingCompanyId}
                options={holdingCompanyList.map((c) => ({
                  value: c.id,
                  label: c.name,
                }))}
                onChange={(value) => setUserCompanyId(value)}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={dataInputColumn}>
            <Form.Item>
              Name <br />
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={dataInputColumn}>
            <Form.Item>
              Address <br />
              <Input
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} style={dataInputColumn}>
            <Form.Item>
              Office Number <br />
              <Input
                value={cell}
                onChange={(e) => {
                  setCell(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={sectionHeader}>
          <Col span="24">CONTACT PERSON</Col>
        </Row>
        <Row>
          <Col span={6} style={dataInputColumn}>
            <Form.Item>
              Name <br />
              <Input
                value={contactName}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={dataInputColumn}>
            <Form.Item>
              Number <br />
              <Input
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={dataInputColumn}>
            <Form.Item>
              Email <br />
              <Input
                value={contactEmail}
                onChange={(e) => {
                  setContactEmail(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={userEditorOpen}
        onCancel={handleUserEditorClose}
        onOk={handleUserSave}
      >
        <Row style={sectionHeader}>
          <Col span="24">ADD USER </Col>
        </Row>
        <Row>
          <Col span={24} style={dataInputColumn}>
            <Form.Item>
              Company <br />
              <Select
                options={distinctCompanies.map((s) => ({
                  value: s.companyId,
                  label: s.companyName,
                }))}
                defaultValue={userCompanyId}
                onChange={(value) => setUserCompanyId(value)}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={dataInputColumn}>
            <Form.Item>
              Name <br />
              <Input
                value={userFullName}
                onChange={(e) => {
                  setUserFullName(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={dataInputColumn}>
            <Form.Item>
              Contact Number <br />
              <Input
                value={userMobileNumber}
                onChange={(e) => {
                  setUserMobileNumber(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={dataInputColumn}>
            <Form.Item>
              Email <br />
              <Input
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12} style={dataInputColumn}>
            <Form.Item>
              Username <br />
              <Input
                value={userUsername}
                onChange={(e) => {
                  setUserUsername(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={dataInputColumn}>
            <Form.Item>
              Email <br />
              <Input
                type="password"
                value={userPassword}
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};
