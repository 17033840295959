import React from 'react';
import { Modal, Button, Upload} from 'antd';
import ClipLoader from "react-spinners/ClipLoader";
import { CloseOutlined } from '@ant-design/icons';

const BreakdownImagesModal = ({
    imageModal,
    handleImageCloseModal,
    uploadedImageFiles,
    frontendImageUpload,
    uploadBreakdownDocument,
    handleImageDeleteFile,
    Imageloading,
    uploadBreakdownImages,
    breakdownImageList,
    openFullScreen,
    selectedZoomedImage,
    closeFullScreen,
    isImagesLoaded
}) => {

    
    return (
        <Modal
            title={<h2 className="text-center">Breakdown Images</h2>}
            open={imageModal}
            onCancel={handleImageCloseModal}
            footer={null}
            centered
            width="75vw"
            className="pt-0 pb-0"
            height="80vh"
        >
            <div>
                <div className="flex justify-end mb-3">
                    <Upload beforeUpload={(file, fileList) => frontendImageUpload(file, fileList)}>
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
                            onClick={uploadBreakdownDocument}
                        >
                            Add
                        </Button>
                    </Upload>
                </div >

                {uploadedImageFiles && uploadedImageFiles.length > 0 && (
                    <div className="flex justify-end mb-0">
                        <ul>
                            {uploadedImageFiles.map((file, index) => (
                                <li key={index}>
                                    {file.name}
                                    <Button onClick={() => handleImageDeleteFile(index)}
                                        className='ml-3 mb-2'>Delete</Button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div className="flex justify-end mb-0">
                    {Imageloading ? (

                        <ClipLoader
                            color="#00FF00"
                            loading={true}
                            size={18}
                            css="display: inline-block; vertical-align: middle;"
                        />

                    ) : null}
                </div>
                {uploadedImageFiles && uploadedImageFiles.length > 0 && (
                    <div className="flex justify-end mb-3">
                        <Button
                            type="primary"
                            style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
                            onClick={uploadBreakdownImages}
                        >
                            Save
                        </Button>
                    </div>
                )}

                {breakdownImageList.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="loader-container">
                        {Imageloading ? (

                            <ClipLoader
                                color="#00FF00"
                                loading={true}
                                size={18}
                                css="display: inline-block; vertical-align: middle;"
                            />

                            ) : 'NO BREAKDOWN IMAGES TO DISPLAY'}
                            
                        </div>

                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '700px', overflowY: 'auto' }}>
                        {breakdownImageList && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', overflowX: 'scroll', maxWidth: '100%' }}>
                                {breakdownImageList.map((imageData, index) => (
                                    <div key={index} style={{ flexBasis: '33%', padding: '5px', width: '300px', height: '200px', border: '1px solid #ccc' }}>
                                        <img
                                            src={imageData}
                                            alt={`Breakdown Image ${index + 1}`}
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                            onClick={() => openFullScreen(imageData)}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}

                        {selectedZoomedImage && (
                            <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.9)', zIndex: 9999 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <img
                                        src={selectedZoomedImage}
                                        alt="Full Screen Image"
                                        style={{
                                            maxHeight: '100%',
                                            maxWidth: '100%',
                                            cursor: 'pointer',
                                            transition: 'transform 0.2s ease-in-out',
                                        }}
                                    />
                                    <button
                                    style={{ position: 'absolute', top: '15px', right: '15px', background: 'none', border: 'none', color: 'white', cursor: 'pointer' }}
                                    onClick={closeFullScreen}
                                >
                                    <CloseOutlined />
                                </button>
                                </div>
                            </div>
                        )}

                    </div>
                )}
            </div>
        </Modal>
    );
};

export default BreakdownImagesModal;
