import React from 'react';
import { Modal } from 'antd';

const ImagePopupModal = ({ imageModalVisible, setImageModalVisible, setImageLoading, selectedImageUrl }) => {
  return (
    <Modal
      title="Image Popup"
      open={imageModalVisible}
      onCancel={() => {
        setImageModalVisible(false);
        setImageLoading(false);
      }}
      footer={null}
      centered
      destroyOnClose
      width="80%"
      bodyStyle={{ textAlign: 'center' }}
    >
      {selectedImageUrl && <img src={selectedImageUrl} alt="Breakdown" style={{ maxWidth: '100%', maxHeight: '80vh' }} />}
    </Modal>
  );
};

export default ImagePopupModal;
