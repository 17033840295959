import { useLocation } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import SubmenuFilter from '../../components/SubmenuFilter';
import Navbar from "../../components/Navbar";
import { DataCountContext, DataCountProvider } from '../../components/DataCountContext';
import FloatingLabel from '../../components/FloatingLabel';
import { Form } from 'antd';

const UserDetails = () => {

    const location = useLocation();
    const dataCount = useContext(DataCountContext) ?? 5;
    const id = location.state.Id;
    const flag = location.state.Flag;
    const email = location.state.Email;
    const fullName = location.state.FullName;
    const idNumber = location.state.IdNumber;
    const mobileNumber = location.state.Mobile;
    const username = location.state.UserName;
    const password = location.state.Password;

    const [emailAddress, setEmailAddress] = ('');
    const [name, setName] = ('');
    const [idnum, setID] = ('');
    const [mobile, setMobile] = ('');
    const [user, setUser] = ('');
    const [pass, setPass] = ('');

    return (

        <div className="flex flex-col">
            <DataCountProvider>
                <div>
                    <Navbar />
                    <SubmenuFilter dataCount={dataCount} />
                    <div></div>
                </div>
            </DataCountProvider>


            <div>
                <div className="flex justify-center items-center flex-column mt-9 pt-8 relative  border border-black p-5 ">
                    <div className="w-80">
                        <Form.Item>
                            <FloatingLabel
                                id="email"
                                name="email"
                                type="text"
                                value={email}
                                onChange={(e) => setEmailAddress(e.target.value)}
                                placeholder="Email Address"
                                label="Email Address"
                                className="relative ml-3"
                            />
                        </Form.Item>
                    </div>

                    <div className="w-80">
                        <Form.Item>
                            <FloatingLabel
                                id="fullName"
                                name="fullName"
                                type="text"
                                value={fullName}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="full Name"
                                label="full Name"
                                className="relative ml-3"
                            />
                        </Form.Item>
                    </div>

                    <div className="w-80">
                        <Form.Item>
                            <FloatingLabel
                                id="idnum"
                                name="idnum"
                                type="text"
                                value={idNumber}
                                onChange={(e) => setID(e.target.value)}
                                placeholder="Identification Number"
                                label="Identification Number"
                                className="relative ml-3"
                            />
                        </Form.Item>
                    </div>

                    <div className="w-80">
                        <Form.Item>
                            <FloatingLabel
                                id="mobileNumber"
                                name="mobileNumber"
                                type="text"
                                value={mobileNumber}
                                onChange={(e) => setMobile(e.target.value)}
                                placeholder="Mobile Number"
                                label="Mobile Number"
                                className="relative ml-3"
                            />
                        </Form.Item>
                    </div>

                    <div className="w-80">
                        <Form.Item>
                            <FloatingLabel
                                id="userName"
                                name="user Name"
                                type="text"
                                value={username}
                                onChange={(e) => setUser(e.target.value)}
                                placeholder="user Name"
                                label="user Name"
                                className="relative ml-3"
                            />
                        </Form.Item>
                    </div>

                    <div className="w-80">
                        <Form.Item>
                            <FloatingLabel
                                id="password"
                                name="password"
                                type="text"
                                value={password}
                                onChange={(e) => setPass(e.target.value)}
                                placeholder="Password"
                                label="Password"
                                className="relative ml-3"
                            />
                        </Form.Item>
                    </div >
                </div>
            </div>



        </div>





    )
}

export default UserDetails;