import { useContext, useState, useEffect } from "react";
import SubmenuFilter from "../../components/SubmenuFilter";
import Navbar from "../../components/Navbar";
import {
  DataCountContext,
  DataCountProvider,
} from "../../components/DataCountContext";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { appSettings } from "../../AppSettings";
import CompaniesManageRules from "../../components/companies/ManageRules";
import useCaseInsensitiveSort from "../../components/utilities/useCaseInsensitiveSort";
import PortalSecureCompanyAddCompany from "./Company/AddCompany";
import PortalSecureCompanyAddRules from "./Rules/AddRules";
import { AiOutlinePlus, AiFillEdit } from "react-icons/ai";
import {  Link } from 'react-router-dom';
import PortalSecureCompanyEditCompany from '../Secure/Company/EditCompany'

const CustomerOnboarding = () => {
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("name");
  const [addCompany, setAddCompany] = useState();
  const [editCompanyVisble, setEditCompanyVisble] = useState();
  const [companyId, setCompanyId] = useState();
  const [addCompanyRules, setaddCompanyRules] = useState();
  

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortField: "id",
      sortable: true,
      omit: true,
    },
    {
      name: "Company Name",
  //    selector: (row) => row.name,
      //sortFunction: caseInsensitiveSortFullName,
      
      selector: (row) => (
        <Link onClick={() => handleCompanyClick(row)}>{row.name}</Link>
      ),
      sortField: "name",
      sortable: true,
      omit: false,
      width: "40%",
    },
    {
      name: "Actions",
      width: "60%",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/*<button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={() => editInspection(row)}
          >
            <span className="mr-2">Edit Insp..</span>
            <AiFillEdit size={20} />
      </button>*/}

          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={() => editUser(row)}
          >
            <span className="mr-2">Users</span>
            <AiFillEdit size={20} />
          </button>
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={() => editRules(row)}
          >
            <span className="mr-2">Rules</span>
            <AiFillEdit size={20} />
          </button>
          {/* <CompaniesManageRules companyData={row} /> */}
        </div>
      ),
    },
  ];

  const [rnrCompanies, setRnrCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  async function fetchHoldingCompanies() {
    try {
      const response = await fetch(
        appSettings.apiUrl + "/Company/GetCompanyList/0"
      );
      const data = await response.json();
      setRnrCompanies(data);
    } catch (error) {
      console.error("Error fetching holding companies:", error);

    }
  }
  const handleCompanyClick = async (row) => {
    setEditCompanyVisble(true);
    setCompanyId(row.id)
  };
  const handleCloseCompanyClick = () => {
    setEditCompanyVisble(false);
    fetchHoldingCompanies();
  };

  const handleCloseRules = () => {
    setaddCompanyRules(false);
    fetchHoldingCompanies();
    setCompanyId(null);
  }

  async function editInspection() {}

  async function addInspection() {}

  async function removeInspection() {}

  async function getvehicles() {}

  async function prefferedSupplier() {}

  async function editUser(row) {
    navigate("/portal/secure/UserDetails", {
      state: {
        breakdownId: row.id,
        breakdownName: row.name,
      },
    });
  }
  const editRules = (row) => {
    setCompanyId(row.id);
    setaddCompanyRules(true);
  }

  const filteredCompanies = rnrCompanies.filter((company) => {
    return company.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  
  const handleCloseCompany = () => {
    setAddCompany(false);
    fetchHoldingCompanies();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    fetchHoldingCompanies();
  }, []);

  async function AddCompany() {
    setAddCompany(true);
  }

  return (
    <>
      <div className="flex flex-col">
        <DataCountProvider>
          <div>
            <Navbar />
            {currentUser ? (
              <>
                <SubmenuFilter dataCount={dataCount} />
              </>
            ) : null}
            <div></div>
          </div>
        </DataCountProvider>
        <br />

        {currentUser ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1%",
                paddingRight: "1%",
                paddingTop: "2px",
              }}
            >
              <button
                className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center  ml-2"
                onClick={AddCompany}
              >
                <span className="mr-2">Add Company</span>
                <AiOutlinePlus />
              </button>

              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="flex-grow py-2 px-4 ml-2 bg-white text-gray-700 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-200 ease-in-out"
                style={{ flex: 1, marginLeft: "5%" }}
              />
            </div>
            <br />

            <div className="pl-5 pr-5">
              <DataTable
                columns={columns}
                data={filteredCompanies}
                pagination={true}
                striped={true}
                highlightOnHover={true}
                className="bg-white border border-[#f9787c] rounded-lg"
                cellClassName="border-b border-[#f9787c] px-4 py-2"
                headerClassName="bg-[#f9787c] text-white font-semibold"
                defaultSortFieldId={2}
                defaultSortAsc={false}
              />
            </div>
          </>
        ) : (
          <h3>Unauthorized Access</h3>
        )}
      </div>
      {addCompany && (
        <PortalSecureCompanyAddCompany
          visible={addCompany}
          onClose={handleCloseCompany}
          type={0}
        />
      )}
        {editCompanyVisble  &&(
        <PortalSecureCompanyEditCompany
          visible={editCompanyVisble}
          onClose={handleCloseCompanyClick}
          companyId={companyId}
        />
      )}
        {addCompanyRules && (
        <PortalSecureCompanyAddRules
          companyId={companyId}
          visible={addCompanyRules}
          onClose={handleCloseRules}
        />
      )}
    </>
  );
};

export default CustomerOnboarding;
