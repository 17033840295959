import React, { useState } from 'react';
import { FaBell, FaMapMarkedAlt, FaSearch, FaBars, FaTimes } from "react-icons/fa";
import ToggleButtonGroup from './ToggleButtonGroup';
import CasesToggle from './CasesToggle';


const SecondaryNavbar = () => {
    const [toggleState, setToggleState] = useState('Breakdowns');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    return (
        <div className="flex justify-between items-center w-full px-4 py-2 bg-gray-100 border-t border-b border-gray-300">
            {/* Hamburger Icon for Mobile */}
            <div className="md:hidden">
                <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    {isMobileMenuOpen ? <FaTimes size={20} /> : <FaBars size={20} />}
                </button>
            </div>

            {/* Left Section: Toggle group with Bell and Map Buttons */}
            <div className="hidden md:flex items-center space-x-4">
                <ToggleButtonGroup />
                <button className="p-2 rounded-full bg-white text-gray-800">
                    <FaBell size={20} />
                </button>
                <button className="p-2 rounded-full bg-white text-gray-800">
                    <FaMapMarkedAlt size={20} />
                </button>
            </div>

            {/* Right Section: My Cases, All Cases Toggle, and Search Bar */}
            <div className="hidden md:flex items-center space-x-20">
                <CasesToggle />
                <div className="relative w-full max-w-xs">
                    <input
                        type="text"
                        className="pl-4 pr-10 py-2 rounded-full bg-white text-gray-800 border border-gray-300"
                        placeholder="Search"
                    />
                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-red-600 rounded-full p-2">
                        <FaSearch className="text-white" />
                    </div>
                </div>
            </div>

            {/* Hamburger Icon for Mobile */}
            <div className="md:hidden">
                <button
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    className="flex items-center justify-center p-2 rounded hover:bg-gray-200"
                >
                    {isMobileMenuOpen ? <FaTimes size={20} /> : null}
                </button>
            </div>


            {/* Mobile Menu */}
            {isMobileMenuOpen && (
                <div className="absolute top-16 left-0 w-full bg-white shadow-md md:hidden">
                    {/* Mobile Menu Content */}
                    {/* Example Item */}
                    <a href="/" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">Home</a>
                    {/* ... other links or content */}
                </div>
            )}

        </div>
    );
};

export default SecondaryNavbar;


