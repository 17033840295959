import { makeAutoObservable, runInAction } from "mobx";
import agent from "../API/agent";

export default class BreakdownStore {
    breakdownRegistry = new Map()
    filter = ['Active'];

    constructor() {
        makeAutoObservable(this)
    }

    addBreakdown = (model) => {
        this.breakdownRegistry.set(model.id, model);
    }

    setFilter = (filter) => {
        runInAction(() => {
            if (!this.filter.includes(filter)) {
                this.filter.push(filter);
            }
        });
    }
    removeFilter = (filter) => {
        runInAction(() => {
            const index = this.filter.findIndex(item => item === filter);
            if (index !== -1) {
                this.filter.splice(index, 1);
            }
        });
    }
   
    get getFilter() {
        return this.filter;
    }
    get breakdownsFromStore() {
        return Array.from(this.breakdownRegistry.values());
    }    
  
}
