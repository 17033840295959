import {useEffect, useState, useRef} from 'react';
import {Layout} from 'antd';
import { FloatingPanel} from 'antd-mobile';
import HereMapsDisplay from '../../Utilities/HereMaps/Display';
import { appSettings } from '../../AppSettings';


const {Header, Content, Footer} = Layout;
const anchors = [100, window.innerHeight * 0.2, window.innerHeight * 0.8]
const minHeight = anchors[0]
const maxHeight = anchors[anchors.length - 1]


export default function MobileSecureMap() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const [breakdowns, setBreakdowns] = useState([]);
    
    const getBreakdowns = async () => {
        const response = await fetch(appSettings.apiUrl + '/Breakdowns/activebreakdowns/' + currentUser.id);
        const json = await response.json();
        
        setBreakdowns(json);
    }

    useEffect(() => {
        /*navigator.geolocation.getCurrentPosition((position) => {
            setBreakdowns([{latitude: position.coords.latitude, longitude: position.coords.longitude}])
        })*/
        getBreakdowns();
    },[])

    return (
        
        <Layout style={{height: '100vh'}}>
            <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', color: '#fff' }}>
                This is the header    
            </Header>
            <Content>
                <HereMapsDisplay Breakdowns={breakdowns} MapHeight='100%' />
                <MyFloatingPanel />
            </Content>
            
            <Footer style={{zIndex:'100'}}>
                
                This is the footer
            </Footer>
            
        </Layout>
        
    )
}

const MyFloatingPanel = () => {
    return (
        <>
            <FloatingPanel anchors={anchors} style={{zIndex:'99'}}>
                This is the floating Panel
            </FloatingPanel>
        </>
    )
}