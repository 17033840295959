// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
    display: flex;
    flex-direction: row;
  }
  
  .column {
    display: flex;
    flex-direction: column;
  }
  
  .field {
    margin: 10px;
  }
  
  .label {
    font-weight: bold;
  }
  
  .input {
    border: none;
    background-color: #f2f2f2;
    padding: 5px;
  }

  .scrollable-list {
    margin-top:2%;
    max-height: 680px;
    overflow-y: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/Portal/styles/breakdownstyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".row {\n    display: flex;\n    flex-direction: row;\n  }\n  \n  .column {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .field {\n    margin: 10px;\n  }\n  \n  .label {\n    font-weight: bold;\n  }\n  \n  .input {\n    border: none;\n    background-color: #f2f2f2;\n    padding: 5px;\n  }\n\n  .scrollable-list {\n    margin-top:2%;\n    max-height: 680px;\n    overflow-y: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
