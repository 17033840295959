import React from 'react';
import { Tooltip } from 'antd';
import { FaRegCommentDots } from 'react-icons/fa';
import { MdOutlineTimer } from 'react-icons/md';
import { BsWrenchAdjustable } from 'react-icons/bs';
import { BiError } from 'react-icons/bi';

const BreakdownStatusIcon = ({ row, updatedBreakdownRules }) => {
  const { color, tooltip } = updatedBreakdownRules(
    row.followupDate,
    row.dateTimeOffset,
    row.assignedMechanics,
    row.breakdownMechStatus,
    row.followUpDateTime,
    row.lastInteractionDate,
    row.nameMatch,
    row.breakdownMechanicStatus,
    row.followUp
  );

  return (
    <Tooltip title={tooltip}>
      {color === "purple" ? (
        <FaRegCommentDots size={30} color='purple' />
      ) : color === "yellow" ? (
        <BsWrenchAdjustable size={30} color='yellow' />
      ) : color === "black" ? (
        <MdOutlineTimer size={30} color='black' />
      ) : color === "blue" ? (
        <MdOutlineTimer size={30} color='blue' />
      ) : color === "red" ? (
        <BiError size={30} color='red' />
      ) : color === "green" ? (
        <MdOutlineTimer size={30} color='green' />
      ) : color === "orange" ? (
        <BiError size={30} color='orange' />
      ) : (
        <div style={{ backgroundColor: color, width: '100%', height: '60%', borderRadius: '60%' }}></div>
      )}
    </Tooltip>
  );
};

export default BreakdownStatusIcon;

