import React from 'react';

const BreakdownDetailsInfo = ({ breakdownDetails }) => {
  return (
    <div className="flex flex-col">
      <div className="flex mb-2">
        <p className="mr-2">Reference:</p>
        <p className="font-bold">{breakdownDetails.breakdownReference}</p>
      </div>
      <div className="flex mb-2">
        <p className="mr-2">Company:</p>
        <p className="font-bold">{breakdownDetails.companyName}</p>
      </div>
      <div className="flex mb-2">
        <p className="mr-2">Description:</p>
        <p className="font-bold">{breakdownDetails.description}</p>
      </div>
      <div className="flex mb-2">
        <div className="flex mr-6">
          <p className="mr-2">Fleet Number:</p>
          <p className="font-bold">{breakdownDetails.fleetNumber}</p>
        </div>
        <div className="flex">
          <p className="mr-2">Registration Number:</p>
          <p className="font-bold">{breakdownDetails.registrationNumber}</p>
        </div>
      </div>
      <div className="flex mb-2">
        <p className="mr-2">Vehicle:</p>
        <p className="font-bold">{breakdownDetails.vehicle}</p>
      </div>
      
      <div className="flex mb-2">
        <div className="flex  mr-6">
          <p className="mr-2">Driver:</p>
          <p className="font-bold mr-2">{breakdownDetails.driverAlias}</p>
        </div>
        <div className="flex">
          <p className="mr-2">Driver Number:</p>
          <p className="font-bold">{breakdownDetails.driverCell}</p>
        </div>
      </div>
      <div className="flex mb-2">
        <div className="flex  mr-6">
          <p className="mr-2">Controller:</p>
          <p className="font-bold mr-2">{breakdownDetails.reportedBy}</p>
        </div>
        <div className="flex">
          <p className="mr-2">Controller Number:</p>
          <p className="font-bold">{breakdownDetails.contactNumber}</p>
        </div>
      </div>
    </div>
  );
};

export default BreakdownDetailsInfo;
