import React, { useState } from 'react';

const ToggleSwitch = () => {
    const [isToggled, setIsToggled] = useState(false);

    const onToggle = () => {
        setIsToggled(!isToggled);
    };

    return (
        <div className="flex items-center justify-center space-x-2">
            {/* Label: My Cases */}
            <span className="text-black whitespace-nowrap">My Cases</span>

            {/* Toggle Container */}
            <div
                className="w-14 h-8 flex items-center bg-gray-800 rounded-full p-1 cursor-pointer"
                onClick={onToggle}
            >
                {/* Toggle Circle with Arrow */}
                <div
                    className={`bg-white w-6 h-6 rounded-full shadow-md flex items-center justify-center transform ${
                        isToggled ? 'translate-x-6' : ''
                    } transition-transform duration-300`}
                >
                    {/* Arrow Icon */}
                    <span className="text-black text-lg">{isToggled ? '→' : '←'}</span>
                </div>
            </div>

            {/* Label: All Cases */}
            <span className="text-black whitespace-nowrap">All Cases</span>
        </div>
    );
};

export default ToggleSwitch;








