import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

const CustomModal = ({ visible, onCancel, content, title, id, rowId, selectedCompany, selectedDriverId, driverName, driverCell, onStateUpdated  }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  const handleOk = (id) => {
    setIsModalVisible(false);
    fetch(`https://localhost:44333/api/Breakdowns/AssignDriverToBreakdownRequest/${rowId}?selectedCompany=${selectedCompany}&selectedDriverId=${selectedDriverId}&driverName=${driverName}&driverCell=${driverCell}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        alert("Request created");
        onStateUpdated(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    onCancel();
  };

  const okButtonStyle = {
    backgroundColor: 'green',
    color: 'white',
  };

  const cancelButtonStyle = {
    backgroundColor: 'red',
    color: 'white',
  };

  return (
    <Modal
      id={id}
      title={title}
      open={isModalVisible && content}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ style: okButtonStyle }}
      cancelButtonProps={{ style: cancelButtonStyle }}
    >
      {content}
    </Modal>
  );
};

export default CustomModal;
