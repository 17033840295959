import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Tag, Tabs } from "antd";
import agent from "../../../Utilities/API/agent";
import { Editor } from "@tinymce/tinymce-react";
import CompanyListCompanies from "./CompanyList";
import { MinusCircleOutlined } from "@ant-design/icons";
import CompanyAddCustomRules from "./AddCustomRules";

const { TabPane } = Tabs;

const PortalSecureCompanyAddRules = ({ companyId, visible, onClose }) => {
  const [companyRulesList, setCompanyRuleList] = useState("");
  const [customRules, setCustomRules] = useState(null);
  const [companyList, setCompanyList] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modalVisible, setModalVisible] = useState(visible);
  const [content, setContent] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [workingId, setWorkingId] = useState(companyId);

  const handleCancel = () => {
    setModalVisible(false);
    onClose();
  };

  //TinyMCE
  const editorRef = useRef(null);

  const getCompanyRulesList = async () => {
    const data = await agent.Company.getRulesByCompanyId(companyId);
    setCompanyRuleList(data[0]);
  };

  const handleMultiClick = async () => {
    setCompanyList(true);
  };
  const handleSelectedCompanies = async (selectedCompanies) => {
    setSelectedCompanies(selectedCompanies);
    setCompanyList(false);
  };
  const closeCompanyList = async () => {
    setCompanyList(false);
  };

  const handleCurrentViewTagClick = (filter) => {
    const index = selectedCompanies.findIndex((item) => item === filter);
    if (index !== -1) {
      selectedCompanies.splice(index, 1);
    }
  };

  const handleSubmit = async () => {
    setIsSaving(true);
    const content = editorRef.current.getContent();
    const requestData = {
      companyId: companyId,
      rules: content,
      selectedCompanies: selectedCompanies,
    };

    let rulesPromise = agent.Company.updatedRulesByCompanyId(requestData);
    let customPromise =
      customRules != null
        ? agent.Company.updatedCustomRulesByCompanyId(customRules)
        : new Promise((resolve, reject) => {
            resolve();
          });

    Promise.all([rulesPromise, customPromise]).then((results) => {
      setWorkingId(null);
      setIsSaving(false);
      onClose();
    });
  };

  useEffect(() => {
    getCompanyRulesList();
  }, []);

  return (
    <>
      <Modal
        open={modalVisible}
        onCancel={handleCancel}
        width={"70%"}
        footer={[
          <Button key='close' onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key='save' type='primary' loading={isSaving} onClick={handleSubmit}>
            SAVE
          </Button>,
        ]}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Standard Rules" key="1">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
              
                <div style={{ marginTop: "10px" }}>
                  Companies :&nbsp; 
                  {selectedCompanies.map((item) => (
                    <Tag key={item.id} onClick={() => handleCurrentViewTagClick(item)}>
                      <MinusCircleOutlined /> {item.name}
                    </Tag>
                  ))}
                </div>
              </div>
              <div>
              <Button onClick={handleMultiClick}>Apply To Multiple Companies</Button>
              </div>
            </div>
            <br/>
          
            {companyList && <CompanyListCompanies visible={companyList} onClose={closeCompanyList} selectedCompaniesParam={handleSelectedCompanies} />}
            <br/>
            <hr/>
            <br/>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={companyRulesList.rules ? companyRulesList.rules : ""}
              apiKey='5uydohwmkgxotp02hejv4gg56879hkgtb455g42l2nhxlr3z'
              init={{
                height: 500,
                menubar: false,
                plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount", "table"],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic forecolor backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | floating | fontsize | table | help | emoticons | hr | subscript superscript",
                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {/* <button onClick={log}>Log editor content</button> */}
          </TabPane>
          <TabPane tab="Custom Rules" key="2">
           <CompanyAddCustomRules companyId={workingId} setCustomRules={setCustomRules}/>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default PortalSecureCompanyAddRules;
