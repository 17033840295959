import {Outlet} from 'react-router-dom';
import {Layout, Menu, theme, Row, Col} from 'antd';
import {MailOutlined,
    AppstoreOutlined,
    SettingOutlined} from '@ant-design/icons';


const {Header, Content, Footer} = Layout;



export function RNRLayout() {
    return (
        <Layout className="layout">
            <Header style={{backgroundColor:'#1e06a3', height: 70 + 'px'}}>
            <Row>
                    <Col span={2}><div className='logo' ><img src={'../rnr_logo.png'} style={{height:65 + 'px'}}/></div></Col>
                    <Col span={22}>
                        <Menu  mode="horizontal" items={headerMenuItems} style={{backgroundColor:'#1e06a3', color:'#fff'}} />
                        
                    </Col>
                </Row>
            </Header>
            <Content
                style={{
                  padding: '10px 50px',
                  
                }}>
                <Outlet />
            </Content>
        </Layout>
    )
}

export function RNRLayoutFullScreen() {
    return (
        <div className="container-fluid mt-3">
           <Outlet /> 
        </div>
    )
}

const headerMenuItems = [
  {
    label: 'Breakdown Request',
    key: 'breakdownRequest',
    icon: <SettingOutlined />,
    children: [
      {
        label: <a href="/breakdownrequest/new" style={{textDecoration: 'none'}}>
                New Request
              </a>,
        key: 'newBreakdownRequest',
      },
      {
        label: 'Option 2',
        key: 'setting:2',
      },
    ],
  },
      {
        label: 'Navigation Two',
        key: 'app',
        icon: <AppstoreOutlined />,
        disabled: true,
      },
      {
        label: 'Navigation Three - Submenu',
        key: 'SubMenu',
        icon: <SettingOutlined />,
        children: [
          {
            type: 'group',
            label: 'Item 1',
            children: [
              {
                label: 'Option 1',
                key: 'setting:1',
              },
              {
                label: 'Option 2',
                key: 'setting:2',
              },
            ],
          },
          {
            type: 'group',
            label: 'Item 2',
            children: [
              {
                label: 'Option 3',
                key: 'setting:3',
              },
              {
                label: 'Option 4',
                key: 'setting:4',
              },
            ],
          },
        ],
      },
      {
        label: (
          <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
            Navigation Four - Link
          </a>
        ),
        key: 'alipay',
      },
    ];
