import React, { createContext, useState, useContext, useEffect } from "react";
import { FiSearch, FiClipboard } from "react-icons/fi";
import { observer } from 'mobx-react-lite';

import {
  BsTruck,
  BsTruckFront,
  BsPerson,
  BsChevronRight,
  BsChevronLeft,
  BsBell,
  BsPass,
  BsGear,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { DataCountContext } from "./DataCountContext";
import NotificationDrawer from "./utilities/NotificationDrawer";
import * as signalR from "@microsoft/signalr";
import "../styles/swing.css";
import {useStore} from '../Utilities/Stores/store'
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

const SubmenuFilter = () => {
  const dataCount = useContext(DataCountContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const [notificationModel,setNotificationModel] = useState([]);
  const { notificationStore,signalRStore} = useStore();
  //const {createHubConnection,stopHubConnection} = signalRStore;
  const {isNotificationOpen, setNotificationDrawer,notifications, myNotifications} = notificationStore
  const [notConsultant,setNotConsultant] = useState();
  const navigate = useNavigate();

  const showDrawer = () => {
  //  setIsDrawerVisible(true);
    setNotificationDrawer(true);
  };

  const onClose = () => {
    setNotificationDrawer(false);
   // setIsDrawerVisible(false);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

 useEffect(() => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  if (currentUser) {
   (currentUser.userType == 'CONSULTANT')? setNotConsultant(true) : setNotConsultant(false);
  }
  else {
    navigate("/");
  }
 },[])


  const renderIcons = () => {
    const iconsPerPage = 6;
    const icons = [
      {
        url: "/Portal/secure/CustomerOnboarding",
        icon: <BsPerson size={20} className="text-gray" />,
        label: "Customer",
      },
      {
        url: "/Portal/secure/Supplier/list",
        icon: <BsTruckFront size={20} className="text-gray" />,
        label: "Supplier",
      },
      {
        url: "/portal/secure/vehicles/ListVehiclesFromCompany",
        icon: <BsTruck size={20} className="text-gray" />,
        label: "Fleet",
      },
      {
        url: "/Portal/Secure/Users",
        icon: <BsPerson size={20} className="text-gray" />,
        label: "Admin Users",
      },
      {
        url: "/Portal/Secure/AssignBreakdown",
        icon: <BsPerson size={20} className="text-gray" />,
        label: "Assign Breakdown",
      },
      {
        url: "/Portal/Secure/Settings/SettingsView",
        icon: <BsGear size={20} className="text-gray" />,
        label: "Settings",
      }
    ];

    const totalPages = Math.ceil(icons.length / iconsPerPage);
    const startIndex = currentIndex * iconsPerPage;
    const endIndex = startIndex + iconsPerPage;

    return (
      <>
        {icons.slice(startIndex, endIndex).map((item, index) => (
          <Link
            key={index}
            to={item.url}
            className="w-full text-black hover:underline"
          >
            <div className="relative flex flex-col items-center icon-hover-black">
              {item.icon}
              <button style={{ color: "gray" }}>{item.label}</button>
              {/* {item.label === "Active Breakdowns" && dataCount > 0 && (
                <div className="absolute top-0 right-0">
                  <div className="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
                    {dataCount}
                  </div>
                </div>
              )} */}
            </div>
          </Link>
        ))}

        {currentIndex < totalPages - 1 && (
          <BsChevronRight
            className="text-gray cursor-pointer"
            onClick={handleNextClick}
          />
        )}
        {currentIndex > 0 && (
          <BsChevronLeft
            className="text-gray cursor-pointer"
            onClick={handlePrevClick}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    // Create a SignalR connection
   // createHubConnection();

    // return () => {
    //   // Close the connection when component unmounts
    //   stopHubConnection();
    // };
  }, []);
  return (
    <div className="flex items-center justify-between">
      <div style={{ position: "absolute", paddingLeft: "15px", zIndex: 99 }}>
       <div>
        <BsBell
          className="text-gray cursor-pointer transform hover:scale-110 hover:rotate-6 transition duration-150 ease-in-out swing"
          size={20}
          onClick={showDrawer}
        />
        {((notifications || myNotifications)&& (notifications.length + myNotifications.length))?
        <Badge pill bg="danger" className="ms-2">
        {notifications.length + myNotifications.length}
        </Badge>: null
}
      </div>
        <NotificationDrawer visible={isNotificationOpen} onClose={onClose}/>
      </div>

      {/* Filters bar */}
      <div className="flex-grow lg:flex justify-center items-center relative shadow-sm shadow-gray-400 border h-20">
        <input
          type="search"
          placeholder=""
          className="py-2.5 w-[33rem] rounded-full outline-0"
        />
        { notConsultant &&
        <div className="flex justify-between absolute w-50 pr-4 pl-4 font-semibold text-gray-600 text-center pt-4 pb-4">
          {renderIcons()}
          <div className="flex items-center">
            {currentIndex < 1 && (
              <BsChevronRight
                className="text-gray cursor-pointer"
                onClick={handleNextClick}
              />
            )}
          </div>
          <div className="flex items-center">
            {currentIndex > 0 && (
              <BsChevronLeft
                className="text-gray cursor-pointer"
                onClick={handlePrevClick}
              />
            )}
          </div> 
        </div>}  <></>
      </div>
    </div>
  );
};

export default observer(SubmenuFilter);
