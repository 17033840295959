import {useState, useEffect} from 'react';
import { AutoComplete, Select} from 'antd';
import { appSettings } from '../../AppSettings';


export function ComponentsVehiclesVehicleSelectByRegistration({companyId, onSelected, onChange, value, isTrailer=0}) {
    const [vehicleList, setVehicleList] = useState([]);
    const [filteredVehicleList, setFilteredVehicleList] = useState([]);

    const [selectedValue, setSelectedValue] = useState(value);
    
    
    const loadVehicles = async () => {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'))
        const response = await fetch(`${appSettings.apiUrl}/vehicles/getvehiclesbycompany/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                Authorization: 'bearer: ' + currentUser.jwtToken
            }
        });
        
        const json = await response.json();


        setVehicleList(json.filter(v => (v.trailer == isTrailer)));
        setFilteredVehicleList(json.filter(v => (v.trailer == isTrailer)));
        
    }
    

    useEffect(() => {
        setSelectedValue(value);

    },[value])

    useEffect(() => {
        
        if (companyId) {
            loadVehicles();
        }

        
    }, [companyId,value]);

    

    const onSelect = (id, value) => {
        
        const vehicle = vehicleList.find((x) => x.registration === id);
        onSelected(id,vehicle);
    }
    
    

    const leavingInput = (e) => {
        const vehicle = vehicleList.find((x) => x.registration.toUpperCase() === e.target.value.toUpperCase());
        
        onSelected(e.target.value, vehicle);
    }


    return (
        <div>
            <AutoComplete 

                style={{width: '100%', textTransform:'uppercase'}}
                options = {filteredVehicleList.map((v) => (
                    {key: v.Id, value: v.registration }
                ))}
                value={selectedValue}
               // onBlur={leavingInput}
                onSelect = {onSelect}
                onChange = {(e) => {setSelectedValue(e.toUpperCase()); onChange(e);}}
                filterOption = {(input, option) => (option.value.toLowerCase().includes(input.toLowerCase()))}
                
            />
        </div>
    )
}

export function ComponentsVehiclesVehicleSelectByFleetNumber({companyId, onSelected, onChange, value, isTrailer=0}) {
    const [vehicleList, setVehicleList] = useState([]);
    const [filteredVehicleList, setFilteredVehicleList] = useState([]);
    const [selectedValue, setSelectedValue] = useState(value);
   
    useEffect(() => {
        setSelectedValue(value);

    },[value])

    const loadVehicles = async () => {
        const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
        const response = await fetch(`${appSettings.apiUrl}/vehicles/getvehiclesbycompany/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                Authorization: 'bearer: ' + currentUser.jwtToken
            }
        });
        
        const json = await response.json();

        setVehicleList(json.filter(v => (v.trailer == isTrailer)));
        setFilteredVehicleList(json.filter(v => (v.trailer == isTrailer)));
        
    }
    

    useEffect(() => {
        
        loadVehicles();
    }, [companyId]);

    

    const onSelect = (id, value) => {
        const vehicle = vehicleList.find((x) => x.fleet === id);
        onSelected(id, vehicle);
    }
    
    

    const leavingInput = (e) => {
        
        const vehicle = vehicleList.find((x) => x.fleet.toUpperCase() === e.target.value.toUpperCase());
        
        onSelected(e.target.value, vehicle);
    }


    return (
        <div>
            <AutoComplete 

                style={{width: '100%', textTransform:'uppercase'}}
                options = {filteredVehicleList.map((v) => (
                    {key: v.Id, value: v.fleet }
                ))}
                value={selectedValue}
              //  onBlur={leavingInput}
                onSelect = {onSelect}
                onChange = {(e) => {setSelectedValue(e.toUpperCase()); onChange(e)}}
                filterOption = {(input, option) => (option.value.toLowerCase().includes(input.toLowerCase()))}
                
            />
        </div>
    )
}