import { makeAutoObservable, runInAction } from "mobx"
import agent from "../API/agent";

export default class NotificationStore {
    systemNotificationRegistry = new Map();
    myNotificationRegistry = new Map();
    notificationDrawerVisible = false;
    constructor() {
        makeAutoObservable(this);
    }

    loadAllSystemNotifications = async () => {
      // const notifications = await agent.Notifications.loadAllSystem();
      // runInAction(()=>{
      //     this.systemNotificationRegistry.clear();
      //     if (Array.isArray(notifications)) {
      //         notifications.forEach(item => {
      //             this.addNotification(item);
      //         });
      //       }
      // })
    };
    loadMyNotifications = async () => {
      // const notifications = await agent.Notifications.loadMyNotifications();
      // runInAction(()=>{
      //     this.myNotificationRegistry.clear();
      //     if (Array.isArray(notifications)) {
      //         notifications.forEach(item => {
      //             this.addMyNotification(item);
      //         });
      //       }
      // })
    };
    addNotification = (notificationModel) => {
      //     this.systemNotificationRegistry.set(notificationModel.id, notificationModel);
    };
    addMyNotification = (notificationModel) => {
     //   this.myNotificationRegistry.set(notificationModel.id, notificationModel);
    }
   
    setNotificationDrawer = (isOpen) => {
        this.notificationDrawerVisible = isOpen;
    }
    get isNotificationOpen() {
        return this.notificationDrawerVisible;
    }

    get notifications() {
        // return Array.from(this.systemNotificationRegistry.values()).sort((a, b) => {
        //     if (a.notificationType < b.notificationType) {
        //         return -1;
        //     }
        //     if (a.notificationType > b.notificationType) {
        //         return 1;
        //     }
        //     return 0;
        // });
    }

    get myNotifications() {

     //   return Array.from(this.myNotificationRegistry.values());
    }
}