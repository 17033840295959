import React, { useState } from 'react';

const ToggleButtonGroup = () => {
  const [active, setActive] = useState('Breakdowns');
  const options = ['Breakdowns', 'Delayed', 'Workshop'];

  // Calculate which button is active to move the indicator accordingly
  const activeIndex = options.indexOf(active);
  const indicatorStyle = {
    transform: `translateX(${activeIndex * (300 / options.length)}%)`,
    zIndex: 0, // Ensure the indicator is below the buttons
    border: '2px solid black', // Add a black border
    backgroundColor: 'white', // Set the background to white
  };

  return (
    <div className="relative w-max p-1 bg-blue-200 rounded-full">
      {/* Animated indicator */}
      <div
        className="absolute top-0 left-0 bg-gray-400 h-full rounded-full transition-transform duration-600"
        style={{ ...indicatorStyle, width: `${100 / options.length}%` }}
      ></div>

      <div className="flex justify-between space-x-10 bg-blue-200 pr-1 rounded-full">
        {options.map((item) => (
          <button
            key={item}
            className={`relative z-10 px-4 py-1 text-sm font-semibold rounded-full transition-colors duration-300 ${
              active === item ? ' text-black' : 'text-gray-800 bg-transparent'
            }`}
            onClick={() => setActive(item)}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ToggleButtonGroup;


