import { useEffect, useState } from "react";
import { appSettings } from "../../../AppSettings";
import { Modal, Input, Select, Button, Checkbox, Form, Row, Col, AutoComplete } from "antd";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import agent from "../../../Utilities/API/agent";

const PortalSecureCompanyEditCompany = ({ companyId, visible, onClose }) => {
  const [selectedCompany, setSelectedCompany] = useState();
  const [workingId, setWorkingId] = useState(companyId);
  const [name, setName] = useState("");
  const [holdingCompanyList, setHoldingCompanyList] = useState([]);

  const { Option } = Select;
  const [spaceIndex, setSpaceIndex] = useState(0);
  const [form] = Form.useForm();

  const [addressOptions, setAddressOptions] = useState([]);
  const [savedAddress, setSavedAddress] = useState("");
  const hereMapsAPIKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [breakdownSearchResult, setBreakdownSearchResult] = useState('');
  const [addressFromLocation, setAddressFromLocation] = useState('');

  const yesNo = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
  ];
  const companyTypes = [
    { value: 0, label: 'Customer' },
    { value: 1, label: 'Supplier' }
  ];
  
  const handleCancel = () => {
    onClose();
  };

  const fetchCompany = async () => {
    const data = await agent.Company.getCompanyById(workingId);

    setSelectedCompany(data);
  };
  
  const getCompanyList = async () => {
    await fetch(`${appSettings.apiUrl}/Company/getallcompanies/`).then(
      async (response) => {
        const json = await response.json();
        setHoldingCompanyList(json);
      }
    );
  };

  const handleSubmit = async () => {
    form
    .validateFields()
    .then((values) => {
      const { holdingCompany, name, ...restValues } = values;

      const updatedValues = {
        ...restValues,
        holdingCompanyId: holdingCompany,
        address: savedAddress,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      };
    debugger;
    
      // Update supplierData with new values
       const updatedSupplierData = { ...values, ...updatedValues };
      //   values.id = selectedCompany.id

      //  updatedFormData.id = selectedCompany.id;
      agent.Company.updateCompany(updatedSupplierData).then(() => console.log());
      // Close the modal
      onClose();
    })
    .catch((errorInfo) => {
      console.log("Validation failed:", errorInfo);
    });

  
   onClose();
  };


  useEffect(() => {
    //   console.log(address.indexOf(' ',spaceIndex + 1));
    if (selectedCompany) {
      if (selectedCompany.address.indexOf(" ") == -1) {
        setSpaceIndex(-1);
      }

      if (selectedCompany.address.indexOf(" ", spaceIndex + 1) > spaceIndex) {
        addressAutocomplete();
        setSpaceIndex(selectedCompany.address.indexOf(" "), spaceIndex + 1);
      }
    }
  }, [selectedCompany?.address]);

  const addressAutocomplete = async () => {
    await fetch(`${appSettings.apiUrl}/geocode/autocomplete/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedCompany.address),
    }).then(async (response) => {
      const json = await response.json();
      
    });
  };
  useEffect(() => {
    fetchCompany();
    getCompanyList();
  }, []);

  useEffect(() => {
    if (visible && selectedCompany) {
      form.setFieldsValue(selectedCompany); // Set form fields with supplier data when modal is opened
    }
  }, [visible, selectedCompany, form]);

  useEffect(() => {
    if (selectedCompany) {
      
      setSavedAddress(selectedCompany.address || ''); 
      setLatitude(selectedCompany.latitude || ''); 
      setLongitude(selectedCompany.longitude || ''); 
    }
  }, [selectedCompany]);

  const lookupAddress = async (address) => {
    if (address.length % 3 === 0) {
      const encodedAddress = encodeURIComponent(address);
      const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodedAddress}&limit=10&apiKey=${hereMapsAPIKey}&in=countryCode:ZAF,ZWE,ZMB,BWA,NAM&type=request`;

      const response = await fetch(url);
      const json = await response.json();

      setAddressOptions(json.items);
    }
  };
  const handleAddressSearch = (address) => {
    const apiKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
    const encodedAddress = encodeURIComponent(address);
    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodedAddress}&apiKey=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBreakdownSearchResult(data);
          if (data.items.length === 1) {
            setLatitude(data.items[0].position.lat);
            setLongitude(data.items[0].position.lng);
            setAddressFromLocation(data.items[0].title);
          } else if (data.items.length > 1) {
            setSavedAddress(data);
            const mappedData = data.items.map((item) => ({
              title: item.title,
              lat: item.position.lat,
              lng: item.position.lng,
            }));
            // setSelectedTitles(mappedData);
            //   setIsModalVisible(true);
          } else {
            alert("No results found. Please try a different search.");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  return (
    <>
      {selectedCompany && (
        <Modal
          open={visible}
          onCancel={handleCancel}
          width={"70%"}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="save" type="primary" onClick={handleSubmit}>
              SAVE
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row style={sectionHeader}>
              <Col span={24}>EDIT COMPANY</Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Row gutter={16}>



                
                <Col span={24} style={dataInputColumn}>
                    <b>Name</b>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter contact name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>  
                  
                  <Col span={24} style={dataInputColumn}>
                    <b>Cell</b>
                    <Form.Item
                      name="cell"
                      rules={[{ required: true, message: "Please enter cell" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                 
                  <Col span={24} style={dataInputColumn}>
                    <b>Holding Company</b>
                    <Form.Item
                      name="holdingCompany"
                      initialValue={selectedCompany.holdingCompanyId}
                      rules={[
                        {
                          required: true,
                          message: "Please enter holding company name",
                        },
                      ]}
                    >
                      <Select >
                        {holdingCompanyList.map((company) => (
                          <Option
                            key={company.id}
                            value={company.id}
                          >
                            {company.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={24} style={dataInputColumn}>
                    <b>Company Type</b>
                    <Form.Item
                      initialValue={selectedCompany.companyType}
                      name="companyType"
                      rules={[
                        {
                          required: true,
                          message: "Please select company type",
                        },
                      ]}
                    >
                      <Select
                      
                      >
                        {companyTypes.map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  
           
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={16}>

                <Col span={24} style={dataInputColumn}>
                    <b>Contact Number</b>
                    <Form.Item
                      name="contactNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter contact number",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={dataInputColumn}>
                    <b>Is Holding Company</b>
                    <Form.Item
                      initialValue={selectedCompany.isHoldingCompany}
                      name="isHoldingCompany"
                      rules={[
                        {
                          required: true,
                          message: "Please select holding company status",
                        },
                      ]}
                    >
                      <Select >
                        {yesNo.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                        
                
                  
                

                  <Col span={24} style={formCSS.inputCol}>
                  <span style={formCSS.inputHeader}></span>
                  <b>Address</b>
                  <br />
                  <Form.Item  name="address">
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={addressOptions?.map((a) => ({
                      value: a.address.label,
                      label: a.address.label,
                    }))}
                    value={savedAddress}
                    onChange={(e) => {
                      setSavedAddress(e);
                      lookupAddress(e);
                    }}
                    onSelect={(e) => {
                      setSavedAddress(e);
                      handleAddressSearch(e);
                    }}
                  />
                   </Form.Item>

                   <Col span={24} style={formCSS.inputCol}>
                  <span style={formCSS.inputHeader}></span>
                  <b>Latitude</b>
                  <Input
                    value={latitude}
                    onChange={(e) => {
                      setLatitude(e.target.value);
                    }}
                  />
                  
                </Col>
                  <Col span={24} style={formCSS.inputCol}>
                    <span style={formCSS.inputHeader}></span>
                    <b>Longitude</b>
                    <Input
                      value={longitude}
                      onChange={(e) => {
                        setLongitude(e.target.value);
                      }}
                    />
                  </Col>

            </Col>

            


            
                  
                </Row>
              </Col>
            </Row>
            <Form.Item 
            hidden={true}
            name='id'>
              <Input/>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default PortalSecureCompanyEditCompany;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};

const formCSS = {
  inputCol: {
    padding: "8px",
  },
  inputHeader: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
};
