import { useState, useEffect, useContext } from "react";
import SubmenuFilter from "../../../components/SubmenuFilter";
import Navbar from "../../../components/Navbar";
import { DataCountContext, DataCountProvider } from "../../../components/DataCountContext";
import { appSettings } from "../../../AppSettings";
import { Row, Col, Input, Select, AutoComplete, Button, Form,Divider  } from "antd";
import {
  ComponentsVehiclesVehicleSelectByRegistration as VehicleRegistrationSelect,
  ComponentsVehiclesVehicleSelectByFleetNumber as VehicleFleetSelect,
  ComponentsVehiclesVehicleSelectByFleetNumber,
} from "../../../components/vehicles/VehicleSelect";
import TextArea from "antd/es/input/TextArea";
import MarkerMap from "../../../Utilities/GoogleMaps/MarkerMap";
import { useNavigate } from "react-router-dom";
import { addressSearch, lookupAddress, reverseGeoSearch } from "../../../Utilities/HereMaps/AddressUtils";
import agent from "../../../Utilities/API/agent";

export default function BreakdownRequestNew() {
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const hereMapsAPIKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
  const [initialFormValues, setInitialFormValues] = useState({});
  const [form] = Form.useForm();

  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [driverLoading, setDriverLoading] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const [trailerRowDisplayStyle, setTrailerRowDisplayStyle] = useState({
    display: "none",
  });
  const [secondTrailerRowDisplayStyle, setSecondTrailerRowDisplayStyle] = useState({
    display: "none",
  });

  //Define the input variables.

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  
  const [savedaddress, setSavedAddress] = useState("");

  const [requestId, setRequestId] = useState(null);
  const [breakdownSearchResult, setBreakdownSearchResult] = useState("");

  //Define the functions
  const getCustomers = async () => {
    setCompanyLoading(true);
   // setSelectedDriver("");
    const result = await agent.Company.getCompaniesByType(0);
    setCustomers(result);
    setCompanyLoading(false);
  };

  const getDrivers = async (companyId) => {
    setDriverLoading(true);
    
    const companyDrivers = await agent.Drivers.getCompanyDrivers(companyId);

    setDrivers(companyDrivers.drivers);

    setDriverLoading(false);
  };

  const handleMarkerPositionChange = (lat, lng) => {
    form.setFieldsValue({
      latitude: lat,
      longitude: lng,
    });
    _reverseGeoSearch(lat, lng);
  };

  const setDriverDetails = (id) => {
    const driver = drivers.find((d) => d.id === id);
    if (driver) {
      form.setFieldsValue({
        driverName: driver.fullName,
        driverCell: driver.mobileNumber,
      });
    }
  };

  const _lookupAddress = async (address) => {
    let items = await lookupAddress(address);
    setAddressOptions(items);
  };

  const handleAddressSearch = async (address) => {
    let result = await addressSearch(address);

    setBreakdownSearchResult(result.data);

    form.setFieldsValue({
      latitude: result.latitude,
      longitude: result.longitude,
      address: result.title,
      addressFromLocation: result.title,
    });
  };

  const _reverseGeoSearch = async (lat, lng) => {
    const result = await reverseGeoSearch(lat, lng);

    form.setFieldsValue({
      address: result,
      addressFromLocation: result,
    });
  };

  const submitRequest = async () => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const userId = currentUser.loginId;
    const values = await form.validateFields();

    const { selectedCustomer, selectedDriver, latitude, longitude, mileage,...restValues } = values;
    const breakdownRequestValues = {
      ...restValues,
      userId: userId,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      companyName:  customers.find((x) => x.id == selectedCustomer)?.name,
      selectedCompany: customers.find((x) => x.id == selectedCustomer)?.name,
      warranty: "", // No corresponding state provided
      attendingMechanic: "", // No corresponding state provided
      breakdownDescription: form.getFieldValue("description"),
      isVehicleLoadedTextVal: "", // No corresponding state provided
      breakdownType: "", // No corresponding state provided
      otherBreakdownInfo: "", // No corresponding state provided
      orderNumber: "", // No corresponding state provided
      imagery: [], // Needs to be populated separately based on UI interaction
      breakdownRequestReference: "", // No corresponding state provided
      breakdownAddress: "", // No corresponding state provided
      mileage: mileage?.toString(),
      driver: "", // No corresponding state provided
      mobileNumber: "", // No corresponding state provided
      breakdownClassType: "", // No corresponding state provided
      equipmentFleetNumber: "", // No corresponding state provided
      equipmentMake: "", // No corresponding state provided
      equipmentModel: "", // No corresponding state provided
      equipmentSerialNumber: "", // No corresponding state provided
      equipmentYear: "", // No corresponding state provided
     
    };

    agent.BreakdownRequest.createBreakdownRequest(breakdownRequestValues)
      .then((data) => {
        navigate("/portal/secure/ActiveBreakdowns");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setSelectedTruck = async (id, e) => {
    if (e) {
      form.setFieldsValue({
        truckRegistrationNumber: e.registration,
        truckFleetNumber: e.fleet,
        truckVinNumber: e.vin,
        truckMake: e.make,
        truckModel: e.model,
        truckYear: e.year,
        leaseType: e.leaseType,
        vehicleId: e.id,
        costCenter: e.costCentreName
      });
    }
  };
  const handleTruckNotInList = async (value) => {
    form.setFieldsValue({
      truckRegistrationNumber: value,
    });
  };
  const handleFleetNotInList = async (value) => {
    form.setFieldsValue({
      truckFleetNumber: value,
    });
  };
  const handleTrailerNotInList = async (value) => {
    form.setFieldsValue({
      trailerRegistrationNumber: value,
    });
  };
  const handleTrailerFleetNotInList = async (value) => {
    form.setFieldsValue({
      trailerFleet: value,
    });
  };
  const handleSecondTrailerNotInList = async (value) => {
    form.setFieldsValue({
      secondTrailerRegistrationNumber: value,
    });
  };
  const handleSecondTrailerFleetNotInList = async (value) => {
    form.setFieldsValue({  
      secondTrailerFleet: value,
    });
  };
  const setSelectedTrailer = async (id, e) => {
    if (e) {
      form.setFieldsValue({
        trailerRegistrationNumber: e.registration,
        trailerFleet: e.fleet,
        trailerVinNumber: e.vin,
        trailerMake: e.make,
        trailerModel: e.model,
        trailerYear: e.year,
        trailerVehicleId: e.id
      });
      
    }
  };

  const setSelectedSecondTrailer = async (id, e) => {
    if (e) {
     
      form.setFieldsValue({
        secondTrailerRegistrationNumber: e.registration,
        secondTrailerFleet: e.fleet,
        secondTrailerVinNumber: e.vin,
        secondTrailerMake: e.make,
        secondTrailerModel: e.model,
        secondTrailerYear: e.year,
        secondTrailerVehicleId: e.id
      });
    }
  };
  const faultOnTrailer = () => {
    form.setFieldsValue({
      faultOnTrailer: 1,
    });
    setTrailerRowDisplayStyle({ display: "flex" });
  };

  const faultOnSecondTrailer = () => {
    form.setFieldsValue({
      faultOnSecondTrailer: 1,
    });
    setSecondTrailerRowDisplayStyle({ display: "flex" });
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <div>
      <DataCountProvider>
        <div>
          <Navbar />
          <SubmenuFilter dataCount={dataCount} />

          <div></div>
        </div>
      </DataCountProvider>

      <div></div>
      <Form form={form}>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Controller Name </span>
                <br />
                <Form.Item name='controllerName'>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Controller Contact Number </span>
                <br />
                <Form.Item name='controllerContactNo'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Select a Customer</span>
                <Form.Item
                  name='selectedCustomer'
                  rules={[
                    {
                      required: false,
                      message: "Please select a company",
                    },
                  ]}>
                  <Select
                    style={{ width: "100%" }}
                    options={customers?.map((c) => ({
                      value: c.id,
                      label: c.name,
                    }))}
                    value={form.getFieldValue("selectedCustomer")}
                    onChange={(e) => {
                      setSelectedCustomer(form.getFieldValue("selectedCustomer"));
                      getDrivers(form.getFieldValue("selectedCustomer"));
                    }}
                    allowClear={true}
                    showSearch
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input)}
                    loading={companyLoading}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Select a Driver</span>
                <Form.Item
                  name='selectedDriverId'
                  rules={[
                    {
                      required: false,
                      message: "Please select a company",
                    },
                  ]}>
                  <Select
                    style={{ width: "100%" }}
                    options={drivers?.map((c) => ({
                      value: c.id,
                      label: c.fullName,
                    }))}
                    value={form.getFieldValue("selectedDriverId")}
                    onChange={(e) => {
                      setDriverDetails(form.getFieldValue("selectedDriverId"));
                    }}
                    allowClear={true}
                    showSearch
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Driver Name </span>
                <br />
                <Form.Item
                  name='driverName'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Driver Contact Number </span>
                <br />
                <Form.Item
                  name='driverCell'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={23} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Address </span>
                <br />
                <Form.Item
                  name='address'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    options={addressOptions?.map((a) => ({
                      value: a.address.label,
                      label: a.address.label,
                    }))}
                    value={savedaddress}
                    onChange={(e) => {
                      setSavedAddress(e);
                      _lookupAddress(e);
                    }}
                    onSelect={(e) => {
                      setSavedAddress(e);
                      handleAddressSearch(e);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Latitude</span>
                <Form.Item
                  name='latitude'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>longitude</span>
                <Form.Item
                  name='longitude'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Truck Registration</span>

                <Form.Item
                  name='truckRegistrationNumber'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <VehicleRegistrationSelect companyId={selectedCustomer} onSelected={setSelectedTruck} onChange={handleTruckNotInList} value={form.getFieldValue("truckRegistrationNumber")} />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Fleet Number</span>
                <Form.Item
                  name='truckFleetNumber'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <ComponentsVehiclesVehicleSelectByFleetNumber
                    companyId={selectedCustomer}
                    onSelected={setSelectedTruck}
                    onChange={handleFleetNotInList}
                    value={form.getFieldValue("truckFleetNumber")}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Vin Number</span>
                <Form.Item
                  name='truckVinNumber'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Make</span>
                <Form.Item
                  name='truckMake'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Model</span>
                <Form.Item
                  name='truckModel'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Year</span>
                <Form.Item
                  name='truckYear'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Mileage</span>
                <Form.Item
                  name='mileage'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Lease Type</span>
                <Form.Item
                  name='leaseType'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>COST CENTER NAME</span>
                <Form.Item
                  name='costCenter'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={formCSS.inputCol}>
                <Button style={{ width: "100%" }} type='primary' onClick={faultOnTrailer}>
                  THERE IS A FAULT ON THE TRAILER
                </Button>
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <Button style={{ width: "100%" }} type='primary' onClick={faultOnSecondTrailer}>
                  THERE IS A FAULT ON THE SECOND TRAILER
                </Button>
              </Col>
            </Row>
            <Row style={trailerRowDisplayStyle}>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Trailer Registration</span>
                <Form.Item
                  name='trailerRegistrationNumber'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <VehicleRegistrationSelect
                    companyId={selectedCustomer}
                    onSelected={setSelectedTrailer}
                    onChange={handleTrailerNotInList}
                    value={form.getFieldValue("trailerRegistrationNumber")}
                    isTrailer={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Fleet Number</span>
                <Form.Item
                  name='trailerFleet'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <ComponentsVehiclesVehicleSelectByFleetNumber
                    companyId={selectedCustomer}
                    onSelected={setSelectedTrailer}
                    onChange={handleTrailerFleetNotInList}
                    value={form.getFieldValue("trailerFleet")}
                    isTrailer={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Vin Number</span>
                <Form.Item
                  name='trailerVinNumber'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Make</span>
                <Form.Item
                  name='trailerMake'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Model</span>
                <Form.Item
                  name='trailerModel'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Year</span>
                <Form.Item
                  name='trailerYear'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row style={secondTrailerRowDisplayStyle}>
              <Row>
                <Col span={24} style={formCSS.inputCol}>
                  <div style={{ backgroundColor: "#333", borderColor: "#333" }}>
                    <Divider />
                  </div>
                </Col>
              </Row>
              {/* Second Trailer input fields */}
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Second Trailer Registration</span>
                <Form.Item
                  name='secondTrailerRegistrationNumber'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <VehicleRegistrationSelect
                    companyId={selectedCustomer}
                    onSelected={setSelectedSecondTrailer}
                    onChange={handleSecondTrailerNotInList}
                    value={form.getFieldValue("secondTrailerRegistrationNumber")}
                    isTrailer={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Second Trailer Fleet Number</span>
                <Form.Item
                  name='secondTrailerFleet'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <ComponentsVehiclesVehicleSelectByFleetNumber
                    companyId={selectedCustomer}
                    onSelected={setSelectedTrailer}
                    onChange={handleSecondTrailerFleetNotInList}
                    value={form.getFieldValue("secondTrailerFleet")}
                    isTrailer={true}
                  />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Second Trailer Vin Number</span>
                <Form.Item
                  name='secondTrailerVinNumber'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Second Trailer Make</span>
                <Form.Item
                  name='secondTrailerMake'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Second Trailer Model</span>
                <Form.Item
                  name='secondTrailerModel'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Second Trailer Year</span>
                <Form.Item
                  name='secondTrailerYear'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={formCSS.inputCol}>
                <div style={{ backgroundColor: "#333", borderColor: "#333" }}>
                  <Divider />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>DESCRIBE THE FAULT</span>
                <Form.Item
                  name='description'
                  rules={[
                    {
                      required: false,
                    },
                  ]}>
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <Col span={8}>
                <Form.Item>
                  <Button danger>Cancel Request</Button>
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item>
                  <Button type='primary' style={{ width: "100%" }} onClick={submitRequest}>
                    GENERATE THIS REQUEST
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div style={{ width: "100%", height: "100%" }} className='w-1/2 bg-gray-400'>
              {breakdownSearchResult && breakdownSearchResult.items.length > 0 && breakdownSearchResult.items[0].position.lat && breakdownSearchResult.items[0].position.lng ? (
                <MarkerMap latitude={breakdownSearchResult.items[0].position.lat} longitude={breakdownSearchResult.items[0].position.lng} onMarkerPositionChange={handleMarkerPositionChange} />
              ) : (
                <MarkerMap latitude={-26.204412366778424} longitude={28.049289079589848} onMarkerPositionChange={handleMarkerPositionChange} />
              )}
            </div>
          </Col>
        </Row>
        {/*Hidden FIelds*/}
        <Form.Item name='faultOnTrailer' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name='faultOnSecondTrailer' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name='addressFromLocation' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name='vehicleId' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name='trailerVehicleId' hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name='secondTrailerVehicleId' hidden={true}>
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
}

const formCSS = {
  inputCol: {
    padding: "8px",
    height:"70px"
  },
  inputHeader: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
};
