import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { AlertOutlined, PhoneOutlined } from "@ant-design/icons";

const NotificationSideBar = ({ notificationModel }) => {
  
  const [counter, setCounter] = useState(0);
  const [bgColour,setBGColour] = useState('red');
  useEffect(() => {
    
    // Custom parsing function for non-standard date format
    const parseCustomDate = (dateString) => {
      const parts = dateString.split(" ");
      const dateParts = parts[0].split("-");
      const timeParts = parts[1].split(":");
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed
      const day = parseInt(dateParts[2], 10);
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);
      const seconds = parseInt(timeParts[2], 10);
      return (
        new Date(
          year, month, day, hours, minutes, seconds
        ).getTime() / 1000
      );
    };

    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{7} \+\d{2}:\d{2}$/;
    
    // Parse the string representation of the date into a Date object
    const startTime = regex.test(notificationModel.createdOn)
      ? parseCustomDate(notificationModel.createdOn)
      : NaN;
    
    const currentTime = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
    if (!isNaN(startTime) && !isNaN(currentTime)) {
      const initialCounter = currentTime - startTime;
      setCounter(initialCounter);
    }
    
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, [notificationModel.createdOn]);



  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
    {notificationModel &&
    <Card
      style={{
        background: (notificationModel.title == 'PANIC') ? 'red': '#C2C2C2',
        color:  (notificationModel.title == 'PANIC') ? 'white': 'black' ,
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>
          {" "}
          { (notificationModel.title == 'PANIC') ? <AlertOutlined /> : <PhoneOutlined />}
          {notificationModel.title}
        </div>
        <div>
          <div style={{ fontSize: "14px" }}>Caller Mobile:</div>
          <div style={{ fontSize: "14px" }}>{notificationModel.cell}</div>
        </div>
      </div>
      <hr
        style={{
          height: "3px",
          backgroundColor: "white",
          margin: "20px 0",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>
        <div style={{ fontSize: "14px" }}>{(notificationModel.title == 'PANIC') ? "Referance":"Name"}:</div>
          <div style={{ fontSize: "14px" }}>{notificationModel.name}</div>
        </div>
       
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ fontSize: "14px" }}>{(notificationModel.title == 'PANIC') ? "Location":"Referance"}:</div>
          <div style={{ fontSize: "14px" }}>{notificationModel.location}</div>
        </div>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>
          {formatTime(counter)}
        </div>
      </div>
    </Card>
      }
      </>
  );
};

export default NotificationSideBar;
