import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { appSettings } from '../../AppSettings';
import { useNavigate } from 'react-router-dom';
import BreakdownStatusIcon from '../../components/BreakdownStatusIcon';
import SubmenuFilter from '../../components/SubmenuFilter';
import { DataCountProvider } from '../../components/DataCountContext';
import FetchApi from '../../components/utilities/fetchApi';
import { Tooltip } from 'antd';
import MyMenu from '../../components/breakdowns/MyBreakdownMenu';
import Navbar from '../../components/Navbar';
import useCaseInsensitiveSort from '../../components/utilities/useCaseInsensitiveSort';
import agent from '../../Utilities/API/agent';
import { store, useStore } from "../../Utilities/Stores/store";
import { observer } from "mobx-react-lite";
export default observer(function MyBreakdowns() {

    const [breakdowns, setBreakdowns] = useState([]);
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const caseInsensitiveSortFullName = useCaseInsensitiveSort('customerName');
    const navigate = useNavigate();
    const { breakdownStore } = useStore();
    const { getFilter, setFilter} = breakdownStore;
    const [searchTerm, setSearchTerm] = useState('');
    const [dataCount, setDataCount] = useState(0);

    const [active, setActive] = useState([]);
    const [qa, setQA] = useState([]);
    const [delayed, setDelayed] = useState([]);
    const [workshop, setWorkshop] = useState([]);

    const [lockedRows, setLockedRows] = useState({});

    const [current, setCurrent] = useState("");

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleData = (data) => {
        setDataCount(data.length);
    };

    useEffect(() => {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const loginId = currentUser.userId;

        if (loginId) {
            agent.MyBreakdowns.active(currentUser.userId).then((data) => {
                setBreakdowns(data);
            })
            // fetch(`${appSettings.apiUrl}/Breakdowns/MyActiveBreakdowns/${loginId}`)
            //     .then(response => response.json())
            //     .then(data => {
                    
            //         setBreakdowns(data);
            //     })
            //     .catch(error => console.error(error));
        }
    }, []);

  /*For Testing Purposes - Remove later */
  useEffect(() => {
    const getBreakdowns = async () => {
      let result;
      switch (store.breakdownStore.filter) {
        case "Delayed":
          result = await agent.MyBreakdowns.delayed(currentUser.userId);
          setFilter("Delayed");
          break;
        case "QA":
          result = await agent.MyBreakdowns.qa(currentUser.userId);
          setFilter("QA");
          break;
        case "Workshop":
          result = await agent.MyBreakdowns.workshop(currentUser.userId);
          setFilter("Workshop");
          break;
        case "Active":
        default:
          result = await agent.MyBreakdowns.active(currentUser.userId);
          setFilter("Active");
          break;
      }
      setBreakdowns(result);
    };

    getBreakdowns();
    const interval = setInterval(getBreakdowns, 30000);
    return () => clearInterval(interval);
  }, []);


    const sortElapsedTime = (rowA, rowB) => {
        const timeA = parseFloat(rowA.elapsedTime);
        const timeB = parseFloat(rowB.elapsedTime);
    
        if (timeA < 0 && timeB < 0) {
          return Math.abs(timeB) - Math.abs(timeA);
        } else {
          return timeA - timeB;
        }
      };
    function updatedBreakdownRules(followupDate, datetimeOffset, assignedMechanics, breakdownMechStatus, followUpDateTime, lastInteractionDate, nameMatch, breakdownMechanicStatus, followUp) {
        const parsedDateTimeOffset = new Date(datetimeOffset);

        const parsedFollowUpDateTime = new Date(followUpDateTime);

        const timeDiff = Math.abs(parsedFollowUpDateTime - parsedDateTimeOffset);
        const minutesDiff = Math.floor(timeDiff / (1000 * 60));

        const currentTime = new Date();

        const dictionaryList = {
            notConsultant: 1,
            noUpdateOrTimer: 2,
            timeLapsed: 3,
            noMechanicOnSite: 4,
            noMechanic30: 5,
            noMechanic15: 6,
            newBreakdown: 7
        };

        var color = "";
        var tooltip = "";
        let conditionResults = {};
        const trueConditions = [];

        if (assignedMechanics === 0 && minutesDiff < 15) {
            conditionResults.newBreakdown = true;
        }
        else {
            conditionResults.newBreakdown = false;
        }

        if (assignedMechanics === 0 && minutesDiff >= 15) {
            conditionResults.noMechanic15 = true;
        }
        else {
            conditionResults.noMechanic15 = false;
        }

        if (assignedMechanics === 0 && minutesDiff > 15 && minutesDiff <= 30) {
            conditionResults.noMechanic30 = true;
        }
        else {
            conditionResults.noMechanic30 = false;
        }

        if (nameMatch === "false") {
            conditionResults.notConsultant = true;
        }
        else {
            conditionResults.notConsultant = false;
        }

        if (followUp === 0 && minutesDiff >= 30) {
            conditionResults.noUpdateOrTimer = true;
        }
        else {
            conditionResults.noUpdateOrTimer = false;
        }

        if (breakdownMechanicStatus != 2) {
            conditionResults.noMechanicOnSite = true;
        }
        else {
            conditionResults.noMechanicOnSite = false;
        }

        if (currentTime > parsedFollowUpDateTime) {
            conditionResults.timeLapsed = true;
        }
        else {
            conditionResults.timeLapsed = false;
        }

        for (const key in conditionResults) {
            if (conditionResults[key]) {
                if (dictionaryList.hasOwnProperty(key)) {
                    trueConditions.push({
                        name: key,
                        value: dictionaryList[key]
                    });
                }
            }
        }

        const lowestValueItem = trueConditions.reduce((minItem, currentItem) => {
            if (currentItem.value < minItem.value) {
                return currentItem;
            }
            return minItem;
        }, trueConditions[0]);

        switch (lowestValueItem.name) {
            case "notConsultant":
                color = "purple";
                tooltip = "Note captured by anyone who is not a consultant.";
                break;
            case "noUpdateOrTimer":
                color = "black";
                tooltip = "No follow up timer set and no update half an hour";
                break;
            case "timeLapsed":
                color = "green";
                tooltip = "Breakdown Not accepted mechanic (15 minutes from creation)";
                break;
            case "noMechanicOnSite":
                color = "red";
                tooltip = "No mechanic on site";
                break;
            case "noMechanic30":
                color = "orange";
                tooltip = "Breakdown Not accepted mechanic (30 minutes from creation)";
                break;
            case "noMechanic15":
                color = "yellow";
                tooltip = "Breakdown Not accepted mechanic (15 minutes from creation)";
                break;
            case "newBreakdown":
                color = "blue";
                tooltip = "New Breakdown";
                break;
            default:
                color = "#FFFFFF";
                break;
        }

        return { color, tooltip };
    }

    function formatTime(minutes, followUpNote) {
        /*if (followUpNote === "None") {
                return 'No Follow Up';
            }*/
    
        if (parseFloat(minutes) == -99999999999999) {
          return "No Follow Up";
        }
    
        var overdue = false;
        if (minutes < 0) {
          minutes *= -1;
          overdue = true;
        }
        const days = Math.floor(minutes / (60 * 24));
        const hours = Math.floor((minutes % (60 * 24)) / 60);
        const mins = minutes % 60;
    
        let formattedTime = "";
        if (days !== 0) {
          formattedTime += `${days} Days `;
        }
        if (hours !== 0) {
          formattedTime += `${hours} Hours `;
        }
        formattedTime += `${mins} Mins`;
    
        if (overdue) {
          formattedTime = "- " + formattedTime;
        }
        return formattedTime;
      }

    const columns = [
        {
            name: '',
            selector: row => row.elapsedTime,
            sortable: false,
            width: '60px',
            cell: (row) => (
                <BreakdownStatusIcon row={row} updatedBreakdownRules={updatedBreakdownRules} />
            )
        },
        {
            name: 'Reference',
            selector: row => row.breakdownReference,
            sortField: 'breakdownReference',
            sortable: true,
            cell: (row) => {
                // const { color } = updatedBreakdownRules(
                //     row.followupDate,
                //     row.dateTimeOffset,
                //     row.assignedMechanics,
                //     row.breakdownMechStatus,
                //     row.followUpDateTime,
                //     row.lastInteractionDate,
                //     row.nameMatch,
                //     row.breakdownMechanicStatus,
                //     row.followUp
                // );
                var color = "#fff";
                return (
                    <div
                        style={{
                            backgroundColor: color, // Set the background color based on the color from updatedBreakdownRules
                            padding: '5px',
                            borderRadius: '5px',
                            color: 'black',
                        }}
                        onClick={()=> handleRowClicked(row)}
                    >
                        {row.breakdownReference}
                    </div>
                );
            },
        },
        
        {
          name: "Customer Name",
          selector: (row) => row.customerName,
          sortField: "customerName",
          sortFunction: caseInsensitiveSortFullName,
          sortable: true,
        },
        {
            name: "Registration Number",
            selector: (row) => row.registrationNumber,
            sortField: "registrationNumber",
            sortable: true,
          },
          {
            name: "Follow-up Time",
            selector: (row) => row.elapsedTime,
            sortField: "elapsedTime",
            sortable: true,
            format: (row) => formatTime(row.elapsedTime, row.followUpNote),
            sortFunction: sortElapsedTime,
            cell: (row) => {
              return (
                <div onClick={()=> handleRowClicked(row)}>
                  <div style={{color:(row.elapsedTime < 0 || row.followUpNote == 'No Follow Up' ) ? 'red':'black'}}>{formatTime(row.elapsedTime, row.followUpNote)}</div>
                </div>
              );
            },
          },
      
        {
            name: "Description",
            selector: (row) => row.description,
            sortField: "description",
         
            sortable: true,
          },
        {
            name: 'Last Note',
            selector: (row) => row.lastNote,
            sortable: true,
            cell: (row) => (
                <Tooltip title={row.lastNote}>
                    <span>{row.lastNote}</span>
                </Tooltip>
            ),
        },{
            name: "Status",
            selector: (row) => row.statusDescription,
            sortField: "statusDescription",
            sortable: true,
            cell: (row) => <span>{row.statusDescription}</span>,
          }
    ];

    const handleRowClicked = (row) => {
        navigate("/portal/secure/breakdowns/breakdownDetails", {
            state: { breakdownId: row.id },
          });
    };

    const filteredBreakdowns = breakdowns.filter((breakdown) => {
        
        return (
            breakdown.breakdownReference.toLowerCase().includes(searchTerm.toLowerCase()) ||
            breakdown.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            breakdown.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            breakdown.fleetNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            breakdown.registrationNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            breakdown.vehicle.toLowerCase().includes(searchTerm.toLowerCase()) ||
            breakdown.driverAlias.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div>
            <DataCountProvider>
                {currentUser ? (
                    <>
                        <Navbar />
                        <SubmenuFilter />

                        <br />

                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1%', paddingRight: '1%', paddingTop: '2px' }}>
                            <MyMenu
                                delayed={delayed}
                                setDelayed={setDelayed}
                                active={active}
                                setActive={setActive}
                                qa={qa}
                                setQA={setQA}
                                workshop={workshop}
                                setWorkshop={setWorkshop}
                                breakdowns={breakdowns}
                                setBreakdowns={setBreakdowns}
                            />

                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <br />

                        <div style={{ padding: '0 30px' }}>
                            <DataTable
                                columns={columns}
                                data={filteredBreakdowns}
                                pagination={false}
                                striped={true}
                                highlightOnHover={true}
                                onRowClicked={handleRowClicked}
                                className="bg-white border border-[#f9787c] rounded-lg cursor-pointer"
                                cellClassName="border-b border-[#f9787c] px-4 py-2"
                                headerClassName="bg-[#f9787c] text-white font-semibold"
                            />
                        </div>

                    </>
                ) : <h3>Unauthorized Access</h3>}
            </DataCountProvider>
        </div>
    );
});