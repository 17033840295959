import { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Select, Input, Button, Modal } from "antd";
import agent from "../../../Utilities/API/agent";
import { Layout } from 'antd';

const BreakdownRequestView = ({modalVisible, onclose, breakdownRef}) => {
    const [showModal, setShowModal] = useState(modalVisible);
    const [breakdownRequestInformation, setBreakdownRequestInformation] = useState(modalVisible);
    const handleOk = () => {
        setShowModal(false);
      };
      const handleCancel = () => {
        onclose();
      };

      const formatDate = (inDate, utcHour = false) => {
        const outDate = new Date(inDate);
        if (utcHour) {
            return outDate.getFullYear() + "-" + String(outDate.getMonth() + 1).padStart(2, '0') + "-" + String(outDate.getDate()).padStart(2, '0') + " " + String(outDate.getUTCHours()).padStart(2, '0') + ":" + String(outDate.getMinutes()).padStart(2, '0')
        } else {
            return outDate.getFullYear() + "-" + String(outDate.getMonth() + 1).padStart(2, '0') + "-" + String(outDate.getDate()).padStart(2, '0') + " " + String(outDate.getHours()).padStart(2, '0') + ":" + String(outDate.getMinutes()).padStart(2, '0')
        }
    }

      const fetchData = async ()=> {
         const result = await agent.BreakdownRequest.getRequestByRef(breakdownRef);
         setBreakdownRequestInformation(result);
      }
      useEffect(()=> {
        fetchData();
      },[])
  // Your code for the breakdown request view
  return (
    <Modal
    open={modalVisible}
    width={'50%'}
    onCancel={handleCancel}
    footer={[
      <Button key="close" onClick={handleCancel}>
        Close
      </Button>,
     
    ]}
    >
    {breakdownRequestInformation && (
      <div className={{ backgroundColor: '#fff', padding: '30px', borderRadius: '10px' }}>
      <Container>

          <Row className="mb-3">
              <Col>
                  <div className="bg-danger p-3 rounded">
                      <h2 className="text-white">Breakdown Request Information</h2>
                  </div>
              </Col>
          </Row>

          {/* Breakdown request details */}
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Breakdown Request Reference:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.breakdownRequestReference}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Date Created:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{formatDate(breakdownRequestInformation.createdDateTime)}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Last Modified Date:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{formatDate(breakdownRequestInformation.lastModifiedOn)}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Registration Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.reg}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Description:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.description}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Truck Fleet Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.fleet}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Vin Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.vin}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Truck Make:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.make}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Truck Model:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.model}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Truck Year:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.year}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Trailer Fleet Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.trailerFleetNumber}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
              Trailer Vin Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.trailerVin}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
              Trailer Registration:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.trailerReg}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
              Trailer Make:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.trailerMake}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
              Trailer Model:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.trailerModel}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
              Trailer Year:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.trailerYear}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Breakdown Address:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.breakdownAddress}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Case Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.orderNumber}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Company Name:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.companyName}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
              Free Text Company Name:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.freeTextCompanyName}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Driver Name:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.driver}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Driver Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.cell}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Controller:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.controllerName}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Controller Number:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.controllerContactNo}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Warranty:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.warranty}</b>
              </Col>
          </Row>
          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                  Load:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.load}</b>
              </Col>
          </Row>

          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                 Mileage:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.mileage}</b>
              </Col>
          </Row>

          <Row className="mb-4 border-bottom border-dotted">
              <Col span={12} className={viewPanelLabels}>
                 Other Information:
              </Col>
              <Col span={12} className={viewPanelLabels}>
                  <b>{breakdownRequestInformation.otherBreakdownInfo}</b>
              </Col>
          </Row>
      </Container>
  </div>
     )} 
  </Modal>
  );
}

const viewPanelLabels = {
    fontFamily: 'roboto',
    fontSize: '15pt'
}

export default BreakdownRequestView;