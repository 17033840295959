// import React from 'react';
// import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

// const containerStyle = {
//   width: '100%',
//   height: '100%'
// };

// function MarkerMap({ latitude, longitude }) {
//   const { isLoaded, loadError } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyCPC4BU1rYr6a3YL6dNW-vFaKZ0VOg935U"
//   });

//   const center = {
//     lat: latitude,
//     lng: longitude
//   };

//   const onLoad = React.useCallback(function callback(map) {
//     const bounds = new window.google.maps.LatLngBounds();
//     bounds.extend(center);
//     const zoomLevel = 13;
//     map.setZoom(zoomLevel);
//   }, [center]);

//   const onUnmount = React.useCallback(function callback() {

//   }, []);

//   return (
//     isLoaded && !loadError ? (
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={9}
//         onLoad={onLoad}
//         onUnmount={onUnmount}
//       >
//         <Marker position={center} 
//                 draggable={true}
//         />
//       </GoogleMap>
//     ) : (
//       <div>Error: Failed to load Google Maps API</div>
//     )
//   );
// }

// export default React.memo(MarkerMap);

import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { FiAlertCircle } from "react-icons/fi";

const containerStyle = {
  width: '100%',
  height: '100%'
};

function MarkerMap({ latitude, longitude, onMarkerPositionChange }) {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDdwbkZz09QW_JFTXlCOQArC3jdk9hBPa0"
  });

  const [markerPosition, setMarkerPosition] = useState({ lat: latitude, lng: longitude });

  const options = {
    disableMapTypeControl: true // Hide map/satellite button
  };

  useEffect(() => {
    setMarkerPosition({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);

  const handleMarkerDragEnd = (event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };

    setMarkerPosition(newPosition);

    onMarkerPositionChange(newPosition.lat, newPosition.lng);
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(markerPosition);
    const zoomLevel = 13;
    map.setZoom(zoomLevel);
  }, [markerPosition]);

  const onUnmount = React.useCallback(function callback() {

  }, []);

  return (
    isLoaded && !loadError ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={9}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
      >

        <div
          style={{
            position: 'absolute',
            top: '60px',
            left: '10px',
            background: 'rgba(255, 90, 96, 0.9)',
            fontWeight: '600',
            color: 'black',
            padding: '9px',
            border: '1px solid red',
            borderRadius: '8px',
            width: '297px'
          }}
        >
          <div className="flex items-center">
            <div className='pt-1 pb-1'>Please click and drag marker to be moved</div>
            <FiAlertCircle className="ml-2" size={24}/>
          </div>

        </div>
        <Marker
          position={markerPosition}
          draggable={true}
          onDragEnd={handleMarkerDragEnd}
        />
      </GoogleMap>
    ) : (
      <div>Error: Failed to load Google Maps API</div>
    )
  );
}

export default React.memo(MarkerMap);


