import { Link } from "react-router-dom";
import {Row, Col} from 'antd';

export default function Start() {
    return (
        <Row>
            <Col span="12">
                <Link className="btn btn-primary" to="/dashboards/tvleft">LEFT T.V.</Link>
            </Col>
            <Col span="12">
                <Link className="btn btn-primary" to="/dashboards/tvright">RIGHT T.V.</Link>
            </Col>
        </Row>
    
    )
}