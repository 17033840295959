import { useEffect, useState, useCallback } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import BreakdownStatusIcon from "../../../components/BreakdownStatusIcon";
import SubmenuFilter from "../../../components/SubmenuFilter";
import Navbar from "../../../components/Navbar";
import { DataCountProvider } from "../../../components/DataCountContext";
import FetchApi from "../../../components/utilities/fetchApi";
import { Tooltip } from "antd";
import MyMenu from "../../../components/breakdowns/BreakdownMenu";
import useCaseInsensitiveSort from "../../../components/utilities/useCaseInsensitiveSort";

import agent from "../../../Utilities/API/agent";
import { store, useStore } from "../../../Utilities/Stores/store";
import { observer } from "mobx-react-lite";
import ConsultantModal from "./ConsultantModal";

const AssignBreakdown = () => {
  const navigate = useNavigate();
  const [breakdowns, setBreakdowns] = useState([]);
  const [breakdownId, setBreakdownId] = useState();
  const [userRole, setUserRole] = useState(null);
  const roleType = "ADMIN";
  const defaultSortFieldId = userRole !== roleType ? 5 : 5;
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("customerName");
  const [searchTerm, setSearchTerm] = useState("");
  const [modalVisible,setModalVisible] = useState();
const currentUser =  sessionStorage.getItem('currentUser');
  const getBreakdowns = async () => {
    let result;
        result = await agent.BreakdownRequest.activeBreakdownList();
    setBreakdowns(result);
  };


  useEffect(() => {
  
    getBreakdowns();
   
  }, []);

  const onClose = () => {
    setModalVisible(false);
    getBreakdowns();
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  let columns = [
    {
      name: "Reference",
      selector: (row) => row.breakdownReference,
      sortField: "breakdownReference",
      sortable: true,
      cell: (row) => {
    
        var color = "#fff";
        return (
          <div
            style={{
              backgroundColor: color, // Set the background color based on the color from updatedBreakdownRules
              padding: "5px",
              borderRadius: "5px",
              color: "black",
              fontSize: "11px",
            }}
            onClick={()=> handleRowClicked(row)}
          >
            {row.breakdownReference}
          </div>
        );
      },
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
      sortField: "customerName",
      sortFunction: caseInsensitiveSortFullName,
      sortable: true,
    },
    {
      name: "Registration Number",
      selector: (row) => row.registrationNumber,
      sortField: "registrationNumber",
      sortable: true,
    },
    {
      name: "Consultant",
      selector: (row) => row.consultant,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.statusDescription,
      sortField: "statusDescription",
      sortable: true,
      cell: (row) => <span onClick={()=> handleRowClicked(row)}>{row.statusDescription}</span>,
    }
  ];

 

   const handleRowClicked = (row) => {
   setModalVisible(true);
   setBreakdownId(row.id);
  };

  const filteredBreakdowns = breakdowns?.filter((breakdown) => {
   
    return (
      breakdown.breakdownReference
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      breakdown.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.fleetNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.registrationNumber
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      breakdown.vehicle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.driverAlias.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.assignedSupplier
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      breakdown.assignedMechanic
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      breakdown.fullLastNote.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div>
      
        {currentUser ? (
          <>
            <Navbar />
            <br/>
            <SubmenuFilter />
            <br/>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
              />
            <br />
            <br/>
            <div style={{ padding: "0 30px" }}>
              <DataTable
                columns={columns}
                data={filteredBreakdowns}
                pagination={false}
                striped={true}
                highlightOnHover={true}
                onRowClicked={handleRowClicked}
                defaultSortFieldId={defaultSortFieldId}
                defaultSortAsc={true}
                sortable={false}
                fixedHeader={true}
             
              />
            </div>
            {breakdownId && modalVisible && <ConsultantModal onClose={onClose} breakdownId={breakdownId} visible={modalVisible}/>}
          </>
        ) : (
          <h3>Unauthorized Access</h3>
        )}
      
    </div>
  );
};

export default observer(AssignBreakdown);
