import axios from 'axios';
import { appSettings } from '../../AppSettings';
import { toast } from 'react-toastify';

axios.defaults.baseURL = appSettings.apiUrl;

const responseBody = (response) => response.data;
// const storedUser = sessionStorage.getItem('currentUser');

//   // If exists, parse and set currentUser
//   const currentUser = (JSON.parse(storedUser));

axios.interceptors.request.use(config => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser && currentUser.jwtToken && config.headers) {
        config.headers.Authorization = `Bearer ${currentUser.jwtToken}`;
    }
    return config;
});

axios.interceptors.response.use(
    response => response,
    (error) => {
    //     debugger;
    //     const { data, status, config } = error.response;
    // switch (status) {
    //     case 400:
    //         if (data.errors) {
    //             const modalStateErrors = [];
    //             for (const key in data.errors) {
    //                 if (data.errors[key]) {
    //                     modalStateErrors.push(data.errors[key]);
    //                 }
    //             }
    //             throw modalStateErrors.flat();
    //         } else {
    //             toast.error(data);
    //         }
    //         break;
    //     case 401:
    //         toast.error('unauthorised');
    //         break;
    //     case 403:
    //         toast.error('forbidden');
    //         break;
    //     case 404:
    //         toast.error('server error');
    //         break;
    //     case 500:
    //         toast.error('server error');
            
    //         break;
    // }
        
        Promise.reject(error)
    }
);

const requests = {
    get: url => axios.get(url).then(responseBody),
    post: (url, body) => axios.post(url, body).then(responseBody),
    put: (url, body) => axios.put(url, body).then(responseBody),
    del: url => axios.delete(url).then(responseBody)
};

const Notifications = {
    panicNotes: panicId => requests.get(`/Panics/GetPanicNotes/${panicId}`),
    callMeNotes: callMeId => requests.get(`/Panics/GetCallMeNotes/${callMeId}`),
    addPanicNote: panicNote => requests.post(`/Panics/AddPanicNote`, panicNote),
    addCallMeNote: callMeNote => requests.post(`/Panics/AddCallMeNote`, callMeNote),
    updatePanic: panic => requests.put(`/Panics/updatePanic`, panic),
    updateCallMe: callMe => requests.put(`/Panics/updateCallMe`, callMe),
    loadAllSystem: () => requests.get('/Panics/GetAllSystemNotifications'),
    loadMyNotifications: () => requests.get('/Panics/GetUserNotifications'),
    getPanicById: panicId => requests.get(`/Panics/GetPanicById/${panicId}`),
    getCallMeById: panicId => requests.get(`/Panics/GetCallMeById/${panicId}`)
};

const Supplier = {
    addSupplier: supplier => requests.post('/Supplier/AddSupplier', supplier),
    updateSupplier: supplier => requests.put('/Supplier/UpdateSupplier', supplier),
    getSupplier: supplierId => requests.get(`/Supplier/GetSupplierById/${supplierId}`),
    getAllSuppliersWithCompany: deleted => requests.get(`/Supplier/GetAllSuppliersWithCompany/${deleted}`),
    getSupplierHoldingCompanies: () => requests.get('/Company/GetAllHoldingCompanies/0')
};

const Settings = {
    addCategory: category => requests.post('/Settings/AddCategory', category),
    addSubCategory: subCategory => requests.post('/Settings/AddSubCategory', subCategory),
    addCauses: cause => requests.post('/Settings/AddCause', cause),
    getAllCategories: () => requests.get('/Settings/GetAllCategories'),
    GetAllSubCategories: () => requests.get('/Settings/GetAllSubCategories'),
    getSubCategoriesByBreakdownCategoryId: breakdownCategoryId => requests.get(`/Settings/GetSubCategoriesByBreakdownCategoryId/${breakdownCategoryId}`),
    getCauses: () => requests.get('/Settings/GetAllCause'),
    updateCauses: (cause) => requests.post('Settings/UpdateCause',cause),
    updateCategories: (categories) => requests.post('Settings/UpdateCategories',categories),
    updateSubCategory: (subCategory) => requests.post('Settings/UpdateSubCategory',subCategory)
};

const Company = {
    getallCompanies: () => requests.get('/Company/getallcompanies'),
    getCompanyById: companyId => requests.get(`/Company/getcompanybyid/${companyId}`),
    updateCompany: company => requests.post('/Company/updatecompany', company),
    addCompany: company => requests.post('/Company/addcompany', company),
    getOwnerById: ownerId => requests.get(`/Company/GetOwnerById/${ownerId}`),
    getDriverById: driverId => requests.get(`/drivers/GetDrivers/GetDriverById/${driverId}`),
    updateOwner: company => requests.put('/Company/UpdateOwner', company),
    updateDriver: company => requests.put('/Company/UpdateDriver', company),
    getRulesByBreakdownId: (breakdownId) => requests.get(`/Breakdowns/GetCompanyRulesByBreakdownId/${breakdownId}`),
    getRulesByCompanyId: companyId => requests.get(`/Breakdowns/GetCompanyRules/${companyId}`),
    updatedRulesByCompanyId: rulesModel => requests.post(`/CompanyRules/updateRulesByCompany`,rulesModel),
    getCustomRulesByCompanyId: companyId => requests.get(`/Breakdowns/GetCompanyCustomRules/${companyId}`),
    updatedCustomRulesByCompanyId: rulesModel => requests.post(`/CompanyRules/updateCustomRulesByCompany`,rulesModel),
    getSupplers: () => requests.get('/Company/GetCompanyList/1'),
    getSupplersByCompanyId: (companyId) => requests.get(`/Company/GetSupplierByCompanyId/${companyId}`),
    getCompaniesByType:(type) => requests.get(`/company/getcompaniesbytype/${type}`)
    
};  

const Users = {
    getAllAdmins: () => requests.get('/User/GetAllAdmins'),
    getById: userId => requests.get(`/User/GetAdminById/${userId}`),
    addUser: user => requests.post('/User/AddUser', user),
    editUser: user => requests.put('/User/UpdateUser', user),
    checkOwnerMobile: number => requests.get(`/User/CheckOwnerMobileNumber/${number}`),
    checkDriverMobile: number => requests.get(`/User/CheckDriverMobileNumber/${number}`)
};

const Vehicle = {
    addVehicle: (vehicle) => requests.post('/VehicleList/AddNewVehicleByCompany', vehicle),
    editVehicle: (vehicle) => requests.put('/VehicleList/EditVehicle', vehicle),
    getById: (id) => requests.get(`/VehicleList/GetVehicleById/${id}`)
};

const BreakdownRequest = {
    getRequestByRef: (breakdownRef) => requests.get(`/breakdownView/GetBreakdownRequestByRef/${breakdownRef}`),
    getById: id => requests.get(`/Breakdowns/GetBreakdownRequestModelById/${id}`),
    createBreakdownRequest:(model) => requests.post('/Breakdowns/CreateBreakdownRequest2',model),
    createBreakdown: requestId => requests.post(`/Breakdowns/CreateBreakdownFromRequest/${requestId}`, {}),
    updateBreakdownRequest: request => requests.put(`/Breakdowns/UpdateBreakdownRequest`, request),
    activeBreakdownList: () => requests.get('/Breakdowns/ActiveBreakdownList'),
    delayed:() => requests.get('/Breakdowns/DelayedBreakdowns'),
    active:() =>requests.get('/Breakdowns/ActiveBreakdownList'),
    qa:() =>requests.get('/Breakdowns/QABreakdowns'),
    workshop:() =>requests.get('/Breakdowns/WorkshopBreakdowns'),
    requestCount: () => requests.get('/Breakdowns/GetActiveBreakdownRequestsCount'),
    insertBreakdownChangeLog:(breakdownChangeLog) => requests.post('/Breakdowns/InsertBreakdownChangeLog', breakdownChangeLog),
    getBreakdownById: (breakdownId) => requests.get(`/Breakdowns/getbreakdownbyid/${breakdownId}`),
    updateBreakdownAddress:(addressDetail) => requests.put('/Breakdowns/updateBreakdownAddress', addressDetail),
    assignBreakdown:(assignBreakdownModel)=> requests.put('/Breakdowns/AssignBreakdown',assignBreakdownModel),
    deleteNote:(noteId) => requests.put(`/Breakdowns/DeleteNote/${noteId}`, {}),
    cancellationReasons: () => requests.get('/Breakdowns/CancellationReasons'),
    updateBreakdownCancelled: (model) => requests.post('/Breakdowns/UpdateBreakdownCancelled', model)
  };

  const BreakdownSearch = {
    SearchForBreakdowns: searchParameters => requests.post(`/BreakdownSearch/breakdownsearch`, searchParameters),
    downloadLog: (breakdownId) => requests.get(`/Breakdowns/DownloadLog/${breakdownId}`)
  }

  const MyBreakdowns = {
    active:(userId) =>requests.get(`/Breakdowns/MyActiveBreakdowns/${userId}`),
    qa:(userId) =>requests.get(`/Breakdowns/MyQABreakdowns/${userId}`),
    delayed:(userId) => requests.get(`/Breakdowns/MyDelayedBreakdowns/${userId}`),
    workshop:(userId) =>requests.get(`/Breakdowns/MyWorkShopBreakdowns/${userId}`),
  }

  const Mechanic = {
    getById: id => requests.get(`/Company/GetMechanicDetailsById/${id}`),
    editMechanicDetail: (mechanic) => requests.put('/Company/UpdateMechanic', mechanic),
    addMechanicDetail: (mechanic) => requests.post('/Company/AddMechanicDetail', mechanic)
  }

const Drivers = {
      getCompanyDrivers:(companyId) => requests.get(`/drivers/getdrivers/getcompanydrivers/${companyId}`)
  }

  const BlobStorage = {
    uploadFile: (formData) => requests.post('/BreakdownBlob/UploadBreakdownImage', formData),
    downloadFile: (containerName, blobName) => requests.get(`/BreakdownBlob/DownloadBreakdownImage?containerName=${containerName}&blobName=${blobName}`)
};



const agent = {
    Notifications,
    Supplier,
    Company,
    Users,
    Vehicle,
    BreakdownRequest,
    BreakdownSearch,
    Mechanic,
    BlobStorage,
    MyBreakdowns,
    Settings,
    Drivers
};

export default agent;
