import * as signalR from "@microsoft/signalr";
import { makeAutoObservable, runInAction } from "mobx";
import { store } from "./store";
import { appSettings,signalRHub } from "../../AppSettings";



export default class SignalRStore {
  hubConnection = null;

  constructor() {
    makeAutoObservable(this);
  }

  createHubConnection = async () => {
    // const user = await JSON.parse(sessionStorage.getItem('currentUser'));
    // this.hubConnection = new signalR.HubConnectionBuilder()
    //   .withUrl(signalRHub , {
    //   //  accessTokenFactory: () =>   user.jwtToken,
    //   //  skipNegotiation: true,
    //   //  transport: signalR.HttpTransportType.WebSockets,
    //   })
    //   .withAutomaticReconnect()

    //   .build();

    // // Start the connection
    // this.hubConnection
    //   .start()
    //   .then(() => {
    //     console.log("SignalR connection established");
    //   })
    //   .catch((error) => {
    //     console.error("SignalR connection error: ", error);
    //   });

    // this.hubConnection.on("SendPanic", (panic) => {
    //   runInAction(() => {
    //     store.notificationStore.addNotification(panic);
    //     store.notificationStore.setNotificationDrawer(true);
    //   });
    // });

    // this.hubConnection.on("SendCallMe", (panic) => {
    //   runInAction(() => {
    //     store.notificationStore.addNotification(panic);
    //     store.notificationStore.setNotificationDrawer(true);
    //   });
    // });

    // this.hubConnection.on("SendBreakdown", (breakdown) => {
    //   runInAction(() => {
    //     store.breakdownStore.addBreakdown(breakdown);
    //   });
    // });

    // this.hubConnection.on("AssignPanic", (panic) => {
    //   runInAction(() => {
      
    //     store.notificationStore.loadAllSystemNotifications();
    //     store.notificationStore.loadMyNotifications();
    //   });
    // });

    // this.hubConnection.on("AssignCallMe", (callMe) => {
    //   runInAction(() => {
      
    //     store.notificationStore.loadAllSystemNotifications();
    //     store.notificationStore.loadMyNotifications();
    //   });
    // });
  };

  stopHubConnection = () => {
    // this.hubConnection
    //   ?.stop()
    //   .catch((error) => console.log("Error stopping connection: ", error));
  };

  assignPanic = async (panicId, loginId) => {
    // try {
     
    //   await this.hubConnection?.invoke("AssignPanic", panicId, loginId);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  assignCallMe = async (callMeId, loginId) => {
    // try {
      
    //   await this.hubConnection?.invoke("AssignCallMe", callMeId, loginId);
    // } catch (error) {
    //   console.log(error);
    // }
  };
}
