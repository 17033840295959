

import { useLocation } from "react-router-dom";
import { useContext, useState, useEffect, useMemo } from "react";
import SubmenuFilter from "../../../../components/SubmenuFilter";
import Navbar from "../../../../components/Navbar";
import { Row, Col, Button, Input, Modal, Form, Select } from "antd";
import {
  DataCountContext,
  DataCountProvider,
} from "../../../../components/DataCountContext";
import { AiOutlinePlus, AiFillEdit, AiOutlineUpload } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import useCaseInsensitiveSort from "../../../../components/utilities/useCaseInsensitiveSort";
import agent from "../../../../Utilities/API/agent";
import EditSubCategory from "./EditSubCategory";
import AddSubCategory from "./AddSubCategory";

const SubCategoryList = () => {
  const location = useLocation();
  const breakdownCategoryId = location.state.breakdownCategoryId;
  const categoryName = location.state.categoryName;
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const [rnrSubCategories, setRnrSubCategories] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState ();
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("categoryName");
  const [addSubCategoryVisble, setAddSubCategoryVisble] = useState();
  const [editSubCategoryVisble, setEditSubCategoryVisble] = useState();
  const sortSubCatAlphabetically = (a, b) => {
    if (a.categoryName < b.categoryName) {
      return -1;
    }
    if (a.categoryName > b.categoryName) {
      return 1;
    }
    return 0;
  };


  const handleAddSubCategoryClick = (row) => {
    setSubCategoryId(row.id)
    setAddSubCategoryVisble(true);
    fetchData();
  };

  const handleCloseAddSubCategoryClick = () => {
    setEditSubCategoryVisble(true);
    fetchData();
  };

  const handleCloseEditSubCategoryClick = () => {
    setEditSubCategoryVisble(false);
    fetchData();
  };
  const handleEditSubCategoryClick = (row) => {
    setSubCategoryId(row.id);
    setEditSubCategoryVisble(true);
    
  };
  const columns = useMemo(
    () => [
      {
        name: "id",
        selector: (row) => row.id,
        sortField: "id",
        sortable: true,
        omit: true,
      },
      {
        name: "Sub Category Names",
        selector: (row) => row.subCategoryName,
        sortField: "subCategoryName",
        //sortFunction: caseInsensitiveSortFullName,
        sortable: true,
        omit: false,
        cell: (row) => <div>{row.categoryName}</div>,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
              onClick={() => handleEditSubCategoryClick(row)}
            >
              <span className="mr-2">Edit Sub Category</span>

              <AiFillEdit />
            </button>
          </div>
        ),
      },
      ,
    ],
    [] // Dependencies array; empty array means columns will be memoized and created only once
  );

  
    const fetchData = async () => { 
      var data = await agent.Settings.getSubCategoriesByBreakdownCategoryId(breakdownCategoryId);
      const sortedCategory = data.sort(sortSubCatAlphabetically);
      setRnrSubCategories(sortedCategory);
    };
    useEffect(() => {

    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <DataCountProvider>
          <div>
            <Navbar />
            <SubmenuFilter dataCount={dataCount} />
            <div></div>
          </div>
        </DataCountProvider>

        <div className="flex items-center justify-center h-full mt-3">
          <div className="text-center font-serif text-lg font-semibold text-gray-800 tracking-wide uppercase border-b-2 border-gray-300 inline-block py-1">
            {categoryName}
          </div>
        </div>

        <div
          className="pt-2 pl-5"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={handleAddSubCategoryClick}
          >
            <span className="mr-2">Add SubCategory</span>
            <AiOutlinePlus />
          </button>
        </div>

        <div className="pl-5 pr-5">
          
          <DataTable
          
            columns={columns}
            data={rnrSubCategories}
            pagination={false}
            striped={true}
            highlightOnHover={true}
            onRowClicked={handleEditSubCategoryClick}
            className=" border"
            cellClassName="border-b border-[#f9787c] px-4 py-2"
            headerClassName="bg-[#f9787c] text-white font-semibold"
             defaultSortFieldId={2}
             defaultSortAsc={false}
          />
        </div>
        <br />
        <br />
      </div>
      {addSubCategoryVisble && (
        <AddSubCategory
          visible={addSubCategoryVisble}
          onClose={handleCloseAddSubCategoryClick}
          breakdownCategoryId={breakdownCategoryId}
          subCategoryId ={subCategoryId}
        />
      )}
      {editSubCategoryVisble && (
        <EditSubCategory
          visible={editSubCategoryVisble}
          onClose={handleCloseEditSubCategoryClick}
          breakdownCategoryId={breakdownCategoryId}
          subCategoryId ={subCategoryId}
        />
      )}
    </>
  );
};

export default SubCategoryList;


