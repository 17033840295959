import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Drawer, Tabs } from 'antd';
import PanicModal from './PanicModal';
import { useStore } from '../../Utilities/Stores/store';
import NotificationSideBar from './NotificationSideBar';
import CallMeModal from './CallMeModal';
//import { currentUser } from '../../AppSettings';

const { TabPane } = Tabs;

const NotificationDrawer = ({ visible, onClose }) => {
  
  const { notificationStore, signalRStore } = useStore();
  const { notifications, myNotifications, loadAllSystemNotifications, loadMyNotifications } = notificationStore;
  const { assignPanic,assignCallMe } = signalRStore;
  
  useEffect(() => {
    loadAllSystemNotifications();
    loadMyNotifications();
  }, []);

  const handlePanicReferenceClick = async (model) => {
    const currentUser = await JSON.parse(sessionStorage.getItem('currentUser'));
   
    switch(model.title) {
      case 'PANIC': assignPanic(model.id, currentUser.loginId);
      break;
      case 'CALL ME': assignCallMe(model.id, currentUser.loginId);
      
    }
    
    // Open the modal for this specific panic
    setModalVisible(model);
  };


  const [openModals, setOpenModals] = useState([]);

  const setModalVisible = (model) => {
   
    setOpenModals([...openModals, model]);
  };

  const closePanicModal = (panicDataId) => {
    setOpenModals(openModals.filter(model => model.id !== panicDataId));
  };

  return (
    <>
      <Drawer
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={visible}
        width={360}
      >
        <Tabs defaultActiveKey="2">
          <TabPane tab="My Notifications" key="1">
           
            {myNotifications && myNotifications.map((message) => (
              <p key={message.id} onClick={() => handlePanicReferenceClick(message)}>
                <NotificationSideBar notificationModel={message} />
              </p>
            ))}
          </TabPane>
          <TabPane tab="System" key="2">
            {notifications && notifications.map((message) => (
              <p key={message.id} onClick={() => handlePanicReferenceClick(message)}>
                <NotificationSideBar notificationModel={message} />
              </p>
            ))}
          </TabPane>
        </Tabs>
      </Drawer>
      {openModals.map((model) => (
        (model.title == 'PANIC') ?
        <PanicModal
        key={model.id}
        panicId={model.id}
        notificationType={(model.title == 'PANIC') ? 1 : 2}
        visible={true}
        onClose={() => closePanicModal(model.id)}
      /> :
       <CallMeModal
       key={model.id}
       panicId={model.id}
       visible={true}
       onClose={() => closePanicModal(model.id)}
       />
      
       
      ))}
    </>
  );
};

export default observer(NotificationDrawer);
