import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsService, DirectionsRenderer, Circle } from '@react-google-maps/api';
import {addressSearch,lookupAddress, reverseGeoSearch} from "../../Utilities/HereMaps/AddressUtils";
import agent from '../API/agent';
const containerStyle = {
  width: '100%',
  height: '400px'
};

function Display({ latitude, longitude, breakdownLatitude, breakdownLongitude,breakdownId, onPinDrop }) {
  
 
  const [keyCount, setKeyCount] = useState(0);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDdwbkZz09QW_JFTXlCOQArC3jdk9hBPa0"
  });

  // const center = {
  //   lat: latitude,
  //   lng: longitude
  // };

  // const breakdownPoint = {
  //   lat: breakdownLatitude,
  //   lng: breakdownLongitude
  // };

  const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 1,
    strokeWeight: 2,
    strokeDashArray: '8,4', // Dashed border
    fillColor: '#4285F4',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 500 // 500 meters
  };

  const [map, setMap] = React.useState(null);
  const [directions, setDirections] = React.useState(null);
  const [distance, setDistance] = React.useState(null);
  const [duration, setDuration] = React.useState(null);
  
  const [center,setCenter] = useState({
    lat: latitude,
    lng: longitude
  });


  const defaultCenter = { lat: 0, lng: 0 };
  const defaultBreakdownPoint = { lat: 0, lng: 0 };

  // Initialize state with default values
  const [breakdownPoint, setBreakdownPoint] = useState({
    lat: breakdownLatitude || defaultBreakdownPoint.lat,
    lng: breakdownLongitude || defaultBreakdownPoint.lng
  });

  useEffect(() => {
    let newVal = keyCount + 1
    setKeyCount(newVal);
  }, [latitude])

  useEffect(() => {
    if (breakdownLatitude && breakdownLongitude) {
      setBreakdownPoint({
        lat: parseFloat(breakdownLatitude),
        lng: parseFloat(breakdownLongitude)
      });
    }
  }, [breakdownLatitude, breakdownLongitude]);


  
  useEffect(() => {

    if (isLoaded) {
      if (latitude && longitude && breakdownLatitude && breakdownLongitude) {
        calculateDirections();
      }else{
        setDirections(null);
        setDistance(null)
      }
    }
  }, [isLoaded, latitude]);

  const _reverseGeoSearch = async (lat, lng) => {

    const result = await reverseGeoSearch(lat,lng);
    agent.BreakdownRequest.updateBreakdownAddress({
      breakdownId:breakdownId,
      latitude:lat.toString(),
      longitude:lng.toString(),
      breakdownAddress:result
    }).then(()=>{onPinDrop();})
    
  };


  const handleMarkerPositionChange = (event) => {
    
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    setBreakdownPoint(newPosition);
    setCenter(newPosition);
    reverseGeoSearch(newPosition.lat, newPosition.lng)
      .then((result) => {
        agent.BreakdownRequest.updateBreakdownAddress({
          breakdownId,
          latitude: newPosition.lat.toString(),
          longitude: newPosition.lng.toString(),
          breakdownAddress: result
        }).then(() => {
          onPinDrop();
        });
      });
  };

  const onLoad = React.useCallback(function callback(map) {
    
    const bounds = new window.google.maps.LatLngBounds();
    if (center.lat && center.lng && !isNaN(center.lat) && !isNaN(center.lng)) {
      bounds.extend(center);
      bounds.extend(breakdownPoint);
      map.fitBounds(bounds);
    }
    
    setMap(map);
  }, [center, breakdownPoint, map]);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const calculateDirections = () => {
    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: center,
        destination: breakdownPoint,
        travelMode: 'DRIVING'
      },
      (result, status) => {
        if (status === 'OK') {
          setDirections(result);
          if (result.routes && result.routes.length > 0) {
            const leg = result.routes[0].legs[0];
            setDistance(leg.distance.text);
            setDuration(leg.duration.text);
          }
        } else {
          console.error('Directions request failed with status:', status);
        }
      }
    );
  };

  

  return (
    isLoaded && !loadError ? (
      <GoogleMap
        key={keyCount}
        mapContainerStyle={containerStyle}
        center={breakdownPoint}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {/* <Marker draggable position={center} /> */}
        <Marker draggable position={breakdownPoint}  onDragEnd={handleMarkerPositionChange}/>
        {directions && <DirectionsRenderer directions={directions} />}
        <Circle center={breakdownPoint} options={circleOptions} />
        {distance && (
          <div
            style={{
              position: 'absolute',
              top: '70px',
              left: '10px',
              background: 'rgba(255, 90, 96, 0.9)',
              fontWeight: '600',
              color: 'white',
              padding: '9px',
              border: '1px solid red',
              borderRadius: '8px',
              width: '180px'
            }}
          >
            <div>Driving Distance: {distance}</div>
            <div>Duration: {duration}</div>
          </div>
        )}
      </GoogleMap>
    ) : (
      <div>Error: Failed to load Google Maps API</div>
    )
  );
}

export default React.memo(Display);
