import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
  Select,
  Col,
  Row,
  Alert,
} from "antd";

import agent from "../../../Utilities/API/agent";


const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
}
const AddUser = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [selectedCompany, setCompany] = useState();
  const [companies, setCompanyList] = useState();
  const [modalVisible, setModalVisible] = useState(visible);
  const [isSaving, setIsSaving] = useState(false);
  const [isConsultant, setIsConsultant] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
       values.isConsultant = isConsultant;
       values.isSupervisor = isSupervisor;

        agent.Users.addUser(values).then(() =>  setIsSaving(false)).then(() => onClose());
         
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
    onClose();
  };


  useEffect(() => {
    
    setModalVisible(true);
    const fetchData = async () => {
      const data = await agent.Company.getallCompanies();
      setCompanyList(data);
    };
    fetchData();
  }, [visible]);

  return (
    <Modal
      open={modalVisible}
      onCancel={handleCancel}
      width={'70%'}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={isSaving} onClick={handleSubmit}>
          SAVE
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row style={sectionHeader}>
          <Col span="24">ADD USER</Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>Full Name</b>
                <Form.Item
                  name="fullName"
                  rules={[
                    { required: true, message: "Please enter full name" },
                  ]}
                >
                  <Input placeholder="Enter full name" />
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                <b>Mobile Number</b>
                <Form.Item
                  name="mobileNumber"
                  rules={[
                    { required: true, message: "Please enter mobile number" },
                  ]}
                >
                  <Input placeholder="Enter mobile number" />
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                <b>Password</b>
                <Form.Item name="password">
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>Email</b>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input placeholder="Enter email" />
                </Form.Item>
              </Col>
                           
              
            </Row>
        <Row gutter={16}>
        <Col span={24} style={dataInputColumn}>
          <b>Additional Options</b>
        </Col>
          <Form.Item className="mb-4">
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isConsultant}
                  value={isConsultant}
                  onChange={(event)=>{ setIsConsultant(event.target.checked)}}
                  className="ml-2"
                />
                Consultant
              </label>
            </div>
          </Form.Item>
          <Form.Item className="mb-4">
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isSupervisor}
                  value={isSupervisor}
                  onChange={(event)=>{ setIsSupervisor(event.target.checked)}}
                  className="ml-2"
                />
                Supervisor
              </label>
            </div>
          </Form.Item>
            </Row>
          </Col>
          
        </Row>
        
      </Form>
    </Modal>
  );
};

export default AddUser;
