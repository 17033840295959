import {useState, useEffect} from 'react';
import { appSettings } from '../AppSettings';


const apiUrl = 'https://uat-m-api-v2.rightnowresponse.com/api';
//const apiUrl = 'https://localhost:7091/api';

export default function DashboardTvLeft() {
    
    const [breakdownStatistics, setBreakdownStatistics] = useState({});

    const getBreakdownStatistics = async () => {
        const response = await fetch(appSettings.apiUrl + '/statistics/DashboardStatistics/screen1');
        //const response = await fetch('https://uat-m-api-v2.rightnowresponse.com/api/statistics/DashboardStatistics/screen1');
        const json = await response.json();

        setBreakdownStatistics(json);
        //This is a test
    }

    useEffect(() => {
        getBreakdownStatistics()

        const interval = setInterval(() => {
            getBreakdownStatistics()
        }, 600000)

        return () => clearInterval(interval);
    },[])

    return (
        <div>
            <div className="row">
                <div className="col-md-8">
                    <BreakdownRequests />                        
                        
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card text-center">
                                <div className="card-header ">
                                <div style={{fontSize:48, fontWeight: 'bold'}}>BREAKDOWNS THIS MONTH</div>
                                </div>
                                <div className="card-body">
                                <div style={{fontSize:72, fontWeight: 'bold'}}> {breakdownStatistics.breakdownCount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="card  text-center">
                                <div className="card-header ">
                                <div style={{fontSize:48, fontWeight: 'bold'}}>AVERAGE REPAIR TIME</div>
                                </div>
                                <div className="card-body">
                                    <div style={{fontSize:72, fontWeight: 'bold'}}> {breakdownStatistics.breakdownAverageRepairTime}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="card  text-center">
                        <div className="card-header ">
                        <div style={{fontSize:48, fontWeight: 'bold'}}>TECHNICIANS <br /> ACCEPTED</div>
                        </div>
                        <div className="card-body">
                            <div style={{fontSize:72, fontWeight: 'bold'}}> { parseFloat(breakdownStatistics.breakdownsAccepted).toFixed(2) + '%'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card  text-center">
                        <div className="card-header ">
                        <div style={{fontSize:48, fontWeight: 'bold'}}>TRANSPORTERS <br /> ASSISTED</div>
                        </div>
                        <div className="card-body">
                            <div style={{fontSize:72, fontWeight: 'bold'}}> {breakdownStatistics.breakdownCustomerCount}</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card  text-center">
                        <div className="card-header ">
                        <div style={{fontSize:48, fontWeight: 'bold'}}>AVERAGE TIME TO ON SITE</div>
                        </div>
                        <div className="card-body">
                            <div style={{fontSize:72, fontWeight: 'bold'}}> {breakdownStatistics.breakdownAverageOnSiteTime}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    )
}

const BreakdownRequests = () => {

    const [breakdownRequests, setBreakdownRequests] = useState([]);
    

    const getActive = async () => {
        const response = await fetch(appSettings.apiUrl + '/breakdownrequests/BreakdownRequest/getactiverequests');
        const json = await response.json();

        setBreakdownRequests(json);
    }
    useEffect(() => {
        getActive();

        const interval = setInterval(() => {
            getActive()
        }, 30000)

        return () => clearInterval(interval);
    },[]);


    return (
        <div className="row">
            {breakdownRequests.map((br) => (
                <div className="col-md-3 mb-2">
                    <div className="card text-center" style={{border:'solid 1px red'}}>
                        <div className="card-header" style={{backgroundColor: 'red', color:'white'}}>
                            {br.breakdownReference}
                        </div>
                        <div className="card-body" style={{fontSize:'36', fontWeight:'bold'}}>
                            {br.companyName} <br />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}