import { Outlet } from 'react-router';
import { Layout } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';

const {Header, Sider, Content, Footer} = Layout;

export default function MobileLayout() {
    
    return (
        <Outlet />
    )
    
}

const footerMenuItems = [
    {
        text: 'Breakdowns',
        link: 'http://localhost'
    },
    {
        text: 'Inspections',
        link: 'http://localhost'
    }
]