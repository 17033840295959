import React from 'react';
import {Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';

const EditNoteModal = ({
  showEditNoteModal,
  closeEditNoteModal,
  currentNoteBeingEdited,
  setCurrentNoteBeingEdited,
  currentNoteBeingEditedId,
  editBreakdownNoteDetails
}) => (
  <Modal
    title={<h2 className="text-center">Edit Note</h2>}
    open={showEditNoteModal}
    onCancel={closeEditNoteModal}
    footer={null}
    centered
    className="pt-0 pb-0"
    height="80vh"
  >
    {currentNoteBeingEdited && currentNoteBeingEditedId && (
      <div>
        <TextArea
          rows={4}
          value={currentNoteBeingEdited}
          onChange={event => setCurrentNoteBeingEdited(event.target.value)}
        />

        <br />
        <br />

        <div className="flex justify-end">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={editBreakdownNoteDetails}
          >
            Save
          </button>
        </div>
      </div>
    )}
  </Modal>
);

export default EditNoteModal;
