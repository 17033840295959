import {useEffect, useState} from 'react';
import GoogleMapReact from 'google-map-react';
import { FaTriangleExclamation } from "react-icons/fa6";
import {Drawer, Col, Row, Card} from 'antd';
import SubmenuFilter from '../../../components/SubmenuFilter';
import { appSettings } from '../../../AppSettings';



export default function PortalSecureDashboardsConsultant() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const token = currentUser.jwtToken;

    //Page Data
    const [breakdowns, setBreakdowns] = useState([]);
    const [viewingBreakdown, setViewingBreakdown] = useState({});
    //Drawer controllers
    
    //Card Controllers
    const [cardsOpen, setCardsOpen] = useState(false)

    const BreakdownLocation = ({breakdownInfo}) => (
        <div style={{cursor:'pointer'}}
             onClick={() => {setViewingBreakdown(breakdownInfo); setCardsOpen(true)}} >
            <FaTriangleExclamation size={20} color={'#FFDA2F'}/>
        </div>
    );

    const mapDefaults = {
        center: {
            lat: -29.957327361811412 ,
            lng: 25.27748099580119
        },
        zoom: 6
    }

    const getBreakdowns = async() => {
        const result = await fetch(appSettings.apiUrl + '/breakdowns/getbreakdownsbyuser', {
            method: 'get',
            headers: {
                Authorization: `bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const json = await result.json();
        setBreakdowns(json.breakdowns);
    }

    useEffect(() => {
        getBreakdowns();
    }, [])

    return (
        <div >
            <SubmenuFilter />
            <div style={{position:'relative'}}>
                <div style={{height:'80vh', position: 'relative' }} >
                        <GoogleMapReact 
                        bootstrapURLKeys={{ key: 'AIzaSyCPC4BU1rYr6a3YL6dNW-vFaKZ0VOg935U' }}
                        defaultCenter={mapDefaults.center}
                        defaultZoom={mapDefaults.zoom}
                        onClick={() => {setCardsOpen(false)}}
                        >
                            {breakdowns.map((breakdown) => (
                                <BreakdownLocation 
                                    breakdownInfo={breakdown}
                                    lat = {breakdown.latitude}
                                    lng = {breakdown.longitude}
                                />
                            ))}
                            
                        </GoogleMapReact>
                        
                        {cardsOpen && (
                            
                        <div
                            style={{
                            position: 'absolute',
                            top: '10px', // Adjust this to position the Card vertically
                            left: '10px', // Adjust this to position the Card horizontally
                            zIndex: 1, // Ensures the Card appears above the map
                            }}
                        >
                            <Card title={viewingBreakdown.breakdownReference} extra="View" style={{ width: 300 }}>
                                <Row>
                                    <Col span={24}>{viewingBreakdown.description}</Col>
                                </Row>
                                <Row style={breakdownDrawerCSS.row}>
                                    <Col span={12}>{viewingBreakdown.registrationNumber}  </Col>
                                    <Col span={12}>{viewingBreakdown.make} </Col>
                                </Row>
                                <Row >
                                    <Col span={12}>{viewingBreakdown.fleetNumber}  </Col>
                                    <Col span={12}>{viewingBreakdown.model} </Col>
                                </Row>
                            </Card>
                        </div>      
                        )}  

                        {cardsOpen && (
                            
                            <div
                                style={{
                                position: 'absolute',
                                top: '10px', // Adjust this to position the Card vertically
                                right: '10px', // Adjust this to position the Card horizontally
                                zIndex: 1, // Ensures the Card appears above the map
                                }}
                            >
                                <Card title="Notes" style={{ width: 300 }}>
                                    
                                </Card>
                            </div>      
                            )}  
                </div>
            </div>

                

        </div>
    )
}

const breakdownDrawerCSS = {
    row : {
    marginTop:'10px', 
    borderTop:'1px solid rgba(5, 5, 5, 0.06)',
    paddingTop: '6px'
    }
}