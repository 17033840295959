import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { appSettings } from '../../../AppSettings';
import { useNavigate, navigate } from 'react-router-dom';
import SubmenuFilter from '../../../components/SubmenuFilter';
import {Container, Row, Col} from 'react-bootstrap';
import { DatePicker, Select, Button, Modal,Card,Input } from 'antd';
import agent from '../../../Utilities/API/agent';
import { DataCountProvider } from '../../../components/DataCountContext';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function PortalSecureBreakdownsSearch() {

    const [breakdowns, setBreakdowns] = useState();
    const [reference, setReference] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedDates, setSelectedDates] = useState();
    const [driverName, setDriverName] = useState('');
    const [fleetNumber, setFleetNumber] = useState('');
    const [supplier, setSupplier] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [mechanic, setMechanic] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [pending, setPending] = useState(false);

    const {RangePicker} = DatePicker;

    const navigate = useNavigate();

    const handleRowClicked = (row) => {
        
        navigate('/portal/secure/breakdownDetails', { state: { breakdownId: row.breakdownId } });
    };
    const downloadLog = async (row) => {
      
       var base64String = await agent.BreakdownSearch.downloadLog(row.breakdownId);
        
           
        const decodedData = atob(base64String);
    
        // Convert the decoded data to a Uint8Array
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; ++i) {
          uint8Array[i] = decodedData.charCodeAt(i);
        }
        
        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
    saveAs(blob, `log.xlsx`);
          
    }
    
const columns = [
    {
        name: 'Reference',
        sortable: true,
        selector: (row) => (row.breakdownReference)
    },
    {
        name: 'Company Name',
        sortable: true,
        selector: (row) => (row.companyName)
    },
    {
        name: 'Date',
        sortable: true,
        selector: (row) => (row.createdDate)
    },
    {
        name: 'Reg.',
        sortable: true,
        selector: (row) => (row.registrationNumber)
    },
    {
        name: 'Status',
        sortable: true,
        selector: (row) => (row.breakdownStatus)
    },
    {
        name: 'Print Log',
        sortable: true,
        selector: (row) => (row.breakdownStatus),
        cell: (row) => (
            <Button type="link" onClick={() => downloadLog(row)}>
              Download
            </Button>
          ),
    }
]

    const fetchBreakdowns = async () => {

        setPending(true);
        if (selectedDates != null) {
            var startDate = selectedDates[0].$y + '-' + (selectedDates[0].$M + 1) + '-' + selectedDates[0].$D ;
            var endDate = selectedDates[1].$y + '-' + (selectedDates[1].$M + 1) + '-' + selectedDates[1].$D ;
        }
        
        const result = await agent.BreakdownSearch.SearchForBreakdowns({
            breakdownReference: reference,
            fromDate: startDate,
            toDate: endDate,
            driverName:driverName,
            fleetNumber:fleetNumber,
            registrationNumber:registrationNumber,
            suppliers:supplier,
            mechanicName:mechanic,
            companyName:companyName,
          });

          setBreakdowns(result);
          setPending(false);

        /*return await fetch(`${appSettings.apiUrl}/BreakdownSearch/breakdownsearch`, {
            method:'post',
            headers:{
                'Content-Type':'application/json',
                Authorization: `bearer ${JSON.parse(sessionStorage.getItem('currentUser')).jwtToken}`
            },
            body: JSON.stringify({
                breakdownReference: reference,
                fromDate: startDate,
                toDate: endDate,
                driverName:driverName,
                fleetNumber:fleetNumber
              })

        })
        .then(async response => {
            const json = await response.json();
            setBreakdowns(json);
            setPending(false);
        })
        .catch(error => console.error(error));*/

        
    }

    return (
        <div>
            <DataCountProvider>
                <SubmenuFilter />
            </DataCountProvider>
            <Container>
                <Row style={{marginTop:"5px"}}>
                    <Col sm={3}>
                        <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Reference</span> <br />
                        <Input value={reference} onChange={(e) => {setReference(e.target.value)}} />
                    </Col>
                    <Col sm={3}>
                        <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Date Range</span> <br />
                        <RangePicker onChange={(e) => {setSelectedDates(e)} } />
                    </Col>
                    <Col sm={3}>
                    <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Fleet Number</span> <br />
                        <Input value={fleetNumber} onChange={(e) => {setFleetNumber(e.target.value)}} />
                    </Col>
                    <Col sm={3}>
                        <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Driver Name</span> <br />
                        <Input value={driverName} onChange={(e) => {setDriverName(e.target.value)}} />
                    </Col>
                    <Col sm={3}>
                        <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Registration Number</span> <br />
                        <Input value={registrationNumber} onChange={(e) => {setRegistrationNumber(e.target.value)}} />
                    </Col>
                    <Col sm={3}>
                        <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Supplier</span> <br />
                        <Input value={supplier} onChange={(e) => {setSupplier(e.target.value)}} />
                    </Col>
                    <Col sm={3}>
                        <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Technician</span> <br />
                        <Input value={mechanic} onChange={(e) => {setMechanic(e.target.value)}} />
                    </Col>
                    <Col sm={3}>
                        <span style={{textTransform:'uppercase', fontWeight:'bold'}}>Company Name</span> <br />
                        <Input value={companyName} onChange={(e) => {setCompanyName(e.target.value)}} />
                    </Col>
                    <Row style={{marginTop:'8px'}}>
                        <Col>
                            <Button type="primary" style={{width:'100%'}} onClick={() => {fetchBreakdowns()}}>Search</Button>
                        </Col>
                    </Row>
                </Row>
                <Row style={{marginTop:'8px'}}>
                    <DataTable
                        title="Breakdowns"
                        columns={columns}
                        data={breakdowns}
                        pagination={true}
                        striped={true}
                        highlightOnHover={true}
                        onRowClicked={handleRowClicked}
                        className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                        cellClassName="border-b border-[#f9787c] px-4 py-2"
                        headerClassName="bg-[#f9787c] text-white font-semibold"
                        progressPending={pending}
                    />
                </Row>
                
            </Container>
        </div>
        
    )
    
}