import React, { useEffect, useState } from "react";
import { Modal, Button, Descriptions, Input, Select, Col, Row } from "antd";

import PanicNoteList from "./PanicNoteList";
import agent from "../../Utilities/API/agent";
import { observer } from "mobx-react-lite";
import { useStore } from "../../Utilities/Stores/store";

const { Option } = Select;

const PanicModal = ({ panicId, notificationType ,visible, onClose }) => {
  
  const [modalVisible, setModalVisible] = useState(visible);
  const { TextArea } = Input;
  const [panicNote, setPanicNote] = useState("");
  const [panicData, setPanicData] = useState();
  const [panicStatus, setPanicStatus] = useState();
  const [panicNotes, setPanicNotes] = useState([]);
  const [updatedPanicNotes, setUpdatedPanicNotes] = useState([]);
  const { signalRStore, notificationStore } = useStore();
  const { assignPanic } = signalRStore;
  const { loadMyNotifications } = notificationStore;
  const [currentUser,setCurrentUSer] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const user = await JSON.parse(sessionStorage.getItem('currentUser'));
      setCurrentUSer(user);
      
      const data = await agent.Notifications.getPanicById(panicId);
      setPanicData(data);
      setModalVisible(true);
      if (data.status == 0) {
        assignPanic(data);
      }
    };
    fetchData();
  }, [panicId]);

  const handleClose = () => {
    setModalVisible(false);

    onClose();
  };

  const handleSaveNote = async () => {
   

    try {
      const noteResponse = await agent.Notifications.addPanicNote({
        Id: "",
        PanicId: panicData.id,
        Note: panicNote,
        CreateDateTime: new Date().toISOString(),
        UserModelId: "",
      }) ;

      const updateResponse = await agent.Notifications.updatePanic({
        ...panicData, // Include all properties of panicData
        PanicStatus: panicStatus, // Update the PanicStatus property
        AssignedUser: currentUser.loginId, // Update the PanicStatus property
      });

      // Fetch updated list of panic notes after saving the note
      const updatedPanicNotes = await agent.Notifications.panicNotes(
        panicData.id
      );
      setUpdatedPanicNotes(updatedPanicNotes);
      loadMyNotifications();
    } catch (error) {
      console.error("Error:", error);
      // You can show an error message to the user if saving fails
    }

    // After saving, you may want to close the modal
    // handleClose();
  };

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  useEffect(() => {
    // Fetch initial list of panic notes when the modal is opened
    const fetchPanicNotes = async () => {
      try {
        const initialPanicNotes = await agent.Notifications.panicNotes(
          panicData.id
        );
        setPanicNotes(initialPanicNotes);
      } catch (error) {
        console.error("Error:", error);
        // You can show an error message to the user if fetching fails
      }
    };

    if (modalVisible && panicData) {
      // Check if panicData is truthy
      fetchPanicNotes();
    }
  }, [modalVisible, panicId, panicData]);

  return (
    currentUser && panicData && (
      <Modal
        title="Panic Details"
        visible={modalVisible}
        onCancel={handleClose}
        footer={[
          <Button key="close" onClick={handleClose}>
            Close
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveNote}>
            Save Note
          </Button>,
        ]}
        width={800} // Set the width of the modal
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Panic Reference">
                {panicData.panicReference}
              </Descriptions.Item>
              <Descriptions.Item label="Registration Number">
                {panicData.registrationNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle">
                {panicData.vehicle}
              </Descriptions.Item>
              <Descriptions.Item label="Panic Status">
                <Select
                  defaultValue={panicData.panicStatus.toString()}
                  style={{ width: "100%" }}
                  onChange={(value) => setPanicStatus(value)}
                >
                  <Option value="0">Open</Option>
                  <Option value="1">In Progress</Option>
                  <Option value="2">Closed</Option>
                  <Option value="3">Canceled</Option>
                  <Option value="4">Resolved</Option>
                </Select>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Latitude">
                {panicData.latitude}
              </Descriptions.Item>
              <Descriptions.Item label="Longitude">
                {panicData.longitude}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {panicData.address}
              </Descriptions.Item>
              <Descriptions.Item label="Assigned User">
                {currentUser.fullName}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <TextArea
          placeholder="Enter panic note..."
          autoSize={{ minRows: 2, maxRows: 3 }}
          value={panicNote}
          onChange={(e) => setPanicNote(e.target.value)}
        />
        <div style={{ marginTop: "10px" }}>
          <h2>Panic Notes</h2>
          <PanicNoteList
            panicId={panicData.id}
            notificationType={1}
            updatedPanicNotes={updatedPanicNotes}
          />
        </div>
      </Modal>
    )
  );
};

export default observer(PanicModal);
