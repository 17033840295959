import { useEffect, useState } from "react";
import { appSettings } from "../../../AppSettings";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { Layout } from "antd";
import Logo from "../../../assets/rnr_logo.png";
//import HereMapsDisplay from '../../../Utilities/HereMaps/Display';

import Display from "../../../Utilities/GoogleMaps/Display"; // Import the Google Maps Display component
const { Header, Content, Footer } = Layout;
const anchors = [100, window.innerHeight * 0.2, window.innerHeight * 0.8];
const minHeight = anchors[0];
const maxHeight = anchors[anchors.length - 1];

export default function PortalSecureBreakdownsBreakdownView() {
  let { breakdownRef } = useParams();
  const [breakdownDisplayInformation, setBreakdownDisplayInformation] = useState({});
  const [note, setNotes] = useState([]);
  const [mechanicsAndSuppliers, setmechanicsAndSuppliers] = useState([]);
  const formatDate = (inDate, utcHour = false) => {
    const outDate = new Date(inDate);
    if (utcHour) {
      return (
        outDate.getFullYear() +
        "-" +
        String(outDate.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(outDate.getDate()).padStart(2, "0") +
        " " +
        String(outDate.getUTCHours()).padStart(2, "0") +
        ":" +
        String(outDate.getMinutes()).padStart(2, "0")
      );
    } else {
      return (
        outDate.getFullYear() +
        "-" +
        String(outDate.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(outDate.getDate()).padStart(2, "0") +
        " " +
        String(outDate.getHours()).padStart(2, "0") +
        ":" +
        String(outDate.getMinutes()).padStart(2, "0")
      );
    }
  };

  const onPindrop = (item) => {
    console.log(item);
  };
  const fetchBreakdownData = async () => {
    await fetch(`${appSettings.apiUrl}/breakdownview/GetBreakdownDetailsIdByReference/${breakdownRef}`)
      .then(async (response) => {
        const json = await response.json();
        // Set the received breakdown data to state
        setBreakdownDisplayInformation({
          truckRegistrationNumber: json.truckRegistrationNumber,
          description: json.description,
          truckFleetNumber: json.fleetNumber,
          driverName: json.driverName,
          vinNumber: json.vinNumber,
          truckModel: json.model,
          truckMake: json.make,
          truckYear: json.year,
          breakdownAddress: json.breakdownAddress,
          caseNumber: json.caseNumber,
          latitude: json.latitude,
          longitude: json.longitude,
          driverAlias: json.driverAlias,
          contactNumber: json.contactNumber,
          vehicleLoad: json.vehicleLoad,
          reportedBy: json.reportedBy,
          registrationNumber: json.registrationNumber,
          driverCell: json.driverCell,
          breakdownReference: json.breakdownReference,
          companyName: json.companyName,
          reportedBy: json.reportedBy,
          fleetNumber: json.fleetNumber,
          warranty: json.warranty,
          dateTimeOffset: json.dateTimeOffset,
          descriptionName: json.descriptionName,
          vehicleLeaseType: json.vehicleLeaseType,
        });
      })
      .catch((error) => console.error(error));

    // Fetch additional details from another API
    await fetch(`${appSettings.apiUrl}/breakdownview/getBreakdownNotes/${breakdownRef}`)
      .then(async (response) => {
        const json = await response.json();

        setNotes(json);

        console.log(note);
      })
      .catch((error) => console.error(error));

    await fetch(`${appSettings.apiUrl}/breakdownview/getMechanicDetails/${breakdownRef}`)
      .then(async (response) => {
        const json = await response.json();

        setmechanicsAndSuppliers(json);
        console.log(mechanicsAndSuppliers);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    fetchBreakdownData();
  }, []);

  return (
    <div className={{ backgroundColor: "#fff", padding: "30px", borderRadius: "10px" }}>
      <Col span={12}>
        <div className='logo'>
          {" "}
          <img src={Logo} style={{ height: 70 + "px", marginTop: "5px" }} />
        </div>{" "}
      </Col>

      <Container>
        <Layout style={{ height: "64vh", width: "100%" }}>
          <Row className='mb-2'>
            <Col>
              <div className='bg-danger p-3 rounded'>
                <h2 className='text-white'>Breakdown Location</h2>
              </div>
            </Col>
          </Row>
          <Content>
            {breakdownDisplayInformation && (
              <Display
                breakdownLatitude={parseFloat(breakdownDisplayInformation.latitude)}
                breakdownLongitude={parseFloat(breakdownDisplayInformation.longitude)}
                breakdownId={breakdownDisplayInformation.id}
                onPinDrop={onPindrop}
              />
            )}
          </Content>
        </Layout>
        <Row className='mb-3'>
          <Col>
            <div className='bg-danger p-3 rounded'>
              <h2 className='text-white'>View Breakdown Details</h2>
            </div>
          </Col>
        </Row>
        {/* Breakdown details */}
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Breakdown Reference:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.breakdownReference}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Breakdown Status:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.descriptionName}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Date Created:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{formatDate(breakdownDisplayInformation.dateTimeOffset)}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Registration Number:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.registrationNumber}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Description:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.description}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Truck Fleet Number:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.fleetNumber}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Vin Number:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.vinNumber}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Truck Make:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.truckMake}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Truck Model:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.truckModel}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Truck Year:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.truckYear}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Breakdown Address:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.breakdownAddress}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Case Number:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.caseNumber}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Company Name:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.companyName}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Driver Name:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.driverAlias}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Driver Number:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.driverCell}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Controller:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.reportedBy}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Controller Number:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.contactNumber}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Warranty:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.warranty}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Vehicle Lease Type:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.vehicleLeaseType}</b>
          </Col>
        </Row>
        <Row className='mb-4 border-bottom border-dotted'>
          <Col span={12} className={viewPanelLabels}>
            Load:
          </Col>
          <Col span={12} className={viewPanelLabels}>
            <b>{breakdownDisplayInformation.vehicleLoad}</b>
          </Col>
        </Row>
        {/* Notes details */}
        <Row className='mb-4'>
          <Col>
            <div className='bg-danger p-3 rounded'>
              <h2 className='text-white'>Notes</h2>
            </div>
          </Col>
        </Row>
        {note.map((note, index) => (
          <Container key={index}>
            <Row className='mb-4 border-bottom border-dotted'>
              <Col span={24} className={`${viewPanelLabels} mb-3`}>
                <b>{note.text}</b>
              </Col>
              <Row>
                <Col span={12} className={viewPanelLabels}>
                  <b>{note.userName}</b>
                </Col>
                <Col span={12} className={viewPanelLabels}>
                  <b>{formatDate(note.createdTime)}</b>
                </Col>
              </Row>
            </Row>
          </Container>
        ))}
        {/* Mechanics details */}
        <Row className='mb-4'>
          <Col>
            <div className='bg-danger p-3 rounded'>
              <h2 className='text-white'>Mechanics</h2>
            </div>
          </Col>
        </Row>
        {mechanicsAndSuppliers.map((mechanicsAndSuppliers) => (
          <Container>
            <Row className='mb-4 border-bottom border-dotted'>
              <Col span={12} className={viewPanelLabels}>
                <b>{mechanicsAndSuppliers.companyName}</b>
              </Col>
              <Col span={12} className={viewPanelLabels}>
                <b>{mechanicsAndSuppliers.fullname}</b>
              </Col>
            </Row>
            {/* <Row className="mb-4 border-bottom border-dotted">
                        <Col span={12} className={viewPanelLabels}>Phone Number:
                        </Col> 
                        <Col span={12} className={viewPanelLabels}>
                         <b>{mechanicsAndSuppliers.mobileNum}</b>         
                        </Col>                  
                        </Row > */}

            {/* <Row className="mb-4 border-bottom border-dotted">
                        <Col span={24} className={viewPanelLabels}>Company Eamil:
                        </Col> 
                        <Col span={12} className={viewPanelLabels}>
                         <b>{mechanicsAndSuppliers.email}</b>         
                        </Col>                  
                        </Row > */}
          </Container>
        ))}
      </Container>
    </div>
  );
}

const viewPanelLabels = {
  fontFamily: "roboto",
  fontSize: "15pt",
};
