import React from 'react';

function BreakdownNotesButtonContainer({ children }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
      {children}
    </div>
  );
}

export default BreakdownNotesButtonContainer;
