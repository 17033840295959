import React from 'react';

function NoteTextarea({ value, onChange }) {
  return (
    <div style={{ height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
         className='rounded-lg border p-2 mb-0  relative border border-gray-300 text-gray-800 bg-white'>
      <textarea
        value={value}
        onChange={onChange}
        style={{
          width: '100%',
          height: '100%',
          marginTop: '0%',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          outline: 'none',
        }}
        onFocus={(e) => { e.target.style.border = '2px double green'; }}
        onBlur={(e) => { e.target.style.border = '1px solid #ccc'; }}
        placeholder="Enter note details...."
      />
    </div>
  );
}

export default NoteTextarea;

