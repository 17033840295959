import UpdatedNavbar from "../../components/utilities/UpdatedNavbar";
import { useContext, useState, useEffect } from "react";
import SubmenuFilter from "../../components/SubmenuFilter";
import Navbar from "../../components/Navbar";
import { AiFillEdit , AiOutlinePlus} from "react-icons/ai";
import useCaseInsensitiveSort from "../../components/utilities/useCaseInsensitiveSort";
import agent from '../../Utilities/API/agent';
import DataTable from "react-data-table-component";
import { Typography } from "antd";
import {
  DataCountContext,
  DataCountProvider,
} from "../../components/DataCountContext";
import { Link } from "react-router-dom";
import AddUser from "./Users/AddUser";
import EditUser from "./Users/EditUser";
const { Text } = Typography;

export default function User() {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const dataCount = useContext(DataCountContext) ?? 5;
    const caseInsensitiveSortFullName = useCaseInsensitiveSort("fullName");
    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState([]);
    const [addUserVisble,setAddUserVisble] = useState();
    const [editUserVisble,setEditUserVisble] = useState();
    const [userId, setUserId] = useState();
    const columns = [
        {
          name: "id",
          selector: (row) => row.id,
          sortable: true,
          omit: true,
        },
     
        {
          name: "Full Name",
          selector: (row) => (
            <Link onClick={() => handleEditUser(row)}>{row.fullName}</Link>
          ),
          sortField: "fullName",
          sortable: true,
          //sortFunction: caseInsensitiveSortFullName,
          omit: false,
          width: "40%",
        },
        {
            name: "Email Address",
            selector: (row) => (
                <Text type="primary" >{row.email}</Text>
              ),
          },
          {
            name: "Mobile Number",
            selector: (row) => (
                <Text type="primary">{row.mobileNumber}</Text>
              ),
          },
        
      ];
      const fetchData = async () => {
        const data = await agent.Users.getAllAdmins();
        setUsers(data);
    };
      useEffect(() => {
        
        
        fetchData();

      },[])
      const handleEditUser = (row) => {
        setUserId(row.id)
        setEditUserVisble(true);
      };
      const handleAddUser = () => {
        setAddUserVisble(true);
      };
      const handleSearch = (event) => {
        setSearchTerm(event.target.value);
      };
      
      const handleCloseAddUserClick = () => {
        setAddUserVisble(false);
        fetchData();
      };
      const handleCloseEditUserClick = () => {
        setEditUserVisble(false);
        fetchData();
      };
      const filteredUsers = users.filter((user) => {
        return user.fullName.toLowerCase().includes(searchTerm.toLowerCase());
      });
  return (
    <>
      <div className="flex flex-col">
        <DataCountProvider>
          <div>
            <Navbar />
            {currentUser ? (
              <>
                <SubmenuFilter dataCount={dataCount} />
              </>
            ) : null}
            <div></div>
          </div>
        </DataCountProvider>
        <br />

        {currentUser ? (
          <>
            <div className="pl-5 pr-5 pb-5">
              <div className="flex justify-end mb-2">
                <button
                  className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mr-2"
                  onClick={handleAddUser}
                >
                  <span className="mr-2">Add User</span>
                  <AiOutlinePlus />
                </button>
              </div>
              
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="pl-5 pr-5">
              <DataTable
                columns={columns}
                data={filteredUsers}
                pagination={true}
                striped={true}
                highlightOnHover={true}
                className="bg-white border border-[#f9787c] rounded-lg"
                cellClassName="border-b border-[#f9787c] px-4 py-2"
                headerClassName="bg-[#f9787c] text-white font-semibold"
                defaultSortFieldId={3}
                // onRowClicked={handleRowClick}
                defaultSortAsc={false}
              />
            </div>
          </>
        ) : (
          <h3>Unauthorized Access</h3>
        )}
      </div>
      {addUserVisble && (
        <AddUser
          visible={addUserVisble}
          onClose={handleCloseAddUserClick}
        />
      )}
      {editUserVisble && (
        <EditUser
          visible={editUserVisble}
          onClose={handleCloseEditUserClick}
          userId={userId}
        />
      )}
    </>
  );
}
