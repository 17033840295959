import {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Select, AutoComplete, Button, Modal} from 'antd';
import { appSettings } from '../../../AppSettings';
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'Registration',
        selector: 'registration',
        sortable: true
    },
    {
        name: 'Make',
        selector: 'make',
        sortable: true
    },
    {
        name: 'Model',
        selector: 'model',
        sortable: true
    },
    {
        name: 'Fleet Number',
        selector: 'fleet',
        sortable: true
    },
    {
        name: 'Lease Type',
        selector: 'leaseType',
        sortable: true
    },
    {
        name: 'Trailer',
        sortable: true,
        cell: (e) => (
            <div>{e.trailer == 1 ? 'Trailer' : 'Truck'}</div>
        )
    },
    {
        name: '',
        sortable: true,
        cell: (e) => (
            <div><VehicleEdit vehicleId={e.id} /></div>
        )
    }
];

export default function PortalSecureVehicleListCompanyVehicles() {

    const [companyType, setCompanyType] = useState();

    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');

    const [vehicles, setVehicles] = useState([]);

    const getCompanies = async (companyType) => {
        await fetch(`${appSettings.apiUrl}/Company/getallcompaniesbycompanytype/${companyType}`)
            .then(async response => {
                const companies = await response.json();
                const interimCompanies = [];

                /*companies.forEach(e => {
                    interimCompanies.push(...interimCompanies, {value: e.id, label: e.name})
                })*/
                setCompanyList(companies.map((v) => ({key: v.id, value: v.extendedName})))
            })
            .catch(error => console.error(error));
    }

    const getVehicles = async (company) => {
         
        await fetch(`${appSettings.apiUrl}/vehiclelist/getvehiclesbycompanyid/${company}`)
            .then(async response => {
                setVehicles(await response.json())
            })
            .catch(error => console.error(error));
    }


    useEffect(() => {

    }, [])


    console.log(companyList);

return (
        <Container>
            <Row>
                <Col sm={4}>
                <Select
                    defaultValue="-1"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => {setCompanyType(e); getCompanies(e)}}
                    options={[
                        {
                            value: '-1',
                            label: 'Select Company Type',
                            },
                        {
                        value: '0',
                        label: 'Customer',
                        },
                        {
                        value: '1',
                        label: 'Supplier',
                        }
                    ]}
                    />
                </Col>
                <Col sm={4}>
                <AutoComplete
                    options={companyList}
                    placeholder="Company"
                    style={{width:'100%'}}
                    filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                    onSelect={(value, option) => {setSelectedCompany(option.key)}} 
                    />
                </Col>
                <Col>
                    <Button type="primary" style={{backgroundColor:'#1677ff'}} allowClear  onClick={() => {getVehicles(selectedCompany)}}>Get Vehicles</Button>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <DataTable
                        title="Vehicles"
                        columns={columns}
                        data={vehicles}
                        pagination={true}
                        striped={true}
                        highlightOnHover={true}
                        className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                        cellClassName="border-b border-[#f9787c] px-4 py-2"
                        headerClassName="bg-[#f9787c] text-white font-semibold"
                    />
                </Col>
            </Row>
            
        </Container>
        
    )
}

const VehicleEdit = (props) => {

    const [vehicle, setVehicle] = useState({});

    const getVehicle = async (vehicleId) => {
        await fetch(`${appSettings.apiUrl}/Vehicles/getvehiclebyid/${props.vehicleId}`)
            .then(async response => {
                setVehicle(await response.json());
                showModal();
            })
            .catch(error => console.error(error));
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    

    return (
        
        <>
        <div><Button type="link" block onClick={() => {getVehicle(props.vehicleId)}}>
            Edit
        </Button></div>
        
        <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            
        </Modal>
        </>
    );
}