import {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import { appSettings } from '../../../../AppSettings';
import  {Container, Row,Col, } from 'react-bootstrap';
import { DatePicker, Space , Button,Modal, message, Upload} from 'antd';
import { InboxOutlined } from '@ant-design/icons';


const columns = [
    {
        name: 'Responder',
        sortable: true,
        selector: (row) => (row.responderName)
    },
    {
        name: 'Extension',
        sortable: true,
        selector: (row) => (row.extension)
    },
    {
        name: 'Type',
        sortable: true,
        selector: (row) => (row.callType)
    },
    {
        name: 'Call Count',
        sortable: true,
        selector: (row) => (row.callCount)
    },
    {
        name: 'Total Lengths',
        sortable: true,
        selector: (row) => (row.callLength)
    }
]

const SubHeader = (params) => {
    const {RangePicker} = DatePicker;
    const [selectedDates, setSelectedDates] = useState();
    
    return (
        <Row>
            <Col sm={12}>
                <RangePicker onChange={(e) => {setSelectedDates(e)} } />
                <Button type="default" style={{marginLeft: '15px'}} onClick={() => {params.performRefresh(selectedDates)}}>Fetch Data</Button>
            </Col>
        </Row>
    )
}

export default function PortalSecureAdminRespondersCallHistory() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    
    const fetchTableData = async (startDate, endDate) => {
        setIsLoading(true);
        
        await fetch(`${appSettings.apiUrl}/respondercallhistory/getcallhistorybydate/${startDate}/${endDate}`)
        .then(async response => {
            setTableData(await response.json())})
        .catch(error => console.error(error));

        setIsLoading(false);
    }

    useEffect(() => {
        fetchTableData('2023-05-16', '2023-05-16');
    }, []);

    const performRefresh = (selectedDates) => {
        var startDate = selectedDates[0].$y + '-' + (selectedDates[0].$M + 1) + '-' + selectedDates[0].$D ;
        var endDate = selectedDates[1].$y + '-' + (selectedDates[1].$M + 1) + '-' + selectedDates[1].$D ;
        
        fetchTableData(startDate, endDate);
    }

    return (
        <DataTable
            title="Responder Call History"
            columns={columns}
            data={tableData}
            pagination={true}
            striped={true}
            highlightOnHover={true}
            className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
            cellClassName="border-b border-[#f9787c] px-4 py-2"
            headerClassName="bg-[#f9787c] text-white font-semibold"
            subHeader={true}
            subHeaderComponent={[<SubHeader performRefresh={performRefresh}/>, <Import  />  ]}
            subHeaderAlign="left"
            
            
        />

    )
}

const Import = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


    const { Dragger } = Upload;
    const props = {
    name: 'file',
    multiple: false,
    action: `${appSettings.apiUrl}/goldtel/importcsv`,
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            
        }
        if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        }
    },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
    };
  return (
    <>
      <Button type="default" onClick={showModal} style={{marginLeft:'50px'}}>
        Import
      </Button>
      <Modal title="Import GoldTel Call Log" open={isModalOpen} destroyOnClose={true} onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
            Close
          </Button>
      ]}>
        <Container>
            <Row style={{marginBottom:'80px'}}>
                <Col sm={12}>
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                        banned files.
                        </p>
                    </Dragger>
                </Col>
            </Row>
        </Container>
        
      </Modal>
    </>
  );
};