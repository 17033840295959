import { appSettings } from '../../../AppSettings';

async function updateExcelData(vehicleExcelValidationModel, selectedCompany, companyType) {
  try {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const userId = currentUser.userId;
    const token = currentUser.jwtToken;
    const requestBody = {
      vehicleExcelValidationModel: vehicleExcelValidationModel,
      selectedCompany: selectedCompany,
      companyType: companyType,
      userId: userId
    };
    
    const response = await fetch(appSettings.apiUrl + '/VehicleList/UpdateVehicleData', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      return true; 
    } else {
      throw new Error(`HTTP Error ${response.status}`);
    }
  } catch (error) {
    console.error(error);
    return false; 
  }
}

export default updateExcelData;
