import React from 'react';

const BreakdownStatusDropdown = ({ breakdownDetails, BreakdownStatus, handleStatusChange }) => {
  return (
    <div className="rounded-lg border p-2 mb-4 relative border border-gray-300 text-gray-800 bg-white">
      <h4 className="text-sm font-bold text-center">Breakdown Status</h4>
      {breakdownDetails && (
        <div className="flex items-center justify-center mt-2 pb-3">
          <label htmlFor="statusDropdown" className="mr-2">
            Status
          </label>
          <select
            id="statusDropdown"
            value={breakdownDetails[0].breakdownStatus}
            onChange={handleStatusChange}
            className="w-full py-2 border rounded"
          >
            {Object.entries(BreakdownStatus).map(([status, value]) => (
              <option
                key={value}
                value={value}
                disabled={breakdownDetails[0].breakdownStatus === value}
              >
                {status}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default BreakdownStatusDropdown;
