import { Outlet } from 'react-router';
import ComponentsNavbar from '../components/Navbar';
import { ToastContainer } from "react-toastify";

export default function PortalLayout() {

    return (
        <div>
              {/* <ToastContainer position="bottom-right" hideProgressBar theme="colored" /> */}
    
            <ComponentsNavbar />
            <Outlet />         
        </div>    
        )
}