import { useEffect } from 'react';
import { useCustomNavigate } from './useCustomNavigate';
import { appSettings } from '../../AppSettings';

function FetchApi(relativeApiEndpoint, httpMethod, dataSetter, current, body = null, dataProcessorFunction = (data) => data, lockedRows = null) {
    const navigate = useCustomNavigate();
    const apiUrl = appSettings.apiUrl + relativeApiEndpoint;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const token = currentUser.jwtToken;

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = () => {
            // const abortController = new AbortController();
            // const signal = abortController.signal;

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            const options = {
                method: httpMethod,
                headers: headers,
                signal,
            };

            if (body) {
                options.body = JSON.stringify(body);
            }

            fetch(apiUrl, options)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 401) {
                        sessionStorage.removeItem('currentUser');
                        navigate('/portal', { state: { Message: "Session has expired, please log in again" } });
                        throw new Error("Unauthorized");
                    } else if (response.status === 500) {
                        throw new Error("Internal Server Error");
                    } else {
                        throw new Error("HTTP Error " + response.status);
                    }
                })
                .then(data => {
                    dataSetter(data);
                    dataProcessorFunction(data);
                })
                .catch(error => {
                    if (error.name === 'AbortError') {
                        console.log('Fetch aborted:', error.message);
                    } else {
                        console.error(error);
                    }
                });

            return () => {
                abortController.abort();
            };
        };

        if (current !== 'QA' && current !== 'Delayed' && current !== 'Workshop') {
            fetchData();
            const intervalId = setInterval(fetchData, 30000);

            return () => {
                clearInterval(intervalId);
                abortController.abort();
            };
        } else {
            abortController.abort();
        }


        // Call fetch immediately, and set up the interval for subsequent calls
        // fetchData();
        // const intervalId = setInterval(fetchData, 30000);

        // Clear the interval when the component unmounts or dependencies change
        // return () => {
        //     clearInterval(intervalId);
        // };
    }, [apiUrl, httpMethod, dataSetter, body, dataProcessorFunction]);

}

export default FetchApi;
