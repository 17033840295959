import React from 'react';

const DriverDetailsHeader = () => {
  return (
    <div className="flex items-center justify-center mb-1">
      <h4 className="text-lg font-bold text-center mr-2">Driver Details</h4>
    </div>
  );
};

export default DriverDetailsHeader;
