import SubmenuFilter from '../../../components/SubmenuFilter';
import Navbar from "../../../components/Navbar";
import { DataCountProvider } from '../../../components/DataCountContext';
import { appSettings } from '../../../AppSettings';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';


const SftpUpload = () => {

    const [ip, setIp] = useState("");
    const [files, setFiles] = useState([]);
    const [error, setError] = useState('');

    const handleUpload = ({ file, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append('file', file);

        fetch(`${appSettings.apiUrl}/SftpUpload/SftpUpload`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                message.success(`${file.name} file uploaded successfully.`);
            })
            .catch((error) => {
                message.error(`${file.name} file not uploaded successfully.`);
            });
    };


    async function getIp() {
        try {
            const response = await fetch(appSettings.apiUrl + '/SftpUpload/GetIpAddress');
            const data = await response.text();
            const dataCleaned = data.replace(/\s/g, '');
            setIp(dataCleaned);
        } catch (error) {
            console.error('Error fetching Ip:', error);
        }
    }

    async function getFiles() {
        try {
            const response = await fetch(appSettings.apiUrl + '/SftpUpload/ViewFiles');
            const data = await response.json();
            console.log(data);

            if (data.success) {
                setFiles(data.fileNames);
                setError('');
            } else {
                setError(data.errorMessage);
                setFiles([]);
            }
        } catch (error) {
            console.error('Error Getting Files:', error);
            setError('An error occurred while fetching the files.');
            setFiles([]);
        }
    }

    useEffect(() => {
        getIp();
        getFiles();
    }, [])

    return (
        <div className="flex flex-col">
            <DataCountProvider>
                <div>
                    <Navbar />
                    <SubmenuFilter />
                    <div></div>
                </div>
            </DataCountProvider>
            <div className="p-4 text-center">
                <h1 className="text-2xl font-semibold mb-4">Upload Report's</h1>
                <div className="flex items-center justify-center">
                    <Upload customRequest={handleUpload}>
                        <Button
                            icon={<UploadOutlined />}
                            type="primary"
                            className="bg-green-500 text-white flex items-center"
                        >
                            Upload
                        </Button>
                    </Upload>
                </div>
            </div>
            <div className="p-4 text-center">
                <h1>IP Address:</h1>
                <p>{ip}</p>
            </div>
            <div>
                {error ? (
                    <div className="p-4 text-center">{error}</div>
                ) : (
                    <ul>
                        {files.map((file, index) => (
                            <li key={index}>{file}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default SftpUpload;