import React, { useEffect, useState } from 'react';
import { appSettings } from '../../../AppSettings';
import DataTable from 'react-data-table-component';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Button } from 'antd';


export default function CompanyListCompanies({ visible, onClose, selectedCompaniesParam }) {
    const [companies, setCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [modalVisible, setModalVisible] = useState(visible);

    const handleCheckboxChange = (row) => {
        const selectedIndex = selectedCompanies.indexOf(row);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCompanies, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCompanies.slice(1));
        } else if (selectedIndex === selectedCompanies.length - 1) {
            newSelected = newSelected.concat(selectedCompanies.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedCompanies.slice(0, selectedIndex),
                selectedCompanies.slice(selectedIndex + 1)
            );
        }

        setSelectedCompanies(newSelected);
    };


    const columns = [
        {
            name: 'Select',
            cell: (row) => <input type="checkbox" onChange={() => handleCheckboxChange(row)} />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        }
    ];

    const handleSubmit = () => {
        // Send the list of selected companies to the server
        selectedCompaniesParam(selectedCompanies);
        
    };

    const handleCancel = () => {
        setModalVisible(false);
        onClose();
    };

    const fetchCompanies = async () => {
        try {
            const response = await fetch(`${appSettings.apiUrl}/company/getallcompanies/`);
            const data = await response.json();
            setCompanies(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, []);

    const filteredCompanies = companies.filter((company) => {
        return company.name.toLowerCase().includes(searchText.toLowerCase());
    });

    return (
        <>
         {/* <Modal open={modalVisible} onCancel={handleCancel} width={'70%'}> */}
            <Row>
                <Col>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        title="Companies"
                        columns={columns}
                        data={filteredCompanies}
                        pagination={true}
                        striped={true}
                        highlightOnHover={true}
                        className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                        cellClassName="border-b border-[#f9787c] px-4 py-2"
                        headerClassName="bg-[#f9787c] text-white font-semibold"
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-right"> 
                    <Button onClick={handleSubmit}>Add Companies</Button>
                </Col>
            </Row>
         {/* </Modal> */}
        </>
    );
}
