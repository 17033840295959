import React, { useEffect, useState } from "react";
import {
    Typography,
  Modal
} from "antd";

import agent from "../../../Utilities/API/agent";
import DataTable from "react-data-table-component";

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
}
const { Text } = Typography;

const ConsultantModal = ({ visible, onClose,breakdownId }) => {
 
  const [modalVisible, setModalVisible] = useState(visible);
  const [isSaving, setIsSaving] = useState(false);
  const [isConsultant, setIsConsultant] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
   
  const handleCancel = () => {
    setModalVisible(false);
    onClose();
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  
  useEffect(() => {
  
    setModalVisible(true);
    const getUsers = async () => {
        const data = await agent.Users.getAllAdmins();
        setUsers(data);
    };
    getUsers();
  }, [visible]);

  const filteredUsers = users.filter((user) => {
    return user.fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

const handleRowClick = (row) => {
    agent.BreakdownRequest.assignBreakdown({
        breakdownId:breakdownId,
        userId:row.id
    }).then(()=>{ onClose();})
}

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      omit: true,
    },
 
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortField: "fullName",
      sortable: true,
      //sortFunction: caseInsensitiveSortFullName,
      omit: false,
      width: "40%",
    },
    {
        name: "Email Address",
        selector: (row) => (
            <Text type="primary" >{row.email}</Text>
          ),
      },
      {
        name: "Mobile Number",
        selector: (row) => (
            <Text type="primary">{row.mobileNumber}</Text>
          ),
      },
    
  ];
  
  return (
    <Modal
      open={modalVisible}
      onCancel={handleCancel}
      width={'70%'}
     
    >
         <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
              />
              <br/>
              <br/>
     <DataTable
                columns={columns}
                data={filteredUsers}
                pagination={true}
                striped={true}
                highlightOnHover={true}
                className="bg-white border border-[#f9787c] rounded-lg"
                cellClassName="border-b border-[#f9787c] px-4 py-2"
                headerClassName="bg-[#f9787c] text-white font-semibold"
                defaultSortFieldId={3}
                onRowClicked={handleRowClick}
                defaultSortAsc={false}
              />
        
    
    </Modal>
  );
};

export default ConsultantModal;
