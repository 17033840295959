import { useEffect, useState, useContext } from 'react';
import { appSettings } from '../../AppSettings';
import Navbar from "../../components/Navbar";
import MarkerMap from '../../Utilities/GoogleMaps/MarkerMap';
import { useNavigate } from 'react-router-dom';
import { BiSearchAlt } from 'react-icons/bi';
import { FaHandPaper } from 'react-icons/fa';
import SubmenuFilter from '../../components/SubmenuFilter';
import FloatingLabel from '../../components/FloatingLabel';
import { DataCountContext, DataCountProvider } from '../../components/DataCountContext';
import { Modal, AutoComplete, Button, Upload } from 'antd';
import UploadFile from '../../components/generic/Upload';
import DataTable from 'react-data-table-component';

const BreakdownRequest = () => {

    const navigate = useNavigate();
    const dataCount = useContext(DataCountContext) ?? 5;

    useEffect(() => {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

        if (currentUser) {
            fetch(`${appSettings.apiUrl}/Breakdowns/getControllerInfo/${currentUser.loginId}`)
                .then(response => response.json())
                .then(data => {
                    setControllerName(data[0].fullName);
                    // setControllerContactNo(data[0].mobileNumber);
                })
                .catch(error => console.error(error));
        }
    }, [])

    const handleCompanyNameClick = () => {
        console.log(companyNameList);
    };

    const [controllerName, setControllerName] = useState('');
    const [controllerContactNo, setControllerContactNo] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyNameList, setCompanyNameList] = useState([]);
    const [selectDriver, setSelectDriver] = useState('');
    const [driverName, setDriverName] = useState('');
    const [driverCell, setDriverCell] = useState('');
    const [address, setAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [addressFromLocation, setAddressFromLocation] = useState('');
    const [isBreakdownOnTrailer, setIsBreakdownOnTrailer] = useState(false);
    const [truckRegistrationNumber, setTruckRegistrationNumber] = useState('');
    const [truckVINNumber, setTruckVINNumber] = useState('');
    const [truckFleet, setTruckFleet] = useState('');
    const [truckMake, setTruckMake] = useState('');
    const [truckModel, setTruckModel] = useState('');
    const [truckYear, setTruckYear] = useState('');
    const [trailerRegistrationNumber, setTrailerRegistrationNumber] = useState('');
    const [trailerVINNumber, setTrailerVINNumber] = useState('');
    const [trailerFleet, setTrailerFleet] = useState('');
    const [trailerMake, setTrailerMake] = useState('');
    const [trailerModel, setTrailerModel] = useState('');
    const [trailerYear, setTrailerYear] = useState('');
    const [leaseType, setLeaseType] = useState('');
    const [mileage, setMileage] = useState('');
    const [warranty, setWarranty] = useState('');
    const [attendingMechanic, setAttendingMechanic] = useState('');
    const [breakdownDescription, setBreakdownDescription] = useState('');
    const [vehicleLoaded, setVehicleLoaded] = useState(false);
    const [breakdownType, setBreakdownType] = useState('');
    const [otherBreakdownInfo, setOtherBreakdownInfo] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [imagery, setImagery] = useState('');
    const [breakdownSearchResult, setBreakdownSearchResult] = useState('');
    const [reverseSearchResult, setReverseSearchResult] = useState('');
    const [assosiatedAddresses, setAssosiatedAddress] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTitles, setSelectedTitles] = useState([]);
    const [similarReg, setSimilarReg] = useState([]);
    const [assosiatedVehicleDetails, setAssosiatedVehicleDetails] = useState([]);
    const [rnrCompaniesAndDrivers, setRnrCompaniesAndDrivers] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedDriverId, setSelectedDriverId] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [id, setId] = useState("");
    const [regList, setRegList] = useState('');
    const [imageModal, setImageModal] = useState(null);
    const [parentFileList, setParentFileList] = useState([]);
    const [breakdownClassType, SetbreakdownClassType] = useState([]);
    const [equipmentFleetNumber, SetEquipmentFleetNumber] = useState([]);
    const [equipmentMake, setEquipmentMake] = useState([]);
    const [equipmentModel, SetEquipmentModel] = useState([]);
    const [equipmentSerialNumber, setEquipmentSerialNumber] = useState([]);
    const [equipmentYear, setEquipmentYear] = useState([]);
    const [equipmentData, setEquipmentData] = useState([]);

    const [equipmentModal, setEquipmentModal] = useState(false);

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    useEffect(() => {
        fetch(appSettings.apiUrl + '/Breakdowns/getCompanyAndDrivers')
            .then(response => response.json())
            .then(data => setRnrCompaniesAndDrivers(data));
    }, [])

    function submitData() {
        console.log(parentFileList);

        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

        const userId = currentUser.loginId;

        let isBreakdownOnTrailerTextVal = "";
        let isVehicleLoadedTextVal = "";


        if (isBreakdownOnTrailer === false) {
            isBreakdownOnTrailerTextVal = "False";
        }
        else {
            isBreakdownOnTrailerTextVal = "True";
        }

        if (vehicleLoaded === false) {
            isVehicleLoadedTextVal = "False";
        }
        else {
            isVehicleLoadedTextVal = "True";
        }

        setCompanyName(selectedCompany);

        const breakdownRequest = {
            id,
            controllerName,
            controllerContactNo,
            userId,
            companyName,
            selectedCompany,
            selectedDriverId,
            driverName,
            driverCell,
            address,
            latitude,
            longitude,
            addressFromLocation,
            isBreakdownOnTrailerTextVal,
            truckRegistrationNumber,
            truckVINNumber,
            truckFleet,
            truckMake,
            truckModel,
            truckYear,
            trailerRegistrationNumber,
            trailerVINNumber,
            trailerFleet,
            trailerMake,
            trailerModel,
            trailerYear,
            leaseType,
            mileage,
            warranty,
            attendingMechanic,
            breakdownDescription,
            isVehicleLoadedTextVal,
            breakdownType,
            otherBreakdownInfo,
            orderNumber,
            imagery: parentFileList,
            breakdownClassType,
            equipmentFleetNumber,
            equipmentMake,
            equipmentModel,
            equipmentSerialNumber,
            equipmentYear,
        };

        fetch(appSettings.apiUrl + '/Breakdowns/CreateBreakdownRequest2', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(breakdownRequest),
        })
            .then(response => response.json())
            .then(data => {
                navigate('/portal/secure/ActiveBreakdowns');
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedTitles([]);
    };

    useEffect(() => {
        console.log(assosiatedAddresses);
    }, [assosiatedAddresses]);

    const handleAddressSearch = () => {
        const apiKey = 'U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c';
        const encodedAddress = encodeURIComponent(address);
        const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodedAddress}&apiKey=${apiKey}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setBreakdownSearchResult(data);
                    if (data.items.length === 1) {
                        setLatitude(data.items[0].position.lat);
                        setLongitude(data.items[0].position.lng);
                        setAddressFromLocation(data.items[0].title);
                    }
                    else if (data.items.length > 1) {
                        setAssosiatedAddress(data);
                        const mappedData = data.items.map(item => ({
                            title: item.title,
                            lat: item.position.lat,
                            lng: item.position.lng
                        }));
                        setSelectedTitles(mappedData);
                        setIsModalVisible(true);
                    }
                    else {
                        alert("No results found. Please try a different search.");
                    }
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const reverseGeoSearch = () => {
        const apiKey = 'U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c';
        const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude}%2C${longitude}&lang=en-US&apiKey=${apiKey}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                setReverseSearchResult(data);
                if (data.items.length > 0) {
                    setAddress(data.items[0].title);
                    setAddressFromLocation(data.items[0].title);
                }
            })
            .catch(error => console.error(error));
    };

    const handleAddressSelection = (lat, lng, title) => {
        setAddress(title);
        setAddressFromLocation(title);
        setLatitude(lat);
        setLongitude(lng);
        setIsModalVisible(false);

        if (breakdownSearchResult && breakdownSearchResult.items.length > 0) {
            const updatedItems = [...breakdownSearchResult.items];
            updatedItems[0].position.lat = lat;
            updatedItems[0].position.lng = lng;
            setBreakdownSearchResult({ ...breakdownSearchResult, items: updatedItems });
        }
    };

    const handleMarkerPositionChange = (lat, lng) => {
        setLatitude(lat);
        setLongitude(lng);
    };

    const handleInputChange = (e) => {

        if (selectedCompany) {
            const value = e.slice(0, 8);
            setTruckRegistrationNumber(value);

            if (value.length >= 4) {
                fetchAutocompleteResults(value);
            }
        }
        else {
            alert("please select a company");
            setTruckRegistrationNumber("");
        }
    };

    const fetchAutocompleteResults = async (searchTerm) => {
        try {
            const response = await fetch(`${appSettings.apiUrl}/Breakdowns/GetVehichleByReg/${searchTerm}/${selectedCompany}`);
            if (!response.ok) {
                throw new Error('Failed to fetch autocomplete results');
            }

            const data = await response.json();

            setSimilarReg(data);

            const mappedData = data.map((item) => ({
                id: item.id,
                vin: item.vin,
                fleet: item.fleet,
                make: item.make,
                model: item.model,
                year: item.year,
                reg: item.registration
            }));

            setAssosiatedVehicleDetails(mappedData);
            setRegList(mappedData.map((v) => ({ key: v.id, value: v.reg })))

            return data;
        } catch (error) {
            console.error(error);
        }
    };

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);
        setDriverName("");
        setDriverCell("");
    };

    useEffect(() => {
        if (selectedCompany) {
            const selectedDriverDetails = JSON.parse(
                rnrCompaniesAndDrivers.find(
                    (company) => company.companyName === selectedCompany
                )?.driverDetails || "[]"
            );

            if (selectedDriverDetails.length) {
                const selectedDriver = selectedDriverDetails[0];
                setSelectedDriverId(selectedDriver.driverId);
                setSelectDriver(selectedDriver.FullName);
                setDriverName(selectedDriver.FullName);
                setDriverCell(selectedDriver.MobileNumber);
            } else {
                setSelectDriver('');
                setDriverName('');
                setDriverCell('');
            }
        } else {
            setSelectDriver('');
            setDriverName('');
            setDriverCell('');
        }
    }, [selectedCompany]);

    function isValidLatitude(latitude) {
        const latitudeRegex = /^-?([1-8]?[0-9]\.{1}\d+|90\.{1}0+)$/;
        return latitudeRegex.test(latitude);
    }

    function isValidLongitude(longitude) {
        const longitudeRegex = /^-?((1[0-7]|[1-9])?\d\.{1}\d+|180\.{1}0+)$/;
        return longitudeRegex.test(longitude);
    }

    function handleRegSearchSelect(value, option) {
        setTruckRegistrationNumber(value);
        if (truckRegistrationNumber) {
            const filteredData = assosiatedVehicleDetails.filter(
                (item) => item.reg === value
            );

            if (filteredData) {
                setTruckRegistrationNumber(filteredData[0].reg);
                setTruckVINNumber(filteredData[0].vin);
                setTruckFleet(filteredData[0].fleet);
                setTruckMake(filteredData[0].make);
                setTruckModel(filteredData[0].model);
                setTruckYear(filteredData[0].year);
            }
        }
    }

    const handleImageOpenModal = () => {
        setImageModal(true);
    }

    const handleImageCloseModal = () => {
        setImageModal(false);
    }

    function handleGetTruckEquipment() {
        fetch(`${appSettings.apiUrl}/Breakdowns/GetTruckAndTrailerEquipment/${truckFleet}`, {
            method: 'POST'
        })
            .then(response => response.json())
            .then(data => {
                setEquipmentData(data);
            })
            .catch(error => console.error(error));
    }

    console.log(equipmentData);

    function handleEquipmentOK() {
        setEquipmentModal(false);
    }

    function handleEquipmentCancel() {
        setEquipmentModal(false);
    }

    const EquipmentColumnData = [
        {
            name: 'id',
            selector: 'id',
            sortable: true,
            omit: true,
        },
        {
            name: 'Type',
            selector: 'equipmentType',
            sortable: true,
            omit: false,
        },
        {
            name: 'SubType',
            selector: 'equipmentSubType',
            sortable: true,
            omit: false,
        },
        {
            name: 'Fleet Number',
            selector: 'fleetNumber',
            sortable: true,
            omit: false,
        },
        {
            name: 'Make',
            selector: 'make',
            sortable: true,
            omit: false,
        },
        {
            name: 'Model',
            selector: 'model',
            sortable: true,
            omit: false,
        },
        {
            name: 'Serial Number',
            selector: 'serialNumber',
            sortable: true,
            omit: false,
        },
        {
            name: 'Warranty ExpiryDate',
            selector: 'warrantyExpiryDate',
            sortable: true,
            omit: false,
        },
        {
            name: 'Year',
            selector: 'year',
            sortable: true,
            omit: false,
        },
        {
            name: 'Select',
            sortable: false,
            center: true,
            cell: (row) => {
                const handleEquipmentSelect = () => {
                    SetbreakdownClassType(row.equipmentType);
                    SetEquipmentFleetNumber(row.fleetNumber);
                    setEquipmentMake(row.make);
                    SetEquipmentModel(row.model);
                    setEquipmentSerialNumber(row.serialNumber);
                    setEquipmentYear(row.year);
                    setEquipmentModal(false);
                };

                return (
                    <div>
                        <button
                            onClick={handleEquipmentSelect}
                            className="bg-red-500 hover:bg-red-500 text-white font-bold py-2 px-2 rounded border border-black border-2 flex items-center justify-center"
                        >
                            Select &nbsp;
                            <FaHandPaper className="transform hover:scale-150" />
                        </button>
                    </div>
                );
            },
        },
    ]


    return (
        <div className="flex flex-col">
            <DataCountProvider>
                <div>
                    <Navbar />
                    {currentUser ? (<>
                        <SubmenuFilter dataCount={dataCount}  />
                    </>) : null} 
                    <div></div>
                </div>
            </DataCountProvider>

            {currentUser ? (
                <>
                    {selectedTitles && (
                        <Modal
                            title="Associated Addresses"
                            open={isModalVisible}
                            onCancel={handleModalClose}
                            footer={null}
                        >
                            <ul className="grid grid-cols-1 gap-3">
                                {selectedTitles.map((item) => (
                                    <li key={item.title} className="grid grid-cols-2 items-center">
                                        <div className="col-span-1 pr-4" style={{ width: '350px' }}>{item.title}</div>
                                        <div className="col-span-1 flex justify-end">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" style={{ width: '90px' }} onClick={() => handleAddressSelection(item.lat, item.lng, item.title)}>Select</button>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        </Modal>
                    )}
                    <div className="flex">
                        <div className="w-1/2">
                            <div className="flex-grow flex overflow-auto pt-15" style={{ height: 'calc(100vh - 165px)' }}>
                                <div className="w-full">
                                    <div className="flex">
                                        <div className="mr-2 w-1/2">
                                            <FloatingLabel
                                                id="controllerName"
                                                name="controllerName"
                                                type="text"
                                                value={controllerName}
                                                onChange={(e) => setControllerName(e.target.value)}
                                                placeholder="controllerName"
                                                label="Controller Name"
                                                className="relative ml-3"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <FloatingLabel
                                                id="controllerContactNo"
                                                name="controllerContactNo"
                                                type="text"
                                                value={controllerContactNo}
                                                onChange={(e) => setControllerContactNo(e.target.value)}
                                                placeholder="controllerContactNo"
                                                label="Controller Number"
                                                className="relative"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-4"></div>
                                    {rnrCompaniesAndDrivers ? (
                                        <div className="relative w-1/2">
                                            <select
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pl-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                value={selectedCompany}
                                                onChange={handleCompanyChange}
                                            >
                                                <option value="">Select a company</option>
                                                {rnrCompaniesAndDrivers
                                                    .sort((a, b) =>
                                                        (a.companyName || a.fullName).localeCompare(b.companyName || b.fullName)
                                                    )
                                                    .map((company) => (
                                                        <option key={company.ownerId} value={company.companyName || company.fullName}>
                                                            {company.companyName || company.fullName}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <FloatingLabel
                                            id="companyName"
                                            name="companyName"
                                            type="text"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            onClick={handleCompanyNameClick}
                                            placeholder="companyName"
                                            label="Company Name"
                                            className="relative ml-3"
                                        />
                                    )}
                                    <div className="my-4"></div>
                                    <div className="flex">
                                        <div className="mr-2 w-1/2">
                                            {selectedCompany ? (
                                                <select
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        const selectedDriver = JSON.parse(selectedValue);
                                                        if (selectedDriver) {
                                                            setDriverName(selectedDriver.FullName);
                                                            setDriverCell(selectedDriver.MobileNumber);
                                                        } else {
                                                            setDriverName("");
                                                            setDriverCell("");
                                                        }
                                                    }}
                                                >
                                                    {JSON.parse(
                                                        rnrCompaniesAndDrivers.find(
                                                            (company) => company.companyName === selectedCompany
                                                        )?.driverDetails || "[]"
                                                    ).length ? (
                                                        JSON.parse(
                                                            rnrCompaniesAndDrivers.find(
                                                                (company) => company.companyName === selectedCompany
                                                            )?.driverDetails || "[]"
                                                        ).map((driver) => (
                                                            <option
                                                                key={driver.DriverId}
                                                                value={JSON.stringify(driver)}
                                                            >
                                                                {driver.FullName}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option value="">Add new driver</option>
                                                    )}
                                                </select>
                                            ) : (
                                                <FloatingLabel
                                                    id="selectDriver"
                                                    name="selectDriver"
                                                    type="text"
                                                    value={selectDriver}
                                                    onChange={(e) => setSelectDriver(e.target.value)}
                                                    placeholder="selectDriver"
                                                    label="Select Driver:"
                                                    className="relative ml-3"
                                                />
                                            )}
                                        </div>
                                        <div className="w-1/2">
                                            <FloatingLabel
                                                id="driverName"
                                                name="driverName"
                                                type="text"
                                                value={driverName}
                                                onChange={(e) => setDriverName(e.target.value)}
                                                placeholder="driverName"
                                                label="Driver Name:"
                                                className="relative"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <FloatingLabel
                                                id="driverCell"
                                                name="driverCell"
                                                type="text"
                                                value={driverCell}
                                                onChange={(e) => setDriverCell(e.target.value)}
                                                placeholder="driverCell"
                                                label="Driver Cell:"
                                                className="relative ml-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-4"></div>
                                    <div className="flex">
                                        <div className="mr-2 flex-grow">
                                            <FloatingLabel
                                                id="address"
                                                name="address"
                                                type="text"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                placeholder="address"
                                                label="Address"
                                                className="relative ml-3"
                                            />
                                        </div>
                                        <div className="w-20 h-45">
                                            <button
                                                onClick={handleAddressSearch}
                                                className="bg-red-500 hover:bg-red-500 text-white font-bold py-2 px-2 rounded border border-black border-2 flex items-center justify-center">
                                                <BiSearchAlt className="transform hover:scale-150" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="my-4"></div>
                                    <div>
                                        <div className="flex">
                                            <div className="mr-2 w-1/2">
                                                <FloatingLabel
                                                    id="latitude"
                                                    name="latitude"
                                                    type="text"
                                                    value={latitude}
                                                    onChange={(e) => {
                                                        setLatitude(e.target.value);
                                                        setErrorMessage("");
                                                    }}
                                                    placeholder="latitude"
                                                    label="Latitude"
                                                    className="relative ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="longitude"
                                                    name="longitude"
                                                    type="text"
                                                    value={longitude}
                                                    onChange={(e) => {
                                                        setLongitude(e.target.value);
                                                        setErrorMessage("");
                                                    }}
                                                    placeholder="longitude"
                                                    label="Longitude"
                                                    className="relative ml-2"
                                                />
                                            </div>
                                            <div>
                                                <div className="w-20 h-45">
                                                    <button
                                                        onClick={reverseGeoSearch}
                                                        className="bg-red-500 hover:bg-red-500 text-white font-bold py-2 px-2 rounded border border-black border-2 flex items-center justify-center">
                                                        <BiSearchAlt className="transform hover:scale-150" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-4"></div>
                                        {/* Address from location */}
                                        <FloatingLabel
                                            id="addressFromLocation"
                                            name="addressFromLocation"
                                            type="text"
                                            value={addressFromLocation}
                                            onChange={(e) => setAddressFromLocation(e.target.value)}
                                            placeholder="addressFromLocation"
                                            label="Address From Location:"
                                            className="relative ml-3"
                                        />
                                    </div>

                                    <div className="my-4"></div>
                                    <br />

                                    <div className='border-2 border-gray-400 p-4 border-l-white border-r-white'>
                                        <div className="w-full">
                                            <h2 className="text-center text-xs font-bold text-black py-1 px-4 mb-3">Truck Details</h2>
                                        </div>
                                        <div className="flex">
                                            <div className="mr-2 w-1/2">
                                                <AutoComplete
                                                    options={regList}
                                                    placeholder="Truck Reg Number"
                                                    style={{ width: '100%' }}
                                                    onChange={handleInputChange}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    onSelect={(value, option) => handleRegSearchSelect(value, option)}
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="truckVINNumber"
                                                    name="truckVINNumber"
                                                    type="text"
                                                    value={truckVINNumber}
                                                    onChange={(e) => setTruckVINNumber(e.target.value)}
                                                    placeholder="truckVINNumber"
                                                    label="VIN Number"
                                                    className="relative  ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="truckFleet"
                                                    name="truckFleet"
                                                    type="text"
                                                    value={truckFleet}
                                                    onChange={(e) => setTruckFleet(e.target.value)}
                                                    placeholder="truckFleet"
                                                    label="Fleet"
                                                    className="relative  ml-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="my-4"></div>
                                        <div className="flex">
                                            <div className="mr-2 w-1/2">
                                                <FloatingLabel
                                                    id="truckMake"
                                                    name="truckMake"
                                                    type="text"
                                                    value={truckMake}
                                                    onChange={(e) => setTruckMake(e.target.value)}
                                                    placeholder="truckMake"
                                                    label="Make"
                                                    className="relative  ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="truckModel"
                                                    name="truckModel"
                                                    type="text"
                                                    value={truckModel}
                                                    onChange={(e) => setTruckModel(e.target.value)}
                                                    placeholder="truckModel"
                                                    label="Model"
                                                    className="relative  ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="truckYear"
                                                    name="truckYear"
                                                    type="text"
                                                    value={truckYear}
                                                    onChange={(e) => setTruckYear(e.target.value)}
                                                    placeholder="truckYear"
                                                    label="Year"
                                                    className="relative  ml-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="my-4"></div>
                                    </div>

                                    <div className="my-4"></div>


                                    <div>
                                        <label
                                            htmlFor="isBreakdownOnTrailer"
                                            className="ml-2 text-gray-700"
                                        >
                                            Is breakdown on a trailer:
                                        </label>
                                        <input
                                            type="checkbox"
                                            id="isBreakdownOnTrailer"
                                            checked={isBreakdownOnTrailer}
                                            className="h-5 w-5 text-red-600 focus:ring-red-500 border-gray-300 rounded"
                                            onChange={(e) => setIsBreakdownOnTrailer(e.target.checked)}
                                        />
                                    </div>
                                    <div className="my-4"></div>

                                    {isBreakdownOnTrailer && (
                                        <div className='border-2 border-black rounded p-4 ml-5 mr-5'>
                                            <div className="w-full">
                                                <h2 className="text-center text-xs font-bold text-black py-1 px-4 mb-3">Trailer Details</h2>
                                            </div>
                                            <div className="flex">
                                                <div className="mr-2 w-1/2">
                                                    <FloatingLabel
                                                        id="trailerRegistrationNumber"
                                                        name="trailerRegistrationNumber"
                                                        type="text"
                                                        value={trailerRegistrationNumber}
                                                        onChange={(e) => setTrailerRegistrationNumber(e.target.value)}
                                                        placeholder="trailerRegistrationNumber"
                                                        label="Reg Number"
                                                        className="relative ml-3"
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <FloatingLabel
                                                        id="trailerVINNumber"
                                                        name="trailerVINNumber"
                                                        type="text"
                                                        value={trailerVINNumber}
                                                        onChange={(e) => setTrailerVINNumber(e.target.value)}
                                                        placeholder="trailerVINNumber"
                                                        label="VIN Number"
                                                        className="relative ml-3"
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <FloatingLabel
                                                        id="trailerFleet"
                                                        name="trailerFleet"
                                                        type="text"
                                                        value={trailerFleet}
                                                        onChange={(e) => setTrailerFleet(e.target.value)}
                                                        placeholder="trailerFleet"
                                                        label="Fleet"
                                                        className="relative ml-3"
                                                    />
                                                </div>
                                            </div>
                                            <div className="my-4"></div>

                                            <div className="flex">
                                                <div className="mr-2 w-1/2">
                                                    <FloatingLabel
                                                        id="trailerMake"
                                                        name="trailerMake"
                                                        type="text"
                                                        value={trailerMake}
                                                        onChange={(e) => setTrailerMake(e.target.value)}
                                                        placeholder="trailerMake"
                                                        label="Make"
                                                        className="relative ml-3"
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <FloatingLabel
                                                        id="trailerModel"
                                                        name="trailerModel"
                                                        type="text"
                                                        value={trailerModel}
                                                        onChange={(e) => setTrailerModel(e.target.value)}
                                                        placeholder="trailerModel"
                                                        label="Model"
                                                        className="relative ml-3"
                                                    />
                                                </div>
                                                <div className="w-1/2">
                                                    <FloatingLabel
                                                        id="trailerYear"
                                                        name="trailerYear"
                                                        type="text"
                                                        value={trailerYear}
                                                        onChange={(e) => setTrailerYear(e.target.value)}
                                                        placeholder="trailerYear"
                                                        label="Year"
                                                        className="relative ml-3"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="my-4"></div>

                                    <div>
                                        <Button
                                            type="primary"
                                            style={{ backgroundColor: '#4CAF50', borderColor: 'green', width: '98%' }}
                                            onClick={() => {
                                                setEquipmentModal(true);
                                                handleGetTruckEquipment();
                                            }}
                                            className='ml-1 mr-0'
                                        >
                                            <span style={{ color: 'white' }}>Get equipment for truck/trailer</span>
                                        </Button>
                                    </div>

                                    <div className="my-4"></div>

                                    <div className='border-2 border-black rounded p-4 ml-5 mr-5'>
                                        <div className="w-full">
                                            <h2 className="text-center text-xs font-bold text-black py-1 px-4 mb-3">Equipment Details</h2>
                                        </div>
                                        <div className="flex">
                                            <div className="mr-2 w-1/2">
                                                <FloatingLabel
                                                    id="eqipmentClassType"
                                                    name="equipmentClassType"
                                                    type="text"
                                                    value={breakdownClassType}
                                                    onChange={(e) => SetbreakdownClassType(e.target.value)}
                                                    placeholder="Equipment Class"
                                                    label="Equipment Class"
                                                    className="relative ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="equipmentFleetNumber"
                                                    name="equipmentFleetNumber"
                                                    type="text"
                                                    value={equipmentFleetNumber}
                                                    onChange={(e) => SetEquipmentFleetNumber(e.target.value)}
                                                    placeholder="Equipment Fleet Number"
                                                    label="Equipment Fleet Number"
                                                    className="relative ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="equipmentMake"
                                                    name="equipmentMake"
                                                    type="text"
                                                    value={equipmentMake}
                                                    onChange={(e) => setEquipmentMake(e.target.value)}
                                                    placeholder="Equipment Make"
                                                    label="Equipment Make"
                                                    className="relative ml-3"
                                                />
                                            </div>
                                        </div>
                                        <div className="my-4"></div>
                                        <div className="flex">
                                            <div className="mr-2 w-1/2">
                                                <FloatingLabel
                                                    id="EquipmentModel"
                                                    name="EquipmentModel"
                                                    type="text"
                                                    value={equipmentModel}
                                                    onChange={(e) => SetEquipmentModel(e.target.value)}
                                                    placeholder="Equipment Model"
                                                    label="Equipment Model"
                                                    className="relative ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="equipmentSerialNumber"
                                                    name="equipmentSerialNumber"
                                                    type="text"
                                                    value={equipmentSerialNumber}
                                                    onChange={(e) => setEquipmentSerialNumber(e.target.value)}
                                                    placeholder="Equipment Serial Number"
                                                    label="Equipment Serial Number"
                                                    className="relative ml-3"
                                                />
                                            </div>
                                            <div className="w-1/2">
                                                <FloatingLabel
                                                    id="Eqipment Year"
                                                    name="Equipment Year"
                                                    type="text"
                                                    value={equipmentYear}
                                                    onChange={(e) => setEquipmentYear(e.target.value)}
                                                    placeholder="Eqipment Year"
                                                    label="Equipment Year"
                                                    className="relative ml-3"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <br />


                                    <Modal
                                        title={"View Equipment"}
                                        open={equipmentModal}
                                        onOk={handleEquipmentOK}
                                        centered
                                        width="100vw"
                                        className='pt-0 pb-0'
                                        onCancel={handleEquipmentCancel}
                                        cancelButtonProps={{ style: { backgroundColor: 'red', border: 'none', color: 'white' } }}
                                        okButtonProps={{ style: { backgroundColor: 'green' } }}
                                    >
                                        <div className="flex flex-col">
                                            {equipmentData.length === 0 ? (
                                                <div>No files to display.</div>
                                            ) : (
                                                <div>
                                                    <DataTable
                                                        columns={EquipmentColumnData}
                                                        data={equipmentData}
                                                        pagination={true}
                                                        striped={true}
                                                        highlightOnHover={true}
                                                        className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                                                        cellClassName="border-b border-[#f9787c] px-4 py-2"
                                                        headerClassName="bg-[#f9787c] text-white font-semibold"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </Modal>

                                    <div className="my-4"></div>
                                    <br />

                                    <div className="my-4"></div>
                                    <div className="flex">
                                        <div className="mr-2 w-1/2">
                                            <FloatingLabel
                                                id="leaseType"
                                                name="leaseType"
                                                type="text"
                                                value={leaseType}
                                                onChange={(e) => setLeaseType(e.target.value)}
                                                placeholder="leaseType"
                                                label="Lease Type"
                                                className="relative  ml-3"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <FloatingLabel
                                                id="mileage"
                                                name="mileage"
                                                type="text"
                                                value={mileage}
                                                onChange={(e) => setMileage(e.target.value)}
                                                placeholder="mileage"
                                                label="Mileage"
                                                className="relative  ml-3"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <div className="w-75">
                                                <div className="flex items-center">
                                                    <label htmlFor="warranty" className="block font-medium ml-3">
                                                        Warranty
                                                    </label>
                                                    <select
                                                        id="warranty"
                                                        name="warranty"
                                                        value={warranty}
                                                        onChange={(e) => setWarranty(e.target.value)}
                                                        className="block w-full py-2.5 px-3 rounded-lg border border-gray-300 focus:outline-none focus:ring focus:border-blue-500 ml-3"
                                                    >
                                                        <option value="0">0</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="my-4"></div>

                                    <div className="flex">
                                        <div className="mr-2 w-1/2">
                                            <FloatingLabel
                                                id="attendingMechanic"
                                                name="attendingMechanic"
                                                type="text"
                                                value={attendingMechanic}
                                                onChange={(e) => setAttendingMechanic(e.target.value)}
                                                placeholder="attendingMechanic"
                                                label="Attending Mechanic"
                                                className="relative  ml-3"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <FloatingLabel
                                                id="vehichleLoaded"
                                                name="vehichleLoaded"
                                                type="text"
                                                value={vehicleLoaded}
                                                onChange={(e) => setVehicleLoaded(e.target.value)}
                                                placeholder="vehichleLoaded"
                                                label="Vehicle loaded"
                                                className="relative  ml-3"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <FloatingLabel
                                                id="orderNumber"
                                                name="orderNumber"
                                                type="text"
                                                value={orderNumber}
                                                onChange={(e) => setOrderNumber(e.target.value)}
                                                placeholder="orderNumber"
                                                label="Order Number"
                                                className="relative  ml-3"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-4"></div>
                                    {/* Other breakdown Info */}
                                    <FloatingLabel
                                        id="breakdownInfo"
                                        name="breakdownInfo"
                                        type="text"
                                        value={otherBreakdownInfo}
                                        onChange={(e) => setOtherBreakdownInfo(e.target.value)}
                                        placeholder="breakdownInfo"
                                        label="Other breakdown Info"
                                        className="relative  ml-3"
                                    />
                                    <div className="my-4"></div>
                                    {/*orderNumber*/}
                                    <FloatingLabel
                                        id="breakdownDescription"
                                        name="breakdownDescription"
                                        type="text"
                                        value={breakdownDescription}
                                        onChange={(e) => setBreakdownDescription(e.target.value)}
                                        placeholder="breakdownDescription"
                                        label="Breakdown Description"
                                        className="relative  ml-3"
                                    />

                                    <div className="my-4"></div>

                                    <div className='pl-5'>
                                        <UploadFile updateFileList={setParentFileList} />
                                    </div>

                                    <div className="my-4"></div>
                                    {(!latitude || !longitude) ? (
                                        <p className="text-red-500 text-sm mt-2 ml-3">
                                            Please enter the latitude and longitude.
                                        </p>
                                    ) : (
                                        <>
                                            {!latitude && (
                                                <p className="text-red-500 text-sm mt-2 ml-3">
                                                    Please enter the latitude.
                                                </p>
                                            )}
                                            {!longitude && (
                                                <p className="text-red-500 text-sm mt-2 ml-3">
                                                    Please enter the longitude.
                                                </p>
                                            )}
                                            {(latitude && !isValidLatitude(latitude)) && (
                                                <p className="text-red-500 text-sm mt-2 ml-3">
                                                    Invalid latitude value. Please enter a valid latitude.
                                                </p>
                                            )}
                                            {(longitude && !isValidLongitude(longitude)) && (
                                                <p className="text-red-500 text-sm mt-2 ml-3">
                                                    Invalid longitude value. Please enter a valid longitude.
                                                </p>
                                            )}
                                        </>
                                    )}

                                    {!driverName && (
                                        <p className="text-red-500 text-sm mt-2 ml-3">
                                            Please enter the driver name.
                                        </p>
                                    )}

                                    {!driverCell && (
                                        <p className="text-red-500 text-sm mt-2 ml-3">
                                            Please enter the driver cell.
                                        </p>
                                    )}

                                    {!selectedCompany && (
                                        <p className="text-red-500 text-sm mt-2 ml-3">
                                            Please select a company.
                                        </p>
                                    )}


                                    <button
                                        className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mb-3 ml-3 ${!latitude || !longitude ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        onClick={submitData}
                                        disabled={!latitude || !longitude || !selectedCompany}
                                    >
                                        Create Breakdown Request
                                    </button>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2 bg-gray-400">
                            {breakdownSearchResult && breakdownSearchResult.items.length > 0 && breakdownSearchResult.items[0].position.lat && breakdownSearchResult.items[0].position.lng ? (
                                <MarkerMap
                                    latitude={breakdownSearchResult.items[0].position.lat}
                                    longitude={breakdownSearchResult.items[0].position.lng}
                                    onMarkerPositionChange={handleMarkerPositionChange}
                                />
                            ) : (
                                <MarkerMap
                                    latitude={-26.204412366778424}
                                    longitude={28.049289079589848}
                                    onMarkerPositionChange={handleMarkerPositionChange}
                                />
                            )}
                        </div>
                    </div>
                </>
            ) : <h3>Unauthorized Access</h3>}


        </div>
    )
}

export default BreakdownRequest;