import {useEffect, useState} from 'react';
import { appSettings } from '../../../AppSettings';
import DataTable from 'react-data-table-component';
import { useNavigate, Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';

const columns = [
    {
        name: 'Name',
        selector: (row) => (<Link to={'/portal/secure/company/editcompany/' + row.id}>{row.name}</Link>),
        sortable: true
    },
    {
        name: 'Company Type',
        selector: (row) => row.companyTypeDescription ,
        sortable: true
    },
    {
        name: 'Is Holding Company',
        selector: (row) => (row.isHoldingCompany === 0) ? "NO"  : "YES" ,
        sortable: true
    },
    {
        name: 'Address',
        selector: (row) => row.address,
        sortable: true
    }
];

const NewButton = () => {
    return (
        <Container fluid>
            <Row>
                <Col md={6} style={{padding: '0 !important'}}>
                    <Link to='/portal/secure/company/editcompany/new'>
                        <button className="text-blue-500 hover:text-blue-700 font-semibold"> 
                            + New Company
                        </button>
                    </Link>
                </Col>
            </Row>
        </Container>
        
    )
}


export default function PortalSecureCompanyListCompanies() {
    
    const [companies, setCompanies] = useState([]);
    const [searchText, setSearchText] = useState('');

    const fetchCompanies = async () => {
        return await fetch(`${appSettings.apiUrl}/company/getallcompanies/`)
            .then(async response => {
                setCompanies(await response.json())})
            .catch(error => console.error(error));
    
    }

    useEffect(() => {
        fetchCompanies();
    }, []);

    const handleRowClicked = (row) => {
        //navigate('/portal/secure/breakdownDetails', { state: { breakdownId: row.id } });
    };

    const filteredCompanies = companies.filter((company) => {
        return (
            company.name.toLowerCase().includes(searchText.toLowerCase())
            )
    })

    
    return (
        <Container>
            <Row>
                <Col>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable
                        title="Companies"
                        columns={columns}
                        data={filteredCompanies}
                        pagination={true}
                        striped={true}
                        highlightOnHover={true}
                        onRowClicked={handleRowClicked}
                        className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                        cellClassName="border-b border-[#f9787c] px-4 py-2"
                        headerClassName="bg-[#f9787c] text-white font-semibold"
                        subHeader
                        subHeaderComponent={<NewButton />}
                    />
                </Col>
            </Row>

        </Container>
        

    )
}