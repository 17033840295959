import {createContext, useContext} from "react";
import NotificationStore from "./notificationStore";
import SignalRStore from "./signalRStore";
import UserStore from "./userStore";
import BreakdownStore from "./breakdownStore";

export const store = {
    notificationStore: new NotificationStore(),
    signalRStore: new SignalRStore(),
    userStore: new UserStore(),
    breakdownStore: new BreakdownStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}