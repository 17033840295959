// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes swing {
    0%, 100% { transform: rotate(15deg); }
     50% { transform: rotate(-15deg); }
  }
  
  @keyframes pulseRed {
    0%, 100% { color: red; }
    50% { color: initial; }
  }
    
  .swing {
    animation: swing 1s ease-in-out infinite;
  }


  `, "",{"version":3,"sources":["webpack://./src/styles/swing.css"],"names":[],"mappings":"AAAA;IACI,WAAW,wBAAwB,EAAE;KACpC,MAAM,yBAAyB,EAAE;EACpC;;EAEA;IACE,WAAW,UAAU,EAAE;IACvB,MAAM,cAAc,EAAE;EACxB;;EAEA;IACE,wCAAwC;EAC1C","sourcesContent":["@keyframes swing {\n    0%, 100% { transform: rotate(15deg); }\n     50% { transform: rotate(-15deg); }\n  }\n  \n  @keyframes pulseRed {\n    0%, 100% { color: red; }\n    50% { color: initial; }\n  }\n    \n  .swing {\n    animation: swing 1s ease-in-out infinite;\n  }\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
