import {useEffect, useState} from 'react';
import { appSettings } from '../AppSettings';
import DataTable from 'react-data-table-component';
import {Container, Row, Col} from 'react-bootstrap';
import Navbar from '../components/Navbar';
import Logo from '../assets/rnr_logo.png';
import {ScaleLoader} from 'react-spinners';
import {Button} from 'react-bootstrap';



const SetCACUpdatedButton = (params) => {
    const [buttonText, setButtonText] = useState('Complete Update');
    //Update the Database
    const updateDatabase = async (id, status) => {
    
        await fetch(`${appSettings.apiUrl}/dealerbreakdowns/setcacupdated/${id}/${status}`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            }
        })
        .then(async response => {
            setButtonText('Update Complete')
            params.performRefresh()})
        .catch(error => console.error(error));
   };

    return (<Button style={{backgroundColor:'#c97676', borderColor:'#bc4b4b', fontSize:'9pt'}} variant='primary' onClick={() => { updateDatabase(params.Id, params.breakdownStatus)}} >{buttonText}</Button>)
}


export default function DashboardDealerStatusMonitor() {

    const columns = [
        {
            name: 'Dealer',
            selector: (row) => row.supplierName,
            sortable: true
        },
        {
            name: 'Customer',
            selector: (row) => row.customerName,
            sortable: true
        },
        {
            name: 'Registration',
            selector: (row) => row.registrationNumber ,
            sortable: true
        },
        {
            name: 'Case Number',
            selector: (row) => row.caseNumber,
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => row.breakdownStatusText,
            sortable: true
        },
        {
            name: 'Status Date',
            selector: (row) => row.breakdownStatusDateTimeFormatted,
            sortable: true
        },
        {
            name: '',
            selector: (row) => <SetCACUpdatedButton Id={row.breakdownId} breakdownStatus={row.breakdownStatus} performRefresh={performRefresh} />,
            sortable: true
        }
    ];

    const [dealerBreakdowns, setDealerBreakdowns] = useState([]);
    const [isLoading,setIsloading] = useState(false);


    const fetchDealerBreakdowns = async () => {
        setIsloading(true);
        await fetch(`${appSettings.apiUrl}/dealerbreakdowns/dealerbreakdownscacdashboard`)
        .then(async response => {
            setDealerBreakdowns(await response.json())})
        .catch(error => console.error(error));
        setIsloading(false);
    };

    const performRefresh = () => {
        fetchDealerBreakdowns();
    }

    useEffect(() => {
        fetchDealerBreakdowns();

        const interval = setInterval(() => {
            fetchDealerBreakdowns();
        },120000 )

        return () => {
            clearInterval(interval);
        };
    },[]);

    return (
        <div>
            <div className="border-b sticky top-0 z-50 bg-white/[95%]">
                <div className="flex justify-between items-center sm:mx-6 md:mx-10 lg:mx-12 ">
                    {/*Left */}
                        <div className="h-20 flex">
                            <img src={Logo} className="object-cover w-200 -my-1" />
                        </div>
                </div>
            </div>
            <Container>
                <Row>
                    <Col>
                        <ScaleLoader color="#36d7b7" loading={isLoading} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DataTable
                            title="Dealer Breakdowns"
                            columns={columns}
                            data={dealerBreakdowns}
                            pagination={false}
                            striped={true}
                            highlightOnHover={true}
                            className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                            cellClassName="border-b border-[#f9787c] px-4 py-2"
                            headerClassName="bg-[#f9787c] text-white font-semibold"
                        />
                    </Col>
                </Row>

        </Container>

        </div>
    )
}