import { Container, Row, Col, Form, FloatingLabel, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Result } from 'antd-mobile';
import { appSettings } from '../../AppSettings';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {useStore} from '../../Utilities/Stores/store'
import { observer } from 'mobx-react-lite';

export default observer(function PortalAuthLogin() {
    const {userStore} = useStore();
    const {setUser} = userStore;
    const navigate = useNavigate();
    const [updatedUser, setUpdatedUser] = useState();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState();
    const [progess, setProgress] = useState("Login");

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    const performLogin = async () => {
      
        setProgress("Logging in.  Please wait...");

        const loginModel = {
            username: username,
            password: password
        };

        const response = await fetch(appSettings.apiUrl + '/auth/performlogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginModel)
        });

        
        if (response.ok) {
            const json = await response.text();
            const decodedToken = jwtDecode(json);

            const fullName = decodedToken.unique_name;
            const loginId = decodedToken.LoginId;
            const userId = decodedToken.nameid;
            const userType = decodedToken.role[0];
            const authority = decodedToken.Authority;

            sessionStorage.setItem('currentUser', JSON.stringify({
                fullName: fullName,
                loginId: loginId,
                userId: userId,
                jwtToken: json,
                userType: userType,
                authority: authority
            }));

            navigate('/portal/secure/breakdownlist');
        } else {
            setProgress("Please try logging in again.");
            setResult(<Result status='error' title='Please check Username or Password.' />);   
        }
    };


    /*useEffect(() => {
        if (updatedUser) {
            sessionStorage.setItem('currentUser', JSON.stringify(updatedUser));
          
           
            setResult(<Result status='success' title='LOGIN SUCCESSFUL' />);
            navigate('/portal/secure/breakdownlist');
        }
    }, [updatedUser, navigate]);
    */


    function signOut() {
        sessionStorage.removeItem('currentUser');
        navigate('/portal');
    }

    /*useEffect(() => {
        if (currentUser && currentUser.userId) {
            navigate('/portal/secure/breakdownlist');
        }
    }, [currentUser, navigate]);*/

    return (
        <div>
            {currentUser ? (
                <div>
                    <h3>Already Signed In as {currentUser.fullName}, <Link><button onClick={signOut}>Sign Out ?</button></Link> </h3>
                </div>
            ) : (
                <Container>
                    <br/>
                    <Row lg="1">
                        <Col>
                            <Form>
                                <Row className="mb-3">
                                    <Col>SAAS Portal Version: 2.1.206</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <InputGroup hasValidation>
                                            <FloatingLabel controlId="username" label="Username">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Username"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                />
                                            </FloatingLabel>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <FloatingLabel controlId="password" label="Password">
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col className="d-grid">
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            onClick={() => performLogin()}
                                            className=" border px-3 py-2 rounded-full gap-2 bg-[#ff5a60] text-white font-bold shadow-lg shadow-gray-300 hover:bg-[#f9787c] duration-100 ease-out"
                                        >
                                            {progess}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>{result}</Col>
                    </Row>
                </Container>
            )}
        </div>
    )
})
