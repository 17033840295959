import { makeAutoObservable, runInAction } from "mobx";
import agent from "../API/agent";

export default class UserStore {
    localCurrentUser = null;

    constructor() {
        makeAutoObservable(this)
    }

    get isLoggedIn() {
        return !!this.user;
    }

    logout = () => {
        this.localCurrentUser = null;
    }

    setUser = (user) => {
        
        this.localCurrentUser = user;
    }

    get currentUser(){
        return this.localCurrentUser;
    }
}
