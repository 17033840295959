import React from 'react';

function FollowUpCheckbox({ isFollowUpClosed, handleCheckboxChange }) {
  return (
    <div className="flex justify-between mt-3 mr-28 ml-20" hidden>
      <label htmlFor="followUpCheckbox" className="mr-15 flex items-center">
        <input
          type="checkbox"
          id="followUpCheckbox"
          checked={isFollowUpClosed}
          onChange={handleCheckboxChange}
          className="h-4 w-4 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        Close FollowUp?
      </label>
    </div>
  );
}

export default FollowUpCheckbox;
