// import SubmenuFilter from '../../components/SubmenuFilter';
// import Navbar from "../../components/Navbar";
// import { DataCountProvider } from '../../components/DataCountContext';
// import { useEffect, useState } from 'react';
// import { appSettings } from '../../AppSettings';
// import KJUR from 'jsrsasign';

const RNRReports = () => {

    // const [token, setToken] = useState(null);
    // const [encodedToken, setEncodedToken] = useState(null);
    // const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // const [iframeError, setIframeError] = useState(null);

    // const handleIframeError = (event) => {
    //     setIframeError(event);
    //     const errorMessage = event.nativeEvent.message;
    //     console.error('Iframe Error:', errorMessage);
    // };

    // useEffect(() => {
    //     if (currentUser) {
    //         const userId = currentUser.loginId;

    //         const apiUrl = `${appSettings.apiUrl}/Auth/generateWebToken?userId=${userId}`;

    //         fetch(apiUrl, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         })
    //             .then((response) => response.text())
    //             .then((data) => {
    //                 debugger

    //                 const secret = 'uJXc9mVNHonqeVSFldZomXEHhgGVsZ3RNcqR1EqiUIHerRKjrDw1kQS2TxDRsQ8j70ER/Mgw0/a63PAW/8Gl4A==';

    //                 const jwtParts = data.split('.');
    //                 console.log('JWT Parts:', jwtParts);
    //                 const header = JSON.parse(atob(jwtParts[0]));
    //                 const payload = JSON.parse(atob(jwtParts[1]));
    //                 console.log('Header:', header);
    //                 console.log('Payload:', payload);

    //                 KJUR.jws.JWS.debug = true;

    //                 const isValid = KJUR.jws.JWS.verify(data, secret, { alg: ['HS256'] });

    //                 if (isValid) {
    //                     const payload = KJUR.jws.JWS.readSafeJSONString(KJUR.b64utoutf8(data.split(".")[1]));
    //                     setToken(data);
    //                     const tokenParam = encodeURIComponent(data);
    //                     setEncodedToken(tokenParam);
    //                     console.log('Decoded payload:', payload);
    //                 } else {
    //                     console.error('Token verification failed.');
    //                     console.error('Debug info:', KJUR.jws.JWS.readSafeJSONString(KJUR.b64utoutf8(data.split(".")[0])));
    //                 }

    //                 setToken(data);

    //                 // setToken(data);
    //                 // const tokenParam = encodeURIComponent(token);
    //                 // setEncodedToken(tokenParam);          
    //             })
    //             .catch((error) => {
    //                 console.error('Error fetching token:', error);
    //             });
    //     }
    // }, []);

    return (
        // <div className="flex flex-col">
        //     <DataCountProvider>
        //         <div>
        //             <Navbar />
        //             <SubmenuFilter />
        //             <div></div>
        //         </div>
        //     </DataCountProvider>

        //     {token && (
        //         <div>
        //             {iframeError && (
        //                 <p>Iframe Error: {iframeError.message}</p>
        //             )}
        //             <iframe
        //                 src={`https://rnr-iframe-git-iframe-login-lowdefy.vercel.app/login?page=customer-overview&token=${token}`}
        //                 width="600"
        //                 height="400"
        //                 title="RNR Iframe"
        //                 onError={handleIframeError}
        //             ></iframe>
        //         </div>
        //     )}

        // </div>
        <div></div>
    )
}

export default RNRReports;