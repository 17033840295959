import { useLocation } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import SubmenuFilter from "../../../../components/SubmenuFilter";
import Navbar from "../../../../components/Navbar";
import {
  DataCountContext,
  DataCountProvider,
} from "../../../../components/DataCountContext";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import useCaseInsensitiveSort from "../../../../components/utilities/useCaseInsensitiveSort";
import { AiOutlinePlus, AiFillEdit } from "react-icons/ai";
import {  Link } from 'react-router-dom';
import agent from "../../../../Utilities/API/agent";

import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";

const ListCategory = () => {

  const location = useLocation();
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("catergoryName");
  const [breakdownCategoryId, setbreakdownCategoryId] = useState();
  const [addCategory, setAddCategory] = useState();
  const [editCategoryVisble, setEditCategoryVisble] = useState();
  const [addCategoryVisble, setAddCategoryVisble] = useState();
  const sortCategoriesAlphabetically = (a, b) => {
    if (a.categoryName < b.categoryName) {
      return -1;
    }
    if (a.categoryName > b.categoryName) {
      return 1;
    }
    return 0;
  };
  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortField: "id",
      sortable: true,
      omit: true,        
    },
    {
      name: "Category Name",
      //selector: (row) => row.categoryName,
      //sortFunction: caseInsensitiveSortFullName,
      
      selector: (row) => (
        <Link onClick={() => handleCategoryClick(row)}>{row.categoryName}</Link>
      ),
      sortField: "catergoryName",
      sortable: true,
      omit: false,
      width: "40%",
    },
    {
      name: "Actions",
      width: "60%",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>

          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={() => editCategory(row)}
          >
            <span className="mr-2">SubCategory</span>
            <AiFillEdit size={20} />
          </button>
        </div>
      ),
    },
  ];

  const [rnrCategories, setRnrCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  
  async function fetchCatagories() {
    try {
      const data = await agent.Settings.getAllCategories();
      setRnrCategories(data);
    } catch (error) {
      console.error("Error fetching holding companies:", error);

    }
  }
  const handleAddCategoryClick = () => { 
    setAddCategoryVisble(true);
    fetchData();
  };

  const handleCloseEditCategoryClick = () => {
    setEditCategoryVisble(false);
  };
  const handleEditCauseClick = (row) => {
    setbreakdownCategoryId(row.id);
    setEditCategoryVisble(true);
   
  };

  const handleCategoryClick = async (row) => {
    setbreakdownCategoryId(row.id);
    setEditCategoryVisble(true);
  };
  const handleCloseAddCategoryClick = () => {
    setEditCategoryVisble(false);
    fetchData();
  };
  
  async function editCategory(row) {
    navigate("/portal/secure/settings/categories/SubCategoryList", {
      state: {
        breakdownCategoryId: row.id,
        categoryName: row.categoryName,
      },
    });
  }


  
    const fetchData = async () => {
      var data = await agent.Settings.getAllCategories();
      const sortedCategories = data.sort(sortCategoriesAlphabetically);
        setRnrCategories(sortedCategories);
    };
    useEffect(() => {

    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <DataCountProvider>
          <div>
            <Navbar />
            <SubmenuFilter dataCount={dataCount} />
            <div></div>
          </div>
        </DataCountProvider>

        {/* <div className="flex items-center justify-center h-full mt-3">
          <div className="text-center font-serif text-lg font-semibold text-gray-800 tracking-wide uppercase border-b-2 border-gray-300 inline-block py-1">
            {cause}
          </div>
        </div> */}

        <div
          className="pt-2 pl-5"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={handleAddCategoryClick}
          >
            <span className="mr-2">Add Category</span>
            <AiOutlinePlus />
          </button>
        </div>

        <div className="pl-5 pr-5">
          <DataTable
            columns={columns}
            data={rnrCategories}
            pagination={false}
            striped={true}
            highlightOnHover={true}
            onRowClicked={handleEditCauseClick}
            className=" border"
            cellClassName="border-b border-[#f9787c] px-4 py-2"
            headerClassName="bg-[#f9787c] text-white font-semibold"
            defaultSortFieldId={2}
            defaultSortAsc={false}
          />
        </div>
        <br />
        <br />
      </div>
      {addCategoryVisble && (
        <AddCategory
          visible={addCategoryVisble}
          onClose={handleCloseAddCategoryClick}
        />
      )}
      {editCategoryVisble && breakdownCategoryId &&  (
        <EditCategory
          visible={editCategoryVisble}
          onClose={handleCloseEditCategoryClick}
          categoryId={breakdownCategoryId}
        />
      )}
    </>
  );
};

export default ListCategory;
