import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Badge } from 'antd';
import { appSettings } from '../../AppSettings';

const ImageViewButton = ({ onClick, id }) => {
   
    const [badgeCount, setBadgeCount] = useState(0); 

    useEffect(() => {
             const fetchBadgeCount = async () => {
                const response = await fetch(`${appSettings.apiUrl}/ImageCount/RetrieveBreakdownImageList/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    // Check if data is not empty before setting the badge count
                    if (data && data.length > 0) {
                        // Summing up the 'imageCount' values
                        const totalCount = data.reduce((acc, item) => acc + item.imageCount, 0);
                        setBadgeCount(totalCount);
                    } else {
                        // If data is empty, set the badge count to 0
                        setBadgeCount(0);
                    }
                }
           };
        fetchBadgeCount(); 
    }, [id]); 

    return (
        <>
            {badgeCount > 0 && (
                <Badge count={badgeCount}>
                    <Button
                        type="primary"
                        onClick={onClick}
                        style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
                        className="w-full"
                    >
                        <span style={{ color: 'white' }}>View Images</span>
                    </Button>
                </Badge>
            )}
            {badgeCount === 0 && (
                <Button
                    type="primary"
                    onClick={onClick}
                    style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
                    className="w-full"
                >
                    <span style={{ color: 'white' }}> View Images </span>
                </Button>
            )}
        </>
    );
};

export default ImageViewButton;