import { useLocation } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import SubmenuFilter from "../../../../components/SubmenuFilter";
import Navbar from "../../../../components/Navbar";
import {
  DataCountContext,
  DataCountProvider,
} from "../../../../components/DataCountContext";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import useCaseInsensitiveSort from "../../../../components/utilities/useCaseInsensitiveSort";
import { AiOutlinePlus, AiFillEdit } from "react-icons/ai";
import {  Link } from 'react-router-dom';
import agent from "../../../../Utilities/API/agent";
import AddCauses from "./AddCauses";
import EditCause from "./EditCause";


const ListCause = () => {
  const location = useLocation();
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("cause");
  const [breakdownCausesId, setbreakdownCausesId] = useState();
  const [editCauseVisble, setEditCauseVisble] = useState();
  const [addCauseVisble, setAddCauseVisble] = useState();

  const sortCauseAlphabetically = (a, b) => {
    if (a.cause < b.cause) {
      return -1;
    }
    if (a.cause > b.cause) {
      return 1;
    }
    return 0;
  };

  const handleAddCauseClick = (row) => {
    setbreakdownCausesId(row.id)
    setAddCauseVisble(true);
  };

  const handleCloseEditCauseClick = () => {
    setEditCauseVisble(false);
    fetchData();
  };
  const handleEditCauseClick = (row) => {
    setbreakdownCausesId(row.id);
    setEditCauseVisble(true);
  };
  

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortField: "id",
      sortable: true,
      omit: true,
    },
    {
      name: "Causes",
  //    selector: (row) => row.cause,
      //sortFunction: caseInsensitiveSortFullName,
      
      selector: (row) => (
        <Link onClick={() => handleCauseClick(row)}>{row.cause}</Link>
      ),
      sortField: "cause",
      sortable: true,
      omit: false,
      width: "40%",
    },
    {
      name: "Actions",
      width: "60%",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>

          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
             onClick={() => handleCauseClick(row)}
          >
            <span className="mr-2">Edit Cause</span>
            <AiFillEdit size={20} />
          </button>
        </div>
      ),
    },
  ];

  const [rnrCauses, setRnrCauses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");


  const handleCauseClick = async (row) => {
    setbreakdownCausesId(row.id)
    setEditCauseVisble(true);
    
  };
  const handleCloseAddCauseClick = () => {
    setEditCauseVisble(false);
    fetchData();
  };

  // async function editCause(row) {
  //   navigate("/portal/secure/settings/categories/ListCause", {
  //     state: {
  //       setbreakdownCausesId: row.id,
  //       cause: row.causeName,
  //     },
  //   });
  // }

  const fetchData = async () => {
    var data = await agent.Settings.getCauses();
    const sortedCauses = data.sort(sortCauseAlphabetically);
    setRnrCauses(sortedCauses);
  };
  useEffect(() => {
   
    fetchData();
  }, []);


  return (
    <>
      <div className="flex flex-col">
        <DataCountProvider>
          <div>
            <Navbar />
            <SubmenuFilter dataCount={dataCount} />
            <div></div>
          </div>
        </DataCountProvider>

        {/* <div className="flex items-center justify-center h-full mt-3">
          <div className="text-center font-serif text-lg font-semibold text-gray-800 tracking-wide uppercase border-b-2 border-gray-300 inline-block py-1">
            {cause}
          </div>
        </div> */}

        <div
          className="pt-2 pl-5"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={handleAddCauseClick}
          >
            <span className="mr-2">Add Cause</span>
            <AiOutlinePlus />
          </button>
        </div>

        <div className="pl-5 pr-5">
          <DataTable
            columns={columns}
            data={rnrCauses}
            pagination={false}
            striped={true}
            highlightOnHover={true}
            onRowClicked={handleEditCauseClick}
            className=" border"
            cellClassName="border-b border-[#f9787c] px-4 py-2"
            headerClassName="bg-[#f9787c] text-white font-semibold"
            defaultSortFieldId={2}
            defaultSortAsc={false}
          />
        </div>
        <br />
        <br />
      </div>
      {addCauseVisble && (
        <AddCauses
          visible={addCauseVisble}
          onClose={handleCloseAddCauseClick}
        />
      )}
      {editCauseVisble && breakdownCausesId &&(
        <EditCause
          visible={editCauseVisble}
          onClose={handleCloseEditCauseClick}
          breakdownCausesId={breakdownCausesId}
        />
      )}
    </>
  );
};

export default ListCause;
