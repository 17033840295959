import { useEffect, useState, useCallback } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import BreakdownStatusIcon from "../../../components/BreakdownStatusIcon";
import SubmenuFilter from "../../../components/SubmenuFilter";
import { DataCountProvider } from "../../../components/DataCountContext";
import FetchApi from "../../../components/utilities/fetchApi";
import { Tooltip } from "antd";
import MyMenu from "../../../components/breakdowns/BreakdownMenu";
import useCaseInsensitiveSort from "../../../components/utilities/useCaseInsensitiveSort";
import { appSettings } from "../../../AppSettings";
import agent from "../../../Utilities/API/agent";
import { store, useStore } from "../../../Utilities/Stores/store";
import { observer } from "mobx-react-lite";
import { Switch } from "antd";

const PortalBreakdownsDetails = () => {
  //Calculate The display height
  var displayHeight = window.innerHeight;
  var displayHeight = displayHeight - 250;
  const { breakdownStore } = useStore();
  const { getFilter, setFilter } = breakdownStore;
  const navigate = useNavigate();
  const [breakdowns, setBreakdowns] = useState([]);
  const [currentUserExists, setCurrentUserExists] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const roleType = "ADMIN";
  const defaultSortFieldId = userRole !== roleType ? 5 : 5;
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("customerName");
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataCount, setDataCount] = useState(0);

  const [active, setActive] = useState([]);
  const [qa, setQA] = useState([]);
  const [delayed, setDelayed] = useState([]);
  const [workshop, setWorkshop] = useState([]);

  const [lockedRows, setLockedRows] = useState({});

  const [current, setCurrent] = useState("");
  const [referenceToggle, setReferenceToggleField] = useState("reference");
  const [regToggle, setRegToggle] = useState("registration");

  useEffect(() => {
    const storedUser = sessionStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (currentUser !== null) {
      setUserRole(currentUser.userType);
      setCurrentUserExists(true);
    }
  }, [currentUser]);

  const getBreakdowns = async () => {
    try {
      const promises = getFilter.map(async (element) => {
        let result;
        switch (element) {
          case "Delayed":
            result = await agent.BreakdownRequest.delayed();
            return result;
          case "QA":
            result = await agent.BreakdownRequest.qa();
            return result;
          case "Workshop":
            result = await agent.BreakdownRequest.workshop();
            return result;
          case "Active":
            result = await agent.BreakdownRequest.activeBreakdownList();
            return result;
          default:
            result = await agent.BreakdownRequest.activeBreakdownList();
            return result;
        }
      });

      const breakdownResults = await Promise.all(promises);
      const combinedResults = breakdownResults.flat(); // Flatten the array of arrays

      breakdownAppend(combinedResults);
    } catch (error) {
      console.error("Error fetching breakdowns:", error);
    }
  };

  /*For Testing Purposes - Remove later */
  useEffect(() => {
    getBreakdowns();
    const interval = setInterval(getBreakdowns, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const breakdownAppend = (breakdownList) => {
    const updatedBreakdowns = [...breakdowns, ...breakdownList];
    const sortedBreakdowns = updatedBreakdowns.sort(sortElapsedTime);
    setBreakdowns(sortedBreakdowns);
  };
  const removeDuplicates = async (filter) => {
    let currentBreakdowns = breakdowns;
    let statusToInclude = [];
    switch (filter) {
      case "Delayed":
        statusToInclude = [18];
        currentBreakdowns = currentBreakdowns.filter((item) => !statusToInclude.includes(item.breakdownStatus));
        break;
      case "QA":
        statusToInclude = [19];
        currentBreakdowns = currentBreakdowns.filter((item) => !statusToInclude.includes(item.breakdownStatus));

        break;
      case "Workshop":
        statusToInclude = [20, 60];
        currentBreakdowns = currentBreakdowns.filter((item) => !statusToInclude.includes(item.breakdownStatus));

        break;
      case "Active":
        statusToInclude = [0, 1, 2, 3, 4, 5, 8, 9, 10, 30, 40, 50];
        currentBreakdowns = currentBreakdowns.filter((item) => !statusToInclude.includes(item.breakdownStatus));

        break;
      default:
    }
    setBreakdowns(currentBreakdowns);
  };

  const niceDate = (dateString) => {
    var date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 as months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  function updatedBreakdownRules(followupDate, datetimeOffset, assignedMechanics, breakdownMechStatus, followUpDateTime, lastInteractionDate, nameMatch, breakdownMechanicStatus, followUp) {
    const parsedDateTimeOffset = new Date(datetimeOffset);

    const parsedFollowUpDateTime = new Date(followUpDateTime);

    const timeDiff = Math.abs(parsedFollowUpDateTime - parsedDateTimeOffset);
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));

    const currentTime = new Date();

    const dictionaryList = {
      notConsultant: 1,
      noUpdateOrTimer: 2,
      timeLapsed: 3,
      noMechanicOnSite: 4,
      noMechanic30: 5,
      noMechanic15: 6,
      newBreakdown: 7,
    };

    var color = "";
    var tooltip = "";
    let conditionResults = {};
    const trueConditions = [];

    if (assignedMechanics === 0 && minutesDiff < 15) {
      conditionResults.newBreakdown = true;
    } else {
      conditionResults.newBreakdown = false;
    }

    if (assignedMechanics === 0 && minutesDiff >= 15) {
      conditionResults.noMechanic15 = true;
    } else {
      conditionResults.noMechanic15 = false;
    }

    if (assignedMechanics === 0 && minutesDiff > 15 && minutesDiff <= 30) {
      conditionResults.noMechanic30 = true;
    } else {
      conditionResults.noMechanic30 = false;
    }

    if (nameMatch === "false") {
      conditionResults.notConsultant = true;
    } else {
      conditionResults.notConsultant = false;
    }

    if (followUp === 0 && minutesDiff >= 30) {
      conditionResults.noUpdateOrTimer = true;
    } else {
      conditionResults.noUpdateOrTimer = false;
    }

    if (breakdownMechanicStatus != 2) {
      conditionResults.noMechanicOnSite = true;
    } else {
      conditionResults.noMechanicOnSite = false;
    }

    if (currentTime > parsedFollowUpDateTime) {
      conditionResults.timeLapsed = true;
    } else {
      conditionResults.timeLapsed = false;
    }

    for (const key in conditionResults) {
      if (conditionResults[key]) {
        if (dictionaryList.hasOwnProperty(key)) {
          trueConditions.push({
            name: key,
            value: dictionaryList[key],
          });
        }
      }
    }

    const lowestValueItem = trueConditions.reduce((minItem, currentItem) => {
      if (currentItem.value < minItem.value) {
        return currentItem;
      }
      return minItem;
    }, trueConditions[0]);

    switch (lowestValueItem.name) {
      case "notConsultant":
        color = "purple";
        tooltip = "Note captured by anyone who is not a consultant.";
        break;
      case "noUpdateOrTimer":
        color = "black";
        tooltip = "No follow up timer set and no update half an hour";
        break;
      case "timeLapsed":
        color = "green";
        tooltip = "Breakdown Not accepted mechanic (15 minutes from creation)";
        break;
      case "noMechanicOnSite":
        color = "red";
        tooltip = "No mechanic on site";
        break;
      case "noMechanic30":
        color = "orange";
        tooltip = "Breakdown Not accepted mechanic (30 minutes from creation)";
        break;
      case "noMechanic15":
        color = "yellow";
        tooltip = "Breakdown Not accepted mechanic (15 minutes from creation)";
        break;
      case "newBreakdown":
        color = "blue";
        tooltip = "New Breakdown";
        break;
      default:
        color = "#FFFFFF";
        break;
    }
    color = "#fffff";
    tooltip = "";

    return { color, tooltip };
  }

  function formatTime(minutes, followUpNote) {
    /*if (followUpNote === "None") {
            return 'No Follow Up';
        }*/

    if (parseFloat(minutes) == -99999999999999) {
      return "No Follow Up";
    }

    var overdue = false;
    if (minutes < 0) {
      minutes *= -1;
      overdue = true;
    }
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const mins = minutes % 60;

    let formattedTime = "";
    if (days !== 0) {
      formattedTime += `${days} Days `;
    }
    if (hours !== 0) {
      formattedTime += `${hours} Hours `;
    }
    formattedTime += `${mins} Mins`;

    if (overdue) {
      formattedTime = "- " + formattedTime;
    }
    return formattedTime;
  }

  const sortElapsedTime = (rowA, rowB) => {
    const timeA = parseFloat(rowA.elapsedTime);
    const timeB = parseFloat(rowB.elapsedTime);

    if (timeA < 0 && timeB < 0) {
      return Math.abs(timeB) - Math.abs(timeA);
    } else {
      return timeA - timeB;
    }
  };

  const handleReferenceToggle = (checked) => {
    setReferenceToggleField(checked ? "caseNumber" : "reference");
  };
  const handleRegToggle = (checked) => {
    setRegToggle(checked ? "fleetNumber" : "registration");
  };

  let columns = [
    {
      name: "",
      selector: (row) => row.elapsedTime,
      sortable: false,
      width: "60px",
      cell: (row) => <BreakdownStatusIcon row={row} updatedBreakdownRules={updatedBreakdownRules} />,
    },
    {
      name: <div style={{color:'black'}}> <Switch  style={{backgroundColor: 'lightGrey'}} checkedChildren='Case Number' unCheckedChildren='Reference' defaultChecked={referenceToggle === "caseNumber"} onChange={handleReferenceToggle} />
      </div> ,
       width: "150px",
     // selector: (row) => (referenceToggle === "reference" ? row.breakdownReference : row.caseNumber),
    
      cell: (row) => {
        /*const { color } = updatedBreakdownRules(
                    row.followupDate,
                    row.dateTimeOffset,
                    row.assignedMechanics,
                    row.breakdownMechStatus,
                    row.followUpDateTime,
                    row.lastInteractionDate,
                    row.nameMatch,
                    row.breakdownMechanicStatus,
                    row.followUp
                    
                );
                */

        var color = "#fff";

        var textColor = "#000";

        if (row.dealershipOfPurchase == "Over 6 Hours") {
          textColor = "red";
        }
        return (
          /*<div
            style={{
              backgroundColor: color, // Set the background color based on the color from updatedBreakdownRules
              padding: "5px",
              borderRadius: "5px",
              color: "black",
              fontSize: "11px",
            }}
            onClick={() => handleRowClicked(row)}
          >
            {row.breakdownReference}
          </div>*/
          <div style={{ color: textColor }}>{(referenceToggle === "reference" ? row.breakdownReference : row.caseNumber)}</div>
        );
      },
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
      sortField: "customerName",
      sortFunction: caseInsensitiveSortFullName,
      sortable: true,
    },
    {
      name: <div style={{color:'black'}}>     <Switch  style={{backgroundColor: 'lightGrey'}}  checkedChildren='Feet Number' unCheckedChildren='Registration' defaultChecked={regToggle === "fleetNumber"} onChange={handleRegToggle} />
      </div>,
       width: "150px",
      selector: (row) => (regToggle == "registration" ? row.registrationNumber : row.fleetNumber),
     
    },
    {
      name: "Follow-up Time",
      selector: (row) => row.elapsedTime,
      sortField: "elapsedTime",
      sortable: true,
      format: (row) => formatTime(row.elapsedTime, row.followUpNote),
      sortFunction: sortElapsedTime,
      cell: (row) => {
        return (
          <div onClick={() => handleRowClicked(row)}>
            <div
              style={{
                color: row.elapsedTime < 0 || row.followUpNote == "No Follow Up" ? "red" : "black",
              }}>
              {formatTime(row.elapsedTime, row.followUpNote)}
            </div>
          </div>
        );
      },
    },
    {
      name: "Last Note",
      sortable: true,
      sortFunction: sortElapsedTime,
      cell: (row) => {
        return (
          <div onClick={() => handleRowClicked(row)}>
            <Tooltip title={row.fullLastNote}>
              <span>{String(row.fullLastNote).substring(0, 34)}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      name: "Last Interacted",
      selector: (row) => row.lastInteractionDate,
      sortField: "lastInteractionDate",
      sortable: true,
      cell: (row) => <span onClick={() => handleRowClicked(row)}>{niceDate(row.lastInteractionDate)}</span>,
    },
    {
      name: "Consultant",
      selector: (row) => row.consultant,
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row.assignedSupplier,
      sortField: "assignedSupplier",
      sortable: true,
    },
    {
      name: "Mechanic",
      selector: (row) => row.assignedMechanic,
      sortField: "assignedMechanic",
      sortable: true,
      cell: (row) => <span onClick={() => handleRowClicked(row)}>{row.assignedMechanic}</span>,
    },
    {
      name: "Status",
      selector: (row) => row.statusDescription,
      sortField: "statusDescription",
      sortable: true,
      cell: (row) => <span onClick={() => handleRowClicked(row)}>{row.statusDescription}</span>,
    },
    {
      name: "Created Date",
      data: (row) => row.dateTimeOffset,
      sortField: "lastInteractionDate",
      sortable: true,
      cell: (row) => <div onClick={() => handleRowClicked(row)}>{niceDate(row.dateTimeOffset)}</div>,
    },
  ];

  //Expander information
  const ExpandedComponent = ({ data }) => {
    return <div style={{ fontWeight: "bold", padding: "8px" }}> LAST NOTE: {data.fullLastNote}</div>;
  };

  if (userRole !== roleType) {
    columns = columns.filter((column) => column.name !== "Lock");
  }

  const handleRowClicked = (row) => {
    navigate("/portal/secure/breakdowns/breakdownDetails", {
      state: { breakdownId: row.id },
    });
  };

  const filteredBreakdowns = breakdowns?.filter((breakdown) => {
    return (
      breakdown.breakdownReference.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.fleetNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.registrationNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.vehicle.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.driverAlias.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.assignedSupplier.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.assignedMechanic.toLowerCase().includes(searchTerm.toLowerCase()) ||
      breakdown.fullLastNote.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  if (!currentUserExists) {
    return null; // Or any loading indicator you prefer
  }
  return (
    <div>
      <DataCountProvider>
        {currentUser ? (
          <>
            <SubmenuFilter />

            <br />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "1%",
                paddingRight: "1%",
                paddingTop: "2px",
              }}>
              <div style={{ width: "50%" }}>
                <MyMenu
                  delayed={delayed}
                  setDelayed={setDelayed}
                  active={active}
                  setActive={setActive}
                  qa={qa}
                  setQA={setQA}
                  workshop={workshop}
                  setWorkshop={setWorkshop}
                  breakdowns={breakdowns}
                  setBreakdowns={breakdownAppend}
                  setLockedRows={setLockedRows}
                  lockedRows={lockedRows}
                  current={current}
                  setCurrent={setCurrent}
                  removeBreakdowns={removeDuplicates}
                />
              </div>
              <div style={{ display: "flex", alignItems: "flex-start", width: "50%" }}>
                <input
                  type='text'
                  placeholder='Search...'
                  value={searchTerm}
                  onChange={handleSearch}
                  className='w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500'
                />
              </div>
            </div>
            <br />
            <div style={{ padding: "0 30px" }}>
              <DataTable
                columns={columns}
                data={filteredBreakdowns}
                pagination={false}
                striped={true}
                highlightOnHover={true}
                onRowClicked={handleRowClicked}
                defaultSortFieldId={defaultSortFieldId}
                defaultSortAsc={true}
                sortable={false}
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                fixedHeader={true}
                fixedHeaderScrollHeight={displayHeight + "px"}
              />
            </div>
          </>
        ) : (
          <h3>Unauthorized Access</h3>
        )}
      </DataCountProvider>
    </div>
  );
};

export default observer(PortalBreakdownsDetails);
