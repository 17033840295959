import ImgCrop from 'antd-img-crop';
import React, { useState, useEffect } from 'react';
import { Upload, Modal } from 'antd';

const UploadFile = ({ updateFileList }) => { 
    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);

    useEffect(() => {
        console.log("Updated fileList:", fileList);
    }, [fileList]);

    function fileToBase64(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const base64String = event.target.result.split(',')[1];
                resolve(base64String);
            };
            reader.readAsDataURL(file);
        });
    }

    const onChange = async ({ fileList: newFileList }) => {
        setFileList(newFileList);
    
        const filesData = [];
        await Promise.all(
            newFileList.map(async (file) => {
                if (file.originFileObj) {
                    const base64String = await fileToBase64(file.originFileObj);
                    filesData.push({
                        imagery: base64String,
                    });
                }
            })
        );
    
        updateFileList(filesData);
    };
    
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        setPreviewImage(src);
        setPreviewVisible(true);
    };

    const handleModalClose = () => {
        setPreviewVisible(false);
    };

    return (
        <div>
            <Upload
                beforeUpload={() => false}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
            >
                {fileList.length < 5 && '+ Upload'}
            </Upload>
            <Modal
                open={previewVisible}
                onCancel={handleModalClose}
                footer={null}
                width="80%"
                bodyStyle={{ textAlign: 'center' }}
            >
                <img alt="Preview" style={{ maxWidth: '100%', maxHeight: '80vh' }} src={previewImage} />
            </Modal>
        </div>
    );
};

export default UploadFile;


