const hereMapsAPIKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";

export const lookupAddress = async (address) => {
    if (address.length % 3 === 0) {
      const encodedAddress = encodeURIComponent(address);
      const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodedAddress}&limit=10&apiKey=${hereMapsAPIKey}&in=countryCode:ZAF,ZWE,ZMB,BWA,NAM&type=request`;

      const response = await fetch(url);
      const json = await response.json();

      return json.items;
    }
  };

  export const addressSearch = async (address) => {
    const apiKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
    const encodedAddress = encodeURIComponent(address);
    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodedAddress}&apiKey=${apiKey}`;
    let result = {}
    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
        
          if (data.items.length === 1) {
            
            result = {
                data: data,
                latitude: data.items[0].position.lat,
                longitude:data.items[0].position.lng,
                title: data.items[0].title,
            }
                 
          } else {
            alert("No results found. Please try a different search.");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

      return result; 
  };

 export const reverseGeoSearch = async (lat, lng) => {
    const apiKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
    const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat}%2C${lng}&lang=en-US&apiKey=${apiKey}`;
    let address = '';
   await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // setReverseSearchResult(data);
        if (data.items.length > 0) {
            address = data.items[0].title;
        }
      })
      .catch((error) => console.error(error));

      return address;
  };