import React from "react";
import { ListGroup } from "react-bootstrap";
import { CiEdit, CiTrash } from "react-icons/ci";
import { Button } from "antd";

import agent from "../../Utilities/API/agent";

function BreakdownNoteList({ breakdownNoteDetails, openEditNoteModal, onClose }) {
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  
  const isSupervisor = currentUser.authority == "SUPERVISOR" ? true : false;
  const formatDate = (inDate, utcHour = false) => {
    const outDate = new Date(inDate);
    if (utcHour) {
      return (
        outDate.getFullYear() +
        "-" +
        String(outDate.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(outDate.getDate()).padStart(2, "0") +
        " " +
        String(outDate.getUTCHours()).padStart(2, "0") +
        ":" +
        String(outDate.getMinutes()).padStart(2, "0")
      );
    } else {
      return (
        outDate.getFullYear() +
        "-" +
        String(outDate.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(outDate.getDate()).padStart(2, "0") +
        " " +
        String(outDate.getHours()).padStart(2, "0") +
        ":" +
        String(outDate.getMinutes()).padStart(2, "0")
      );
    }
  };

  const deleteNote = async (noteId) => {
    agent.BreakdownRequest.deleteNote(noteId).then(() => {
      onClose();
    });
  };

  return (
    <div>
      {breakdownNoteDetails && (
        <ListGroup className='scrollable-list' style={{ height: "80vh", overflowY: "auto" }} id='NoteListID'>
          {breakdownNoteDetails.map((note, index) => (
            <ListGroup.Item key={index}>
              <span>{note.text}</span>
              <br />
              <span>
                Created by : <span style={{ fontWeight: "bold" }}>{note.userName}</span>
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                Date Created : <span style={{ fontWeight: "bold" }}>{formatDate(note.createdTime)}</span>
              </span>
              {note.isFollow === 1 && (
                <div style={{ marginLeft: "auto" }}>
                  <span>Follow Up:</span>{" "}
                  <span style={{ fontWeight: "bold", color: "red" }}>
                    {note.followUp.toString().substring(0, 10)} {note.followUp.toString().substring(11, 16)}
                  </span>
                </div>
              )}
              <div className='flex justify-end' style={{ marginTop: "-40px" }}>
                <div style={{ marginLeft: "auto" }}>
                  <Button variant='link' style={{ padding: 0, border: "none", outline: "none" }}>
                    <CiEdit style={{ fontSize: "1.5em", fontWeight: "bold", color: "black" }} onClick={() => openEditNoteModal(note.text, note.noteId)} />
                  </Button>
                  {isSupervisor && (
                    <Button variant='link' style={{ padding: 0, border: "none", outline: "none" }}>
                      <CiTrash style={{ fontSize: "1.5em", fontWeight: "bold", color: "black" }} onClick={() => deleteNote(note.noteId)} />
                    </Button>
                  )}
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
}

export default BreakdownNoteList;
