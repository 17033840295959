import React, { useState } from 'react';
import { IoMenu, IoClose } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import '../../styles/hamburger.css';
import Logo from '../../assets/rnr_logo.png';
import { FaTruck, FaUsers, FaWarehouse, FaClipboardCheck, FaChartLine } from "react-icons/fa";
import { IoHome, IoInformationCircle, IoHammer, IoCall, IoDocumentText, IoPeople, IoBusiness, IoCarSport } from "react-icons/io5";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const FONT_FAMILY = "font-poppins";
    const FONT_TYPE = "font-bold";

    return (
        <div className="flex justify-between items-center w-full px-4 py-2.5 bg-white shadow-md">
            {/*Logo*/}
            <div className="flex items-center">
                <img src={Logo} alt="RNR Logo" className="h-10" />
                <span className={`ml-2 ${FONT_TYPE} ${FONT_FAMILY}`}>RNR | WHEREVER YOU ARE</span>
            </div>

            {/*Centered Menu Items*/}
            <div className="hidden md:flex space-x-4">
                <div className="menu-item-group">
                    <a href="/" className="menu-item">
                        <FaTruck className="icon" />
                        <span>Requests</span>
                    </a>
                </div>
                <div className="menu-item-group">
                    <a href="/" className="menu-item">
                        <FaUsers className="icon" />
                        <span>Users</span>
                    </a>
                </div>
                <div className="menu-item-group">
                    <a href="/" className="menu-item">
                        <FaWarehouse className="icon" />
                        <span>Supplier</span>
                    </a>
                </div>
                <div className="menu-item-group">
                    <a href="/" className="menu-item">
                        <FaTruck className="icon" />
                        <span>Fleet</span>
                    </a>
                </div>
                <div className="menu-item-group">
                    <a href="/" className="menu-item">
                        <FaClipboardCheck className="icon" />
                        <span>Inspect</span>
                    </a>
                </div>
                <div className="menu-item-group">
                    <a href="/" className="menu-item">
                        <FaChartLine className="icon" />
                        <span>Reports</span>
                    </a>
                </div>
            </div>

            {/*Action Button*/}
            <div className="flex items-center ">
                <div className="hidden md:flex items-center mr-5">
                    <button className="bg-gradient-to-r from-red-500 via-red-600 to-pink-700 text-white px-4 py-2 rounded-lg shadow-lg">
                        Log a Breakdown
                    </button>
                </div>

                {/*Hamburger and User Icon*/}
                <div className="flex items-center rounded-full p-1 bg-white rounded-full shadow-md ">
                    <button onClick={() => setIsOpen(true)} className="p-2 focus:outline-none">
                        <IoMenu className="h-6 w-6 text-black" />
                    </button>

                    <span className="bg-gray-500 p-2 rounded-full ml-2">
                        <FaUser className="h-6 w-6 text-white" />
                    </span>
                </div>
            </div>

            {/*Overlay*/}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40"
                    onClick={() => setIsOpen(false)}
                ></div>
            )}

            {/* Sliding Menu */}
            <div className={`fixed top-0 right-0 h-full bg-white w-64 z-50 transform transition-transform duration-300 overflow-y-auto ${isOpen ? "translate-x-0" : "translate-x-full"}`}>
                <button
                    className="text-black p-4 focus:outline-none"
                    onClick={() => setIsOpen(false)}
                >
                    <IoClose className="h-6 w-6" />
                </button>
                <div className="flex flex-col mt-0 divide-y divide-gray-300">
                    <div className="md:hidden bg-gradient-to-r from-red-500 via-red-600 to-pink-700 text-white text-center">
                        <button className="w-full px-4 py-2 rounded-t-lg">
                            Log a Breakdown
                        </button>
                    </div>
                    <a href="/" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                        <IoHome className="icon mr-2" /> Home
                    </a>
                    <a href="/about" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                        <IoInformationCircle className="icon mr-2" /> About
                    </a>
                    <a href="/services" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                        <IoHammer className="icon mr-2" /> Services
                    </a>
                    <a href="/contact" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                        <IoCall className="icon mr-2" /> Contact
                    </a>

                    {/* Minimised Menu on smaller screens */}
                    <div className="md:hidden flex flex-col divide-y divide-gray-300">
                        <a href="/" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                            <IoDocumentText className="icon mr-2" /> Requests
                        </a>
                        <a href="/users" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                            <IoPeople className="icon mr-2" /> Users
                        </a>
                        <a href="/supplier" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                            <IoBusiness className="icon mr-2" /> Supplier
                        </a>
                        <a href="/" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                            <IoCarSport className="icon mr-2" /> Fleet
                        </a>
                        <a href="/users" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                            <FaClipboardCheck className="icon mr-2" /> Inspect
                        </a>
                        <a href="/supplier" className="flex items-center px-4 py-2 text-black hover:bg-gray-200">
                            <FaChartLine className="icon mr-2" /> Reports
                        </a>
                    </div>
                </div>
            </div>
           

        </div>
        
    );
};

export default Navbar;











