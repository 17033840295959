import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Modal, Select, Col, Row } from "antd";
import agent from "../../../Utilities/API/agent";

const { Option } = Select;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
};
const EditCustomer = ({ visible, onClose, customerId, isOwner }) => {
  const [form] = Form.useForm();
  const [companies, setCompanyList] = useState([]);
  const [customerData, setCustomerData] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
        let updatedValues = {};
        if (isOwner) {
        const { companyName, ...restValues } = values;
        const selectedCompany = companies.find(
          (company) => company.name === companyName
        );
        const company = selectedCompany ? selectedCompany.name : customerData.companyName;
        const companyId = selectedCompany ? selectedCompany.id : customerData.companyId;

         updatedValues = {
          ...restValues,
          companyName: company,
          companyId: companyId,
        };
      }
      else {
        const { ...restValues} = values;
        updatedValues = {
          ...restValues}
      }
        // Update customerData with new values
        const updatedCustomerData = { ...customerData, ...updatedValues };
        setCustomerData(updatedCustomerData);

        if(isOwner){
            agent.Company.updateOwner(updatedCustomerData).then(() => onClose());
        }
        else {
          agent.Company.updateDriver(updatedCustomerData).then(() => onClose());
        }
        // Close the modal
        onClose();
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
    
      const companyData = await agent.Company.getallCompanies();
      if (isOwner) {
        const data = await agent.Company.getOwnerById(customerId);
        setCustomerData(data);
      } else {
        const data = await agent.Company.getDriverById(customerId);
        setCustomerData(data);
      }

      setCompanyList(companyData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (visible && customerData) {
      form.setFieldsValue(customerData); // Set form fields with customer data when modal is opened
    }
  }, [visible, customerData, form]);


  return (
    <>
      {customerData && (
        <Modal
         
          open={visible}
          width={'70%'}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="save" type="primary" onClick={handleSubmit}>
              SAVE
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row style={sectionHeader}>
              <Col span="24">EDIT CUSTOMER</Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Row gutter={16}>
                 {isOwner &&( <Col span={24} style={dataInputColumn}>
                    <b>Company Name</b>
                    <Form.Item
                      name="companyName"
                      rules={[{ required: true, message: "Please select company name" }]}
                    >
                      <Select defaultValue={customerData.companyId}>
                        {companies.map((company) => (
                          <Option key={company.id} value={company.id}>
                            {company.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>)}
                  <Col span={24} style={dataInputColumn}>
                    <b>Full Name</b>
                    <Form.Item
                      name="fullName"
                      rules={[{ required: true, message: "Please enter full name" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={dataInputColumn}>
                    <b>Mobile Number</b>
                    <Form.Item
                      name="mobileNumber"
                      rules={[{ required: true, message: "Please enter mobile number" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={dataInputColumn}>
                    <b>Password</b>
                    <Form.Item name="password">
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={16}>
                  <Col span={24} style={dataInputColumn}>
                    <b>Email</b>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, type: "email", message: "Please enter a valid email" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={dataInputColumn}>
                    <b>Username</b>
                    <Form.Item name="username">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={dataInputColumn}>
                    <b>ID Number</b>
                    <Form.Item name="idNumber">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Form.Item hidden={true} name="id">
                      <Input />
                    </Form.Item>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditCustomer;
