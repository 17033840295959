import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Modal, Select, Col, Row } from "antd";
import agent from "../../../../Utilities/API/agent";

const { Option } = Select;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
};

const EditSubCategory = ({ visible, onClose, subCategoryId, breakdownCategoryId }) => {
  const [form] = Form.useForm();
  const [causes, setCategoryList] = useState([]);
  const [categoryData, setCategoryData] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [fullNameChecked, setFullNameChecked] = useState(false);

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
       const updatedCategoryData = {
        id: subCategoryId,
        categoryName : values.cause,
        active: fullNameChecked,
        breakdownCategoryId: breakdownCategoryId,
       }
        agent.Settings.updateSubCategory(updatedCategoryData)
        .then(()=>{ 
          setIsSaving(false);  
          onClose();});
        // Close the modal
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
        setIsSaving(false);
      });
  };

  const handleFullNameChange = (e) => {
    setFullNameChecked(e.target.checked);
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (subCategoryId) {
        const data = await agent.Settings.getSubCategoriesByBreakdownCategoryId(subCategoryId);
        setCategoryData(data);
      }
      const categoryData = await agent.Settings.GetAllSubCategories();
      setCategoryList(categoryData);
    };
    fetchData();
  }, [subCategoryId]);

  useEffect(() => {
    if (visible && categoryData) {
      form.setFieldsValue(categoryData); 
    }
  }, [visible, categoryData, form]);

  useEffect(() => {
    if (categoryData) {
      form.setFieldsValue(categoryData);
    }
  }, [categoryData, form]);

  return (
    <>
      {categoryData && (
        <Modal
         
        open={visible}
          width={'30%'}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="save" type="primary" loading={isSaving} onClick={handleSubmit}>
              SAVE
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row style={sectionHeader}>
              <Col span="24">Edit SubCategory</Col>
            </Row>
            <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>SubCategory Name</b>
                <Form.Item
                  name="cause"
                  rules={[
                    { required: true, message: "Please enter Subcategory name" },
                  ]}
                >
                  <Input placeholder="Enter Subcategory name" />
                </Form.Item>
              </Col>
              <Col span={24} style={{ ...dataInputColumn, marginBottom: "8px" }}>
              <span style={{ fontWeight: 'bold' }}>{fullNameChecked ? "Active" : "Inactive"}</span>
              <br />
              <Checkbox onChange={handleFullNameChange} />
            </Col>
            </Row>
          </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditSubCategory;
