import React, { useEffect, useState, useRef } from "react";
import agent from "../../../Utilities/API/agent";
import { Editor } from "@tinymce/tinymce-react";

const CompanyAddCustomRules = ({ companyId, setCustomRules }) => {
  const [companyRulesList, setCompanyRuleList] = useState("");
  const [content, setContent] = useState("");

  //TinyMCE
  const editorRef = useRef(null);
  
  const getCompanyRulesList = async () => {
    const data = await agent.Company.getCustomRulesByCompanyId(companyId);
    
    setCompanyRuleList(data[0]);
  };

  const handleChange = async () => {
       
    const content = editorRef.current.getContent();
    const requestData = {
      companyId: companyId,
      rules: content,
      selectedCompanies: []
    };

    setCustomRules(requestData);
  };

  useEffect(() => {
    
    getCompanyRulesList();
  }, []);

  return (
    <>
         <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={companyRulesList.rules ? companyRulesList.rules : ""}
          apiKey='5uydohwmkgxotp02hejv4gg56879hkgtb455g42l2nhxlr3z'
          init={{
            height: 500,
            menubar: false,
            plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount", "table"],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic forecolor backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | floating | fontsize | table | help | emoticons | hr | subscript superscript",
            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          onChange={handleChange}
        />
        <div dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};

export default CompanyAddCustomRules;

