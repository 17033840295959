import React from 'react';
import { Button } from 'antd';

const ViewDocumentsButton = ({ onClick, disabled }) => {
  return (
    <Button
      type="primary"
      onClick={onClick}
      style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
      className="w-full"
      disabled={disabled}
    >
      <span style={{ color: 'white' }}>View Documents</span>
    </Button>
  );
};

export default ViewDocumentsButton;
