import React, { useState, useEffect } from "react";
import { List, Avatar } from "antd";
import { currentUser, appSettings } from "../../AppSettings";
import agent from "../../Utilities/API/agent";

const PanicNoteList = ({ panicId, notificationType , updatedPanicNotes }) => {
  const [panicNotes, setPanicNotes] = useState([]);

  useEffect(() => {
    const fetchPanicNotes = async () => {
      try {
        
        let data = null ;
        if (notificationType == 1 )
        {
         data = await agent.Notifications.panicNotes(panicId);
        }
        else {
           data = await agent.Notifications.callMeNotes(panicId);
        }
        setPanicNotes(data);
      } catch (error) {
        console.error("Error fetching panic notes:", error);
      }
    };

    fetchPanicNotes();
    setPanicNotes(updatedPanicNotes);
  }, [panicId, updatedPanicNotes]);

  useEffect(() => {
    // Update panic notes when updatedPanicNotes prop changes
    setPanicNotes(updatedPanicNotes);
  }, [updatedPanicNotes]);

  return (
    <List
    itemLayout="horizontal"
    dataSource={panicNotes}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar>{item.userDetails.fullName[0]}</Avatar>}
          title={<span>{item.userDetails.fullName}</span>}
          description={
            <>
              <div>{item.note}</div>
              <div>Created On: {item.createDateTime}</div>
            </>
          }
        />
      </List.Item>
    )}
  />
  );
};

export default PanicNoteList;
