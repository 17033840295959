import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Modal, Select, Col, Row } from "antd";
import agent from "../../../../Utilities/API/agent";

const { Option } = Select;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
};

const EditCause = ({ visible, onClose, breakdownCausesId }) => {
  const [form] = Form.useForm();
  const [causes, setCausesList] = useState([]);
  const [causeData, setCauseData] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [fullNameChecked, setFullNameChecked] = useState(false);

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
       const updatedcauseData = {
        id: breakdownCausesId,
        cause : values.cause,
        active: fullNameChecked
       }

        agent.Settings.updateCauses(updatedcauseData).then(()=>{ setIsSaving(false);  onClose();});
        // Close the modal
       
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleFullNameChange = (e) => {
    setFullNameChecked(e.target.checked);
  };

  const handleCancel = () => {
    onClose();
  };


  useEffect(() => {
    const fetchData = async () => {
      const causeData = await agent.Settings.getCauses();
      const data = await agent.Settings.getSubCategoriesByBreakdownCategoryId(breakdownCausesId);
      setCauseData(data);
      setCausesList(causeData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (visible && causeData) {
      form.setFieldsValue({ cause: causeData.cause }); 
      //setFullNameChecked(causeData.isActive);
    }
  }, [visible, causeData, form]);

  return (
    <>
      {causeData && (
        <Modal
         
          open={visible}
          width={'30%'}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="save" type="primary" loading={isSaving} onClick={handleSubmit}>
              SAVE
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row style={sectionHeader}>
              <Col span="24">Edit Cause</Col>
            </Row>
            <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>Cause Name</b>
                <Form.Item
                  name="cause"
                  rules={[
                    { required: true, message: "Please enter cause name" },
                  ]}
                >
                  <Input placeholder="Enter cause name" />
                </Form.Item>
              </Col>
              <Col span={24} style={{ ...dataInputColumn, marginBottom: "8px" }}>
              <span style={{ fontWeight: 'bold' }}>{fullNameChecked ? "Active" : "Inactive"}</span>
              <br />
              <Checkbox onChange={handleFullNameChange} />
            </Col>
            </Row>
          </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditCause;
