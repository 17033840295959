// import React, { createContext, useState, useEffect } from 'react';
// import { appSettings } from '../AppSettings';

// export const DataCountContext = createContext();

// export const DataCountProvider = ({ children }) => {
//   const [dataCount, setDataCount] = useState(0);

//   useEffect(() => {
//     fetchDataCount();
//   }, []);

//   const fetchDataCount = () => {
//       fetch(appSettings.apiUrl + '/Breakdowns/returnActiveRequests')
//       .then(response => response.json())
//       .then(data => {
//         setDataCount(data.length);
//       })
//       .catch(error => {
//         console.error('Error fetching active breakdowns:', error);
//       }); 
//   };

//   return (
//     <DataCountContext.Provider value={dataCount}>
//       {children}
//     </DataCountContext.Provider>
//   );
// };

import React, { createContext, useState, useEffect } from 'react';
import { appSettings } from '../AppSettings';

export const DataCountContext = createContext();

export const DataCountProvider = ({ children }) => {
  const [dataCount, setDataCount] = useState(0);

  const fetchDataCount = () => {
    console.log('I am loading');
    fetch(appSettings.apiUrl + '/Breakdowns/returnActiveRequests')
      .then(response => response.json())
      .then(data => {
        setDataCount(data.length);
      })
      .catch(error => {
        console.error('Error fetching active breakdowns:', error);
      });
  };

  useEffect(() => {
    //const interval = setInterval(fetchDataCount, 30000); 
    //return () => clearInterval(interval); 
  }, []);

  return (
    <DataCountContext.Provider value={dataCount}>
      {children}
    </DataCountContext.Provider>
  );
};


