const useCaseInsensitiveSort = (rowName) => {
    return (rowA, rowB) => {
        const a = rowA[rowName].toLowerCase();
        const b = rowB[rowName].toLowerCase();

        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };
};

export default useCaseInsensitiveSort;