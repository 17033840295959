import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
  Select,
  Col,
  Row,
  Alert,
} from "antd";

import agent from "../../../../Utilities/API/agent";


const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
}
const AddCauses = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [causes, setCause] = useState();
  const [modalVisible, setModalVisible] = useState(visible);
  const [isSaving, setIsSaving] = useState(false);
  const [fullNameChecked, setFullNameChecked] = useState(false);
  const [active, setActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
        const { cause, ...restValues } = values;
        const selectedCause = causes.find((causeItem) => causeItem.name === cause);
        const causeId = selectedCause ? selectedCause.id : null;
        const requestData = {
          id: "",
          cause: cause,
          active: fullNameChecked,
        };
        agent.Settings.addCauses(requestData)
          .then((result) => {
            setIsSaving(false);
            if (!result) {
              setErrorMessage("Cause name already exists!");
            } else {
              // Close the modal if the cause was added successfully
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error adding causes:", error);
            setIsSaving(false);
          });
      })
      .catch((error) => {
        console.error("Error adding causes:", error);
        setIsSaving(false);
      });
  };

  const handleFullNameChange = (e) => {
    setFullNameChecked(e.target.checked);
  };

  const handleCancel = () => {
    setModalVisible(false);
    onClose();
  };

  useEffect(() => {
    console.log(visible);
    setModalVisible(true);
    const fetchData = async () => {
      const data = await agent.Settings.getCauses();
      setCause(data);
    };
    fetchData();
  }, [visible]);

  return (
    <Modal
      open={modalVisible}
      onCancel={handleCancel}
      width={'30%'}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={isSaving} onClick={handleSubmit}>
          SAVE
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row style={sectionHeader}>
          <Col span="24">Add Cause </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>Cause Name</b>
                <Form.Item
                  name="cause"
                  rules={[
                    { required: true, message: "Please enter cause name" },
                  ]}
                >
                  <Input placeholder="Enter cause name" />
                </Form.Item>
              </Col>
              <Col span={24} style={{ ...dataInputColumn, marginBottom: "8px" }}>
              <span style={{ fontWeight: 'bold' }}>{fullNameChecked ? "Active" : "Inactive"}</span>
              <br />
              <Checkbox onChange={handleFullNameChange} />
            </Col>
            </Row>
          </Col>
            </Row>
            {errorMessage && (
          <Row>
            <Col span={24}>
              <Alert message={errorMessage} type="error" showIcon />
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default AddCauses;
