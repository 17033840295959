import React, { useEffect, useState } from "react";
import { Modal, Input, Select, Button, Form, Row, Col } from "antd";

import agent from "../../../Utilities/API/agent";

const AddVehicle = ({ visible, onClose, selectedCompany, InCompanyName, InCompanyType }) => {
  
  const [form] = Form.useForm();
  const [isTrailer, setIsTrailer] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [companyName, setCompanyName] = useState();
    const [companyType, setCompanyType] = useState();

  const handleTrailerChange = (event) => {
    setIsTrailer(event.target.checked);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async () => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const userId = currentUser.id;

    const values = await form.validateFields();

     if (isTrailer) {
        values.state = true;
    }
    else {
        values.state = false;
    }
    values.selectedCompany = companyId;
    values.id = 'new';
    values.userId = userId;
    values.companyName = companyName;
    values.companyType = companyType;
    await agent.Vehicle.addVehicle(values);
    onClose();
    
};

  useEffect(() => {
    
    setCompanyId(selectedCompany);
    setCompanyName(InCompanyName);
    setCompanyType(InCompanyType)
  }, []);

  return (
    <>
      (
      <Modal
        width={"70%"}
        open={visible}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="save" type="primary" onClick={handleSubmit}>
            SAVE
          </Button>,
        ]}
      >
        {" "}
        <Row style={sectionHeader}>
              <Col span={24}>ADD VEHICLE</Col>
        </Row>
        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Registration Number</b>
              <Form.Item
                name="regNumber"
                rules={[{ required: true, message: "Registration Number" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Make</b>
              <Form.Item
                name="make"
                
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Model</b>
              <Form.Item
                name="model"
                
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Fleet</b>
              <Form.Item
                name="fleet"
                
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Vin Number</b>
              <Form.Item
                name="vin"
         
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Year</b>
              <Form.Item
                name="year"
   
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Lease Type</b>
              <Form.Item
                name="leaseType"
              
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Cost Center Name</b>
              <Form.Item
                name="costCenterName"
                
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Cost Center Number</b>
              <Form.Item
                name="costCenterNumber"
                
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} style={dataInputColumn}>
              <b>Vehicle Class</b>
              <Form.Item
                name="vehicleClass"
             
              >
                <Input />
              </Form.Item>
            </Col>
           
            <Form.Item
                name="id"
                hidden={true}
              >
                <Input />
              </Form.Item>
        
            <Col xs={24} style={dataInputColumn}>
              <label htmlFor="isTrailer" className="inline-flex items-center space-x-2">
                <span>Is Trailer</span>
                <input
                  type="checkbox"
                  id="isTrailer"
                  checked={isTrailer}
                  onChange={handleTrailerChange}
                  className="form-checkbox h-5 w-5 text-green-500"
                />
              </label>
            </Col>
          </Row>
        </Form>
      </Modal>
      ; )
    </>
  );
};

export default AddVehicle;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};
