import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
  Select,
  Col,
  Row,
  Alert,
} from "antd";

import agent from "../../../Utilities/API/agent";


const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
}
const AddSupplier = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [selectedCompany, setCompany] = useState();
  const [companies, setCompanyList] = useState();
  const [modalVisible, setModalVisible] = useState(visible);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
        const { companyName, ...restValues } = values;
        const selectedCompany = companies.find(
          (company) => company.id === companyName
        );
        const company = selectedCompany ? selectedCompany.name : null;
        values.companyName = company;
        values.companyId = selectedCompany.id;
        values.id = "";

        agent.Supplier.addSupplier(values).then(() =>  setIsSaving(false));
         // Close the modal
         onClose();
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
    onClose();
  };

  useEffect(() => {
    console.log(visible);
    setModalVisible(true);
    const fetchData = async () => {
      const data = await agent.Company.getallCompanies();
      setCompanyList(data);
    };
    fetchData();
  }, [visible]);

  return (
    <Modal
      open={modalVisible}
      onCancel={handleCancel}
      width={'70%'}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={isSaving} onClick={handleSubmit}>
          SAVE
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row style={sectionHeader}>
          <Col span="24">ADD SUPPLIER</Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>Company Name</b>
                <Form.Item
                  name="companyName"
                  rules={[
                    { required: true, message: "Please select company name" },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select company"
                  >
                    {companies &&
                      companies.map((company) => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                <b>Full Name</b>
                <Form.Item
                  name="fullName"
                  rules={[
                    { required: true, message: "Please enter full name" },
                  ]}
                >
                  <Input placeholder="Enter full name" />
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                <b>Mobile Number</b>
                <Form.Item
                  name="mobileNumber"
                  rules={[
                    { required: true, message: "Please enter mobile number" },
                  ]}
                >
                  <Input placeholder="Enter mobile number" />
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                <b>Password</b>
                <Form.Item name="password">
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>Email</b>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter a valid email",
                    },
                  ]}
                >
                  <Input placeholder="Enter email" />
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                <b>Username</b>
                <Form.Item name="username">
                  <Input placeholder="Enter username" />
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                <b>ID Number</b>
                <Form.Item name="idNumber">
                  <Input placeholder="Enter ID number" />
                </Form.Item>
              </Col>
              <Col span={24} style={dataInputColumn}>
                {/* <b>Register Secura</b> */}
                <Form.Item
                  hidden={true}
                  name="registerSecura"
                  valuePropName="checked"
                >
                  <Checkbox checked={false} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddSupplier;
