import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row, Input } from "antd";
import FloatingLabel from "../../components/FloatingLabel";
import agent from "../../Utilities/API/agent";

const EditBasicMechanicDetails = ({ visible, onClose, mechanicId }) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [mechanicData, setMechanicData] = useState();

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
        // Handle submission logic
        agent.Mechanic.editMechanicDetail(values);
        onClose();
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      // Fetch mechanic data using mechanicId
      
      const data = await agent.Mechanic.getById(mechanicId);
      setMechanicData(data);
    };
    fetchData();
  }, [mechanicId]);

  useEffect(() => {
    if (visible && mechanicData) {
      form.setFieldsValue(mechanicData); // Set form fields with mechanic data when modal is opened
    }
  }, [visible, mechanicData, form]);

  return (
    <>
      {mechanicData && (
        <Modal
          visible={visible}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="save" type="primary" onClick={handleSubmit}>
              SAVE
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row style={sectionHeader}>
              <Col span={24}>EDIT MECHANIC</Col>
            </Row>
            
            <Col span={24} style={dataInputColumn}>
              <b>Full Name</b>

              <Form.Item
                name="fullName"
                rules={[{ required: true, message: "Please enter full name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Email Address</b>
              <Form.Item
                name="email"
                            >
                <Input />
              </Form.Item>
            </Col>
            
            <Col span={24} style={dataInputColumn}>
              <b>ID Number</b>

              <Form.Item
                name="idNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter identification number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Mobile Number</b>

              <Form.Item
                name="mobileNumber"
                rules={[
                  { required: true, message: "Please enter mobile number" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Username</b>

              <Form.Item
                name="username"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Password</b>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter password" }]}
              >
                <Input type="password"/>
              </Form.Item>
              <Form.Item
                name="id"
                hidden={true}
              >
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditBasicMechanicDetails;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};
