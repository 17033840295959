export const appSettings = {
//apiUrl: 'https://localhost:7091/api'
      
     //apiUrl: 'https://10.0.0.118:7091/api'
     //apiUrl: 'https://localhost:7091/api'
     //apiUrl: 'https://uat-m-api-v2.rightnowresponse.com/api'

 //  apiUrl: 'https://api-v2.rightnowresponse.com/api'

     //apiUrl : 'https://localhost:44333/api';

      apiUrl : 'https://customer-poc-api-v2.rightnowresponse.com/api'
}

export const  signalRHub = 'https://customer-poc-api-v2.rightnowresponse.com/panic'

export const currentUser = await JSON.parse(sessionStorage.getItem('currentUser'))
