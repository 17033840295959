import React from 'react';
import SyncLoader from "react-spinners/SyncLoader";

function AddNoteButton({ isLoading, onClick }) {
  const buttonStyles = {
    marginTop: '2%',
    marginLeft: '0%',
    backgroundColor: '#4CAF50',
    color: 'white',
    borderRadius: '5px',
    padding: '10px 20px',
    border: 'none',
    cursor: isLoading ? 'progress' : 'pointer',
    fontFamily: 'Arial',
    fontSize: '14px',
    width: '110px',
    height: '40px'
  };

  return (
    <button onClick={onClick} style={buttonStyles} disabled={isLoading}>
      {isLoading ? (
        <SyncLoader
          color="#FFFFFF"
          loading={true}
          size={5}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        'Add Note'
      )}
    </button>
  );
}

export default AddNoteButton;
