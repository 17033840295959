import { appSettings } from '../../../AppSettings';

async function validateExcelData(token, requestBody, setCombinedVehicleList, setNewVehicle, setOldVehicle, setModifiedVehicle, setExcelDataLoaded) {
  try {
    const response = await fetch(appSettings.apiUrl + '/VehicleList/ValidateExcelData', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const data = await response.json();

      setCombinedVehicleList(data);

      if (data) {
        setNewVehicle(data.filter(vehicle => vehicle.flag === 'newVehicle'));
        setOldVehicle(data.filter(vehicle => vehicle.flag === 'oldVehicle'));
        setModifiedVehicle(data.filter(vehicle => vehicle.flag === 'modifiedVehicle'));
      }

      setExcelDataLoaded(false);
    } else if (response.status === 401) 
    {
        throw new Error("Unauthorized Access");
    } 
    else if (response.status === 500) 
    {
        throw new Error("Something went wrong please try again");
    } 
    else 
    {
      throw new Error("HTTP Error " + response.status);
    }
  } catch (error) {
    console.error(error);
  }
}

export default validateExcelData;
