import React from 'react';
import { Modal, Select, Button } from 'antd';

const AssignMechanicModal = ({
  mechanicModalVisible,
  handleMechanicCloseModal,
  filteredCompanies,
  handleCompanyChange,
  selectedCompany,
  allMechanics,
  filteredMechanics,
  handleMechanicChange,
  selectedMechanic,
  handleAsignMechanic,
}) => {
  return (
    <Modal
      title={<h2 className="text-center">Assign Supplier And Mechanic</h2>}
      open={mechanicModalVisible}
      onCancel={handleMechanicCloseModal}
      footer={null}
      centered
      width="70vw"
      className="pt-0 pb-0"
      height="80vh"
    >
      <div style={{ display: 'flex' }}>
        {filteredCompanies && (
          <Select
            placeholder="Select Supplier"
            onChange={handleCompanyChange}
            value={selectedCompany || undefined}
            style={{ flex: 1, marginRight: '16px' }}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {filteredCompanies.map(companyId => {
              const mechanic = allMechanics.find(mechanic => mechanic.companyId === companyId);
              return (
                <Select.Option key={companyId} value={companyId}>
                  {mechanic ? mechanic.companyName : ''}
                </Select.Option>
              );
            })}
          </Select>
        )}

        {filteredMechanics && (
          <Select
            placeholder="Select Mechanic"
            onChange={handleMechanicChange}
            value={selectedMechanic || undefined}
            style={{ flex: 1 }}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {filteredMechanics.map(mechanic => (
              <Select.Option key={mechanic.id} value={mechanic.id}>
                {mechanic.mechanicName}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>

      <br />
      <Button
        type="primary"
        onClick={handleAsignMechanic}
        style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
      >
        <span style={{ color: 'white' }}>Assign</span>
      </Button>
    </Modal>
  );
};

export default AssignMechanicModal;
