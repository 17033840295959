import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { currentUser, appSettings } from "../../../AppSettings";
import "../../styles/breakdownstyles.css";
import ClipLoader from "react-spinners/ClipLoader";
import { DataCountProvider } from "../../../components/DataCountContext";
import Display from "../../../Utilities/GoogleMaps/Display";
import { DatePicker, Select, Input } from "antd";
import moment from "moment";
import { Button, Modal, Form, Upload } from "antd";
import SubmenuFilter from "../../../components/SubmenuFilter";
import DataTable from "react-data-table-component";
import Resizer from "react-image-file-resizer";
import AssignMechanicButton from "../../../components/mechanic/AssignMechanicButton";
import AssignMechanicModal from "../../../components/mechanic/AssignMechanicModal";
import MechanicDetails from "../../../components/mechanic/MechanicDetails";
import MechanicList from "../../../components/mechanic/MechanicDetailsList";
import BreakdownHistoryTab from "../../../components/mechanic/BreakdownHistoryTab";
import BreakdownNotesHeader from "../../../components/breakdowns/BreakdownNotesHeader";
import NoteTextarea from "../../../components/breakdowns/NoteTextarea";
import FollowUpCheckbox from "../../../components/breakdowns/FollowUpCheckbox";
import AddNoteButton from "../../../components/breakdowns/AddNoteButton";
import BreakdownNoteList from "../../../components/breakdowns/BreakdownNoteList";
import BreakdownNotesButtonContainer from "../../../components/breakdowns/BreakdownNotesButtonContainer";
import BreakdownNotesContainer from "../../../components/breakdowns/BreakdownNotesContainer";
import EditNoteModal from "../../../components/breakdowns/EditNoteModal";
import ImageViewButton from "../../../components/breakdowns/AddBreakdownImageButton";
import BreakdownImagesModal from "../../../components/breakdowns/BreakdownImagesModal";
import ImagePopupModal from "../../../components/breakdowns/ImagePopupModal";
import ViewDocumentsButton from "../../../components/breakdowns/ViewDocumentsButton";
import BreakdownStatusDropdown from "../../../components/breakdowns/BreakdownStatusDropdown";
import BreakdownDetailsContainer from "../../../components/breakdowns/BreakdownDetailsContainer";
import FloatingLabel from "../../../components/FloatingLabel";
import FetchApi from "../../../components/utilities/fetchApi";
import { ComponentsBreakdownsBreakdownEditForm as EditForm } from "../../../components/breakdowns/BreakdownEditForm";
import agent from "../../../Utilities/API/agent";

export default function BreakdownDetails() {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const location = useLocation();
  const breakdownId = location.state.breakdownId;

  const [breakdownDetails, setBreakdownDetails] = useState(null);
  const [breakdownNoteDetails, setBreakdownNoteDetails] = useState(null);
  const [note, setNote] = useState("");
  const [mechanic, setMechanicDetails] = useState(null);
  const [breakdownStatus, setBreakdownStatus] = useState(null);
  const [LatestLatitude, setLatestLatitude] = useState(null);
  const [LatestLongitude, setLatestLongitude] = useState(null);
  const [regNumber, setRegNumber] = useState(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [breakdownLatitude, setBreakdownLatitude] = useState(null);
  const [breakdownLongitude, setBreakdownLongitude] = useState(null);
  const [isFollowUp, setIsFollowUp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [breakdownHistory, setBreakdownHistory] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [fileModalVisible, setFileModalVisible] = useState(null);
  const [imageModal, setImageModal] = useState(null);
  //const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const [breakdownSubCategoryList, setBreakdownSubCategoryList] = useState(null);
  const [driverName, setDriverName] = useState(null);
  const [driverCell, setDriverCell] = useState(null);
  const [breakdownDescription, setBreakdownDescription] = useState(null);
  const [breakdownCategory, setBreakdownCategory] = useState(null);
  const [breakdownSubCategory, setBreakdownSubCategory] = useState(null);
  const [breakdownAddress, setBreakdownAddress] = useState(null);
  const [caseNumber, setCaseNumber] = useState(null);
  const [vehicleMake, setVehicleMake] = useState(null);
  const [vehicleModel, setVehicleModel] = useState(null);
  const [vehicleLoad, setVehicleLoad] = useState(null);
  const [vinNumber, setVinNumber] = useState(null);
  const [warranty, setWarranty] = useState(null);
  const [year, setYear] = useState(null);
  const [trailFleetNumber, setTrailerFleetNumber] = useState(null);
  const [trailerMake, setTrailerMake] = useState(null);
  const [trailerModel, setTrailerModel] = useState(null);
  const [trailerRegistration, setTrailerRegistration] = useState(null);
  const [trailerVehicle, setTrailerVehicle] = useState(null);
  const [trailerVin, setTrailerVin] = useState(null);
  const [trailerYear, setTrailerYear] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyContactNumber, setCompanyContactNumber] = useState(null);
  const [customerQuoteNumber, setCustomerQuoteNumber] = useState(null);
  const [dealerQuoteNumber, setDealerQuoteNumber] = useState(null);
  const [supplierQuoteNumber, setSupplierQuoteNumber] = useState(null);
  const [breakdownCategoryName, setBreakdownCategoryName] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [selectedCategoryKey, setSelectedCategoryKey] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [mechanicStatedUpdated, setMechanicStatusUpdated] = useState(null);
  const [allMechanics, setAllMechanics] = useState(null);
  const [mechanicModalVisible, SetMechanicModalVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedMechanic, setSelectedMechanic] = useState("");
  const [supplierModelId, setSupplierModelId] = useState("");
  const [mechanicName, setMechanicName] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [mechanicStatusValue, setMechanicStatusValue] = useState(null);
  const [updatedMechanic, setUpdatedMechanic] = useState(null);
  const [companiesWithNoMechanics, SetcompaniesWithNoMechanics] = useState(null);
  const [deleteCompanyFlag, SetDeleteCompanyFlag] = useState(null);
  const [isFollowUpClosed, setFollowUpIsClosed] = useState(false);
  const [isCompanyRefresh, setCompanyRefreshed] = useState(false);
  const [invoiceQuoteFiles, setInvoiceQuoteFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedImageFiles, setUploadedImageFiles] = useState([]);
  const [breakdownDocsAdded, setBreakdownDocsAdded] = useState(false);
  const [breakdownImagesAdded, setBreakdownImagesAdded] = useState(false);
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const [isDocumentDeleted, setDocumentDeleted] = useState(false);
  const [base64Document, setBase64Document] = useState("");
  const [breakdownImage, setBreakdownImage] = useState([]);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [Imageloading, setImageLoading] = useState(false);
  const [showEditNoteModal, setEditNoteShowModal] = useState(false);
  const [currentNoteBeingEdited, setCurrentNoteBeingEdited] = useState("");
  const [currentNoteBeingEditedId, setCurrentNoteBeingAddedId] = useState("");
  const [breakdownImageList, setBreakdownImageList] = useState([]);
  const [selectedZoomedImage, setSelectedZoomedImage] = useState(null);
  const [isNoteEdited, setNoteEdited] = useState(false);
  const [originalNoteEdited, setOriginalNoteEdited] = useState("");
  const [isImagesLoaded, setImagesLoaded] = useState(true);
  const [vehicleId, setVehicleId] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentId, setEquipmentID] = useState("");
  const [equipmentSubType, setEquipmentSubType] = useState("");
  const [equipmentType, setEquipmentType] = useState("");
  const [equipmentMake, setEquipmentMake] = useState("");
  const [equipmentModel, setEquipmentModel] = useState("");
  const [equipmentYear, setEquipmentYear] = useState("");
  const [IsEquipmentLoading, setEquipmentLoading] = useState(false);
  const [isEquipmentModalVisible, setEquipmentModalVisible] = useState(false);
  const [companyAddedFlag, setCompanyAddedFlag] = useState(false);
  const [rules, setRules] = useState([]);
  const [mechanicRules, setMechanicRules] = useState([]);
  const [fullResolve, setFullResolve] = useState(false);
  //const token = currentUser.jwtToken;
  const [resolutionButtonText, setResolutionButtonText] = useState("Full Resolution");
  const [resolutionButtonState, setResolutionButtonState] = useState(false);
  const [cause, setCause] = useState();
  const [correction, setCorrection] = useState();
  const [breakdownStatuses, setBreakdownStatuses] = useState();
  const [breakdownStatusSelectOptions, setBreakdownStatusSelectOptions] = useState();
  const [cancelledOptions, setCancelledOptions] = useState();
  const [cancelOther, setCancelOther] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [isCancelOther, setIsCancelOther] = useState(false);
  const [cancellationReason, setCancellationReason] = useState(false);
  const [breakdownImageCount, setBreakdownImageCount] = useState(null);
  const [breakdownRules, setBreakdownRules] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const handleDateTimeChange = (date, dateString) => {
    const formattedDateTime = moment(dateString).format("YYYY-MM-DD HH:mm:ss");
    setSelectedDateTime(formattedDateTime);
  };
  const { TextArea } = Input;
  const [activeTab, setActiveTab] = useState("3");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleAddressUpdate = () => {
    setIsUpdated(true);
    setRefreshData(true);
  };

  function getBreakdownNotes() {
    return fetch(`${appSettings.apiUrl}/Breakdowns/getBreakdownNotes/${breakdownId}`)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        return data;
      })
      .catch((error) => console.error(error));
  }

  async function getBreakdownRules(breakdownId) {
    //return
    const rules = await agent.Company.getRulesByBreakdownId(breakdownId);
    setRules(rules);
  }
  const cancellationReasons = async () => {
    const result = await agent.BreakdownRequest.cancellationReasons();
    setCancelledOptions(result);
  };

  function getBreakdownImages() {
    if (breakdownId) {
      return fetch(`${appSettings.apiUrl}/Breakdowns/RetrieveBreakdownImageList`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(breakdownId),
      })
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            const resizedImageUris = [];
            data.forEach((item) => {
              try {
                const byteCharacters = atob(item.image);
                const byteArrays = [];
                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                  const slice = byteCharacters.slice(offset, offset + 512);
                  const byteNumbers = new Array(slice.length);
                  for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, { type: "image/jpeg" });

                Resizer.imageFileResizer(
                  blob,
                  "100%",
                  "100%",
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                    resizedImageUris.push(uri);
                    if (resizedImageUris.length === data.length) {
                      setBreakdownImageList(resizedImageUris);
                    }
                  },
                  "base64"
                );
              } catch (error) {
                console.error("Error:", error);
              }
            });
            console.log("I am here");
            setImageLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setImageLoading(false);
        });
    }
  }

  const openFullScreen = (imageData) => {
    setSelectedZoomedImage(imageData);
  };

  const closeFullScreen = () => {
    setSelectedZoomedImage(null);
  };

  useEffect(() => {
    cancellationReasons();

    fetch(`${appSettings.apiUrl}/Breakdowns/getbreakdownbyid/${breakdownId}`)
      .then((response) => response.json())
      .then((data) => {
        setBreakdownDetails(data);
        setBreakdownStatus(data.breakdownStatus);

        if (data.cancellationReasonId && data.cancellationReasonId > 0) {
          setIsCancel(true);
          setCancellationReason(data.cancellationReasonId);
        }
        getBreakdownRules(data.id);

        //if (data.length > 0) {
        setBreakdownLatitude(data.latitude);
        setBreakdownLongitude(data.longitude);
        setRegNumber(data.registrationNumber);
        setIsFollowUp(data.followUp);
        setVehicleId(data.vehicleId);

        getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));

        /*if (data.vehicleId != null) {
                        fetch(`${appSettings.apiUrl}/Breakdowns/GetEquipment/${data.vehicleId}`, {
                            method: 'POST'
                        })
                            .then(response => response.json())
                            .then(data => {
                                setEquipmentData(data);
                                setEquipmentID(data.id);
                                setEquipmentSubType(data.equipmentSubType);
                                setEquipmentType(data.equipmentType);
                                setEquipmentMake(data.make);
                                setEquipmentModel(data.model);
                                setEquipmentYear(data.year);
                            })
                            .catch(error => console.error(error));
                    }*/

        //}
      })
      .catch((error) => console.error(error));
  }, [breakdownId, isUpdated, isEquipmentModalVisible]);

  useEffect(() => {
    if (regNumber) {
      let trailerReg = breakdownDetails.trailerRegistration == "" ? null : breakdownDetails.trailerRegistration;

      fetch(`${appSettings.apiUrl}/Breakdowns/getBreakdownHistory/${breakdownId}/${regNumber}/${trailerReg}`)
        .then((response) => response.json())
        .then((data) => {
          setBreakdownHistory(data);
        })
        .catch((error) => console.error(error));
    }
  }, [regNumber]);

  /*useEffect(() => {
    getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));
  }, [mechanicStatedUpdated]);
  */

  // useEffect(() => {
  //     getBreakdownImages();
  // }, [breakdownImagesAdded]);

  useEffect(() => {
    fetch(appSettings.apiUrl + "/Breakdowns/getAllMechanicsAndSuppliers")
      .then((response) => response.json())
      .then((data) => setAllMechanics(data));

    //Get the BreakdownStatusMaster;
    const getBreakdownStatusMaster = async () => {
      const response = await fetch(appSettings.apiUrl + "/BreakdownStatus/getbreakdownstatuses");
      const json = await response.json();

      let a = json.statuses.map((status) => ({
        value: status.value,
        label: status.name,
      }));

      setBreakdownStatusSelectOptions(a);
    };

    getBreakdownStatusMaster();
  }, []);

  const filteredMechanics =
    allMechanics && allMechanics.length > 0
      ? allMechanics.filter((mechanic) => (!selectedCompany || mechanic.companyId === selectedCompany) && (!selectedMechanic || mechanic.id === selectedMechanic))
      : [];

  const filteredCompanies =
    allMechanics && allMechanics.length > 0 ? Array.from(new Set(allMechanics.filter((mechanic) => !selectedMechanic || mechanic.id === selectedMechanic).map((mechanic) => mechanic.companyId))) : [];

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    setSelectedMechanic("");
  };

  const handleMechanicChange = (value) => {
    setSelectedMechanic(value);
    const mechanic = allMechanics.find((mechanic) => mechanic.id === value);
    if (mechanic) {
      setSelectedCompany(mechanic.companyId);
    }
  };

  const handleAsignMechanic = (value) => {
    const selectedMechanicObj = allMechanics.find((mechanic) => mechanic.id === selectedMechanic);

    const selectedCompanyObj = allMechanics.find((company) => company.companyId === selectedCompany);

    if (selectedCompanyObj && selectedMechanicObj) {
      const supplierModelId = selectedMechanicObj.supplierModelId;
      const mechanicName = selectedMechanicObj.mechanicName;
      const companyName = selectedMechanicObj.companyName;
      setSupplierModelId(supplierModelId);
      setMechanicName(mechanicName);
      setSupplierName(companyName);

      fetch(
        `${appSettings.apiUrl}/Breakdowns/AsignMechanicAndSupplier/${breakdownId}?mechanicId=${selectedMechanic}&mechanicName=${mechanicName}&supplierId=${supplierModelId}&companyId=${selectedCompany}&companyName=${companyName}`,
        {
          method: "POST",
          headers: {
            Authorization: "bearer: " + currentUser.jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          SetMechanicModalVisible(false);
          getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));
        })
        .catch((error) => console.error(error));
    } else if (selectedCompanyObj && !selectedMechanicObj) {
      setCompanyAddedFlag(true);
      fetch(`${appSettings.apiUrl}/Breakdowns/AsignMechanicSupplier/${breakdownId}?companyId=${selectedCompany}`, {
        method: "POST",
        headers: {
          Authorization: "bearer: " + currentUser.jwtToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          SetMechanicModalVisible(false);
          setCompanyRefreshed(true);
          setCompanyAddedFlag(false);
          getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));
        })
        .catch((error) => console.error(error));
    }
  };

  /*useEffect(() => {
    setIsLoading(true);
    /*getBreakdownNotes()
      .then((data) => setBreakdownNoteDetails(data))
      .finally(() => {
        setIsLoading(false);
        setIsButtonClicked(false);
      });
  }, [isButtonClicked]); */

  /*useEffect(() => {
        if (mechanic && mechanic.length > 0 && mechanic[0].companyName) {
            const companyName = mechanic[0].companyName;
            fetchData('/Breakdowns/GetCompanyRulesLinkedByMechanic', setMechanicRules, companyName);
        }
    }, [mechanic]);*/

  useEffect(() => {
    fetch(`${appSettings.apiUrl}/Breakdowns/getMechanicDetails/${breakdownId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          // Check if data is not null or undefined and has elements

          const sortedMechanics = data.sort((a, b) => a.status - b.status);
          let latestLatitude = null;
          let latestLongitude = null;

          if (sortedMechanics.length > 0) {
            if (sortedMechanics[0].status != 0) {
              latestLatitude = null;
              latestLongitude = null;
            } else {
              const latestMechanic = sortedMechanics[0];
              latestLatitude = latestMechanic.latitude;
              latestLongitude = latestMechanic.longitude;
            }
          }

          setMechanicDetails(data);
          setLatestLatitude(latestLatitude);
          setLatestLongitude(latestLongitude);
          setMechanicStatusUpdated(false);
          getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));
        }
      })
      .catch((error) => console.error(error));
  }, [mechanicModalVisible, mechanicStatedUpdated]);

  useEffect(() => {
    if (breakdownId) {
      fetch(`${appSettings.apiUrl}/Breakdowns/getCompaniesWithNoAsignedMechanics/${breakdownId}`)
        .then((response) => response.json())
        .then((data) => {
          SetcompaniesWithNoMechanics(data);
        })
        .catch((error) => console.error(error));
    }
  }, [mechanicModalVisible, deleteCompanyFlag, isCompanyRefresh, companyAddedFlag]);

  useEffect(() => {
    if (breakdownId) {
      fetch(`${appSettings.apiUrl}/Breakdowns/GetAllInvoiceQuoteFiles/${breakdownId}`)
        .then((response) => response.json())
        .then((data) => {
          setInvoiceQuoteFiles(data);
          setIsDocumentUploaded(false);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsDocumentUploaded(false);
          setBreakdownDocsAdded(false);
          setDocumentDeleted(false);
          setUploadedFiles([]);
        });
    }
  }, [breakdownId, breakdownDocsAdded, isDocumentDeleted]);

  useEffect(() => {
    if (breakdownId) {
      fetch(`${appSettings.apiUrl}/Breakdowns/GetBreakdownImageCount/${breakdownId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setBreakdownImage(data);
          setBreakdownImageCount(data.length);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [breakdownImagesAdded]);

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "#39a644",
    marginTop: "10%",
  };

  if (!breakdownDetails) {
    return <ClipLoader color='	#00FF00' cssOverride={CSSProperties} loading={true} size={150} aria-label='Loading Spinner' data-testid='loader' />;
  }

  const { description, registrationNumber, vehicle, fleetNumber, driverAlias } = breakdownDetails;

  function AddNote() {
    setIsButtonClicked(true);

    const postValues = {
      breakdownId: breakdownId,
      note: note,
      followUpDate: selectedDateTime,
      isFollowUpComplete: isFollowUpClosed,
    };
    fetch(`${appSettings.apiUrl}/Breakdowns/insertbreakdownnotes/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUser.jwtToken}`,
      },
      body: JSON.stringify(postValues),
    })
      .then((response) => response.json())
      .then((data) => {
        setNote("");

        //Refresh the notes list
        //setBreakdownNoteDetails([...breakdownNoteDetails]);

        setIsButtonClicked(false);
        //setBreakdownNoteDetails(getBreakdownNotes());

        getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));

      })
      .catch((error) => console.error(error));
  }

  const statusChanged = (statusEvent) => {
    setBreakdownStatus(statusEvent);

    if (statusEvent == 7) {
      setIsCancel(true);
    } // 7 is cancel
    else {
      setIsCancel(false);
    }
  };
  const setCancel = (cancelReason) => {
    if (cancelReason) {
      setCancellationReason(cancelReason);
      if (cancelReason == 6) {
        setIsCancelOther(true);
      } else {
        setCancelOther(cancelledOptions.find((x) => x.id == cancelReason).description);
      }
    }
  };

  const updateNewStatus = async () => {
    const response = await fetch(`${appSettings.apiUrl}/Breakdowns/UpdateBreakdownStatus/`, {
      method: "POST",
      headers: {
        Authorization: "bearer: " + currentUser.jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        breakdownId: breakdownId,
        status: breakdownStatus,
        cancellationReason: cancelOther,
      }),
    });
    const json = await response.json();

    if (cancellationReason && breakdownStatus == 7) {
      await agent.BreakdownRequest.updateBreakdownCancelled({
        breakdownId: breakdownId,
        cancellationReasonId: cancellationReason,
      });
    }

    getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));

    let fullName = currentUser.fullName;
    agent.BreakdownRequest.getBreakdownById(location.state.breakdownId).then((updatedBreakdownDetails) => {
      setBreakdownDetails(updatedBreakdownDetails);
      const breakdownChangeLog = {
        address: updatedBreakdownDetails.breakdownAddress,
        breakdownId: location.state.breakdownId,
        driver: updatedBreakdownDetails.driverModelName,
        driverNumber: updatedBreakdownDetails.driverCell,
        initiatorName: updatedBreakdownDetails.initiatorName,
        initiatorNumber: updatedBreakdownDetails.initiatorCell,
        controllerName: updatedBreakdownDetails.reportedBy,
        controllerNumber: updatedBreakdownDetails.contactNumber,
        caseNumber: updatedBreakdownDetails.caseNumber,
        category: updatedBreakdownDetails.breakdownCategory,
        subCategory: updatedBreakdownDetails.breakdownSubCategory,
        description: updatedBreakdownDetails.description,
        cause: updatedBreakdownDetails.breakdownCause,
        correction: updatedBreakdownDetails.correction,
        vehicleLoad: updatedBreakdownDetails.vehicleLoad,
        vehicleFleet: updatedBreakdownDetails.fleetNumber,
        vehicleRegistration: updatedBreakdownDetails.registrationNumber,
        vehicleVINNumber: updatedBreakdownDetails.vinNumber,
        vehicleMake: updatedBreakdownDetails.make,
        vehicleModel: updatedBreakdownDetails.model,
        vehicleYear: updatedBreakdownDetails.year,
        vehicleWarranty: updatedBreakdownDetails.warranty,
        trailerFleet: updatedBreakdownDetails.trailerFleetNumber,
        trailerRegistration: updatedBreakdownDetails.trailerReg,
        trailerVINNumber: updatedBreakdownDetails.trailerVin,
        trailerMake: updatedBreakdownDetails.trailerMake,
        trailerModel: updatedBreakdownDetails.trailerModel,
        trailerYear: updatedBreakdownDetails.trailerYear,
        loggedInUserId: fullName,
        breakdownType: updatedBreakdownDetails.breakdownType,
        handoverNotes: updatedBreakdownDetails.handoverNotes,
        dateUpdated: new Date(),
        breakdownStatusId: updatedBreakdownDetails.breakdownStatus,
        breakdownStatus: "",
        imageCount: 0,
      };

      agent.BreakdownRequest.insertBreakdownChangeLog(breakdownChangeLog);
    });
  };

  /*
    const updateBreakdownDetails = async () => {
        const matchingCategory = breakdownSubCategoryList && breakdownSubCategoryList.find(item => item.id === breakdownSubCategory);
        const categoryName = matchingCategory ? matchingCategory.categoryName : '';

        var subCatVal = "";

        if (!categoryName) {
            subCatVal = breakdownSubCategory;
        }
        else {
            subCatVal = categoryName;
        }

        const formValues = {
            breakdownId,
            companyName,
            companyContactNumber,
            driverName,
            driverCell,
            breakdownAddress,
            caseNumber,
            breakdownCategoryName,
            subCatVal,
            breakdownDescription,
            vehicleMake,
            vehicleModel,
            vehicleLoad,
            latitude,
            year,
            regNumber,
            vinNumber,
            longitude,
            warranty,
            trailFleetNumber,
            trailerMake,
            trailerModel,
            trailerRegistration,
            trailerVehicle,
            trailerVin,
            trailerYear,
            customerQuoteNumber,
            dealerQuoteNumber,
            supplierQuoteNumber,
            cause,
            correction
        }

        fetch(`${appSettings.apiUrl}/Breakdowns/updatebreakdowndetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formValues)
        })
            .then(response => response.json())
            .then(data => {
                handleCloseModal();
            })
            .catch(error => console.error(error));
    }

   
    const handleOpenModal = () => {
        setDriverName(breakdownDetails.driverAlias);
        setDriverCell(breakdownDetails.driverCell);
        setBreakdownDescription(breakdownDetails.description);
        setBreakdownCategory(breakdownDetails.breakdownCategory);
        setBreakdownSubCategory(breakdownDetails.breakdownSubCategory);

        setBreakdownCategoryName(breakdownDetails.breakdownCategory);

        setBreakdownAddress(breakdownDetails.breakdownAddress);
        setCaseNumber(breakdownDetails.caseNumber);
        setVehicleMake(breakdownDetails.make);
        setVehicleModel(breakdownDetails.model);
        setVehicleLoad(breakdownDetails.vehicleLoad);
        setVinNumber(breakdownDetails.vinNumber);
        setWarranty(breakdownDetails.warranty);
        setYear(breakdownDetails.year);
        setTrailerFleetNumber(breakdownDetails.trailerFleetNumber);
        setTrailerMake(breakdownDetails.trailerMake);
        setTrailerModel(breakdownDetails.trailerModel);
        setTrailerRegistration(breakdownDetails.trailerRegistration);
        setTrailerVehicle(breakdownDetails.trailerVehicle);
        setTrailerVin(breakdownDetails.trailerVin);
        setTrailerYear(breakdownDetails.trailerYear);
        setLatitude(breakdownDetails.latitude);
        setLongitude(breakdownDetails.longitude);
        setCompanyName(breakdownDetails.companyName);
        setCompanyContactNumber(breakdownDetails.contactNumber);

        setCustomerQuoteNumber(breakdownDetails.customerQuoteNumber);
        setDealerQuoteNumber(breakdownDetails.dealerQuoteNumber);
        setSupplierQuoteNumber(breakdownDetails.quoteNumber);
        setCause(breakdownDetails.breakdownCause);
        setCorrection(breakdownDetails.correction);

        setModalVisible(true);
        setIsUpdated(false);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setIsUpdated(true);
    };

    */

  const handleFileOpenModal = () => {
    setFileModalVisible(true);
  };

  const handleFileCloseModal = () => {
    setFileModalVisible(false);
    setUploadedFiles(null);
  };

  const handleImageOpenModal = () => {
    setImageModal(true);
    getBreakdownImages();
  };

  const handleImageCloseModal = () => {
    setImageModal(false);
  };

  const handleMechanicCloseModal = () => {
    SetMechanicModalVisible(false);
  };

  const handleMechanicOpenModal = () => {
    SetMechanicModalVisible(true);
  };

  const handleEditCloseModal = () => {
    setIsUpdated(true);
  };
  const handleNotesClose = () => {
    setIsButtonClicked(true);
  };

  const handleButtonClick = (event, mechanicItemId, status, fullName, companyName) => {
    var loginId = currentUser.loginId;

    var loginIdIfNull = currentUser.loginId;
    var newvar = breakdownNoteDetails[0]?.userId || loginIdIfNull;

    fetch(
      `${appSettings.apiUrl}/Breakdowns/updateMechanicStatuses/${breakdownId}?mechanicId=${mechanicItemId}&status=${status}&loginId=${loginId}&UserId=${newvar}&userName=${currentUser.fullName}&fullName=${fullName}&companyName=${companyName}`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMechanicStatusUpdated(true);
        getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));
      })
      .catch((error) => console.error(error));
  };

  const handleRemoveButtonClick = (event, rowId, companyName, companyId, breakdownId) => {
    fetch(`${appSettings.apiUrl}/Breakdowns/RemoveCompany/${breakdownId}?rowId=${rowId}`, {
      method: "POST",
      headers: {
        Authorization: "bearer: " + currentUser.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        SetDeleteCompanyFlag(true);
        getBreakdownNotes().then((data) => setBreakdownNoteDetails(data));
      })
      .catch((error) => console.error(error));
  };

  const { Item } = Form;

  const handleMechStatusChange = (event, mechanicItemId) => {
    const newStatus = event.target.value;

    const updatedMechanic = mechanic.map((item) => {
      if (item.id === mechanicItemId) {
        return { ...item, status: newStatus };
      }
      return item;
    });
    setMechanicDetails(updatedMechanic);
    setUpdatedMechanic(updatedMechanic);
  };

  if (breakdownDetails) {
    if (breakdownDetails.breakdownReference !== null) {
      sessionStorage.setItem("reference", breakdownDetails.reference);
    }
    sessionStorage.setItem("description", breakdownDetails.description);
    sessionStorage.setItem("fleetNumber", breakdownDetails.fleetNumber);
    sessionStorage.setItem("registrationNumber", breakdownDetails.registrationNumber);
    sessionStorage.setItem("vehicle", breakdownDetails.vehicle);
    sessionStorage.setItem("driverAlias", breakdownDetails.driverAlias);
  }

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setFollowUpIsClosed(isChecked);
  };

  function uploadBreakdownDocument() {}

  const frontendUpload = (file, fileList) => {
    setUploadedFiles([...uploadedFiles, ...fileList]);
  };

  const frontendImageUpload = (file, fileList) => {
    setUploadedImageFiles([...uploadedImageFiles, ...fileList]);
  };

  const handleDeleteFile = (index) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedFiles(newUploadedFiles);
  };

  const handleImageDeleteFile = (index) => {
    const newUploadedFiles = [...uploadedImageFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedImageFiles(newUploadedFiles);
  };

  function uploadBreakdownImages() {
    const filesData = [];
    Promise.all(
      uploadedImageFiles.map((file) => {
        return new Promise((resolve) => {
          fileToBase64(file, (base64String) => {
            filesData.push({
              BreakdownId: breakdownId,
              Image: base64String,
              LastModifiedOn: null,
              Deleted: null,
              DeletedOn: null,
              CreatedOn: null,
            });
            resolve();
          });
        });
      })
    ).then(() => {
      fetch(`${appSettings.apiUrl}/Breakdowns/AddBreakdownImage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filesData),
      })
        .then((response) => response.json())
        .then((data) => {
          setBreakdownImagesAdded(true);
          setUploadedImageFiles([]);
          setImagesLoaded(false);
        })
        .catch((error) => {
          console.error(error);
          setImagesLoaded(false);
        });
    });
  }

  function uploadInvoiceDocuments() {
    const filesData = [];
    Promise.all(
      uploadedFiles.map((file) => {
        return new Promise((resolve) => {
          fileToBase64(file, (base64String) => {
            filesData.push({
              BreakdownId: breakdownId,
              InvoiceQuoteData: base64String,
              InvoiceQuoteName: file.name,
              InvoiceQuoteType: file.type,
              LastModifiedOn: null,
              Deleted: null,
              DeletedOn: null,
              CreatedOn: null,
            });
            resolve();
          });
        });
      })
    ).then(() => {
      fetch(`${appSettings.apiUrl}/Breakdowns/AddBreakdownQuoteInvoice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filesData),
      })
        .then((response) => response.json())
        .then((data) => {
          setBreakdownDocsAdded(true);
        })
        .catch((error) => console.error(error));
    });
  }

  function fileToBase64(file, callback) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64String = event.target.result.split(",")[1];
      callback(base64String);
    };
    reader.readAsDataURL(file);
  }

  const InvoiceQuoteFileColumns = [
    {
      name: "id",
      selector: "id",
      sortable: true,
      omit: true,
    },
    {
      name: "FileName",
      selector: (row) => row.invoiceQuoteName,
      sortable: true,
    },
    {
      name: "FileType",
      selector: (row) => row.invoiceQuoteType,
      sortable: true,
    },
    {
      name: "Download",
      sortable: false,
      center: true,
      width: "110px",
      cell: (row) => {
        const handleDownloadClick = () => {
          downloadFile(row.invoiceQuoteType, row.invoiceQuoteData, row.invoiceQuoteName, row.id);
        };

        return (
          <div>
            <button onClick={handleDownloadClick}>Download</button>
          </div>
        );
      },
    },
    {
      name: "Delete",
      sortable: false,
      center: true,
      cell: (row) => {
        const handleDeleteClick = () => {
          deleteFile(row.id);
        };

        return (
          <div>
            <button onClick={handleDeleteClick}>Delete</button>
          </div>
        );
      },
    },
  ];

  const downloadFile = (type, data, name, id) => {
    if (id) {
      fetch(`${appSettings.apiUrl}/Breakdowns/RetrieveInvoiceDocument/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.text())
        .then((data) => {
          setBase64Document(true);
          const link = document.createElement("a");
          link.href = `data:${type};base64,${data}`;
          link.download = name;
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const deleteFile = (id) => {
    if (id) {
      fetch(`${appSettings.apiUrl}/Breakdowns/DeleteBreakdownQuoteInvoice/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDocumentDeleted(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const viewImage = async (id) => {
    if (id) {
      setImageLoading(true);
      fetch(`${appSettings.apiUrl}/Breakdowns/RetrieveBreakdownImage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(id),
      })
        .then((response) => response.text())
        .then((data) => {
          try {
            const byteCharacters = atob(data);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: "image/jpeg" });

            Resizer.imageFileResizer(
              blob,
              "100%",
              "100%",
              "JPEG",
              10,
              0,
              (uri) => {
                setSelectedImageUrl(uri);
                setImageModalVisible(true);
                setImageLoading(false);
              },
              "base64"
            );
          } catch (error) {
            console.error("Error:", error);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const openEditNoteModal = (note, id) => {
    setEditNoteShowModal(true);
    setCurrentNoteBeingEdited(note);
    setOriginalNoteEdited(note);
    setCurrentNoteBeingAddedId(id);
  };

  const closeEditNoteModal = () => {
    setEditNoteShowModal(false);
  };

  const editBreakdownNoteDetails = () => {
    setNoteEdited(false);
    if (currentNoteBeingEdited && currentNoteBeingEditedId) {
      fetch(`${appSettings.apiUrl}/Breakdowns/EditBreakdownNote/${currentNoteBeingEditedId}/${currentNoteBeingEdited}/${originalNoteEdited}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setNoteEdited(true);
          setEditNoteShowModal(false);
        })
        .catch((error) => console.error(error));
    }
  };

  function handleEquipmentAddOK() {
    setEquipmentLoading(true);

    const equipmentModelData = {
      equipmentId,
      equipmentSubType,
      equipmentType,
      equipmentMake,
      equipmentModel,
      equipmentYear,
    };

    fetch(appSettings.apiUrl + "/Breakdowns/EditVehicleEquipment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(equipmentModelData),
    })
      .then((response) => response.json())
      .then((data) => {
        setEquipmentModalVisible(false);
        setEquipmentLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setEquipmentLoading(false);
      });
  }

  function handleEquipmentAddCancel() {
    setEquipmentModalVisible(false);
  }

  const fetchData = (url, setter, parameter = null) => {
    const apiUrl = appSettings.apiUrl + url + (parameter ? `/${parameter}` : "");

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setter(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // if (mechanic && mechanic.length > 0) {
  //     if (mechanic[0].companyName) {
  //         const companyName = mechanic[0].companyName;
  //         fetchData('/Breakdowns/GetCompanyRulesLinkedByMechanic', setMechanicRules, companyName);
  //     }
  // }

  const fullResolutionExecute = async () => {
    const response = await fetch(appSettings.apiUrl + "/Breakdowns/FullResolve", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.jwtToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(breakdownId),
    });

    if (response.status === 200) {
      setResolutionButtonState(true);
      setResolutionButtonText("Resolution Complete");
    }
    let fullName = currentUser.fullName;
    agent.BreakdownRequest.getBreakdownById(location.state.breakdownId).then((updatedBreakdownDetails) => {
      setBreakdownDetails(updatedBreakdownDetails);
      const breakdownChangeLog = {
        address: updatedBreakdownDetails.breakdownAddress,
        breakdownId: location.state.breakdownId,
        driver: updatedBreakdownDetails.driverModelName,
        driverNumber: updatedBreakdownDetails.driverCell,
        initiatorName: updatedBreakdownDetails.initiatorName,
        initiatorNumber: updatedBreakdownDetails.initiatorCell,
        controllerName: updatedBreakdownDetails.reportedBy,
        controllerNumber: updatedBreakdownDetails.contactNumber,
        caseNumber: updatedBreakdownDetails.caseNumber,
        category: updatedBreakdownDetails.breakdownCategory,
        subCategory: updatedBreakdownDetails.breakdownSubCategory,
        description: updatedBreakdownDetails.description,
        cause: updatedBreakdownDetails.breakdownCause,
        correction: updatedBreakdownDetails.correction,
        vehicleLoad: updatedBreakdownDetails.vehicleLoad,
        vehicleFleet: updatedBreakdownDetails.fleetNumber,
        vehicleRegistration: updatedBreakdownDetails.registrationNumber,
        vehicleVINNumber: updatedBreakdownDetails.vinNumber,
        vehicleMake: updatedBreakdownDetails.make,
        vehicleModel: updatedBreakdownDetails.model,
        vehicleYear: updatedBreakdownDetails.year,
        vehicleWarranty: updatedBreakdownDetails.warranty,
        trailerFleet: updatedBreakdownDetails.trailerFleetNumber,
        trailerRegistration: updatedBreakdownDetails.trailerReg,
        trailerVINNumber: updatedBreakdownDetails.trailerVin,
        trailerMake: updatedBreakdownDetails.trailerMake,
        trailerModel: updatedBreakdownDetails.trailerModel,
        trailerYear: updatedBreakdownDetails.trailerYear,
        loggedInUserId: fullName,
        breakdownType: updatedBreakdownDetails.breakdownType,
        handoverNotes: updatedBreakdownDetails.handoverNotes,
        dateUpdated: new Date(),
        breakdownStatusId: updatedBreakdownDetails.breakdownStatus,
        breakdownStatus: "",
        imageCount: 0,
      };

      agent.BreakdownRequest.insertBreakdownChangeLog(breakdownChangeLog);
    });
    //alert(breakdownId);
  };

  return (
    <div>
      <DataCountProvider>
        <SubmenuFilter />
      </DataCountProvider>
      <div className='custom-container'>
        <div
          style={{
            flex: "30%",
            padding: "10px",
            justifyContent: "center",
            height: "100%",
          }}
          className='rounded-md'
          id='block1'>
          <MechanicDetails mechanic={mechanic} />
          <br />
          <AssignMechanicButton onClick={handleMechanicOpenModal} />
          <AssignMechanicModal
            mechanicModalVisible={mechanicModalVisible}
            handleMechanicCloseModal={handleMechanicCloseModal}
            filteredCompanies={filteredCompanies}
            handleCompanyChange={handleCompanyChange}
            selectedCompany={selectedCompany}
            allMechanics={allMechanics}
            filteredMechanics={filteredMechanics}
            handleMechanicChange={handleMechanicChange}
            selectedMechanic={selectedMechanic}
            handleAsignMechanic={handleAsignMechanic}
          />
          <br />
          <br />
          <MechanicList
            mechanic={mechanic}
            mechanicStatusValue={mechanicStatusValue}
            handleMechStatusChange={handleMechStatusChange}
            handleButtonClick={handleButtonClick}
            companiesWithNoMechanics={companiesWithNoMechanics}
            handleRemoveButtonClick={handleRemoveButtonClick}
            rules={rules}
            setRules={setRules}
          />
          <br />
          <BreakdownHistoryTab
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            breakdownHistory={breakdownHistory}
            LatestLatitude={LatestLatitude}
            LatestLongitude={LatestLongitude}
            breakdownLatitude={breakdownLatitude}
            breakdownLongitude={breakdownLongitude}
            rules={rules}
            setRules={setRules}
            mechanicRules={mechanicRules}
            breakdownAddress={breakdownDetails.breakdownAddress}
            onAddressUpdate={handleAddressUpdate}
            breakdownId={breakdownId}
          />
        </div>

        <BreakdownNotesContainer>
          <BreakdownNotesHeader breakdownNoteDetails={breakdownNoteDetails} />
          <NoteTextarea value={note} onChange={(e) => setNote(e.target.value)} />
          <BreakdownNotesButtonContainer>
            <DatePicker showTime showSecond={false} style={{ height: "40px", marginTop: "14px" }} onChange={handleDateTimeChange} />
            <FollowUpCheckbox isFollowUpClosed={isFollowUpClosed} handleCheckboxChange={handleCheckboxChange} />
            <AddNoteButton isLoading={isLoading} onClick={AddNote} />
          </BreakdownNotesButtonContainer>
          <BreakdownNoteList breakdownNoteDetails={breakdownNoteDetails} openEditNoteModal={openEditNoteModal} onClose={handleNotesClose} />
        </BreakdownNotesContainer>

        <EditNoteModal
          showEditNoteModal={showEditNoteModal}
          closeEditNoteModal={closeEditNoteModal}
          currentNoteBeingEdited={currentNoteBeingEdited}
          setCurrentNoteBeingEdited={setCurrentNoteBeingEdited}
          currentNoteBeingEditedId={currentNoteBeingEditedId}
          editBreakdownNoteDetails={editBreakdownNoteDetails}
        />

        <div
          style={{
            flex: "30%",
            padding: "10px",
            justifyContent: "center",
            height: "100%",
          }}>
          <EditForm breakdownId={breakdownId} onClose={handleEditCloseModal} refreshData={refreshData} />

          <div style={{ marginBottom: "12px" }}>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>Breakdown Status</div>
            <Select style={{ width: "100%" }} options={breakdownStatusSelectOptions} onChange={statusChanged} value={breakdownStatus} />
            {cancelledOptions && isCancel && (
              <>
                <div style={{ textAlign: "center", fontWeight: "bold" }}>Cancellation Reason</div>
                <Select
                  style={{ width: "100%" }}
                  value={cancellationReason}
                  options={cancelledOptions.map((option) => ({
                    value: option.id,
                    label: option.description,
                  }))}
                  onChange={setCancel}
                />
                {isCancelOther && (
                  <>
                    <br />
                    <br />
                    <TextArea value={cancelOther} onChange={(e) => setCancelOther(e.target.value)} placeholder='Cancellation reason' autoSize={{ minRows: 3, maxRows: 5 }} />
                  </>
                )}
              </>
            )}

            <div>
              <Button
                type='primary'
                style={{ width: "100%", marginTop: "12px" }}
                onClick={() => {
                  updateNewStatus();
                }}>
                Set Breakdown Status
              </Button>
            </div>
          </div>

          <div className='rounded-lg border p-2 mb-4  relative border border-gray-300 text-gray-800 bg-white' style={{ display: "flex", justifyContent: "space-evenly" }}>
            {breakdownDetails && (
              <div className='flex items-center justify-center mt-2 pb-3'>
                <ViewDocumentsButton onClick={handleFileOpenModal} disabled={!invoiceQuoteFiles} />
                <Modal
                  title={<h2 className='text-center'>Breakdown Documents</h2>}
                  open={fileModalVisible}
                  onCancel={handleFileCloseModal}
                  footer={null}
                  centered
                  width='75vw'
                  className='pt-0 pb-0'
                  height='80vh'>
                  <div>
                    {isDocumentDeleted && (
                      <div className='loader-container'>
                        <ClipLoader color='#00FF00' loading={true} size={18} />
                      </div>
                    )}
                    <div className='flex justify-end mb-3'>
                      <Upload beforeUpload={(file, fileList) => frontendUpload(file, fileList)}>
                        <Button
                          type='primary'
                          style={{
                            backgroundColor: "#4CAF50",
                            borderColor: "green",
                          }}
                          onClick={uploadBreakdownDocument}>
                          Add
                        </Button>
                      </Upload>
                    </div>
                    {uploadedFiles && uploadedFiles.length > 0 && (
                      <div className='flex justify-end mb-0'>
                        <ul>
                          {uploadedFiles.map((file, index) => (
                            <li key={index}>
                              {file.name}
                              <Button onClick={() => handleDeleteFile(index)} className='ml-3 mb-2'>
                                Delete
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {uploadedFiles && uploadedFiles.length > 0 && (
                      <div className='flex justify-end mb-3'>
                        <Button
                          type='primary'
                          style={{
                            backgroundColor: "#4CAF50",
                            borderColor: "green",
                          }}
                          onClick={uploadInvoiceDocuments}>
                          {breakdownDocsAdded ? <ClipLoader color='#00FF00' loading={true} size={18} css='display: inline-block; vertical-align: middle; margin-right: 8px;' /> : "Save"}
                        </Button>
                      </div>
                    )}
                    {invoiceQuoteFiles.length === 0 ? (
                      <div>No files to display.</div>
                    ) : (
                      <DataTable
                        title='Invoice Quote Files'
                        columns={InvoiceQuoteFileColumns}
                        data={invoiceQuoteFiles}
                        pagination={true}
                        striped={true}
                        highlightOnHover={true}
                        className='bg-white border border-[#f9787c] rounded-lg shadow-lg'
                        cellClassName='border-b border-[#f9787c] px-4 py-2'
                        headerClassName='bg-[#f9787c] text-white font-semibold'
                      />
                    )}
                  </div>
                </Modal>
              </div>
            )}

            {breakdownDetails && (
              <div className='flex items-center justify-center mt-2 pb-3'>
                <ImageViewButton onClick={handleImageOpenModal} id={breakdownId} />
                <BreakdownImagesModal
                  imageModal={imageModal}
                  handleImageCloseModal={handleImageCloseModal}
                  uploadedImageFiles={uploadedImageFiles}
                  frontendImageUpload={frontendImageUpload}
                  uploadBreakdownDocument={uploadBreakdownDocument}
                  handleImageDeleteFile={handleImageDeleteFile}
                  Imageloading={Imageloading}
                  uploadBreakdownImages={uploadBreakdownImages}
                  breakdownImageList={breakdownImageList}
                  openFullScreen={openFullScreen}
                  selectedZoomedImage={selectedZoomedImage}
                  closeFullScreen={closeFullScreen}
                  isImagesLoaded={isImagesLoaded}
                />
                <ImagePopupModal imageModalVisible={imageModalVisible} setImageModalVisible={setImageModalVisible} setImageLoading={setImageLoading} selectedImageUrl={selectedImageUrl} />
              </div>
            )}

            {/* {breakdownDetails && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    setEquipmentModalVisible(true);
                                }}
                                style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
                                className='mt-2'
                            >
                                <span style={{ color: 'white' }}>Equipment</span>
                            </Button>
                        )} */}

            <Modal
              title={"Edit Equipment"}
              open={isEquipmentModalVisible}
              onOk={handleEquipmentAddOK}
              width={800}
              centered
              onCancel={handleEquipmentAddCancel}
              cancelButtonProps={{
                style: {
                  backgroundColor: "red",
                  border: "none",
                  color: "white",
                },
              }}
              okButtonProps={{ style: { backgroundColor: "green" } }}>
              <div className='flex flex-col'>
                <div className='my-2'></div>
                <FloatingLabel
                  id='equipmentType'
                  name='equipmentType'
                  type='text'
                  value={equipmentType}
                  onChange={(e) => setEquipmentType(e.target.value)}
                  placeholder='Equipment Type'
                  label='Equipment Type'
                  className='relative ml-3'
                />
                <div className='my-2'></div>
                <FloatingLabel
                  id='equipmentSubType'
                  name='equipmentSubType'
                  type='text'
                  value={equipmentSubType}
                  onChange={(e) => setEquipmentSubType(e.target.value)}
                  placeholder='Equipment Sub Type'
                  label='Equipment Sub Type'
                  className='relative ml-3'
                />
                <div className='my-2'></div>
                <FloatingLabel
                  id='equipmentMake'
                  name='equipmentMake'
                  type='text'
                  value={equipmentMake}
                  onChange={(e) => setEquipmentMake(e.target.value)}
                  placeholder='Equipment Make'
                  label='Equipment Make'
                  className='relative ml-3'
                />
                <div className='my-2'></div>
                <FloatingLabel
                  id='equipmentModel'
                  name='equipmentModel'
                  type='text'
                  value={equipmentModel}
                  onChange={(e) => setEquipmentModel(e.target.value)}
                  placeholder='Equipment Model'
                  label='Equipment Model'
                  className='relative ml-3'
                />
                <div className='my-2'></div>
                <FloatingLabel
                  id='equipmentYear'
                  name='equipmentYear'
                  type='text'
                  value={equipmentYear}
                  onChange={(e) => setEquipmentYear(e.target.value)}
                  placeholder='Equipment Year'
                  label='Equipment Year'
                  className='relative ml-3'
                />
                <div className='my-2'></div>
              </div>
            </Modal>
          </div>

          <div className='rounded-lg border p-2 mb-4  relative border border-gray-300 text-gray-800 bg-white' style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              type='primary'
              onClick={() => {
                fullResolutionExecute();
              }}
              style={{
                backgroundColor: "#4CAF50",
                borderColor: "green",
                width: "100%",
              }}
              className='mt-2'
              disabled={resolutionButtonState}>
              <span style={{ color: "white" }}>{resolutionButtonText}</span>
            </Button>
          </div>

          {/* <div className="w-full relative border border-gray-300 text-gray-800 bg-white">
                        {LatestLatitude && LatestLongitude && breakdownLatitude && breakdownLongitude && (
                            <Display
                                latitude={parseFloat(LatestLatitude)}
                                longitude={parseFloat(LatestLongitude)}
                                breakdownLatitude={parseFloat(breakdownLatitude)}
                                breakdownLongitude={parseFloat(breakdownLongitude)}
                            />
                        )}
                    </div> */}
        </div>
      </div>
    </div>
  );
}
