import {useState, useEffect} from 'react';
import {} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Modal, Button, Col, Row} from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { appSettings } from '../../AppSettings';
import agent from "../../Utilities/API/agent";
import {Input, Select, Form, } from "antd";
import PortalSecureCompanyAddRules from '../../Portal/Secure/Rules/AddRules';

export default function CompaniesManageRules(parameters) {

    
    const [valueLeft, setValueLeft] = useState('');
    const [valueRight, setValueRight] = useState('');
    const [rules, setRules] = useState([]);
    const [addCompanyRules, setAddCompanyRules] = useState();
    const [editCompanyVisble, setEditCompanyVisble] = useState();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleCloseCompany = () => {
      setAddCompanyRules(false);
    };
    const handleShow = () => {
      
      setShow(true);
    };

    const [showModal, setShowModal] = useState(false);

   
    

    async function getBreakdownRules(breakdownId){
      //return 
      const rules = await agent.Company.getRulesByBreakdownId(breakdownId);
      setRules(rules);
    }
    async function AddCompanyRules() {
      setAddCompanyRules(true);
    }

    const getRule = async (parameters) => {
      
     
        return fetch(appSettings.apiUrl + '/CompanyRules/getrulesbycompany/' + parameters.companyData.id);
        // const json = await response.json();
        // setValueLeft(json.rule);
        
        // handleShow(); 
    };

    const updateRule = async () => {
      const response =  await fetch(appSettings.apiUrl + '/CompanyRules/updatecompanyrule/', {
        method:'POST',
        headers: {
          'Content-Type':'application/json'
        },
        body: JSON.stringify({
          id:parameters.companyData.id, 
          rules: valueLeft
        })
      });

      const json = await response.json();
      
    }
    

    return (
          <>  
          <Button 
          className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
          onClick={AddCompanyRules}>
            
          <span className="mr-2" style={{fontSize: '10pt'}}>Rules</span>
          <AiFillEdit size={20} />
          {addCompanyRules && (
        <PortalSecureCompanyAddRules
          visible={addCompanyRules}
          companyId={parameters.companyData.id}
          onClose={handleCloseCompany}
          type={0}
        />
      )}
          </Button>
          

          
       
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>RULE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row>
                    
                </Row>
                <Row style={{marginBottom:'40px'}}>
                    <Col xs={12}>
                        <ReactQuill theme="snow" value={valueLeft} onChange={(e) => {setValueLeft(e)}}  style={{height:'300px'}} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={() => {updateRule()}} >Save</Button>
            </Modal.Footer>
          </Modal>
          </>
      
        
    )

}
