import React from "react";
import { Modal, Select } from "antd";
import { Form, Input, Col, Row } from "antd";
import FloatingLabel from "../../components/FloatingLabel";
import { useEffect } from "react";
import agent from "../../Utilities/API/agent";

const { Option } = Select;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};
const AddUserModal = ({
  showUserModal,
  closeUserModal,
  userModal,
  rnrCompanies,
  rnrUser,
  setRnrUser,
  setUsername,
  setEmail,
  setPassword,
  setMobileNumber,
  selectedCompanyIds,
  setSelectedCompanyIds,
  isDriver,
  setIsDriver,
  isAdmin,
  setIsAdmin,
  handleAddUser,
  companyId,
  isKeyOwner,
  setKeyOwner,
  rnrOwners,
  setCompanyOwners,
  companyOwners,
  mobileNumberError
}) => {
  const [activeCheckbox, setActiveCheckbox] = React.useState("");

  const [form] = Form.useForm();
  const handleCompanySelect = (selectedIds) => {
    setSelectedCompanyIds(selectedIds.length > 0 ? selectedIds : [companyId]);
  };

  const handleDriverChange = (event) => {
    
    setIsDriver(event.target.checked);
    setIsAdmin(false);
    setKeyOwner(false);
  };

  const handleAdminChange = (event) => {
    setIsAdmin(event.target.checked);
    setIsDriver(false);
    setKeyOwner(false);
  };

  const handleKeyOwnerChange = (event) => {
    setKeyOwner(event.target.checked);
    setIsDriver(false);
    setIsAdmin(false);
  };

  const handleOwnerChange = (value) => {
    setCompanyOwners(value);
  };


  useEffect(() => {
    
  }, []);

  return (
    <Modal
      open={userModal}
      onCancel={closeUserModal}
      footer={null}
      width={"70%"}
      centered
      className="pt-0 pb-0"
      height="80vh"
    >
      <Row style={sectionHeader}>
        <Col span="24">ADD USER</Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Col span={24} style={dataInputColumn}>
            <b>Full Name</b>
            <Form.Item
              name="fullName"
              rules={[{ required: true, message: "Please enter full name" }]}
            >
              <Input
                onChange={(e) => setRnrUser(e.target.value)}
                placeholder="Enter full name"
              />
            </Form.Item>
          </Col>
          <Col span={24} style={dataInputColumn}>
            <b>Mobile Number</b>
            <Form.Item
              name="mobileNumber"
              rules={[
                { required: true, message: "Please enter mobile number" },
              ]}
            >
              <Input
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder="Enter mobile number"
              />
              {mobileNumberError && (
                <p style={{ color: "red" }}>{mobileNumberError}</p>
              )}
            </Form.Item>
          </Col>
          <Col span={24} style={dataInputColumn}>
            <b>Email</b>
            <Form.Item name="email" onChange={(e) => setEmail(e.target.value)}>
              <Input placeholder="Enter email" />
            </Form.Item>
          </Col>
        </Col>
        <Col span={12}>
          <Col span={24} style={dataInputColumn}>
            <b>Username</b>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please enter username" }]}
            >
              <Input
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter username"
              />
            </Form.Item>
          </Col>
          <Col span={24} style={dataInputColumn}>
            <b>Password</b>
            <Form.Item name="password">
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
            </Form.Item>
          </Col>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} style={dataInputColumn}>
          <b>Additional Options</b>
        </Col>
        <Col span={24}>
          <Form.Item className="mb-4">
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isDriver}
                  onChange={handleDriverChange}
                  className="ml-2"
                />
                Driver
              </label>
            </div>
          </Form.Item>

          {!isDriver ? (
            true
          ) : (
            <>
              <h3 style={{ color: "red" }} className="pt-0">
                Please select an owner
              </h3>
              <Form.Item className="mb-4">
                <Select
                  mode="single"
                  style={{ width: "100%" }}
                  placeholder="Select Owner"
                  value={companyOwners || rnrOwners}
                  onChange={handleOwnerChange}
                >
                  {rnrOwners
                    .filter((owner) => owner.flag === "Owner")
                    .map((owner) => (
                      <Option key={owner.id} value={owner.id}>
                        {owner.fullName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <br />
            </>
          )}

          <Form.Item className="mb-4">
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={isKeyOwner}
                  onChange={handleKeyOwnerChange}
                  className="ml-2"
                />
                Key Owner
              </label>
            </div>
          </Form.Item>
        </Col>
      </Row>
      {isKeyOwner && (
        <Row gutter={16}>
          <Col span={24}>
            <h3 style={{ color: "red" }}>Please select additional companies</h3>
            {rnrCompanies && rnrCompanies.length > 0 && (
              <Form.Item className="mb-4">
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Companies"
                  value={
                    selectedCompanyIds.length > 0
                      ? selectedCompanyIds
                      : [companyId]
                  }
                  onChange={handleCompanySelect}
                  disabled={!isKeyOwner}
                >
                  {rnrCompanies.map((company) => (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      )}
      <button
        className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
        onClick={handleAddUser}
      >
        <span className="mr-2">Submit</span>
      </button>
    </Modal>
  );
};

export default AddUserModal;
