import { useLocation } from "react-router-dom";
import { useContext, useState, useEffect, useMemo } from "react";
import SubmenuFilter from "../../../components/SubmenuFilter";
import Navbar from "../../../components/Navbar";
import { Row, Col, Button, Input, Modal, Form, Select } from "antd";
import {
  DataCountContext,
  DataCountProvider,
} from "../../../components/DataCountContext";
import { AiOutlinePlus, AiFillEdit, AiOutlineUpload } from "react-icons/ai";

import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import useCaseInsensitiveSort from "../../../components/utilities/useCaseInsensitiveSort";
import agent from "../../../Utilities/API/agent";
import AddSupplier from "../Supplier/AddSupplier";
import EditSupplier from "../Supplier/EditSupplier";

const SupplierUserList = () => {
  const location = useLocation();

  const companyId = location.state.companyId;
  const companyName = location.state.companyName;
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const [rnrSuppliers, setRnrSuppliers] = useState([]);

  const [supplierId, setSupplierId] = useState(false);
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("fullName");
  const [addSupplierVisble, setAddSupplierVisble] = useState();
  const [editSupplierVisble, setEditSupplierVisble] = useState();

  const handleAddSupplierClick = () => {
    setAddSupplierVisble(true);
  };

  const handleCloseAddSupplierClick = () => {
    setAddSupplierVisble(false);
  };

  const handleCloseEditSupplierClick = () => {
    setEditSupplierVisble(false);
  };
  const handleEditSupplierClick = (row) => {
    setEditSupplierVisble(true);
    setSupplierId(row.id);
  };
  /*const columns = useMemo(
    () => [
      {
        name: "id",
        selector: "id",
        sortable: true,
        omit: true,
      },
      {
        name: "Full Name",
        selector: (row) => row.fullName,
        sortField: "fullName",
        sortFunction: caseInsensitiveSortFullName,
        sortable: true,
        omit: false,
        cell: (row) => <div>{row.fullName}</div>,
      },
      {
        name: "Mobile Number",
        selector: "mobileNumber",
        sortable: true,
        omit: false,
        cell: (row) => <div>{row.mobileNumber}</div>,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        omit: false,
        cell: (row) => <div>{row.flag}</div>,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
              onClick={() => editMechanics(row)}
            >
              <span className="mr-2">Mechanics</span>

              <AiFillEdit />
            </button>
          </div>
        ),
      },
      ,
    ],
    [] // Dependencies array; empty array means columns will be memoized and created only once
  );*/

  const columns = [
    {
      name:"id",
      selector: (e) => (e.id),
      omit:true
    }, 
    {
      name: 'Full Name',
      selector: (e) => (e.fullName),
      sortable: true
    },
    {
      name: 'Mobile Number',
      selector: (e) => (e.mobileNumber),
      sortable: true
    },
    {
      name: 'Email Address',
      selector: (e) => (e.email),
      sortable: true
    },
    {
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={() => editMechanics(row)}
          >
            <span className="mr-2">Mechanics</span>

            <AiFillEdit />
          </button>
        </div>
      )
    }
  ]

  function editMechanics(row) {
    navigate("/portal/secure/MechanicDetails", {
      state: {
        breakdownId: row.id,
        breakdownName: row.companyName,
      },
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      var data = await agent.Company.getSupplersByCompanyId(companyId);
      setRnrSuppliers(data);
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <DataCountProvider>
          <div>
            <Navbar />
            <SubmenuFilter dataCount={dataCount} />
            <div></div>
          </div>
        </DataCountProvider>

        <div className="flex items-center justify-center h-full mt-3">
          <div className="text-center font-serif text-lg font-semibold text-gray-800 tracking-wide uppercase border-b-2 border-gray-300 inline-block py-1">
            {companyName}
          </div>
        </div>

        <div
          className="pt-2 pl-5"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={handleAddSupplierClick}
          >
            <span className="mr-2">Add User</span>
            <AiOutlinePlus />
          </button>
        </div>

        <div className="pl-5 pr-5">
          <DataTable
            columns={columns}
            data={rnrSuppliers}
            pagination={false}
            striped={true}
            highlightOnHover={true}
            onRowClicked={handleEditSupplierClick}
            className=" border"
            cellClassName="border-b border-[#f9787c] px-4 py-2"
            headerClassName="bg-[#f9787c] text-white font-semibold"
            defaultSortFieldId={2}
            defaultSortAsc={false}
          />
        </div>
        <br />
        <br />
      </div>
      {addSupplierVisble && (
        <AddSupplier
          visible={addSupplierVisble}
          onClose={handleCloseAddSupplierClick}
        />
      )}
      {editSupplierVisble && (
        <EditSupplier
          visible={editSupplierVisble}
          onClose={handleCloseEditSupplierClick}
          supplierId={supplierId}
        />
      )}
    </>
  );
};

export default SupplierUserList;
