import React from 'react';
import { Button } from 'antd';

const AssignMechanicButton = ({ onClick }) => {
    return (
        <Button
            type="primary"
            onClick={onClick}
            style={{ backgroundColor: '#4CAF50', borderColor: 'green' }}
            className="w-full"
        >
            <span style={{ color: 'white' }}>Assign Mechanic/Supplier</span>
        </Button>
    );
};

export default AssignMechanicButton;