import { BrowserRouter, Routes, Route, Red } from "react-router-dom"
import { useState, useEffect } from "react";
import { RNRLayout, RNRLayoutFullScreen } from './RNRLayout';
import "@fontsource/roboto";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import DashboardTvLeft from "./Dashboards/TvLeft";
import DashboardTvRight from "./Dashboards/TvRight";
import Start from "./Start";
import MobileProtected from "./Mobile/Protected";
import MobileAuthLogin from "./Mobile/Auth/Login";
import MobileIndex from "./Mobile/Index";
import MobileSecureMap from "./Mobile/Secure/Map";
import MobileLayout from "./Mobile/Layout";
import PortalAuthLogin from "./Portal/Auth/Login"
import PortalBreakdowns from "./Portal/Secure/Breakdowns/Breakdownlist.js"
import PortalBreakdownsDetails from "./Portal/Secure/Breakdowns/BreakdownDetails"
import PortalLayout from "./Portal/Layout";
import PortalSecureCompanyListCompanies from "./Portal/Secure/Company/ListCompanies";
import PortalSecureCompanyEditCompany from "./Portal/Secure/Company/EditCompany";
import UtilitiesRouteMapping from "./Utilities/RouteMapping";
import DashboardDealerStatusMonitor from "./Dashboards/DealerStatusMonitor";
import PortalSecureAdminRespondersCallHistory from "./Portal/Secure/Admin/Responders/CallHistory";
import PortalSecureInspectionsListInspections from "./Portal/Secure/Inspections/ListInspections";
import DashboardsResponderLeaderboard from './Dashboards/ReponderLeaderboard';
import PortalSecureVehicleListCompanyVehicles from "./Portal/Secure/Vehicle/ListCompanyVehicles";
import SftpUpload from "./Portal/Secure/Misc/SftpUpload";
import LocalTest from "./localtestcomponents/test"
import BreakdownRequest from "./Portal/Secure/BreakdownRequest";
import ActiveBreakdowns from "./Portal/Secure/ActiveBreakdowns";
import CustomerOnboarding from "./Portal/Secure/CustomerOnboarding";
import MechanicDetails from "./Portal/Secure/MechanicDetails";
import Supplier from "./Portal/Secure/Supplier.jsx";
import VehicleEquipmentList from "./Portal/Secure/VehicleEquipmentList";
import MyBreakdowns from "./Portal/Secure/MyBreakdowns";
import EditBasicUserDetails from "./Portal/Secure/EditBasicUserDetails";
import EditBasicMechanicDetails from "./Portal/Secure/EditBasicMechanicDetails";
import UserDetails from "./Portal/Secure/UserDetails";
import PortalSecureBreakdownsSearch from "./Portal/Secure/Breakdowns/Search";
import ListVehiclesFromCompany from "./Portal/Secure/Vehicle/ListVehiclesFromCompany";
import RNRReports from "./Portal/Secure/RNRReports";
import Users from "./Portal/Secure/Users";
import PortalSecureDashboardsConsultant from './Portal/Secure/Dashboards/Consultant.js';
import PortalSecureBreakdownsBreakdownView from "./Portal/Secure/Breakdowns/BreakdownView";
import PortalSecureSupplierList from "./Portal/Secure/Supplier/List.js";
import SupplierOnboarding from "./Portal/Secure/Supplier/List2.js"
import SupplierUserList from "./Portal/Secure/Supplier/SupplierUserList.js"

import BreakdownRequestNew from "./Portal/Secure/BreakdownRequest/new.js";
import BreakdownRequestEdit from "./Portal/Secure/BreakdownRequest/edit.js";
import SearchBreakdownLog from "./Portal/Secure/BreakdownLog/SearchBreakdownLog.js";
import AssignBreakdown from "./Portal/Secure/Breakdowns/AssignBreakdown.js";
import SettingsView from "./Portal/Secure/Settings/SettingsView.js";
import ListCategory from "./Portal/Secure/Settings/Categories/ListCategory.js";
import AddSubCategory from "./Portal/Secure/Settings/Categories/AddSubCategory.js";
import EditSubCategory from "./Portal/Secure/Settings/Categories/EditSubCategory.js";
import SubCategoryList from "./Portal/Secure/Settings/Categories/SubCategoryList.js"
import AddCategory from "./Portal/Secure/Settings/Categories/AddCategory.js";
import EditCategory from "./Portal/Secure/Settings/Categories/EditCategory.js";
import AddCauses from "./Portal/Secure/Settings/Causes/AddCauses.js";
import ListCause from "./Portal/Secure/Settings/Causes/ListCause.js";
import EditCause from "./Portal/Secure/Settings/Causes/EditCause.js";
import BreakdownRequestView from "./Portal/Secure/Breakdowns/BreakdownRequestView.js";




function App() {

  return (
    
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<PortalAuthLogin />}>
            <Route index element={<PortalAuthLogin />} />
          </Route>
          <Route path="/dashboards" element={<RNRLayoutFullScreen />}>
            <Route path="tvleft" element={<DashboardTvLeft />} />
            <Route path="tvright" element={<DashboardTvRight />} />
            <Route path="dealerbreakdownmonitor" element={<DashboardDealerStatusMonitor />} />
            <Route path="responderleaderboard" element={<DashboardsResponderLeaderboard />} />
          </Route>
          <Route path="/breakdownrequest" element={<RNRLayout />}>
            <Route path="" element={<DashboardTvRight />} />
          </Route>
          <Route path="/mobile" element={<MobileProtected />}>
            <Route path="/mobile/" element={<MobileIndex />} />
              <Route path="/mobile/secure" element={<MobileLayout />} >
                <Route path="/mobile/secure/map" element={<MobileSecureMap />} />
              </Route>
          </Route>
          <Route path="/portal/secure/breakdowns/breakdownview/:breakdownRef" element={<PortalSecureBreakdownsBreakdownView />} />
          <Route path="/mobile/auth/" element={<MobileAuthLogin />} />   
          <Route path="/portal/secure/" element={<PortalLayout />} >
            <Route path="/portal/secure/breakdownlist" element={<PortalBreakdowns />} />   
            <Route path="/portal/secure/breakdowns/breakdowndetails" element={<PortalBreakdownsDetails />} /> 
            <Route path="/portal/secure/company/listcompanies" element={<PortalSecureCompanyListCompanies />} />
            <Route path="/portal/secure/company/editcompany/:companyId" element={<PortalSecureCompanyEditCompany />} />
            <Route path="/portal/secure/Inspections/listInspections" element={<PortalSecureInspectionsListInspections />} />
            <Route path="/portal/secure/admin/responders/callhistory" element={<PortalSecureAdminRespondersCallHistory />} />
            <Route path="/portal/secure/vehicles/listcompanyvehicles" element={<PortalSecureVehicleListCompanyVehicles />} />
            <Route path="/portal/secure/breakdowns/search" element={<PortalSecureBreakdownsSearch />} />
            <Route path="/portal/secure/dashboards/consultant" element={<PortalSecureDashboardsConsultant />} />
            <Route path="/portal/secure/company/editcompany/:companyId" element={<EditCause />} />
          </Route>    
          <Route path="/portal" element={<PortalAuthLogin />} />
          <Route path="/Utilities/RouteMapping/" element={<UtilitiesRouteMapping />} />       

          <Route path="/mobile/auth/" element={<MobileAuthLogin />} />         
          <Route path="/Portal" element={<PortalAuthLogin />} />
          <Route path="/Portal/secure/breakdownlist" element={<PortalBreakdowns />} />  
          <Route path="/Portal/secure/breakdownDetails" element={<PortalBreakdownsDetails />} />  
          <Route path="/Portal/secure/breakdownRequest" element={<BreakdownRequestNew />} />  
          <Route path="/Portal/secure/breakdownRequestEdit/:breakdownRequestId" element={<BreakdownRequestEdit />} />  
          
          <Route path="/Portal/secure/breakdownRequest/new" element={<BreakdownRequestNew />} />  
          <Route path="/Portal/secure/ActiveBreakdowns" element={<ActiveBreakdowns />} />  
          <Route path="/Portal/secure/CustomerOnboarding" element={<CustomerOnboarding/>} />
          <Route path="/Portal/secure/MechanicDetails" element={<MechanicDetails/>} />
          <Route path="/Portal/secure/Supplier/list" element={<SupplierOnboarding/>} />
          <Route path="/portal/secure/SupplierDetails" element={<SupplierUserList/>} />
          <Route path="/Portal/secure/Supplier" element={<Supplier/>} />
          <Route path="/Portal/secure/EditBasicUserDetails" element={<EditBasicUserDetails/>} />
          <Route path="/Portal/secure/EditBasicMechanicDetails" element={<EditBasicMechanicDetails/>} />
          <Route path="/Portal/secure/UserDetails" element={<UserDetails/>} />
          <Route path="/localtestcomponents/test" element={<LocalTest />} />   
          <Route path="/portal/secure/vehicles/ListVehiclesFromCompany" element={<ListVehiclesFromCompany/>}/>
          <Route path="/Portal/Secure/Settings/SettingsView" element={<SettingsView/>}/> 
          <Route path="/Portal/secure/VehicleEquipmentList" element={<VehicleEquipmentList/>} />
          <Route path="/Portal/secure/RNRReports" element={<RNRReports/>} />
          <Route path="/Portal/secure/MyBreakdowns" element={<MyBreakdowns/>} />
          <Route path="/Portal/Secure/Misc/SftpUpload" element={<SftpUpload/>}/>
          <Route path="/Portal/Secure/Users" element={<Users/>}/>
          <Route path="/Portal/Secure/BreakdownLog" element={<SearchBreakdownLog/>}/>
          <Route path="/Portal/Secure/AssignBreakdown" element={<AssignBreakdown/>}/>
          <Route path="/Portal/Secure/Settings/Categories/ListCategory" element={<ListCategory/>} />
          <Route path="/Portal/Secure/Settings/Categories/AddCategory" element={<AddCategory/>} />
          <Route path="/Portal/Secure/Settings/Categories/EditCategory" element={<EditCategory/>} />
          <Route path="/Portal/Secure/Settings/Categories/AddSubCategory" element={<AddSubCategory/>} />
          <Route path="/Portal/Secure/Settings/Categories/EditSubCategory" element={<EditSubCategory/>} />
          <Route path="/Portal/Secure/Settings/Causes/ListCause" element={<ListCause/>} />
          <Route path="/Portal/Secure/Settings/Causes/EditCause" element={<EditCause/>} />
          <Route path="/Portal/Secure/Settings/Causes/AddCauses" element={<AddCauses/>} />
          <Route path="/Portal/Secure/Settings/Categories/SubCategoryList" element={<SubCategoryList/>} />
          <Route path="/Portal/Secure/Breakdowns/BreakdownRequestView" element={<BreakdownRequestView/>} />
        </Routes>
      </BrowserRouter>
      
  );
}

export default App;