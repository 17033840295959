import React from 'react';

function BreakdownNotesHeader({ breakdownNoteDetails }) {
  return (
    <div className="flex items-center justify-center mb-1">
      <h4 className="text-lg font-bold text-center mr-2">Breakdown Notes</h4>
      {breakdownNoteDetails && breakdownNoteDetails.length > 0 && (
        <span className="inline-flex items-center justify-center rounded-full text-black w-7 h-7">
          {breakdownNoteDetails.length}
        </span>
      )}
    </div>
  );
}

export default BreakdownNotesHeader;
