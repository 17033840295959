import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { appSettings } from '../../../AppSettings';
import { Container, Row, Col, } from 'react-bootstrap';
import { DatePicker, Select, Button, Modal, Card } from 'antd';
import { saveAs } from 'file-saver';
import SubmenuFilter from '../../../components/SubmenuFilter';

const columns = [
    {
        name: 'Company',
        sortable: true,
        selector: (row) => (row.companyName),
        width: '12%'
    },
    {
        name: 'Reference',
        sortable: true,
        selector: (row) => (row.inspectionReference),
        width: '10%'
    },
    {
        name: 'Inspection Name',
        sortable: true,
        selector: (row) => (row.inspectionName),
        width: '16%'
    },
    {
        name: 'Date',
        sortable: true,
        selector: (row) => (row.inspectionDateString),
        width: '12%'
    },
    {
        name: 'User',
        sortable: true,
        selector: (row) => (row.inspectionUser),
        width: '24%'

    },
    {
        name: 'Breakdown',
        sortable: true,
        selector: (row) => (row.breakdownCreated),
        width: '10%'
    },
    {
        name: '',
        sortable: true,
        selector: (row) => (<><InspectionDisplay Id={row.id} /> <InspectionPDF Id={row.id} inspectionReference={row.inspectionReference} /></>)
    }
]

export default function PortalSecureInspectionsListInspections() {
    const [companyOptions, setCompanyOptions] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedDates, setSelectedDates] = useState();
    const [tableData, setTableData] = useState([]);
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    const { RangePicker } = DatePicker;

    const fetchCompanies = async () => {
        return await fetch(`${appSettings.apiUrl}/company/getallcompanies/`)
            .then(async response => {
                const json = await response.json();
                var interimCompany = [];
                json.map((e) => (
                    interimCompany.push({ value: e.id, label: e.name })
                ));
                setCompanyOptions(interimCompany)
            })
            .catch(error => console.error(error));
    }

    const fetchInspections = async () => {
        var startDate = selectedDates[0].$y + '-' + (selectedDates[0].$M + 1) + '-' + selectedDates[0].$D;
        var endDate = selectedDates[1].$y + '-' + (selectedDates[1].$M + 1) + '-' + selectedDates[1].$D;

        return await fetch(`${appSettings.apiUrl}/inspectionslist/getinspectionbycompanydaterange?companyid=${selectedCompany}&From=${startDate}&To=${endDate}`)
            .then(async response => {
                const json = await response.json();
                setTableData(json)
            })
            .catch(error => console.error(error));
    }


    useEffect(() => {
        fetchCompanies();
    }, [])


    return (
        <div>
            {currentUser ? (<>
                <SubmenuFilter />
                <Container>
                    <Row>
                        <Col sm={4}>
                            Company <br />
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                placeholder="Select Company"
                                optionFilterProp="children"
                                onChange={(option, object) => { setSelectedCompany(option) }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={companyOptions}
                                value={selectedCompany}

                            />

                        </Col>
                        <Col sm={4}>
                            Date Range <br />
                            <RangePicker onChange={(e) => { setSelectedDates(e) }} />
                        </Col>
                        <Col sm="4">
                            <br />
                            <Button onClick={() => { fetchInspections() }}>Get Inspections</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '8px' }}>
                        <DataTable
                            title="Inspections"
                            columns={columns}
                            data={tableData}
                            pagination={true}
                            striped={true}
                            highlightOnHover={true}
                            className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                            cellClassName="border-b border-[#f9787c] px-4 py-2"
                            headerClassName="bg-[#f9787c] text-white font-semibold"
                        />
                    </Row>
                </Container>
            </>) : <h3>Unauthorized Access</h3>}



        </div>
    )
}

const InspectionDisplay = (props) => {

    const columns = [
        {
            name: '',
            sortable: true,
            width: '100px',
            cell: (row) => {
                var itemColor;
                console.log(row.badgeType);
                if (row.badgeType == 1) {
                    itemColor = 'green'
                }
                if (row.badgeType == 2) {
                    itemColor = 'orange'
                }
                if (row.badgeType == 3) {
                    itemColor = 'red'
                }
                console.log(itemColor);
                return (

                    <div style={{ height: '20px', width: '20px', backgroundColor: itemColor, borderRadius: 10 }}></div>
                )
            }

        },
        {
            name: 'Item',
            sortable: true,
            selector: (row) => (row.displayValue)
        },
        {
            name: 'Inspection Date',
            sortable: true,
            selector: (row) => (row.createdDate)
        },
        {
            name: 'Comment',
            sortable: true,
            selector: (row) => (row.comment)
        }
    ]
    const [inspection, setInspection] = useState({});
    const [inspectionItems, setInspectionItems] = useState([]);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const fetInspection = async () => {
        await fetch(`${appSettings.apiUrl}/inspection/getinspectiondetailbyid/${props.Id}`)
            .then(async response => {
                const json = await response.json();
                setInspection(json)
            })
            .catch(error => console.error(error));

        await fetch(`${appSettings.apiUrl}/inspection/getinspectionitemsbyinspectionid/${props.Id}`)
            .then(async response => {
                const json = await response.json();
                setInspectionItems(json)
            })
            .catch(error => console.error(error));

    }

    return (
        <>


            <Modal
                open={open}
                title="View Inspection"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Return
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        Submit
                    </Button>,
                ]}
                width="100%"
                style={{ top: 0 }}
                bodyStyle={{ height: 'calc(100vh - 108px)', overflow: 'auto' }}
            >
                <Container>
                    <Row>
                        <Col sm={12}>
                            Inspection: {inspection.inspectionReference} - {inspection.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            {inspection.companyName}
                        </Col>
                        <Col sm={3}>
                            {inspection.createdDate}
                        </Col>
                        <Col sm={3}>
                            {inspection.userName}
                        </Col>
                        <Col sm={3}>
                            {inspection.Role}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            {inspection.address}
                        </Col>
                        <Col sm={6}>
                            {inspection.latitude}, {inspection.longitude}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card title="Vehicle">
                                <Container>
                                    <Row>
                                        <Col sm={2}>
                                            <span style={{ borderBottom: 'solid 1px' }}>Registration</span> <br />
                                            {inspection.registrationNumber}
                                        </Col>
                                        <Col sm={2}>
                                            <span style={{ borderBottom: 'solid 1px' }}>Make</span> <br />
                                            {inspection.truckMake}
                                        </Col>
                                        <Col sm={2}>
                                            <span style={{ borderBottom: 'solid 1px' }}>Model</span> <br />
                                            {inspection.truckModel}
                                        </Col>
                                        <Col sm={2}>
                                            <span style={{ borderBottom: 'solid 1px' }}>Fleet Number</span> <br />
                                            {inspection.fleetNumber}
                                        </Col>
                                    </Row>
                                    <Row style={{ scrollSnapMarginTop: '12px' }}>
                                        <Col sm={2}>
                                            <span style={{ borderBottom: 'solid 1px' }}>KM</span> <br />
                                            {inspection.km}
                                        </Col>
                                        <Col sm={2}>
                                            <span style={{ borderBottom: 'solid 1px' }}>Hours</span> <br />
                                            {inspection.hours}
                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card title="Inspection Items">
                                <Container>
                                    <Row>
                                        <Col sm={12}>
                                            <DataTable
                                                columns={columns}
                                                data={inspectionItems}
                                                pagination={true}
                                                striped={true}
                                                highlightOnHover={true}
                                                className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                                                cellClassName="border-b border-[#f9787c] px-4 py-2"
                                                headerClassName="bg-[#f9787c] text-white font-semibold"
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </>
    )
}

const InspectionPDF = (props) => {

    const [pdf, setPdf] = useState();

    const generatePDF = async (inspectionId) => {
        return await fetch(`${appSettings.apiUrl}/inspection/getinspectionpdfreport/${inspectionId}`, {
            responseType: 'arrayBuffer'
        })
            .then(async response => {
                //const json = await response.json();
                const blob = await response.blob();

                // Initiate the download using FileSaver.js
                //saveAs(blob, 'downloaded_document.pdf');

            })
            .catch(error => console.error(error));
    }


    return (
        <Button onClick={() => { generatePDF(props.Id) }}>Generate PDF</Button>
    )
}