import SubmenuFilter from "../../../components/SubmenuFilter";
import { useContext } from "react";
import Navbar from "../../../components/Navbar";
import {
  DataCountContext,
  DataCountProvider,
} from "../../../components/DataCountContext";
import { Button } from "antd";
import { useNavigate } from 'react-router-dom';

const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

const SettingsView = () => {
  const navigate = useNavigate();
  const dataCount = useContext(DataCountContext) ?? 5;

  const handleAddCategoryClick = () => {
    // Route to Add Category page when the "Add Category" button is clicked
    navigate('/Portal/Secure/Settings/Categories/ListCategory');
  };

  const handleAddCauseClick = () => {
    // Route to Add Cause page when the "Add Cause" button is clicked
    navigate('/Portal/Secure/Settings/Causes/ListCause');
  };

  return (
    <>
      <DataCountProvider>
        <div>
          <Navbar />
          {currentUser ? (
            <SubmenuFilter dataCount={dataCount} />
          ) : null}
          <div></div>
        </div>
      </DataCountProvider>
      <br />
      <div>
        <h2 className="text-white" style={{ fontSize: '24px' }}>Settings Options</h2>
      </div>
      <div style={{ marginTop: '30px', marginLeft: '20px'}}>
        <Button type="primary" onClick={handleAddCategoryClick}>Add Category</Button>
      </div>
      <div style={{ marginTop: '20px' , marginLeft: '20px'}}>
        <Button type="primary" onClick={handleAddCauseClick}>Add Cause</Button> 
      </div>
    </>
  );
};

export default SettingsView;
