import * as XLSX from 'xlsx/xlsx.mjs';

export const exportToExcel = (newVehicle, oldVehicle, modifiedVehicle) => {
    const combinedData = [...newVehicle, ...oldVehicle, ...modifiedVehicle];
    const worksheetData = {
        newVehicle: newVehicle,
        oldVehicle: oldVehicle,
        modifiedVehicle: modifiedVehicle,
    };

    const workbook = XLSX.utils.book_new();
    for (const sheetName in worksheetData) {
        const worksheet = XLSX.utils.json_to_sheet(worksheetData[sheetName]);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    }

    XLSX.writeFile(workbook, 'vehicle_data.xlsx');
};
