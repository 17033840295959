// import  GoogleMapReact  from 'google-map-react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const CustomMarker = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <circle cx="16" cy="16" r="10" fill="#FF0000" />
  </svg>
);

const MapContainer = () => {
    
    const mapOptions = {
      center: { lat: -28.891695025559542, lng: 24.73821656018326 },
      zoom: 6
    };

  
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        {/* <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCPC4BU1rYr6a3YL6dNW-vFaKZ0VOg935U' }}
          defaultCenter={mapOptions.center}
          defaultZoom={mapOptions.zoom}
        >
          <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"  
        > 
          
         </AnyReactComponent> 
        </GoogleMapReact> */}
      </div>
    );
  };

  const UtilitiesRouteMapping = () => {
    
    const handleButtonClick = () => {
        
    }

    return (
      <div>
        <MapContainer />
        <InputGroup 
        style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            padding: '10px',
            zIndex: '999',
            width:'300px' }}>
      <FormControl placeholder="RNR Refrence Number" />
      <Button style={{backgroundColor: '#4CAF50',
                        color: 'white'}} onClick={handleButtonClick}>
        Submit
      </Button>
    </InputGroup>
        
      </div>
    );
  };
  
  export default UtilitiesRouteMapping;