import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row, Input } from "antd";
import agent from "../../../Utilities/API/agent";

const AddMechanic = ({ visible, onClose, supplierId }) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);


  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
        values.supplierModelId = supplierId
        values.id = '';
        values.loginId = '';
        // Handle submission logic
        agent.Mechanic.addMechanicDetail(values);
        onClose();
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    
      
        <Modal
          visible={visible}
          onCancel={handleCancel}
          footer={[
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>,
            <Button key="save" loading={isSaving} type="primary" onClick={handleSubmit}>
              SAVE
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row style={sectionHeader}>
              <Col span={24}>ADD MECHANIC</Col>
            </Row>
            <Col span={24} style={dataInputColumn}>
              <b>Full Name</b>

              <Form.Item
                name="fullName"
                rules={[{ required: true, message: "Please enter full name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Email Address</b>

              <Form.Item
                name="email"
                            >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>ID Number</b>

              <Form.Item
                name="idNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter identification number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Mobile Number</b>

              <Form.Item
                name="mobileNumber"
             
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Username</b>

              <Form.Item
                name="username"
                rules={[{ required: true, message: "Please enter user name" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} style={dataInputColumn}>
              <b>Password</b>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter password" }]}
              >
                <Input type="password"/>
              </Form.Item>
              {/* <Form.Item
                name="id"
                hidden={true}
              >
              </Form.Item> */}
            </Col>
          </Form>
        </Modal>
      
    
  );
};

export default AddMechanic;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};
