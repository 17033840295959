import { useLocation } from "react-router-dom";
import { useContext, useState, useEffect, useMemo } from "react";
import SubmenuFilter from "../../components/SubmenuFilter";
import Navbar from "../../components/Navbar";
import {
  DataCountContext,
  DataCountProvider,
} from "../../components/DataCountContext";
import { AiOutlinePlus, AiFillEdit, AiOutlineUpload } from "react-icons/ai";
import AddUserModal from "../../components/user/AddUserModal";
import { appSettings } from "../../AppSettings";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import useCaseInsensitiveSort from "../../components/utilities/useCaseInsensitiveSort";
import EditCustomer from "./Customer/EditCustomer";
import agent from "../../Utilities/API/agent";

const UserDetails = () => {
  const location = useLocation();
  const companyId = location.state.breakdownId;
  const companyName = location.state.breakdownName;
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const [userModal, setUserModal] = useState(null);
  const [rnrCompanies, setRnrCompanies] = useState([]);
  const [rnrUser, setRnrUser] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [isDriver, setIsDriver] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isKeyOwner, setKeyOwner] = useState(false);
  const [rnrOwners, setRnrOwners] = useState([]);
  const [userAdded, setUserAdded] = useState(false);
  const [companyOwners, setCompanyOwners] = useState([]);
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("fullName");
  const [editCustomerVisible,setEditCustomerVisible] = useState(false);
  const [isOwner, setIsOwner] = useState();
  const [customerId, setCustomerId] = useState();
  const [mobileNumberError, setMobileNumberError] = useState("");


  /*const columns = useMemo(
    () => [
      {
        name: "id",
        selector: "id",
        sortable: true,
        omit: true,
      },
      {
        name: "Full Name",
        selector: (row) => row.fullName,
        sortField: "fullName",
        sortFunction: caseInsensitiveSortFullName,
        sortable: true,
        omit: false,
        cell: (row) => <div>{row.fullName}</div>,
      },
      {
        name: "Mobile Number",
        selector: "mobileNumber",
        sortable: true,
        omit: false,
        cell: (row) => <div>{row.mobileNumber}</div>,
      },
      {
        name: "Flag",
        selector: "flag",
        sortable: true,
        omit: false,
        cell: (row) => <div>{row.flag}</div>,
      },
      {
        name: "Edit",
        cell: (row) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
              onClick={() => editUser(row)}
            >
              <span className="mr-2">Edit User</span>
              <AiFillEdit size={20} />
            </button>
          </div>
        ),
      },
    ],
    [] // Dependencies array; empty array means columns will be memoized and created only once
  );
*/

const columns = [
  {
    name: "id",
    selector: (e) => (e.id),
    sortable: true,
    omit:true
  },{
    name: "Full Name",
    selector: (e) => (e.fullName),
    sortable: true
  },
  {
    name:'Mobile Number',
    selector: (e) => (e.mobileNumber),
    sortable: true
  },
  {
    name:'User Role',
    selector: (e) => (e.flag),
    sortable:true
  },
  {
    name: 'Edit',
    cell: (row) => (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <button
          className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
          onClick={() => editUser(row)}
        >
          <span className="mr-2">Edit User</span>
          <AiFillEdit size={20} />
        </button>
      </div>
    )
    
  }
]
  async function viewDriver(row) {
    alert("working");
  }


  async function editUser(row) {
 
    setEditCustomerVisible(true);
    setCustomerId(row.id);
    const flagBool = (row.flag == 'Owner') ? true : false;
    setIsOwner(flagBool);
    // navigate("/portal/secure/EditBasicUserDetails", {
    //   state: {
    //     Id: row.id,
    //     Flag: row.flag,
    //     Email: row.email,
    //     FullName: row.fullName,
    //     IdNumber: row.idNumber,
    //     Mobile: row.mobileNumber,
    //     UserName: row.username,
    //     Password: row.password,
    //   },
    // });
  }

  async function fetchHoldingCompanies() {
    // try {
    //     const response = await fetch(appSettings.apiUrl + '/Company/GetCompanyList');
    //     const data = await response.json();
    //     setRnrCompanies(data);
    // } catch (error) {
    //     console.error('Error fetching holding companies:', error);
    // }

    try {
      const response = await fetch(
        appSettings.apiUrl + "/Company/GetAllHoldingCompanies"
      );
      const data = await response.json();
      setRnrCompanies(data);
    } catch (error) {
      console.error("Error fetching holding companies:", error);
    }
  }

  async function fetchCompanyOwners() {
    try {
      const response = await fetch(
        `${appSettings.apiUrl}/Company/GetCompanyOwnersAndDrivers/${companyId}`
      );
      const data = await response.json();
      setRnrOwners(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching holding companies:", error);
    }
  }

  useEffect(() => {
    if (companyId) {
      fetchCompanyOwners();
    }
  }, [userAdded]);

  useEffect(() => {
    fetchHoldingCompanies();
  }, []);

  async function addUser() {}

  const showUserModal = () => {
    setUserModal(true);
  };

  const closeUserModal = () => {
    setUserModal(false);
  };

const handleCustomerClose = () => {
    setEditCustomerVisible(false);
    setIsOwner(null);
    setCustomerId(null);
    fetchCompanyOwners();
  };
  
  async function handleAddUser() {
    setUserAdded(false);
  let isValidMobileNumber = true; // Assume mobile number is valid by default

  if (isDriver) {
    await agent.Users.checkDriverMobile(mobileNumber).then((result) => {
      if (result) {
        setMobileNumberError("Mobile number already exists");
        isValidMobileNumber = false; // Update validity flag
      } else {
        setMobileNumberError('');
      }
    });
  }

  if (isKeyOwner && isValidMobileNumber) { // Check if mobile number is still valid
    await agent.Users.checkOwnerMobile(mobileNumber).then((result) => {
      if (result) {
        setMobileNumberError("Mobile number already exists");
        isValidMobileNumber = false; // Update validity flag
      } else {
        setMobileNumberError('');
      }
    });
  }

  if (!isValidMobileNumber) {
    return;
  }
    // const user = {
    //     rnrUser,
    //     selectedCompanyIds,
    //     isDriver,
    //     isAdmin,
    //     companyName,
    //     companyId,
    //     isKeyOwner
    // };

    // if (companyOwners !== null && companyOwners.length > 0) {
    //     user.companyOwners = companyOwners;
    // }

    const user = {
      rnrUser,
      email,
      password,
      username,
      mobileNumber,
      selectedCompanyIds,
      isDriver,
      isAdmin,
      companyName,
      companyId,
      isKeyOwner,
    };

    if (
      user.selectedCompanyIds == null ||
      user.selectedCompanyIds.length <= 0
    ) {
      user.selectedCompanyIds = [user.companyId];
    }
    if (companyOwners !== null && companyOwners.length > 0) {
      user.companyOwners = companyOwners;
    }

    fetch(appSettings.apiUrl + "/Company/AddUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((response) => response.json())
      .then((data) => {
        closeUserModal();
        setRnrUser("");
        setEmail("");
        setUsername("");
        setPassword("");
        setMobileNumber("");
        setSelectedCompanyIds([]);
        setIsDriver(false);
        setIsAdmin(false);
        setUserAdded(true);
        setKeyOwner(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>  
    <div className="flex flex-col">
      <DataCountProvider>
        <div>
          <Navbar />
          <SubmenuFilter dataCount={dataCount} />
          <div></div>
        </div>
      </DataCountProvider>

      <div className="flex items-center justify-center h-full mt-3">
        <div className="text-center font-serif text-lg font-semibold text-gray-800 tracking-wide uppercase border-b-2 border-gray-300 inline-block py-1">
          {companyName}
        </div>
      </div>

      <div
        className="pt-2 pl-5"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <button
          className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
          onClick={showUserModal}
        >
          <span className="mr-2">Add User</span>
          <AiOutlinePlus />
        </button>
        <button
          className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
          onClick={() => addUser()}
        >
          <span className="mr-2">Excel Upload</span>
          <AiOutlineUpload />
        </button>
      </div>
      <AddUserModal
        showUserModal={showUserModal}
        closeUserModal={closeUserModal}
        userModal={userModal}
        rnrCompanies={rnrCompanies}
        rnrUser={rnrUser}
        setRnrUser={setRnrUser}
        setEmail={setEmail}
        setUsername={setUsername}
        setPassword={setPassword}
        setMobileNumber={setMobileNumber}
        setSelectedCompanyIds={setSelectedCompanyIds}
        selectedCompanyIds={selectedCompanyIds}
        isDriver={isDriver}
        setIsDriver={setIsDriver}
        isAdmin={isAdmin}
        setIsAdmin={setIsAdmin}
        handleAddUser={handleAddUser}
        companyId={companyId}
        isKeyOwner={isKeyOwner}
        setKeyOwner={setKeyOwner}
        rnrOwners={rnrOwners}
        setCompanyOwners={setCompanyOwners}
        companyOwners={companyOwners}
        mobileNumberError={mobileNumberError}
      />
   
      <div className="pl-5 pr-5">
        <DataTable
          columns={columns}
          data={rnrOwners}
          pagination={false}
          striped={true}
          highlightOnHover={true}
          className=" border"
          cellClassName="border-b border-[#f9787c] px-4 py-2"
          headerClassName="bg-[#f9787c] text-white font-semibold"
          defaultSortFieldId={2}
          defaultSortAsc={false}
        />
      </div>
      <br />
      <br />
    </div>
    { customerId &&
        <EditCustomer
          visible={editCustomerVisible}
          isOwner={isOwner}
          customerId={customerId}
          onClose={handleCustomerClose}
        />
  }</>
  );
};

export default UserDetails;
