import {useState, useEffect} from 'react';
import { appSettings } from '../AppSettings';
import HereMapsDisplay from '../Utilities/HereMaps/Display';


const apiUrl = 'https://uat-m-api-v2.rightnowresponse.com/api';
//const apiUrl = 'https://localhost:7091/api';

export default function DashboardTvRight() {
    const [breakdownStatistics, setBreakdownStatistics] = useState({});
    const [activebreakdowns, setActiveBreakdowns] = useState([]);

    const getBreakdownStatistics = async () => {
        const response = await fetch(appSettings.apiUrl + '/statistics/DashboardStatistics/screen2');
        //const response = await fetch('https://uat-m-api-v2.rightnowresponse.com/api/statistics/DashboardStatistics/screen1');
        const json = await response.json();

        setBreakdownStatistics(json);
    }

    const getActiveBreakdowns = async () => {
        const response = await fetch(appSettings.apiUrl + '/Breakdowns/activebreakdowns');
        //const response = await fetch('https://uat-m-api-v2.rightnowresponse.com/api/statistics/DashboardStatistics/screen1');
        const json = await response.json();

        setActiveBreakdowns(json);

    }

    useEffect(() => {
        getBreakdownStatistics();
        getActiveBreakdowns();

        const interval = setInterval(() => {
            getBreakdownStatistics()
            getActiveBreakdowns();
        }, 60000)

        return () => clearInterval(interval);
    }, [])
    
    return (
        <div>
            <div className="row">
                <div className="col-md-8 text-center">
                    <HereMapsDisplay 
                        Breakdowns={activebreakdowns} MapHeight='500px'
                    />
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card text-center">
                                <div className="card-header ">
                                <div style={{fontSize:48, fontWeight: 'bold'}}>ESCALATION NEEDED</div>
                                </div>
                                <div className="card-body">
                                <div style={{fontSize:72, fontWeight: 'bold'}}> 0 </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="card  text-center">
                                <div className="card-header ">
                                <div style={{fontSize:48, fontWeight: 'bold'}}>FOLLOW UP DUE</div>
                                </div>
                                <div className="card-body">
                                    <div style={{fontSize:72, fontWeight: 'bold'}}> {breakdownStatistics.followUpDue} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-4">
                    <div className="card  text-center">
                        <div className="card-header ">
                        <div style={{fontSize:48, fontWeight: 'bold'}}>NO TECHNICIAN ASSIGNED</div>
                        </div>
                        <div className="card-body">
                            <div style={{fontSize:72, fontWeight: 'bold'}}> {breakdownStatistics.noMechanicAssigned} </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card  text-center">
                        <div className="card-header ">
                        <div style={{fontSize:48, fontWeight: 'bold'}}>NO TECHNICIAN ACCEPTED</div>
                        </div>
                        <div className="card-body">
                            <div style={{fontSize:72, fontWeight: 'bold'}}> {breakdownStatistics.noMechanicAccepted} </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card  text-center">
                        <div className="card-header ">
                        <div style={{fontSize:48, fontWeight: 'bold'}}>PANIC /<br /> CALL ME</div>
                        </div>
                        <div className="card-body">
                            <div style={{fontSize:72, fontWeight: 'bold'}}> 0</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    )
}

