import React, { useEffect, useState, useCallback } from 'react';
import Navbar from '../../components/Navbar';
import SubmenuFilter from '../../components/SubmenuFilter';
import { appSettings } from '../../AppSettings';
import { DataCountProvider } from '../../components/DataCountContext';
import DataTable from 'react-data-table-component';
import { GiCarWheel } from 'react-icons/gi';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaEye } from 'react-icons/fa';
import CustomModal from '../../components/modal';
import FloatingLabel from '../../components/FloatingLabel';
import { useNavigate } from 'react-router-dom';

const ActiveBreakdowns = () => {
  const [activeBreakdowns, setActiveBreakdowns] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rnrCompaniesAndDrivers, setRnrCompaniesAndDrivers] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedDriverId, setSelectedDriverId] = useState('');
  const [selectDriver, setSelectDriver] = useState('');
  const [driverName, setDriverName] = useState('');
  const [driverCell, setDriverCell] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameList, setCompanyNameList] = useState([]);
  const [acceptClicked, setAcceptClicked] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(false);
  const navigate = useNavigate();
  const handleStateUpdated = (updatedValue) => {
    setModalState(updatedValue);
    setUpdatedValue(updatedValue);
  };

  const handleButtonClick = (rowId) => {
    setIsModalVisible((prevVisibleModals) => ({
      ...prevVisibleModals,
      [rowId]: !prevVisibleModals[rowId],
    }));
  };

  const handleModalCancel = (rowId) => {
    setDriverName("");
    setDriverCell("");
    setIsModalVisible((prevVisibleModals) => ({
      ...prevVisibleModals,
      [rowId]: false,
    }));
  };

  const handleOk = (rowId) => {
    setIsModalVisible((prevVisibleModals) => ({
      ...prevVisibleModals,
      [rowId]: !prevVisibleModals[rowId],
    }));
  };

  useEffect(() => {
    fetch(appSettings.apiUrl + '/Breakdowns/GetAllActiveRequests')
      .then(response => response.json())
      .then(data => {
        
        setActiveBreakdowns(data);
        setDataCount(data.length);
      })
      .catch(error => {
        console.error('Error fetching active breakdowns:', error);
      });
  }, [acceptClicked, updatedValue]);
  
  
  useEffect(() => {
    fetch(appSettings.apiUrl + '/Breakdowns/getCompanyAndDrivers')
      .then(response => response.json())
      .then(data => setRnrCompaniesAndDrivers(data));
  }, [])

  useEffect(() => {
    if (selectedCompany) {
      const selectedDriverDetails = JSON.parse(
        rnrCompaniesAndDrivers.find(
          (company) => company.companyName === selectedCompany
        )?.driverDetails || "[]"
      );

      if (selectedDriverDetails.length) {
        const selectedDriver = selectedDriverDetails[0];
        setSelectedDriverId(selectedDriver.driverId);
        setSelectDriver(selectedDriver.FullName);
        setDriverName(selectedDriver.FullName);
        setDriverCell(selectedDriver.MobileNumber);
      } else {
        setSelectDriver('');
        setDriverName('');
        setDriverCell('');
      }
    } else {
      setSelectDriver('');
      setDriverName('');
      setDriverCell('');
    }
  }, [selectedCompany]);

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    setDriverName("");
    setDriverCell("");
  };

  const handleCompanyNameClick = () => {
    console.log(companyNameList);
  };

  const handleModalSubmit = (data) => {
    alert(data);
  }
  const handleRowClick = (row) => {
    
    navigate(`/Portal/secure/breakdownRequestEdit/${ row.id}`);
  }

  const handleAsignButtonClick = (rowId, breakdownRequestReference) => {

    var updatedReference = breakdownRequestReference;
    if (breakdownRequestReference.endsWith("RB")) {
      updatedReference = breakdownRequestReference.slice(0, -2) + "B";
    }

    fetch(`${appSettings.apiUrl}/Breakdowns/acceptDriverAndCompanyToActiveBreakdown/${rowId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setAcceptClicked(true);

        if (updatedReference) {
          fetch(`${appSettings.apiUrl}/Breakdowns/GetBreakdownIdByReference?reference=${updatedReference}`)
            .then(response => response.json())
            .then(data => {
              navigate('/portal/secure/breakdownDetails', { state: { breakdownId: data } });
            });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };

  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      isVisible: true,
      omit: true
    },
    {
      name: 'Reference',
      selector: (row) => row.breakdownRequestReference || 'N/A',
      sortable: true
    },
    {
      name: 'Driver Name',
      selector: (row) => row.driver || row.name|| 'N/A',
      sortable: true
    },
    {
      name: 'Driver No',
      selector: (row) => row.cell || row.mobileNumber ||  'N/A',
      sortable: true
    },
    {
      name: 'Company Name',
      selector: (row) => row.companyName || row.freeTextCompanyName || 'N/A',
      sortable: true
    },
    {
      name: 'Controller Name',
      selector: (row) => row.controllerName || 'N/A',
      sortable: true
    },
    {
      name: 'Controller No',
      selector: (row) => row.controllerContactNo || 'N/A',
      sortable: true
    },
    {
      name: 'Description',
      selector: (row) => row.description || 'N/A',
      sortable: true
    },
    {
      name: 'Address',
      selector: (row) => row.breakdownAddress || 'N/A',
      sortable: true
    } //,
    // {
    //   name: 'Asign Driver',
    //   sortable: true,
    //   cell: (row) => {
    //     if (row.driver === null && row.mobileNumber === null) {
    //       return (
    //         <div className="flex justify-center">
    //           <button
    //             className="bg-red-500 hover:bg-red-700 text-white w-full font-bold py-2 px-2 rounded border border-black border-2 flex items-center"
    //             onClick={() => handleButtonClick(row.id)}>
    //             <span className="mr-4">Assign</span>
    //             <GiCarWheel size={20} />
    //           </button>
    //           {isModalVisible && (
    //             <CustomModal
    //               id={row.id}
    //               onSubmit={() => handleModalSubmit(row.id)}
    //               title={"Select Driver"}
    //               visible={isModalVisible[row.id]}
    //               onCancel={() => handleModalCancel(row.id)}
    //               onOk={() => handleOk(row.id)}
    //               rowId={row.id}
    //               selectedCompany={selectedCompany}
    //               selectedDriverId={selectedDriverId}
    //               driverName={driverName}
    //               driverCell={driverCell}
    //               onStateUpdated={handleStateUpdated}
    //               content={<div>
    //                 <div>
    //                   <div> {rnrCompaniesAndDrivers ? (
    //                     <div className="relative">
    //                       <select
    //                         className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pl-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    //                         value={selectedCompany}
    //                         onChange={handleCompanyChange}
    //                       >
    //                         <option value="">Select a company</option>
    //                         {rnrCompaniesAndDrivers.map((company, index) => (
    //                           <option key={index} value={company.companyName || company.fullName}>
    //                             {company.companyName || company.fullName}
    //                           </option>
    //                         ))}
    //                       </select>
    //                     </div>
    //                   ) : (
    //                     <FloatingLabel
    //                       id="companyName"
    //                       name="companyName"
    //                       type="text"
    //                       value={companyName}
    //                       onChange={(e) => setCompanyName(e.target.value)}
    //                       onClick={handleCompanyNameClick}
    //                       placeholder="companyName"
    //                       label="Company Name"
    //                       className="relative ml-3"
    //                     />
    //                   )}</div>
    //                   <div className="my-4"></div>
    //                   <div className='flex'>
    //                     <div className="mr-2 w-1/2">
    //                       {selectedCompany ? (
    //                         <select
    //                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    //                           onChange={(e) => {
    //                             const selectedValue = e.target.value;
    //                             const selectedDriver = JSON.parse(selectedValue);
    //                             if (selectedDriver) {
    //                               setDriverName(selectedDriver.FullName);
    //                               setDriverCell(selectedDriver.MobileNumber);
    //                             } else {
    //                               setDriverName("");
    //                               setDriverCell("");
    //                             }
    //                           }}
    //                         >
    //                           {JSON.parse(
    //                             rnrCompaniesAndDrivers.find(
    //                               (company) => company.companyName === selectedCompany
    //                             )?.driverDetails || "[]"
    //                           ).length ? (
    //                             JSON.parse(
    //                               rnrCompaniesAndDrivers.find(
    //                                 (company) => company.companyName === selectedCompany
    //                               )?.driverDetails || "[]"
    //                             ).map((driver) => (
    //                               <option
    //                                 key={`${driver.DriverId}-${driver.FullName}`}
    //                                 value={JSON.stringify(driver)}
    //                               >
    //                                 {driver.FullName}
    //                               </option>
    //                             ))
    //                           ) : (
    //                             <option value="">No drivers</option>
    //                           )}
    //                         </select>

    //                       ) : (
    //                         <FloatingLabel
    //                           id="selectDriver"
    //                           name="selectDriver"
    //                           type="text"
    //                           value={selectDriver}
    //                           onChange={(e) => setSelectDriver(e.target.value)}
    //                           placeholder="selectDriver"
    //                           label="Select Driver:"
    //                           className="relative ml-3"
    //                         />
    //                       )}
    //                     </div>
    //                     <div className="w-1/2">
    //                       <FloatingLabel
    //                         id="driverName"
    //                         name="driverName"
    //                         type="text"
    //                         value={driverName}
    //                         onChange={(e) => setDriverName(e.target.value)}
    //                         placeholder="driverName"
    //                         label="Driver Name:"
    //                         className="relative"
    //                       />
    //                     </div>
    //                     <div className="w-1/2">
    //                       <FloatingLabel
    //                         id="driverCell"
    //                         name="driverCell"
    //                         type="text"
    //                         value={driverCell}
    //                         onChange={(e) => setDriverCell(e.target.value)}
    //                         placeholder="driverCell"
    //                         label="Driver Cell:"
    //                         className="relative ml-3"
    //                       />
    //                     </div>
    //                   </div>
    //                   <div className="my-4"></div>
    //                 </div>
    //               </div>}
    //             />
    //           )}
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div className="flex justify-center">
    //           {/* <button
    //             className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 rounded border border-black border-2 flex items-center"
    //             onClick={() => handleAsignButtonClick(row.id, row.breakdownRequestReference)}>
    //             <span className="mr-2">Accept</span>
    //             <AiOutlineCheck size={20} />
    //           </button> */}
    //         </div>
    //       );
    //     }
    //   }
    // }
  ];


  console.log(dataCount);

  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));


  return (
    <DataCountProvider>
      <div>
        <Navbar />
        {currentUser ? (<>
          <SubmenuFilter dataCount={dataCount} />
        </>) : null}
      </div>
      {currentUser ? (<>
        <div style={{ padding: '0 30px' }}>
        <DataTable
          title="Breakdown requests"
          id="breakdownrequest"
          columns={columns}
          pagination={true}
          striped={true}
          data={activeBreakdowns}
          highlightOnHover={true}
          onRowClicked={handleRowClick}
          className="bg-white border border-[#f9787c] rounded-lg cursor-pointer"
          cellClassName="border-b border-[#f9787c] px-4 py-2"
          headerClassName="bg-[#f9787c] text-white font-semibold"
          customStyles={customStyles}
        />
      </div>
      </>) : <h3>Unauthorized Access</h3>}
    
    </DataCountProvider>

  );
};

export default ActiveBreakdowns;



