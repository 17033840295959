import React from 'react';

const MechanicDetails = ({ mechanic }) => {
  return (
    <div className="flex items-center justify-center mb-1">
      <h4 className="text-lg font-bold text-center mr-2">Mechanic Details</h4>
      {mechanic && mechanic.length > 0 && (
        <span className="inline-flex items-center justify-center rounded-full text-black w-7 h-7">
          {mechanic.length}
        </span>
      )}
    </div>
  );
};

export default MechanicDetails;
