import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const useTokenValidation = () => {
    const navigate = useNavigate();

    useEffect(() => {
        

        const token = sessionStorage.getItem('currentUser');

        const isTokenValid = validateToken(token);

        if (!isTokenValid) {
            navigate('/portal');
        }
    }, [navigate]);

    const validateToken = (token) => {
        
        if (token) {
            try {                
                // const decodedToken = jwtDecode(token);

                // const fullName = decodedToken.unique_name; 
                // const loginId = decodedToken.role[1]; 
                // const userId = decodedToken.nameid;
                // const jwtToken = decodedToken.token;

                // const updatedUser = {
                //     fullName: fullName,
                //     loginId: loginId,
                //     userId:userId,
                //     jwtToken:jwtToken,
                //   };

                // localStorage.setItem('currentUser', JSON.stringify(updatedUser));

                return true; 
            } catch (error) {
                console.error('Error decoding token:', error);
                return false; 
            }
        } else {
            return false; 
        }
    };

    return null;
};

export default useTokenValidation;

