import Navbar from '../components/Navbar';
import SubmenuFilter from '../components/SubmenuFilter';
import UseTokenValidation from '../components/utilities/useTokenValidation'

export default function test()
{

    UseTokenValidation();

    return(
        <div>
            <Navbar/>
            <SubmenuFilter/>
            

            <div>Token Validated</div>
        </div>
    );
}
