import { useState, useEffect, useContext, useRef } from "react";
import SubmenuFilter from "../../../components/SubmenuFilter";
import Navbar from "../../../components/Navbar";
import {
  DataCountContext,
  DataCountProvider,
} from "../../../components/DataCountContext";
import { appSettings, currentUser } from "../../../AppSettings";
import { Row, Col, Input, Select, AutoComplete, Button, Form, Divider} from "antd";
import {
  ComponentsVehiclesVehicleSelectByRegistration as VehicleRegistrationSelect,
  ComponentsVehiclesVehicleSelectByFleetNumber as VehicleFleetSelect,
  ComponentsVehiclesVehicleSelectByFleetNumber,
} from "../../../components/vehicles/VehicleSelect";
import TextArea from "antd/es/input/TextArea";
import MarkerMap from "../../../Utilities/GoogleMaps/MarkerMap";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../../../Utilities/API/agent";

export default function BreakdownRequestEdit() {
  let { breakdownRequestId } = useParams();
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();
  const hereMapsAPIKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [driverLoading, setDriverLoading] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const [trailerRowDisplayStyle, setTrailerRowDisplayStyle] = useState({
    display: "none",
  });

  //Define the input variables.

  const [controllerName, setControllerName] = useState("");
  const [controllerNumber, setControllerNumber] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverContactNumber, setDriverContactNumber] = useState("");
  const [savedaddress, setSavedAddress] = useState("");
  const [truckRegistrationNumber, setTruckRegistrationNumber] = useState("");
  const [truckFleetNumber, setTruckFleetNumber] = useState("");
  const [truckVinNumber, setTruckVinNumber] = useState("");
  const [truckMake, setTruckMake] = useState("");
  const [truckModel, setTruckModel] = useState("");
  const [truckYear, setTruckYear] = useState("");
  const [trailerRegistrationNumber, setTrailerRegistrationNumber] =
    useState("");
  const [trailerFleetNumber, setTrailerFleetNumber] = useState("");
  const [trailerVinNumber, setTrailerVinNumber] = useState("");
  const [trailerMake, setTrailerMake] = useState("");
  const [trailerModel, setTrailerModel] = useState("");
  const [trailerYear, setTrailerYear] = useState("");
  const [isTrailer, setIsTrailer] = useState(0);
  const [mileage, setMileage] = useState(0);
  const [leaseType, setLeaseType] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [description, setDescription] = useState("");
  const [requestId, setRequestId] = useState(null);
  const [breakdownSearchResult, setBreakdownSearchResult] = useState("");
  const [addressFromLocation, setAddressFromLocation] = useState("");
  const [currentBreakdownRequest, setCurrentBreakdownRequest] = useState();
  const [driverError, setDriverError] = useState();
  const [companyName, setCompanyName] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [trailerId, setTrailerId] = useState("");
  const [secondTrailerId, setSecondTrailerId] = useState("");
  const [secondTrailerRegistrationNumber, setSecondTrailerRegistrationNumber] =
    useState("");
  const [secondTrailerFleetNumber, setSecondTrailerFleetNumber] = useState("");
  const [secondTrailerVinNumber, setSecondTrailerVinNumber] = useState("");
  const [secondTrailerMake, setSecondTrailerMake] = useState("");
  const [secondTrailerModel, setSecondTrailerModel] = useState("");
  const [secondTrailerYear, setSecondTrailerYear] = useState("");
  const [isSecondTrailer, setIsSecondTrailer] = useState(0);
  const [secondTrailerRowDisplayStyle, setSecondTrailerRowDisplayStyle] =
  useState({
    display: "none",
  });

  const selectRef = useRef(null);
  //Define the functions
  const getCustomers = async () => {
    setCompanyLoading(true);
    setSelectedDriver("");
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const response = await fetch(
      `${appSettings.apiUrl}/company/getcompaniesbytype/0`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "bearer: " + currentUser.jwtToken,
        },
      }
    );
    const json = await response.json();
    setCustomers(json);
    setCompanyLoading(false);
  };
  const triggerSelectChange = (customerId) => {
    selectRef.current.focus();
    // Change the selected value programmatically
    selectRef.current.value = customerId; // Assuming option2 is the value you want to select
    selectRef.current.dispatchEvent(new Event("change", { bubbles: true }));
  };
  const getDrivers = async (companyId) => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    setDriverLoading(true);
    const response = await fetch(
      `${appSettings.apiUrl}/drivers/getdrivers/getcompanydrivers/${companyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "bearer: " + currentUser.jwtToken,
        },
      }
    );
    const json = await response.json();
    setDrivers(json.drivers);
    setDriverLoading(false);
  };

  const handleMarkerPositionChange = (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    reverseGeoSearch(lat, lng);
  };

  // const setDriverDetails = (id) => {
  //   const driver = drivers.find((d) => d.id === id);
  //   if (driver) {
  //     setDriverName(driver.fullName);
  //     setDriverContactNumber(driver.mobileNumber);
  //   }
  // };
  const handleTruckNotInList = async (value) => {
    setTruckRegistrationNumber(value);
  };
  const handleFleetNotInList = async (value) => {
    setTruckFleetNumber(value);
  };
  const handleTrailerNotInList = async (value) => {
    setTrailerRegistrationNumber(value);
  };
  const handleTrailerFleetNotInList = async (value) => {
    setTrailerFleetNumber(value);
  };
  const handleSecondTrailerNotInList = async (value) => {
    setSecondTrailerRegistrationNumber(value);
  };
  const handleSecondTrailerFleetNotInList = async (value) => {
    setSecondTrailerFleetNumber(value);
  };

  const lookupAddress = async (address) => {
    if (address.length % 3 === 0) {
      const encodedAddress = encodeURIComponent(address);
      const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodedAddress}&limit=10&apiKey=${hereMapsAPIKey}&in=countryCode:ZAF,ZWE,ZMB,BWA,NAM&type=request`;

      const response = await fetch(url);
      const json = await response.json();

      setAddressOptions(json.items);
    }
  };
  const handleAddressSearch = (address) => {
    const apiKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
    const encodedAddress = encodeURIComponent(address);
    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodedAddress}&apiKey=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBreakdownSearchResult(data);
          if (data.items.length === 1) {
            setLatitude(data.items[0].position.lat);
            setLongitude(data.items[0].position.lng);
            setAddressFromLocation(data.items[0].title);
          } else if (data.items.length > 1) {
            setSavedAddress(data);
            const mappedData = data.items?.map((item) => ({
              title: item.title,
              lat: item.position.lat,
              lng: item.position.lng,
            }));
            // setSelectedTitles(mappedData);
            //   setIsModalVisible(true);
          } else {
            alert("No results found. Please try a different search.");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const reverseGeoSearch = (lat, lng) => {
    const apiKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
    const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat}%2C${lng}&lang=en-US&apiKey=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // setReverseSearchResult(data);
        if (data.items.length > 0) {
          setSavedAddress(data.items[0].title);
          setAddressFromLocation(data.items[0].title);
        }
      })
      .catch((error) => console.error(error));
  };
  const submitRequest = async () => {
    //define the post body;

    const postValues = {};
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

    const userId = currentUser.loginId;
    let isDriverSelected = true;
    if (!selectedDriver || selectedDriver == "") {
      isDriverSelected = false;
      setDriverError("Please Select a driver");
    } else {
      setDriverError("");
    }

    if (!isDriverSelected) {
      return;
    }
    const breakdownRequest = {
      Id: breakdownRequestId, // Assuming this will be generated on the server side
      UserId: userId,
      ControllerName: controllerName,
      ControllerContactNo: controllerNumber,
      CompanyName: customers.find((x) => x.id == selectedCustomer)?.name,
      SelectedCompany: customers.find((x) => x.id == selectedCustomer)?.name,
      SelectedDriverId: selectedDriver,
      DriverName: driverName,
      DriverCell: driverContactNumber,
      Address: savedaddress,
      Latitude: latitude ? parseFloat(latitude) : null,
      Longitude: longitude ? parseFloat(longitude) : null,
      AddressFromLocation: addressFromLocation,
      IsBreakdownOnTrailerTextVal: isTrailer.toString(),
      TruckRegistrationNumber: truckRegistrationNumber,
      TruckVINNumber: truckVinNumber,
      TruckFleet: truckFleetNumber,
      TruckMake: truckMake,
      TruckModel: truckModel,
      TruckYear: truckYear,
      TrailerRegistrationNumber: trailerRegistrationNumber,
      TrailerVINNumber: trailerVinNumber,
      TrailerFleet: trailerFleetNumber,
      TrailerMake: trailerMake,
      TrailerModel: trailerModel,
      TrailerYear: trailerYear,
      LeaseType: leaseType,
      Mileage: mileage?.toString(),
      Warranty: "", // No corresponding state provided
      AttendingMechanic: "", // No corresponding state provided
      BreakdownDescription: description,
      IsVehicleLoadedTextVal: "", // No corresponding state provided
      BreakdownType: "", // No corresponding state provided
      OtherBreakdownInfo: "", // No corresponding state provided
      OrderNumber: "", // No corresponding state provided
      Imagery: [], // Needs to be populated separately based on UI interaction
      BreakdownRequestReference: "", // No corresponding state provided
      BreakdownAddress: "", // No corresponding state provided
      Description: "", // No corresponding state provided
      Driver: "", // No corresponding state provided
      MobileNumber: "", // No corresponding state provided
      BreakdownClassType: "", // No corresponding state provided
      EquipmentFleetNumber: "", // No corresponding state provided
      EquipmentMake: "", // No corresponding state provided
      EquipmentModel: "", // No corresponding state provided
      EquipmentSerialNumber: "", // No corresponding state provided
      EquipmentYear: "", // No corresponding state provided
      VehicleId: vehicleId, // No corresponding state provided
      FaultOnTrailer: isTrailer,
      FaultOnSecondTrailer: isSecondTrailer,
      TrailerVehicleId: trailerId,
      SecondTrailerVehicleId: secondTrailerId,
      SecondTrailerRegistrationNumber: secondTrailerRegistrationNumber,
      SecondTrailerVinNumber: secondTrailerVinNumber,
      SecondTrailerFleet: secondTrailerFleetNumber,
      SecondTrailerMake: secondTrailerMake,
      SecondTrailerModel: secondTrailerModel,
      SecondTrailerYear: secondTrailerYear,
    };

    agent.BreakdownRequest.updateBreakdownRequest(breakdownRequest).then(() => {
      agent.BreakdownRequest.createBreakdown(breakdownRequestId).then(
        (message) => {
          navigate("/portal/secure/breakdownlist");
        }
      );
    });

    // fetch(appSettings.apiUrl + '/Breakdowns/CreateBreakdownRequest2', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(breakdownRequest),
    // })
    //     .then(response => response.json())
    //     .then(data => {
    //         navigate('/portal/secure/ActiveBreakdowns');
    //     })
    //     .catch(error => {
    //         console.error(error);
    //     });
  };

  const setSelectedTruck = async (id, e) => {
    if (e) {
      setTruckRegistrationNumber(e.registration);
      setTruckFleetNumber(e.fleet);
      setTruckVinNumber(e.vin);
      setTruckMake(e.make);
      setTruckModel(e.model);
      setTruckYear(e.year);
      setLeaseType(e.leaseType);
      setVehicleId(e.id);
    }
  };

  const setSelectedTrailer = async (id, e) => {
    if (e) {
      setTrailerRegistrationNumber(e.registration);
      setTrailerFleetNumber(e.fleet);
      setTrailerVinNumber(e.vin);
      setTrailerMake(e.make);
      setTrailerModel(e.model);
      setTrailerYear(e.year);
      setTrailerId(e.id);
    }
  };

  const faultOnTrailer = () => {
    setIsTrailer(1);
    setTrailerRowDisplayStyle({ display: "flex" });
  };

  const setSelectedSecondTrailer = async (id, e) => {
    if (e) {
      setSecondTrailerRegistrationNumber(e.registration);
      setSecondTrailerFleetNumber(e.fleet);
      setSecondTrailerVinNumber(e.vin);
      setSecondTrailerMake(e.make);
      setSecondTrailerModel(e.model);
      setSecondTrailerYear(e.year);
      setSecondTrailerId(e.id);
    }
  };
 

  const faultOnSecondTrailer = () => {
    setIsSecondTrailer(1);
    setSecondTrailerRowDisplayStyle({ display: "flex" });
  };

  const fetchData = async () => {
    const data = await agent.BreakdownRequest.getById(breakdownRequestId);
    const latIsNumber = !isNaN(data.latitude) && !isNaN(parseFloat(data.latitude));
    const lngIsNumber = !isNaN(data.longitude) && !isNaN(parseFloat(data.longitude));
   console.log(data);
    if(!latIsNumber || !lngIsNumber)
    {
      data.latitude = -26.204412366778424;
      data.longitude = 28.049289079589848;
    }

    setCurrentBreakdownRequest(data);
  };

  useEffect(() => {
    getCustomers();
    fetchData();
  }, []);
  useEffect(() => {
    if (currentBreakdownRequest && customers.length > 0) {
      
      setControllerName(currentBreakdownRequest.controllerName);
      setControllerNumber(currentBreakdownRequest.controllerContactNo);
      setCompanyName(
        currentBreakdownRequest.companyName
          ? currentBreakdownRequest.companyName
          : currentBreakdownRequest.freeTextCompanyName
      );
      // setSelectedCustomer(
      //   currentBreakdownRequest.selectedCustomer
      // );
      setSelectedCustomer(
        customers.find((x) => x.name == currentBreakdownRequest.companyName)?.id
      );
      getDrivers(
        customers.find((x) => x.name == currentBreakdownRequest.companyName)?.id
      );
      setDriverName(
        currentBreakdownRequest.driverName
          ? currentBreakdownRequest.driverName
          : currentBreakdownRequest.name
      );
      setSelectedDriver(currentBreakdownRequest.selectedDriverId);
      setDriverContactNumber(
        currentBreakdownRequest.driverCell
          ? currentBreakdownRequest.driverCell
          : currentBreakdownRequest.cell
      );
      setSavedAddress(currentBreakdownRequest.breakdownAddress);
   
        setLatitude(currentBreakdownRequest.latitude);
        setLongitude(currentBreakdownRequest.longitude);
  

      setLeaseType(currentBreakdownRequest.leaseType);
      setTruckRegistrationNumber(
        currentBreakdownRequest.truckRegistrationNumber
      );
      setTruckFleetNumber(currentBreakdownRequest.truckFleet);
      setTruckVinNumber(currentBreakdownRequest.truckVINNumber);
      setTruckMake(currentBreakdownRequest.truckMake);
      setTruckModel(currentBreakdownRequest.truckModel);
      setTruckYear(currentBreakdownRequest.truckYear);
      setDescription(currentBreakdownRequest.description);
      setMileage(currentBreakdownRequest.mileage);
      setTrailerRegistrationNumber(currentBreakdownRequest.trailerRegistrationNumber);
      setTrailerFleetNumber(currentBreakdownRequest.trailerFleet);
      setTrailerVinNumber(currentBreakdownRequest.trailerVINNumber);
      setTrailerMake(currentBreakdownRequest.trailerMake);
      setTrailerModel(currentBreakdownRequest.trailerModel);
      setTrailerYear(currentBreakdownRequest.trailerYear);
      setTrailerId(currentBreakdownRequest.trailerId);
      setSecondTrailerRegistrationNumber(currentBreakdownRequest.secondTrailerRegistrationNumber);
      setSecondTrailerFleetNumber(currentBreakdownRequest.secondTrailerFleet);
      setSecondTrailerVinNumber(currentBreakdownRequest.secondTrailerVINNumber);
      setSecondTrailerMake(currentBreakdownRequest.secondTrailerMake);
      setSecondTrailerModel(currentBreakdownRequest.secondTrailerModel);
      setSecondTrailerYear(currentBreakdownRequest.secondTrailerYear);
      setSecondTrailerId(currentBreakdownRequest.secondTrailerId);
      setVehicleId(currentBreakdownRequest.vehicleId);
      setIsTrailer(currentBreakdownRequest.faultOnTrailer);
      setIsSecondTrailer(currentBreakdownRequest.faultOnSecondTrailer);

      if (currentBreakdownRequest.faultOnTrailer)
        faultOnTrailer();
      if (currentBreakdownRequest.faultOnSecondTrailer)
        faultOnSecondTrailer();
     
    

    }
  }, [currentBreakdownRequest, customers]);

  return (
    <div>
      <DataCountProvider>
        <div>
          <Navbar />
          <SubmenuFilter dataCount={dataCount} />

          <div></div>
        </div>
      </DataCountProvider>

      <div></div>
      <Form form={form}>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Controller Name </span>
                <br />
                <Input
                  value={controllerName}
                  onChange={(e) => {
                    setControllerName(e.target.value);
                  }}
                />
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>
                  Controller Contact Number{" "}
                </span>
                <br />
                <Input
                  value={controllerNumber}
                  onChange={(e) => {
                    setControllerNumber(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Select a Customer</span>
                <Select
                  style={{ width: "100%" }}
                  options={customers?.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  value={selectedCustomer}
                  onChange={(e) => {
                    setSelectedCustomer(e);
                    getDrivers(e);
                  }}
                  allowClear={true}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(input)
                  }
                  loading={companyLoading}
                />
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Select a Driver</span>
                <Select
                  ref={selectRef}
                  style={{ width: "100%" }}
                  options={drivers?.map((c) => ({
                    value: c.id,
                    label: c.fullName,
                  }))}
                  value={selectedDriver}
                  onChange={(e) => {
                    setSelectedDriver(e);
                 //   setDriverDetails(e);
                  }}
                  allowClear={true}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(input)
                  }
                />
                {driverError && <p style={{ color: "red" }}>{driverError}</p>}
              </Col>
            </Row>
            <Row>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Driver Name </span>
                <br />
                <Input
                  value={driverName}
                  onChange={(e) => {
                    setDriverName(e.target.value);
                  }}
                />
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Driver Contact Number </span>
                <br />
                <Input
                  value={driverContactNumber}
                  onChange={(e) => {
                    setDriverContactNumber(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={23} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Address </span>
                <br />
                <AutoComplete
                  style={{ width: "100%" }}
                  options={addressOptions?.map((a) => ({
                    value: a.address.label,
                    label: a.address.label,
                  }))}
                  value={savedaddress}
                  onChange={(e) => {
                    setSavedAddress(e);
                    lookupAddress(e);
                  }}
                  onSelect={(e) => {
                    setSavedAddress(e);
                    handleAddressSearch(e);
                  }}
                />
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Latitude</span>
                <Input
                  value={latitude}
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                />
              </Col>
              <Col span={12} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>longitude</span>
                <Input
                  value={longitude}
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Truck Registration</span>
                <VehicleRegistrationSelect
                  companyId={selectedCustomer}
                  onSelected={setSelectedTruck}
                  onChange={handleTruckNotInList}
                  value={truckRegistrationNumber}
                />
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Fleet Number</span>
                <ComponentsVehiclesVehicleSelectByFleetNumber
                  companyId={selectedCustomer}
                  onSelected={setSelectedTruck}
                  onChange={handleFleetNotInList}
                  value={truckFleetNumber}
                />
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Vin Number</span>

                <Input
                  value={truckVinNumber}
                  onChange={(e) => {
                    setTruckVinNumber(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Make</span>
                <Input
                  value={truckMake}
                  onChange={(e) => {
                    setTruckMake(e.target.value);
                  }}
                />
              </Col>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Model</span>
                <Input
                  value={truckModel}
                  onChange={(e) => {
                    setTruckModel(e.target.value);
                  }}
                />
              </Col>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Year</span>
                <Input
                  value={truckYear}
                  onChange={(e) => {
                    setTruckYear(e.target.value);
                  }}
                />
              </Col>
              <Col span={6} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Mileage</span>
                <Input
                  value={mileage}
                  onChange={(e) => {
                    setMileage(e.target.value);
                  }}
                />
              </Col>
              <Col span={24} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Lease Type</span>
                <Input
                  value={leaseType}
                  onChange={(e) => {
                    setLeaseType(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col span={12} style={formCSS.inputCol}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={faultOnTrailer}
              >
                THERE IS A FAULT ON THE TRAILER
              </Button>
            </Col>
            <Col span={12} style={formCSS.inputCol}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={faultOnSecondTrailer}
              >
                THERE IS A FAULT ON THE SECOND TRAILER
              </Button>
            </Col>
          </Row>
            <Row style={trailerRowDisplayStyle}>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Trailer Registration</span>
                <VehicleRegistrationSelect
                  companyId={selectedCustomer}
                  onSelected={setSelectedTrailer}
                  onChange={handleTruckNotInList}
                  value={trailerRegistrationNumber}
                  isTrailer={true}
                />
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Fleet Number</span>
                <ComponentsVehiclesVehicleSelectByFleetNumber
                  companyId={selectedCustomer}
                  onSelected={setSelectedTrailer}
                  onChange={handleFleetNotInList}
                  value={trailerFleetNumber}
                  isTrailer={true}
                />
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Vin Number</span>

                <Input
                  value={trailerVinNumber}
                  onChange={(e) => {
                    setTrailerVinNumber(e.target.value);
                  }}
                />
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Make</span>
                <Input
                  value={trailerMake}
                  onChange={(e) => {
                    setTrailerMake(e.target.value);
                  }}
                />
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Model</span>
                <Input
                  value={trailerModel}
                  onChange={(e) => {
                    setTrailerModel(e.target.value);
                  }}
                />
              </Col>
              <Col span={8} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>Year</span>
                <Input
                  value={trailerYear}
                  onChange={(e) => {
                    setTrailerYear(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row style={secondTrailerRowDisplayStyle}>
            <Row>
           <Col span={24} style={formCSS.inputCol}>
            <div style={{backgroundColor: '#333', borderColor:'#333'}}>
            <Divider/>
            </div> 
            </Col>
           </Row>
            {/* Second Trailer input fields */}
            <Col span={8} style={formCSS.inputCol}>
              <span style={formCSS.inputHeader}>
                Second Trailer Registration
              </span>
              <VehicleRegistrationSelect
                companyId={selectedCustomer}
                onSelected={setSelectedSecondTrailer}
                onChange={handleSecondTrailerNotInList}
                value={secondTrailerRegistrationNumber}
                isTrailer={true}
              />
            </Col>
            <Col span={8} style={formCSS.inputCol}>
              <span style={formCSS.inputHeader}>
                Second Trailer Fleet Number
              </span>
              <ComponentsVehiclesVehicleSelectByFleetNumber
                companyId={selectedCustomer}
                onSelected={setSelectedTrailer}
                onChange={handleSecondTrailerFleetNotInList}
                value={secondTrailerFleetNumber}
                isTrailer={true}
              />
            </Col>
            <Col span={8} style={formCSS.inputCol}>
              <span style={formCSS.inputHeader}>Second Trailer Vin Number</span>
              <Input
                value={secondTrailerVinNumber}
                onChange={(e) => setSecondTrailerVinNumber(e.target.value)}
              />
            </Col>
            <Col span={8} style={formCSS.inputCol}>
              <span style={formCSS.inputHeader}>Second Trailer Make</span>
              <Input
                value={secondTrailerMake}
                onChange={(e) => setSecondTrailerMake(e.target.value)}
              />
            </Col>
            <Col span={8} style={formCSS.inputCol}>
              <span style={formCSS.inputHeader}>Second Trailer Model</span>
              <Input
                value={secondTrailerModel}
                onChange={(e) => setSecondTrailerModel(e.target.value)}
              />
            </Col>
            <Col span={8} style={formCSS.inputCol}>
              <span style={formCSS.inputHeader}>Second Trailer Year</span>
              <Input
                value={secondTrailerYear}
                onChange={(e) => setSecondTrailerYear(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
           <Col span={24} style={formCSS.inputCol}>
            <div style={{backgroundColor: '#333', borderColor:'#333'}}>
            <Divider/>
            </div> 
            </Col>
           </Row>
            <Row>
              <Col span={24} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}>DESCRIBE THE FAULT</span>
                <TextArea
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: "12px" }}>
              <Col span={8}>
                <Button danger>Cancel Request</Button>
              </Col>
              <Col span={16}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={submitRequest}
                >
                  CREATE BREAKDOWN
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div
              style={{ width: "100%", height: "100%" }}
              className="w-1/2 bg-gray-400"
            >
              {breakdownSearchResult &&
              breakdownSearchResult.items.length > 0 &&
              breakdownSearchResult.items[0].position.lat &&
              breakdownSearchResult.items[0].position.lng ? (
                <MarkerMap
                  latitude={breakdownSearchResult.items[0].position.lat}
                  longitude={breakdownSearchResult.items[0].position.lng}
                  onMarkerPositionChange={handleMarkerPositionChange}
                />
              ) : currentBreakdownRequest != undefined ? (
                <MarkerMap
                  latitude={currentBreakdownRequest.latitude}
                  longitude={currentBreakdownRequest.longitude}
                  onMarkerPositionChange={handleMarkerPositionChange}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const formCSS = {
  inputCol: {
    padding: "8px",
  },
  inputHeader: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
};
