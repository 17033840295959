import {useEffect, useState} from 'react';
import { appSettings } from '../AppSettings';
import DataTable from 'react-data-table-component';
import {Container, Row, Col} from 'react-bootstrap';
import {Radio, DatePicker, Button} from 'antd';
import {BeatLoader} from 'react-spinners';


const columns = [
    {
        name: 'P',
        selector: (row) => row.leaderboardPosition,
        sortable: true,
        width:'50px'
        
    },
    {
        name: 'Responder',
        selector: (row) => row.responderName,
        sortable: true
    },
    {
        name: 'Rating',
        selector: (row) => row.finalRating,
        sortable: true,
        center: true,
        width:'10%'
        

    },
    {
        name: 'Breakdowns Created',
        sortable: true,
        center: true,
        width:'15%',
        cell: (row) => (
            <div>{row.breakdownCreatedRating} ({row.createdBreakdownCount})</div>
        )
    },        
    {
        name: 'Breakdowns Created - Elapsed',
        selector: (row) => row.breakdownCreatedTimeRating ,
        sortable: true,
        center: true,
        width:'20%'
    },
    {
        name: 'Follow Up - Elapsed',
        selector: (row) => row.followUpTimeRating,
        sortable: true,
        center: true
    },
    {
        name: 'Supplier Assignment',
        selector: (row) => row.supplierFinalRating,
        sortable: true,
        center: true
    },
    {
        name: 'Calls',
        selector: (row) => row.callRating,
        sortable: true,
        center: true
    },
    
];

export default function DashboardsResponderLeaderboard() {
    const [responders, setResponders] = useState([]);
    const [loading, setLoading] = useState(false);
    const {RangePicker} = DatePicker;
    const [selectedDates, setSelectedDates] = useState();
    const [intervalId, setIntervalId] = useState();
    
    
    const fetchResponders = async (dateFrom, dateTo) => {
            setLoading(true);
            await fetch(`${appSettings.apiUrl}/responder/getresponderstatistics?FromDate=${dateFrom}&ToDate=${dateTo}`)
            .then(async response => {
                setResponders(await response.json())})
            .catch(error => console.error(error));
            setLoading(false);
            
    }

    const fetchRespondersOnTimer = async (dateFrom, dateTo) => {
        
            setLoading(true);
            await fetch(`${appSettings.apiUrl}/responder/getresponderstatistics?FromDate=${dateFrom}&ToDate=${dateTo}`)
            .then(async response => {
                setResponders(await response.json())})
            .catch(error => console.error(error));
            setLoading(false);
        
};

    
    useEffect(() => {
        const now = new Date();

        fetchResponders(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate(), now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());

        setIntervalId(setInterval(() => {
            const now = new Date();
            fetchRespondersOnTimer(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate(), now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
        },120000 ));

        return () => {
            clearInterval(intervalId);
        };
    },[]);

    

    const periodChange = async (e) => {
        
        const now = new Date();
        if (e.target.value == 'b') {
            const lastDay = new Date(now.getFullYear(), now.getMonth() + 1,0).getDate();
            fetchResponders(now.getFullYear() + '-' + (now.getMonth() + 1) + '-01', now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + lastDay);
            clearInterval(intervalId);
        }else{
            fetchResponders(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate(), now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
            setIntervalId(setInterval(() => {
                const now = new Date();
                fetchRespondersOnTimer(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate(), now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
            },120000 ));
        }
        
    }

    const setDateRange = (selectedDates) => {
        console.log(selectedDates);
        if (selectedDates != null) {
            var startDate = selectedDates[0].$y + '-' + (selectedDates[0].$M + 1) + '-' + selectedDates[0].$D ;
            var endDate = selectedDates[1].$y + '-' + (selectedDates[1].$M + 1) + '-' + selectedDates[1].$D ;
            fetchResponders(startDate, endDate);
            clearInterval(intervalId);
        }else{
            setIntervalId(setInterval(() => {
                const now = new Date();
                fetchRespondersOnTimer(now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate(), now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate());
            },120000 ));
        }
        
    }


    return (
        <div>
            <Container>
                    <Row>
                        <Col sm={2}>
                        <Radio.Group
                            defaultValue="a"
                            size="small"
                            style={{
                                marginTop: 16,
                            }}
                            onChange={(e)=>{periodChange(e)}}
                            >
                            <Radio.Button value="a">DAILY</Radio.Button>
                            <Radio.Button value="b">MONTHLY</Radio.Button>
                            </Radio.Group>
                        </Col>
                        <Col sm={8} style={{paddingTop:'10px'}}>
                            <RangePicker onChange={(e) => {(e != null) ? setSelectedDates(e) : periodChange({target: {value: 'a'}})} } />
                            <Button type="default" style={{marginLeft: '15px'}} onClick={() => {setDateRange(selectedDates)}}>Fetch Data</Button>
                        </Col>
                        <Col sm={2} style={{paddingTop:'10px', textAlign:'end'}}>
                            <BeatLoader 
                                loading={loading}
                                
                            ></BeatLoader>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <DataTable
                                title="Responder Leaderboard"
                                columns={columns}
                                data={responders}
                                pagination={false}
                                striped={true}
                                highlightOnHover={true}
                                className="bg-white border border-[#f9787c] rounded-lg shadow-lg"
                                cellClassName="border-b border-[#f9787c] px-4 py-2"
                                headerClassName="bg-[#f9787c] text-white font-semibold"
                                defaultSortFieldId={1}
                                defaultSortAsc={true}
                                omit
                            />
                        </Col>
                        
                    </Row>

            </Container>

        </div>
    )
}