import React, { useState, useEffect } from 'react';
import Logo from '../assets/rnr_logo.png';
import Logo2 from '../assets/rnr_logo.png';
import { BiUser } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { appSettings } from '../AppSettings';
import axios from 'axios';
import agent from '../Utilities/API/agent';
import { store, useStore } from "../Utilities/Stores/store";

const ComponentsNavbar = () => {

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const [current, setCurrent] = useState('');
    const [countData, setCountData] = useState([]);
    const navigate = useNavigate();
    const [newlogo, setNewLogo] = useState(Logo); 
    const [requestCount,setRequestCount] = useState();
    const { breakdownStore } = useStore();
    const { breakdownsFromStore} = breakdownStore;
    
    useEffect(() => {
        const hostname = window.location.hostname;
            agent.BreakdownRequest.requestCount().then((data) =>{
                setRequestCount(data);
            });
        if (hostname === "example.com") { 
            setNewLogo(Logo); 
        } else {
            setNewLogo(Logo2); 
        }
    }, []);
  

    const handleSignOut = () => {
        sessionStorage.removeItem('currentUser');

        navigate('/portal');
    };

    const fetchData = (url, setter) => {
        const apiUrl = appSettings.apiUrl + url;
      
        fetch(apiUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setter(data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      };

    /*useEffect(() => {

        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        const loginId = currentUser.loginId;

        if (loginId) {
            fetchData('/Breakdowns/GetAllActiveRequests', setCountData);
            const interval = setInterval(() => {
                fetchData('/Breakdowns/GetAllActiveRequests', setCountData);
            }, 30000);

            return () => {
                clearInterval(interval);
            };
        }
    }, []);
    */


    return (
        <div className="border-b sticky top-0 z-50 bg-white/[95%]">
            <div className="flex justify-between items-center sm:mx-6 md:mx-10 lg:mx-12 ">
                {/*Left */}
                <Link to="/Portal">
                    <div className="h-20 flex">
                        <img src={newlogo} className="object-cover w-200 -my-1" />
                    </div>
                </Link>

                {/* Middle */}
                {currentUser && (
                    <div className="hidden lg:flex justify-center items-center relative  border rounded-full my-custom-div">
                        <input
                            type="search"
                            placeholder=""
                            className="py-2.5 w-[33rem] rounded-full outline-0"
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '100%', paddingRight: '16px', paddingLeft: '6px', fontWeight: 'bold', color: '#4a5568', marginLeft: '7px' }}>
                            <Link to="/Portal/secure/breakdownrequest" style={{ borderRight: '1px solid #c2c2c2', padding: '6px 24px', width: '100%', color: '#000', textDecoration: 'none' }}>
                                <button>New request</button>
                            </Link>
                            <Link to="/Portal/secure/activebreakdowns" style={{ borderRight: '1px solid #c2c2c2', padding: '6px 24px', width: '100%', paddingLeft: '56px', color: '#000', textDecoration: 'none' }}>
                                <button>
                                    Requests
                                     <span style={{ backgroundColor: '#e53e3e', color: '#fff', fontWeight: 'bold', borderRadius: '9999px', padding: '2px 6px', marginLeft: '4px' }}>
                                        {requestCount && requestCount}
                                    </span> 
                                </button>
                            </Link>
                            <Link to="/Portal/secure/MyBreakdowns" style={{ borderRight: '1px solid #c2c2c2', padding: '6px 24px', width: '100%', color: '#000', textDecoration: 'none' }}>
                                <button>My Breakdowns</button>
                            </Link>
                            <Link to="/Portal/secure/breakdownlist" style={{ borderRight: '1px solid #c2c2c2', padding: '6px 24px', width: '100%', paddingLeft: '14px', color: '#000', textDecoration: 'none', marginLeft:'10px' }}>
                                <button>Active Breakdowns</button>
                            </Link>
                            <Link to="/Portal/secure/breakdowns/search" style={{ display:'inline-block', padding:'8px', backgroundColor:'#ccc', borderRadius:'50%' }}>
                                <FiSearch style={{ color:'#fff', fontSize:'14px'}} />
                            </Link>
                        </div>
                        
                    </div>
                )}

                {/*Right */}
                
                    <div className="flex items-center pr-3 font-semibold text-gray-600">
                        {currentUser ? (
                            <div className="flex items-center border px-3 py-2 rounded-full gap-2 bg-gray-500 text-white font-bold  hover:bg-[#f9787c] duration-100 ease-out cursor-default">
                                <p>{currentUser.fullName}</p>
                                <FaSignOutAlt
                                    className="text-[18px]"
                                    onClick={handleSignOut}
                                />
                            </div>
                        ) : (
                            <div className="flex items-center border px-3 py-2 rounded-full gap-2 bg-gray-500 text-white font-bold hover:bg-[#f9787c] duration-100 ease-out">
                                <p>Sign in</p>
                                <BiUser className="text-[22px]" />
                            </div>
                        )}
                    </div>
            </div>
        </div>
    )
}

export default ComponentsNavbar;
