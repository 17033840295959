import React, { useEffect, useState } from "react";
import { appSettings } from "../../../AppSettings";
import { Modal, Input, Select, Button, Form, Row, Col, AutoComplete } from "antd";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import agent from "../../../Utilities/API/agent";
import { BiSearchAlt } from "react-icons/bi";

const PortalSecureCompanyAddCompany = ({ visible, onClose, type }) => {
  const [selectedCompany, setSelectedCompany] = useState();

  const [holdingCompanyList, setHoldingCompanyList] = useState([]);
  const [spaceIndex, setSpaceIndex] = useState(0);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [form] = Form.useForm();
  const { Option } = Select;
  const yesNo = [
    { value: 0, label: "No" },
    { value: 1, label: "Yes" },
  ];
  const companyTypes = [
    { value: 0, label: "Customer" },
    { value: 1, label: "Supplier" },
  ];

  const handleCancel = () => {
    onClose();
  };
  const [address, setAddress] = useState("");
  const [breakdownSearchResult, setBreakdownSearchResult] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [addressFromLocation, setAddressFromLocation] = useState("");
  const [assosiatedAddresses, setAssosiatedAddress] = useState([]);
  const [reverseSearchResult, setReverseSearchResult] = useState("");
  const [addressOptions, setAddressOptions] = useState([]);
  const [savedaddress, setSavedAddress] = useState("");
  const hereMapsAPIKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";

  //   const fetchCompany = async () => {
  //     const data = await agent.Company.getCompanyById(workingId);
  //     setSelectedCompany(data);
  //   };

  const getCompanyList = async () => {
    const response = await fetch(`${appSettings.apiUrl}/Company/getallcompanies/`);
    const json = await response.json();
    setHoldingCompanyList(json);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { holdingCompany, ...restValues } = values;
      const updatedValues = {
        ...restValues,
        holdingCompanyId: holdingCompany,
        address: savedaddress,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      };

      await agent.Company.addCompany(updatedValues);
      onClose();
    } catch (errorInfo) {
      console.log("Validation failed:", errorInfo);
    }
  };

  useEffect(() => {
    setInitialFormValues({
      companyType: type,
    });
    // fetchCompany();

    getCompanyList();
  }, []);

  const lookupAddress = async (address) => {
    if (address.length % 3 === 0) {
      const encodedAddress = encodeURIComponent(address);
      const url = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodedAddress}&limit=10&apiKey=${hereMapsAPIKey}&in=countryCode:ZAF,ZWE,ZMB,BWA,NAM&type=request`;

      const response = await fetch(url);
      const json = await response.json();

      setAddressOptions(json.items);
    }
  };
  const handleAddressSearch = (address) => {
    const apiKey = "U9ZiA2apQ1UL7Bsuc_QWpQ_pSrrGOheXYsF7dNrwe3c";
    const encodedAddress = encodeURIComponent(address);
    const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodedAddress}&apiKey=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBreakdownSearchResult(data);
          if (data.items.length === 1) {
            setLatitude(data.items[0].position.lat);
            setLongitude(data.items[0].position.lng);
            setAddressFromLocation(data.items[0].title);
          } else if (data.items.length > 1) {
            setSavedAddress(data);
            const mappedData = data.items.map((item) => ({
              title: item.title,
              lat: item.position.lat,
              lng: item.position.lng,
            }));
            // setSelectedTitles(mappedData);
            //   setIsModalVisible(true);
          } else {
            alert("No results found. Please try a different search.");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      (
      <Modal
        open={visible}
        onCancel={handleCancel}
        width={"70%"}
        footer={[
          <Button key='close' onClick={handleCancel}>
            Close
          </Button>,
          <Button key='save' type='primary' onClick={handleSubmit}>
            SAVE
          </Button>,
        ]}>
        <Form
          form={form}
          layout='vertical'
          initialValues={{
            companyType: type,
          }}>
          <Row style={sectionHeader}>
            <Col span={24}>ADD COMPANY</Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              {/* <Row gutter={16}> */}

              <Col span={24} style={dataInputColumn}>
                <b> Name</b>
                <Form.Item name='name' rules={[{ required: true, message: "Please enter name" }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24} style={dataInputColumn}>
                <b>Cell</b>
                <Form.Item name='cell' rules={[{ required: true, message: "Please enter cell" }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col span={24} style={formCSS.inputCol}>
                <span style={formCSS.inputHeader}></span>
                <b>Longitude</b>
                <Input
                  value={longitude}
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                />
              </Col>

              <Col span={24} style={dataInputColumn}>
                <b>Holding Company</b>
                <Form.Item
                  name='holdingCompany'
                  rules={[
                    {
                      required: false,
                      message: "Please enter holding company name",
                    },
                  ]}>
                  <Select>
                    {holdingCompanyList.map((company) => (
                      <Option key={company.id} value={company.id}>
                        {company.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* </Row> */}
            </Col>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={24} style={dataInputColumn}>
                  <b>Contact Number</b>
                  <Form.Item
                    name='contactNumber'
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact number",
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24} style={formCSS.inputCol}>
                  <span style={formCSS.inputHeader}> </span>
                  <Form.Item name='address'>
                    <b>Address</b>
                    <br />
                    <AutoComplete
                      style={{ width: "100%" }}
                      options={addressOptions?.map((a) => ({
                        value: a.address.label,
                        label: a.address.label,
                      }))}
                      value={savedaddress}
                      onChange={(e) => {
                        setSavedAddress(e);
                        lookupAddress(e);
                      }}
                      onSelect={(e) => {
                        setSavedAddress(e);
                        handleAddressSearch(e);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} style={formCSS.inputCol}>
                  <span style={formCSS.inputHeader}></span>
                  <b>Latitude</b>
                  <Input
                    value={latitude}
                    onChange={(e) => {
                      setLatitude(e.target.value);
                    }}
                  />
                </Col>

                <Col span={24} style={dataInputColumn}>
                  <b>Is Holding Company</b>
                  <Form.Item
                    name='isHoldingCompany'
                    rules={[
                      {
                        required: true,
                        message: "Please select holding company status",
                      },
                    ]}>
                    <Select>
                      {yesNo.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Add other fields as needed */}
          <Form.Item
            name='companyType'
            hidden={true}
            rules={[
              {
                required: true,
                message: "Please select company type",
              },
            ]}>
            <Select disabled>
              {companyTypes.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      )
    </>
  );
};

export default PortalSecureCompanyAddCompany;

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};

const formCSS = {
  inputCol: {
    padding: "8px",
  },
  inputHeader: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
};
