import { useLocation } from 'react-router-dom';
import { useContext, useState, useEffect, useMemo } from 'react';
import SubmenuFilter from '../../components/SubmenuFilter';
import Navbar from "../../components/Navbar";
import { DataCountContext, DataCountProvider } from '../../components/DataCountContext';
import { AiOutlinePlus, AiFillEdit, AiOutlineUpload } from 'react-icons/ai';
import { appSettings } from '../../AppSettings';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Input, Button } from 'antd';
import FloatingLabel from '../../components/FloatingLabel';
import EditBasicMechanicDetails from './EditBasicMechanicDetails';
import AddMechanic from './Mechanic/AddMechanic';


const MechanicDetails = () => {
    
    const location = useLocation();
    const companyId = location.state.breakdownId;
    const companyName = location.state.breakdownName;
    const dataCount = useContext(DataCountContext) ?? 5;
    const navigate = useNavigate();
    const [mechanicDetails, setMechanicDetails] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [mechanicName, setMechanicName] = useState('');
    const [mechanicNumber, setMechanicNumber] = useState('');
    const [mechanicEmail, setMechanicEmail] = useState('');
    const [flag,setFlag] = useState(false);
    const[editVisible, setEditVisble] = useState();
    const [mechanicId, setMechanicId] = useState();
    const[addVisible, setAddVisble] = useState();
    const [mechanicIdNumber, setMechanicIdNumber] = useState();
    
    const handleEditClose = () => {
        setEditVisble(false);
        setTimeout(() => {
            getMechanics();
          }, 1000);
     // getMechanics();
    }
    const handleAddClose = () => {
        setAddVisble(false);
        setTimeout(() => {
            getMechanics();
          }, 1000);
     // getMechanics();
    }


    const getMechanics = () => {
        if (companyId) {
            fetch(`${appSettings.apiUrl}/Company/GetSupplierMechanics/${companyId}`)
                .then(response => response.json())
                .then(data => {
                    setMechanicDetails(data);
                    setMechanicId(data.id);
                    console.log(data);
                })
                .catch(error => {
                    console.error('Error fetching mechanics:', error);
                });
        }
    };

    useEffect(() => {
        getMechanics();
    }, [flag])

    /*const columns = useMemo(
        () => [
            {
                name: 'id',
                selector: 'id',
                sortable: true,
                omit: true,
            },
            {
                name: 'Full Name',
                selector: 'fullName',
                sortable: true,
                omit: false,
                cell: (row) => <div>{row.fullName}</div>,
            },
            {
                name: 'Mobile Number',
                selector: 'mobileNumber',
                sortable: true,
                omit: false,
                cell: (row) => <div>{row.mobileNumber}</div>,
            },
            {
                name: 'Company Name',
                sortable: true,
                omit: false,
                cell: (row) => <div>{companyName}</div>,
            },
            {
                name: 'Edit',
                cell: (row) => (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            type="primary"
                            onClick={() => editUser(row)}
                        >
                            Edit Mechanic
                        </Button>
                    </div>
                ),
            },
        ],
        []
    );
    */

    const columns = [
        {
            name: 'Full Name',
            cell: (e) => (e.fullName),
            sortable: true,
            selector: (e) => (e.fullName)
        },
        {
            name: 'Mobile Number',
            selector: (row) => (row.mobileNumber),
            cell: (row) => (row.mobileNumber),
            sortable: true
        },
        {
            name: 'Edit',
            cell: (row) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                        type="primary"
                        onClick={() => editUser(row)}
                    >
                        Edit Mechanic
                    </Button>
                </div>
            ),
            sortable:false
        }
    ]


    async function editUser(row) {
        
        setMechanicId(row.id);
        setEditVisble(true);
        // navigate('/portal/secure/EditBasicMechanicDetails', {
        //     state: {
        //         Id: row.id,
        //         FullName: row.fullName,
        //         Email: row.email,
        //         MobileNumber: row.mobileNumber
        //     }
        // });
    }

    const handleFormSubmit = (values) => {
        setFlag(false);
        const mechanicModel = {
            name: mechanicName,
            number: mechanicNumber,
            email: mechanicEmail,
            companyId: companyId,
            idNumber: mechanicIdNumber
        };

        if (mechanicModel) {
            fetch(appSettings.apiUrl + '/Company/AddMechanic', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mechanicModel),
            })
                .then(response => response.json())
                .then(data => {
                    setMechanicName('');
                    setMechanicNumber('');
                    setMechanicEmail('');
                    setFlag(true);
                    setIsModalVisible(false);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    const showUserModal = () => {
        setIsModalVisible(true);
    };


    return (
        <div className="flex flex-col">
            <DataCountProvider>
                <div>
                    <Navbar />
                    <SubmenuFilter dataCount={dataCount} />
                    <div></div>
                </div>
            </DataCountProvider>

            <Modal
                title="Add Mechanic"
                open={isModalVisible}
              //  onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered
                className="pt-0 pb-0"
                height="80vh"
            >
                <Form onFinish={handleFormSubmit}>
                    <br />
                    <Form.Item className="mb-4">
                        <FloatingLabel
                            id="EnterName"
                            name="entername"
                            type="text"
                            value={mechanicName}
                            onChange={(e) => setMechanicName(e.target.value)}
                            placeholder="Enter Mechanic Name"
                            label="Enter Name"
                            className="relative ml-3"
                        />
                    </Form.Item>
                    <br />
                    <Form.Item className="mb-4">
                        <FloatingLabel
                            id="EnterName"
                            name="entername"
                            type="text"
                            value={mechanicNumber}
                            onChange={(e) => setMechanicNumber(e.target.value)}
                            placeholder="Enter Mechanic Number"
                            label="Enter Mechanic Number"
                            className="relative ml-3"
                        />
                    </Form.Item>
                    <br />
                    <Form.Item className="mb-4">
                        <FloatingLabel
                            id="EnterName"
                            name="entername"
                            type="text"
                            value={mechanicEmail}
                            onChange={(e) => setMechanicEmail(e.target.value)}
                            placeholder="Enter Mechanic Email"
                            label="Enter Mechanic   "
                            className="relative ml-3"
                        />
                    </Form.Item>
                    <br />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>


            <br />
            <div className='pt-2 pl-5' style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                    type="primary"
                    onClick={() => setAddVisble(true)}
                >
                    <span className="mr-2">Add Mechanic</span>
                </Button>
            </div>
            <br />

            <div className='pl-5 pr-5'>
                <DataTable
                    columns={columns}
                    data={mechanicDetails}
                    pagination={false}
                    striped={true}
                    highlightOnHover={true}
                    className=" border"
                    cellClassName="border-b border-[#f9787c] px-4 py-2"
                    headerClassName="bg-[#f9787c] text-white font-semibold"
                />
            </div>
{editVisible && mechanicId &&<EditBasicMechanicDetails visible={editVisible} onClose={handleEditClose} mechanicId={mechanicId}  />}
{addVisible &&<AddMechanic visible={addVisible} onClose={handleAddClose} supplierId={companyId}  />}
        </div>
    )
}

export default MechanicDetails;