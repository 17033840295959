import React, { useState, useEffect } from 'react';
import { ClockCircleOutlined, BellOutlined, SettingOutlined, FlagOutlined } from '@ant-design/icons';
import { Menu ,Badge} from 'antd';
import { appSettings } from '../../AppSettings';
import axios from 'axios';
import agent from '../../Utilities/API/agent';
import { useStore } from '../../Utilities/Stores/store';
const items = [
  {
    label: 'Active',
    key: 'Active',
    icon: <BellOutlined />,
  },
  {
    label: 'QA',
    key: 'QA',
    icon: <FlagOutlined />,
  },
  {
    label: 'Delayed',
    key: 'Delayed',
    icon: <ClockCircleOutlined />,
    disabled: false,
  },
  {
    label: 'Workshop',
    key: 'Workshop',
    icon: <SettingOutlined />,
  },
];

const MyMenu = ({ delayed, setDelayed, active, setActive, qa, setQA, workshop, setWorkshop, breakdowns, setBreakdowns }) => {
  const [current, setCurrent] = useState('');
  const [countData, setCountData] = useState([]);

  useEffect(() => {

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const loginId = currentUser.userId;

    if(loginId)
    {
        fetchData('/Breakdowns/GetMyBreakdownCount', setCountData, loginId);

        const interval = setInterval(() => {
            fetchData('/Breakdowns/GetMyBreakdownCount', setCountData, loginId);
        }, 30000); 
    
        return () => {
          clearInterval(interval); 
        };
    }

  }, []);

  const getCountByStatus = (status) => {
    const countEntry = countData.find((entry) => entry.status === status);
    return countEntry ? countEntry.count : 0;
  };

 const fetchData = (url, setter, parameter = null) => {
  const apiUrl = appSettings.apiUrl + url + (parameter ? `/${parameter}` : '');

  axios.get(apiUrl)
    .then(response => {
      setter(response.data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
};


  const onClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const loginId = currentUser.userId;
    if (selectedItem) {
      switch (e.key) {
        case 'Active':
          fetchData('/Breakdowns/MyActiveBreakdowns', setBreakdowns, loginId);
          break;
        case 'QA':
          fetchData('/Breakdowns/MyQABreakdowns', setBreakdowns, loginId);
          break;
        case 'Delayed':
          fetchData('/Breakdowns/MyDelayedBreakdowns', setBreakdowns, loginId);
          break;
        case 'Workshop':
          fetchData('/Breakdowns/MyWorkShopBreakdowns', setBreakdowns, loginId);
          break;
        default:
          break;
      }
    }
    setCurrent(e.key);
  };

    return (
      <Menu mode="horizontal" onClick={onClick} selectedKeys={[current]}>
      {items.map((item) => (
        <Menu.Item key={item.key} icon={item.icon} style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1 }}>{item.label}</span>
          <Badge count={getCountByStatus(item.label)} showZero style={{marginTop:'-3px'}}/>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default MyMenu;