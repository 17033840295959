import { useEffect, useState } from "react";
import { appSettings } from "../../../AppSettings";
import Navbar from "../../../components/Navbar";
import SubmenuFilter from "../../../components/SubmenuFilter";
import { Select, AutoComplete, Button, Modal, Upload, Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import ClockLoader from "react-spinners/ClockLoader";
import { AiOutlinePlus, AiFillEdit, AiOutlineUpload } from "react-icons/ai";
import { BiCheckDouble } from "react-icons/bi";
import FloatingLabel from "../../../components/FloatingLabel";
import * as XLSX from "xlsx/xlsx.mjs";
import { useNavigate } from "react-router-dom";
import { exportToExcel } from "../../../components/utilities/exportToExcel";
import validateExcelData from "../../../components/utilities/Api/validateExcelData";
import updateExcelData from "../../../components/utilities/Api/updateExcelData";
import AddVehicle from "./AddVehicle";
import EditVehicle from "./EditVehicle";
import axios from 'axios';
import React from 'react';
import  {Container} from 'react-bootstrap';

export default function ListVehiclesFromCompany() {
 

  const navigate = useNavigate();
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const columns = [
     {
       name: "id",
       selector: "id",
       sortable: true,
       omit: true
     },
     {
       name: "Registration",
       selector: "registration",
       sortable: true,
       cell: (e) => <div>{e.registration}</div>
     },
     {
       name: "Make",
       selector: "make",
       sortable: true,
       cell: (e) => <div>{e.make}</div>
     },
     {
       name: "Model",
       selector: "model",
       sortable: true,
       cell: (e) => <div>{e.model}</div>
     },
     {
       name: "Fleet",
       selector: "fleet",
       sortable: true,
       cell: (e) => <div>{e.fleet}</div>
     },
    {
      name: "Lease Type",
      selector: "leaseType",
      sortable: true,
      cell: (e) => <div>{e.leaseType}</div>
    },
    {
      name: "Vehicle Type",
      selector: "trailer",
      sortable: true,
      cell: (e) => <div>{e.trailer == 1 ? "Trailer" : "Self Propelled "}</div>,
    },
    {
      name: "Edit Vehicle",
      cell: (row) => (
        <div>
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2"
            onClick={() => editVehicleData(row)}
          >
            <span className="mr-2">Edit</span>
            <AiFillEdit size={20} />
          </button>
        </div>
      ),
    },
    {
      name: "Equipment",
      cell: (row) => (
        <div>
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2"
            onClick={() => viewVehicleEquipment(row)}
          >
            <span className="pl-0 pr-0">View</span>
            <AiFillEdit size={20} />
          </button>
        </div>
      ),
    },
  ];

  const [customerVehicleList, setCustomerVehicleList] = useState([]);
  const [supplierVehicleList, setSupplierVehicleList] = useState([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companyType, setCompanyType] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [excelDataLoading, setExcelDataLoading] = useState(false);

  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [regNumber, setRegNumber] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [fleet, setFleet] = useState("");
  const [vin, setVin] = useState("");
  const [leaseType, setLeaseType] = useState("");
  const [isTrailer, setIsTrailer] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [year, setYear] = useState("");
  const [id, setId] = useState("");

  const [constCenterName, setConstCenterName] = useState("");
  const [constCenterNumber, setConstCenterNumber] = useState("");

  const [vehicleAddedFlag, setVehicleAddedFlag] = useState(false);
  const [vehicleEditedFlag, setVehicleEditedFlag] = useState(false);
  const [vehicleListAdded, setVehicleListAdded] = useState(false);
  const [excelDataLoaded, setExcelDataLoaded] = useState(false);
  const [rowAddedMessage, setRowAddedMessage] = useState("");
  const [fileData, setFileData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [combinedVehicleList, setCombinedVehicleList] = useState([]);

  const [oldVehicle, setOldVehicle] = useState([]);
  const [newVehicle, setNewVehicle] = useState([]);
  const [modifiedVehicle, setModifiedVehicle] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [vehicleClass, setVehicleClass] = useState([]);
  const [equipmentType, setEquipmentType] = useState([]);
  const [equipmentSubType, setEquipmentSubType] = useState([]);
  const [addVehicle, setAddVehicle] = useState();
  const [editVehicle, setEditVehicle] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  

  const selectCompanyTypeHandler = (value) => {
    if (value === "0") {
      setAutocompleteOptions(
        customerVehicleList.map((v) => ({ key: v.id, value: v.name }))
      );
      setCompanyType(0);
      setSelectedCompany("");
      setVehicleList([]);
      setIsDataAvailable(false);
    } else if (value === "1") {
      setAutocompleteOptions(
        supplierVehicleList.map((v) => ({ key: v.id, value: v.name }))
      );
      setCompanyType(1);
      setSelectedCompany("");
      setVehicleList([]);
      setIsDataAvailable(false);
    } else {
      setAutocompleteOptions([]);
      setCompanyType("");
      setSelectedCompany("");
      setVehicleList([]);
      setIsDataAvailable(false);

    }
  };

  function editVehicleData(row) {
    setSelectedVehicle(row.id);
    setEditVehicle(true);
    setIsModalVisible(true);
    setId(row.id);
    setRegNumber(row.registration);
    setMake(row.make);
    setModel(row.model);
    setFleet(row.fleet);
    setVin(row.vin);
    setLeaseType(row.leaseType);
    setIsTrailer(row.trailer === 1);
    setCompanyName(row.companyName);
    setYear(row.year);
    setConstCenterName(row.costCentreName);
    setConstCenterNumber(row.costCentreNumber);
    setVehicleClass(row.vehicleClass);
    setEquipmentType(row.equipmentType);
    setEquipmentSubType(row.equipmentSubType);
    setModalMode("Edit");
  }

  function viewVehicleEquipment(row) {
    navigate("/portal/secure/VehicleEquipmentList", {
      state: {
        vehicleId: row.id,
        fleetNo: row.fleet,
      },
    });
  }

  useEffect(() => {
    if (vehicleAddedFlag || vehicleEditedFlag || vehicleListAdded) {
      getVehicles();
      setVehicleAddedFlag(false);
      setVehicleEditedFlag(false);
    }
  }, [vehicleAddedFlag, vehicleEditedFlag]);

  useEffect(() => {
    fetch(appSettings.apiUrl + "/Company/getallcompanies")
      .then((response) => response.json())
      .then((data) => {
        const customerVehicles = data.filter(
          (item) => item.companyTypeDescription === "CUSTOMER"
        );
        const supplierVehicles = data.filter(
          (item) => item.companyTypeDescription === "SUPPLIER"
        );

        setCustomerVehicleList(customerVehicles);
        setSupplierVehicleList(supplierVehicles);
      })
      .catch((error) => {
        console.error("Error fetching active breakdowns:", error);
      });

    setFileData("");
  }, []);

  const getVehicles = () => {
    setIsLoading(true);
    fetch(
      `${appSettings.apiUrl}/VehicleList/getvehiclesbycompanyid/${selectedCompany}`
    )
      .then((response) => response.json())
      .then((data) => {
        setVehicleList(data);
        setIsLoading(false);
        console.log(data);
        const dataAvailable =
          selectedCompany !== "" && autocompleteOptions.length > 0;
        setIsDataAvailable(dataAvailable);
      })
      .catch((error) => {
        console.error("Error fetching vehicles:", error);
      });
  };

const handleAddVehicle = () => {
    setAddVehicle(true);
}

  const handleOk = () => {
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    const userId = currentUser.id;
    var state = false;

    if (isTrailer) {
      state = true;
    } else {
      state = false;
    }

    const vehicleSubmitModel = {
      id,
      selectedCompany,
      regNumber,
      make,
      model,
      fleet,
      state,
      vin,
      leaseType,
      companyName,
      companyType,
      year,
      userId,
      constCenterName,
      constCenterNumber,
    };
    fetch(appSettings.apiUrl + "/VehicleList/AddNewVehicleByCompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(vehicleSubmitModel),
    })
      .then((response) => response.json())
      .then((data) => {
        setRegNumber("");
        setMake("");
        setModel("");
        setFleet("");
        setIsTrailer("");
        setVin("");
        setYear("");
        setLeaseType("");
        setConstCenterName("");
        setConstCenterNumber("");
        setRowAddedMessage("Vehicle has been added successfully");
        setVehicleAddedFlag(true);
      })
      .catch((error) => {
        console.error(error);
      });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // setRegNumber("");
    // setMake("");
    // setModel("");
    // setFleet("");
    // setIsTrailer("");
    // setIsModalVisible(false);
    setAddVehicle(false);
    setEditVehicle(false);
  };

  // const handleEditVehicle = () => {
  //   const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  //   const userId = currentUser.id;

  //   var state = false;

  //   if (isTrailer) {
  //     state = true;
  //   } else {
  //     state = false;
  //   }

  //   const vehicleSubmitModel = {
  //     id,
  //     selectedCompany,
  //     regNumber,
  //     make,
  //     model,
  //     fleet,
  //     state,
  //     vin,
  //     leaseType,
  //     companyName,
  //     companyType,
  //     year,
  //     userId,
  //     vehicleClass,
  //     equipmentType,
  //     equipmentSubType,
  //   };

  //   fetch(appSettings.apiUrl + "/VehicleList/EditVehicle", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(vehicleSubmitModel),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setRegNumber("");
  //       setMake("");
  //       setModel("");
  //       setFleet("");
  //       setIsTrailer("");
  //       setVin("");
  //       setYear("");
  //       setLeaseType("");
  //       setRowAddedMessage("Vehicle has been edited successfully");
  //       setVehicleEditedFlag(true);
  //       setIsModalVisible(false);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const handleUploadOK = async () => {
    setIsDataUpdated(true);
    setUploadModalVisible(false);
    const success = await updateExcelData(
      combinedVehicleList,
      selectedCompany,
      companyType
    )
      .then((data) => {
        setIsDataUpdated(false);
        
      })
      .catch((error) => {
        setIsDataUpdated(false);
       
      });
      
  };


  const handleUploadCancel = () => {
    setCombinedVehicleList([]);
    setUploadModalVisible(false);
    setFileData(null);
    setVehicleListAdded(true);
    setExcelDataLoaded(false);
  };

  const frontendUpload = (file, fileList) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = [
        "CONTRACT",
        "VEHICLECLASS",
        "EQUIPMENTTYPE",
        "EQUIPMENTSUBTYPE",
        "REGNO",
        "VINNUMBER",
        "FLEETNO",
        "MAKE",
        "MODEL",
        "YEAR",
        "TYPE",
        "WARRANTYEXPIRYDATE",
        "WARRANTYEXPIRYMILEAGE",
        "WARRANTYEXPIRYEXCLUSIONS",
        "PREFERREDSUPPLIER",
      ];

      const rows = parsedData.slice(6);

      const tableData = rows
        .map((row) =>
          headers.reduce((acc, curr, index) => {
            if (
              curr === "YEAR" ||
              curr === "FLEETNO" ||
              curr === "VINNUMBER" ||
              curr === "COSTCENTRENAME" ||
              curr === "COSTCENTRENUMBER" ||
              curr === "WARRANTYEXPIRYDATE" ||
              curr === "REGNO" ||
              curr === "VEHICLECLASS"
            ) {
              const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
              console.log(row[index]);
              if (
                curr === "WARRANTYEXPIRYDATE" &&
                row[index] &&
                dateRegex.test(row[index])
              ) {
                
                const dateParts = row[index].split("/");
                const year = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1], 10);
                const day = parseInt(dateParts[2], 10);

                console.log("Year:", year);
                console.log("Month:", month);
                console.log("Day:", day);

                if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                  const inputDate = new Date(Date.UTC(year, month - 1, day));
                  console.log("Input Date:", inputDate);

                  acc[curr] = inputDate.toISOString();
                } else {
                  acc[curr] = new Date("0001-01-01").toISOString();
                }
              } else if (curr === "WARRANTYEXPIRYDATE") {
                acc[curr] = new Date("0001-01-01").toISOString();
              } else if (curr === "TYPE" && row[index] === "YES") {
                acc[curr] = 1;
              } else if (
                curr === "REGNO" &&
                row["VEHICLECLASS"] !== "Trailer" &&
                row["VEHICLECLASS"] !== "Self-Propelled" &&
                row[index] === "N/A"
              ) {
                acc[curr] = "NoReg";
              } else {
                acc[curr] = (row[index] || "N/A").toString();
              }
            } else if (curr === "TYPE") {
              
              acc[curr] = (row[index] || "N/A").toString();
            } else {
              acc[curr] = (row[index] || "N/A").toString();
            }
            return acc;
          }, {})
        )
        .filter((row) => row["REGNO"] !== "N/A");

      const jsonTable = { tableData };
      setFileData(jsonTable);
    };
    reader.readAsBinaryString(file);
  };

  const handleExcelDataValidation = async () => {
    try {
      const token = currentUser.jwtToken;

      const vehicleExcelValidationModel = fileData.tableData;

      const requestBody = {
        vehicleExcelValidationModel: vehicleExcelValidationModel,
        selectedCompany: selectedCompany,
      };

      await validateExcelData(
        token,
        requestBody,
        setCombinedVehicleList,
        setNewVehicle,
        setOldVehicle,
        setModifiedVehicle,
        setExcelDataLoaded
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleExportToExcel = () => {
    exportToExcel(newVehicle, oldVehicle, modifiedVehicle);
  };

  const filteredVehicles = vehicleList.filter((vehicle) => {
    return (
      vehicle.registration.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.make.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.fleet.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vehicle.leaseType.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // const filteredVehicles = vehicleList;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  }; 

  const [templateFileName, setTemplateFileName] = useState(''); 

    const downloadTemplate = async () => {
        await fetch(`${appSettings.apiUrl}/Vehicles/downloadfile?fileName=Template.xlsx`, {
            method: 'GET',
            responseType: 'blob'
        })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            setTemplateFileName('Template.xlsx');
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Template.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
        .catch(error => console.error('Error downloading template:', error));
    };    
  
  

  return (
    <div>
      <Navbar />

      {currentUser ? (
        <>
          <SubmenuFilter />
          <br />
          <div className="flex justify-center">
            <div className="w-3/5 flex" style={{ height: "31px" }}>
              <div className="w-2/5 pr-2">
                <Select
                  defaultValue="-1"
                  style={{
                    width: "100%",
                  }}
                  onChange={selectCompanyTypeHandler}
                  options={[
                    {
                      value: "-1",
                      label: "Select Company Type",
                    },
                    {
                      value: "0",
                      label: "Customer",
                    },
                    {
                      value: "1",
                      label: "Supplier",
                    },
                  ]}
                />
              </div>
              <div className="w-2/5 pr-2">
                {customerVehicleList.length > 0 &&
                supplierVehicleList.length > 0 ? (
                  <AutoComplete
                    options={autocompleteOptions}
                    placeholder="Company"
                    allowClear={true}
                    style={{ width: "100%" }}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSelect={(value, option) => {
                      setSelectedCompany(option.key);
                      setCompanyName(value);
                    }}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </div>
              <div className="w-1/5 flex items-center">
                <button
                  className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded ${
                    !selectedCompany ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={getVehicles}
                  disabled={!selectedCompany || isLoading}
                  style={{ width: "150px", marginLeft: "20px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  {isLoading ? (
                    <ClipLoader
                      color="#00FF00"
                      loading={true}
                      size={18}
                      css="display: inline-block; vertical-align: middle; margin-right: 8px;"
                    />
                  ) : (
                    "Get Vehicles"
                  )}
                </button>
                
                {/* Download template button */}
                <button
                  className={`bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded`}
                  style={{ width: "150px", marginLeft: "20px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center"}}
                  onClick={downloadTemplate}
                  
                >
                  Download Template
                  <AiOutlineUpload size={30} />
                </button>
              </div>
            </div>
          </div>
          <br />
          <div className="flex justify-center">
            <div className="w-4/5">
              {isDataAvailable && (
                <div className="flex">
                  <button
                    className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2"
                    onClick={() => {
                    //  setIsModalVisible(true);
                      handleAddVehicle();
                      setModalMode("Add");
                    }}
                  >
                    <span className="mr-2">Add New Vehicle</span>
                    <AiOutlinePlus size={20} />
                  </button>

                  <button
                    className="bg-blue-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 ml-2"
                    onClick={() => {
                      setUploadModalVisible(true);
                    }}
                  >
                    <span className="mr-2">Upload Document</span>
                    <AiOutlineUpload size={20} />
                  </button>

                  <div className="flex justify-end ml-3">
                    {isDataUpdated ? (
                      <ClockLoader color="#123abc" loading={true} size={35} />
                    ) : null}
                  </div>
                </div>
              )}
              {addVehicle && (
                <AddVehicle
                  visible={addVehicle}
                  onClose={handleCancel}
                  selectedCompany={selectedCompany}
                  InCompanyName={companyName}
                  InCompanyType={companyType}
                />
              )}
              {editVehicle && selectedVehicle && (
                <EditVehicle
                visible={editVehicle}
                onClose={handleCancel}
                selectedCompany={selectedCompany}
                InCompanyName={companyName}
                InCompanyType={companyType}
                vehicleId={selectedVehicle}
                />
              )}

              <Modal
                title={"Upload Document"}
                open={isUploadModalVisible}
                onOk={handleUploadOK}
                width={800}
                centered
                styles={{ height: "calc(100vh - 450px)", overflowY: "auto" }}
                onCancel={handleUploadCancel}
                cancelButtonProps={{
                  style: {
                    backgroundColor: "red",
                    border: "none",
                    color: "white",
                  },
                }}
                okButtonProps={{
                  style: {
                    backgroundColor: "green",
                    color:
                      combinedVehicleList.length === 0 ? "white" : "inherit",
                  },
                  disabled: combinedVehicleList.length === 0,
                }}
              >
                <div>
                  <div>
                    <Upload
                      beforeUpload={(file, fileList) =>
                        frontendUpload(file, fileList)
                      }
                    >
                      <Button
                        icon={<UploadOutlined />}
                        style={{ width: "752px" }}
                      >
                        Click to Upload
                      </Button>
                    </Upload>
                  </div>

                  <br />
                  {fileData ? (
                    <div className="flex justify-center">
                      {excelDataLoaded ? (
                        <ClipLoader color="#000000" loading={true} size={20} />
                      ) : (
                        <button
                          className="bg-red-500 text-white font-bold py-2 px-2 rounded flex items-center w-full"
                          onClick={handleExcelDataValidation}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span className="mr-2">Validate Excel Data</span>
                          <BiCheckDouble size={20} />
                        </button>
                      )}
                    </div>
                  ) : null}

                  <br />

                  {combinedVehicleList.length > 0 ? (
                    <>
                      {newVehicle.length > 0 && (
                        <div className="flex justify-center w-full">
                          Number of New Vehicles:{" "}
                          <span className="font-bold text-green-800">
                            {newVehicle.length}
                          </span>
                        </div>
                      )}
                      {oldVehicle.length > 0 && (
                        <div className="flex justify-center w-full">
                          Number of Old Vehicles:{" "}
                          <span className="font-bold text-red-800">
                            {oldVehicle.length}
                          </span>
                        </div>
                      )}
                      {modifiedVehicle.length > 0 && (
                        <div className="flex justify-center w-full">
                          Number of Modified Vehicles:{" "}
                          <span className="font-bold text-blue-500">
                            {modifiedVehicle.length}
                          </span>
                        </div>
                      )}
                      <br />
                      <div className="flex justify-center">
                        <button
                          onClick={handleExportToExcel}
                          className="bg-blue-500 text-white py-2 px-2 rounded items-center w-full"
                        >
                          Export to Excel
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-center w-full">
                      <span className="font-bold text-blue-500">
                        No new data.
                      </span>
                    </div>
                  )}
                </div>
              </Modal>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
              />
              <br />
              <br />
              <DataTable
                columns={columns}
                data={filteredVehicles}
                pagination={true}
                striped={true}
                highlightOnHover={true}
                className="bg-white border border-[#f9787c] rounded-lg"
                cellClassName="border-b border-[#f9787c] px-4 py-2"
                headerClassName="bg-[#f9787c] text-white font-semibold"
              />
            </div>
          </div>
        </>
      ) : (
        <h3>Unauthorized Access</h3>
      )}
    </div>
  );
}
