import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
  Select,
  Col,
  Row,
  Alert,
} from "antd";

import agent from "../../../../Utilities/API/agent";


const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};
const sectionHeader = {
  fontSize:'18pt',
  fontWeight:'bold',
  borderBottom:'solid 1px #000',
  paddingBottom: '8px',
  paddingTop:'16px'
}
const AddSubCategory = ({ visible, onClose, breakdownCategoryId }) => {
  const [form] = Form.useForm();
  const [categories, setCategoriesList] = useState([]);
  const [modalVisible, setModalVisible] = useState(visible);
  const [isSaving, setIsSaving] = useState(false);
  const [active, setActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fullNameChecked, setFullNameChecked] = useState(false);

  const handleSubmit = () => {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
        const { subCategoryName } = values;
        const selectedCategory = categories.find(
          (category) => category.name === subCategoryName
        );

        if (selectedCategory) {
          setErrorMessage("Subcategory name already exists!");
          setIsSaving(false);
          return;
        }

        const requestData = {
          id: "",
          categoryName: subCategoryName,
          active: fullNameChecked,
          breakdownCategoryId: breakdownCategoryId,
        };

        agent.Settings.addSubCategory(requestData)
          .then((result) => {
            setIsSaving(false);
            if (!result) {
              setErrorMessage("Sub Category name already exists!");
            } else {
              // Close the modal if the category was added successfully
              onClose();
            }
          })
          .catch((error) => {
            console.error("Error adding category:", error);
            setIsSaving(false);
          });
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  // const handleActiveChange = (checked) => {
  //   setActive(checked);
  // };

  const handleFullNameChange = (e) => {
    setFullNameChecked(e.target.checked);
  };

  const handleCancel = () => {
    setModalVisible(false);
    onClose();
  };

  useEffect(() => {
    setModalVisible(visible);
    const fetchData = async () => {
      const data = await agent.Settings.getAllCategories();
      setCategoriesList(data);
    };
    fetchData();
  }, [visible]);

  return (
    <Modal
    open={modalVisible}
      onCancel={handleCancel}
      width={"30%"}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={isSaving} onClick={handleSubmit}>
          SAVE
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row style={sectionHeader}>
          <Col span="24">ADD SUBCATEGORY</Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24} style={dataInputColumn}>
                <b>Subcategory Name</b>
                <Form.Item
                  name="subCategoryName"
                  rules={[
                    { required: true, message: "Please enter subcategory name" },
                  ]}
                >
                  <Input placeholder="Enter Subcategory Name" />
                </Form.Item>
              </Col>
              <Col span={24} style={{ ...dataInputColumn, marginBottom: "8px" }}>
              <span style={{ fontWeight: 'bold' }}>{fullNameChecked ? "Active" : "Inactive"}</span>
              <br />
              <Checkbox onChange={handleFullNameChange} />
            </Col>
            </Row>
          </Col>
        </Row>
        {errorMessage && (
          <Row>
            <Col span={24}>
              <Alert message={errorMessage} type="error" showIcon />
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default AddSubCategory;

