import { Container, Row, Col, Form, FloatingLabel, Button, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {useState, useffect} from 'react';
import { Result } from 'antd-mobile';
import { appSettings } from '../../AppSettings';

export default function MobileAuthLogin() {
    
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [statusUsername, setStatusUsername] = useState('');
    const [result, setResult] = useState();
    
    
    const  performLogin = async () => {
        const loginModel = {
            username: username,
            password: password
        };

        const response = await fetch(appSettings.apiUrl + '/auth/performlogin', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(loginModel)
        });

        
        if (response.status === 204 ) {
            setResult(<Result status='error' title='INVALID CREDENTIALS'  />);
        }else{
            const json = await response.json();
            if (json.id === "") {
                setResult(<Result status='error' title='INVALID CREDENTIALS'  />);
            }else{
                sessionStorage.setItem('currentUser', JSON.stringify(json));
                setResult(<Result status='success' title='LOGIN SUCCESSFUL'  />);
                navigate('/mobile/secure/map');
            }
        }
        
        
    }

    return (
        <Container>
            <Row>
                <Col>
                    <img src='../../rnr_logo.png' style={{width: 100 + '%'}} />
                </Col>
            </Row>
            <Row lg='1'>
                <Col>
                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup hasValidation>
                                    <FloatingLabel
                                        controlId="username"
                                        label="Username">
                                        <Form.Control type="text" placeholder="Username" value={username} onChange={(e) => {setUsername(e.target.value)}} />
                                    </FloatingLabel>    
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row  className="mb-3">
                            <Col>
                                <FloatingLabel
                                    controlId="password"
                                    label="Password">
                                    <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => {setPassword(e.target.value)}} />
                                </FloatingLabel>    
                            </Col>
                        </Row>
                        <Row  className="mb-3">
                            <Col className="d-grid">
                                <Button variant="primary" size="lg" onClick={() => {performLogin()}}>LOGIN</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    {result}
                </Col>
            </Row>
        </Container>
        
    )
}