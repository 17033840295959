import { useContext, useState, useEffect } from "react";
import SubmenuFilter from "../../components/SubmenuFilter";
import Navbar from "../../components/Navbar";
import {
  DataCountContext,
  DataCountProvider,
} from "../../components/DataCountContext";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { appSettings } from "../../AppSettings";
import { AiFillEdit , AiOutlinePlus} from "react-icons/ai";
import useCaseInsensitiveSort from "../../components/utilities/useCaseInsensitiveSort";
import AddSupplier from "./Supplier/AddSupplier";
import EditSupplier from "./Supplier/EditSupplier";
import { Link } from "react-router-dom";
import agent from '../../Utilities/API/agent';
import PortalSecureCompanyEditCompany from '../Secure/Company/EditCompany'
import PortalSecureCompanyAddCompany from "./Company/AddCompany";


const Supplier = () => {
  const dataCount = useContext(DataCountContext) ?? 5;
  const navigate = useNavigate();

  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const caseInsensitiveSortFullName = useCaseInsensitiveSort("fullName");
  const [addSupplierVisble, setAddSupplierVisble] = useState();
  const [editSupplierVisble, setEditSupplierVisble] = useState();
  const [addCompany, setAddCompany] = useState();
  const [editCompanyVisble, setEditCompanyVisble] = useState();
  const [supplierId, setSupplierId] = useState();
  const [companyId, setCompanyId] = useState();

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      omit: true,
    },
    {
      name: "Company Name",
      selector: (row) => (
        <Link onClick={() => handleCompanyClick(row)}>{row.companyName}</Link>
      ),
      sortable: true,
      omit: false,
      width: "40%",
    },
    {
      name: "Full Name",
      selector: (row) => (
        <Link onClick={() => handleRowClick(row)}>{row.fullName}</Link>
      ),
      sortField: "fullName",
      sortable: true,
      sortFunction: caseInsensitiveSortFullName,
      omit: false,
      width: "40%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <button
            className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mb-2 mr-2"
            onClick={() => editMechanics(row)}
          >
            <span className="mr-2">Mechanics</span>
            <AiFillEdit size={20} />
          </button>
        </div>
      ),
    },
  ];
  const handleAddSupplierClick = () => {
    setAddSupplierVisble(true);
  };
  const handleCloseAddSupplierClick = () => {
    setAddSupplierVisble(false);
  };
  const handleCloseCompany = () => {
    setAddCompany(false);
  };
  const handleAddCompanydClick = () => {
    
    setAddCompany(true);
  }
  const handleCloseEditSupplierClick = () => {
    setEditSupplierVisble(false);
  };
  const handleRowClick = (row) => {
    setSupplierId(row.id);
    setEditSupplierVisble(true);
  };

  
  const handleCompanyClick = async (row) => {
    const supplier = await agent.Supplier.getSupplier(row.id);
    setCompanyId(supplier.companyId);
    setEditCompanyVisble(true);
  };
  const handleCloseCompanyClick = () => {
    setEditCompanyVisble(false);
  };
  function editMechanics(row) {
    navigate("/portal/secure/MechanicDetails", {
      state: {
        breakdownId: row.id,
        breakdownName: row.companyName,
      },
    });
  }

  const [rnrCompanies, setRnrCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  async function fetchHoldingCompanies() {
    try {
      const response = await fetch(
        appSettings.apiUrl + "/Company/GetAllSuppliers"
      );
      const data = await response.json();

      setRnrCompanies(data);
    } catch (error) {
      console.error("Error fetching holding companies:", error);
    }
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    fetchHoldingCompanies();
  }, []);

  const filteredSuppliers = rnrCompanies.filter((company) => {
    return company.fullName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <>
      <div className="flex flex-col">
        <DataCountProvider>
          <div>
            <Navbar />
            {currentUser ? (
              <>
                <SubmenuFilter dataCount={dataCount} />
              </>
            ) : null}
            <div></div>
          </div>
        </DataCountProvider>
        <br />

        {currentUser ? (
          <>
            <div className="pl-5 pr-5 pb-5">
              <div className="flex  mb-2">
                <button
                  className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mr-2"
                  onClick={handleAddSupplierClick}
                >
                  <span className="mr-2">Add User</span>
                  <AiOutlinePlus />
                </button>
              </div>
              <div className="flex  mb-2">
                <button
                  className="bg-green-500 text-white font-bold py-2 px-2 rounded flex items-center mr-2"
                  onClick={handleAddCompanydClick}
                >
                  <span className="mr-2">Add Company</span>
                  <AiOutlinePlus />
                </button>
              </div>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full py-2.5 px-8 rounded-full outline-none border border-gray-300 hover:border-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="pl-5 pr-5">
              <DataTable
                columns={columns}
                data={filteredSuppliers}
                pagination={true}
                striped={true}
                highlightOnHover={true}
                className="bg-white border border-[#f9787c] rounded-lg"
                cellClassName="border-b border-[#f9787c] px-4 py-2"
                headerClassName="bg-[#f9787c] text-white font-semibold"
                defaultSortFieldId={3}
                // onRowClicked={handleRowClick}
                defaultSortAsc={false}
              />
            </div>
          </>
        ) : (
          <h3>Unauthorized Access</h3>
        )}
      </div>
      {addSupplierVisble && (
        <AddSupplier
          visible={addSupplierVisble}
          onClose={handleCloseAddSupplierClick}
        />
      )}
      {editSupplierVisble && (
        <EditSupplier
          visible={editSupplierVisble}
          onClose={handleCloseEditSupplierClick}
          supplierId={supplierId}
        />
      )}
      {editCompanyVisble && companyId &&(
        <PortalSecureCompanyEditCompany
          visible={editCompanyVisble}
          onClose={handleCloseCompanyClick}
          companyId={companyId}
        />
      )}
      {addCompany  &&(
        <PortalSecureCompanyAddCompany
          visible={addCompany}
          onClose={handleCloseCompany}
          type={1}
        />
      )}
    </>
  );
};

export default Supplier;
