import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Select, Input, Button, Row, Col, Modal, AutoComplete } from "antd";
import { appSettings } from "../../AppSettings";
import {
  ComponentsVehiclesVehicleSelectByRegistration as VehicleRegistrationSelect,
  ComponentsVehiclesVehicleSelectByFleetNumber as VehicleFleetSelect,
} from "../vehicles/VehicleSelect";
import {
  addressSearch,
  lookupAddress,
  reverseGeoSearch,
} from "../../Utilities/HereMaps/AddressUtils";
import BreakdownRequestView from "../../Portal/Secure/Breakdowns/BreakdownRequestView";

export function ComponentsBreakdownsBreakdownEditForm({ breakdownId, onClose, refreshData }) {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  //define value fields for the form
  const [companyName, setCompanyName] = useState();
  const [companyContactNumber, setCompanyContactNumber] = useState();
  const [driverName, setDriverName] = useState();

  const [driverCell, setDriverCell] = useState();
  const [breakdownAddress, setBreakdownAddress] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [caseNumber, setCaseNumber] = useState();
  const [category, setCategory] = useState();
  const [subcategory, setSubCategory] = useState();
  const [description, setDescription] = useState();
  const [cause, setCause] = useState();
  const [correction, setCorrection] = useState();
  const [truckFleetNumber, setTruckFleetNumber] = useState();
  const [truckMake, setTruckMake] = useState();
  const [truckModel, setTruckModel] = useState();
  const [truckYear, setTruckYear] = useState();
  const [truckRegistrationNumber, setTruckRegistrationNumber] = useState();
  const [truckVinNumber, setTruckVinNumber] = useState();
  const [truckWarranty, setTruckWarranty] = useState();
  const [truckMileage, setTruckMileage] = useState();
  const [trailer1FleetNumber, setTrailer1FleetNumber] = useState("");
  const [trailer1Make, setTrailer1Make] = useState();
  const [trailer1Model, setTrailer1Model] = useState();
  const [trailer1Year, setTrailer1Year] = useState();
  const [trailer1RegistrationNumber, setTrailer1RegistrationNumber] =
    useState();
  const [trailer2VinNumber, setTrailer2VinNumber] = useState();
  const [trailer2FleetNumber, setTrailer2FleetNumber] = useState("");
  const [trailer2Make, setTrailer2Make] = useState();
  const [trailer2Model, setTrailer2Model] = useState();
  const [trailer2Year, setTrailer2Year] = useState();
  const [trailer2RegistrationNumber, setTrailer2RegistrationNumber] =
    useState();
  const [trailer1VinNumber, setTrailer1VinNumber] = useState();
  const [vehicleLeaseType, setVehicleLeaseType] = useState();
  
  const [reportedBy, setReportedBy] = useState();
  const [load, setLoad] = useState();
  const [customerQuoteNumber, setCustomerQuoteNumber] = useState();
  const [dealerQuoteNumber, setDealerQuoteNumber] = useState();
  const [supplierQuoteNumber, setSupplierQuoteNumber] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const [isTrailerFault, setIsTrailerFault] = useState(null);
  const [breakdownType, setBreakdownType] = useState();
  const [handoverNotes, setHandoverNotes] = useState();
  const [initiatorCell, setInitiatorCell] = useState();
  const [initiatorName, setInitiatorName] = useState();
  const [addressOptions, setAddressOptions] = useState([]);
  const [breakdownReference, setBreakdownReference] = useState('');
  const [breakdownRequestReference, setBreakdownRequestReference] =useState()
  const [showModal, setShowModal] = useState(false);
  const [breakdownDisplayInformation, setBreakdownDisplayInformation] =
    useState({});
    const [breakdownRequestInformation, setBreakdownRequestInformation] =
    useState({});
  const [breakdownCategoryList, setBreakdownCategoryList] = useState([]);
  const [breakdownSubCategoryList, setBreakdownSubCategoryList] = useState([]);
  const [causeList, setCauseList] = useState([]);
  //UI handling
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [isBreakdownModalOpen, setIsBreakdownModalOpen] = useState(false);
  

  // const handleBreakdownRequestClick = async (breakdownRef) => {
  //   debugger;
  //   const results = await fetch(
  //     `${appSettings.apiUrl}/breakdownView/GetBreakdownRequestByRef/${breakdownRef}`);
  //     debugger;
  //     const json = await results.json();
  //     setBreakdownRequestInformation(json);
  //   setBreakdownAddress(json.breakdownAddress);
  //   setShowModal(true);
  // };


  const closeModal = () => {
    setShowModal(false);
  };

  const saveBreakdown = async () => {
    
    setIsSaving(true);
    //create the post model
    const postModel = {
      breakdownId: breakdownId,
      companyName: companyName,
      companyContactNumber: companyContactNumber,
      driverName: driverName,
      driverCell: driverCell,
      address: breakdownAddress,
      caseNumber: caseNumber,
      category: category,
      subcategory: subcategory,
      description: description,
      truckMake: truckMake,
      truckModel: truckModel,
      vehicleLoad: "",
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      truckRegistrationNumber: truckRegistrationNumber,
      truckVinNumber: truckVinNumber,
      truckFleetNumber: truckFleetNumber,
      truckWarranty: truckWarranty,
      truckYear: truckYear,
      trailer1FleetNumber: trailer1FleetNumber,
      trailer1Registration: trailer1RegistrationNumber,
      trailer1VinNumber: trailer1VinNumber,
      trailer1Year: trailer1Year,
      trailer1Make: trailer1Make,
      trailer1Model: trailer1Model,
      customerQuoteNumber: customerQuoteNumber,
      supplierQuoteNumber: supplierQuoteNumber,
      dealerQuoteNumber: dealerQuoteNumber,
      cause: cause,
      correction: correction,
      jobcardNumber: truckMileage,
      orderNumber: orderNumber,
      isTrailerFault: isTrailerFault,
      vehicleLoad: load,
      breakdownType: breakdownType,
      handoverNotes: handoverNotes,
      initiatorCell: initiatorCell,
      initiatorName: initiatorName,
      reportedBy: reportedBy,
      contactNumber: contactNumber,
      breakdownRequestReference: breakdownRequestReference,
      vehicleLeaseType: vehicleLeaseType, 
    };

    const response = await fetch(
      `${appSettings.apiUrl}/breakdowns/updatebreakdowndetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + currentUser.jwtToken,
        },
        body: JSON.stringify(postModel),
      }
    );

    const json = await response.json();

    setIsSaving(false);

    setIsModalOpen(false);

    //Reload the breakdown to show updated info.
    await loadBreakdown();
    onClose();
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


 const showRequestModal = () => {
  setIsRequestModalOpen(true);
 }

const handleRequestClose = () => {
  setIsRequestModalOpen(false);
}

  const handleTruckNotInList = async (value) => {
    setTruckRegistrationNumber(value);
  };
  const handleFleetNotInList = async (value) => {
    setTruckFleetNumber(value);
  };
  const handleTrailerNotInList = async (value) => {
    setTrailer1RegistrationNumber(value);
  };
  const handleTrailerFleetNotInList = async (value) => {
    setTrailer1FleetNumber(value);
  };
  
  const handleTrailer2NotInList = async (value) => {
    setTrailer2RegistrationNumber(value);
  };
  const handleTrailer2FleetNotInList = async (value) => {
    setTrailer2FleetNumber(value);
  };
  const _lookupAddress = async (address) => {
    let items = await lookupAddress(address);
    setAddressOptions(items);
  };

  const handleAddressSearch = async (address) => {
    let result = await addressSearch(address);
    setLatitude(result.latitude);
    setLongitude(result.longitude);
    setBreakdownAddress(result.title);
  };

  const _reverseGeoSearch = async (lat, lng) => {
    const result = await reverseGeoSearch(lat, lng);
    setBreakdownAddress(result);
  };

  const loadBreakdown = async () => {
    const result = await fetch(
      `${appSettings.apiUrl}/breakdowns/getbreakdownbyid/${breakdownId}`
    );

    const json = await result.json();
    setBreakdownDisplayInformation(json);

    setBreakdownAddress(json.breakdownAddress);
    setCaseNumber(json.caseNumber);
    setCategory(json.breakdownCategory);
    setSubCategory(json.breakdownSubCategory);
    setDescription(json.description);
    setCause(json.breakdownCause);
    setCorrection(json.correction);
    setTruckRegistrationNumber(json.registrationNumber);
    setTruckVinNumber(json.vinNumber);
    setTruckMake(json.make);
    setTruckModel(json.model);
    setTruckFleetNumber(json.fleetNumber);
    setTruckWarranty(json.warranty);
    setTruckYear(json.year);
    setTrailer1RegistrationNumber(json.trailerReg);
    setTrailer1VinNumber(json.trailerVin);
    setTrailer1Make(json.trailerMake);
    setTrailer1Model(json.trailerModel);
    setTrailer1FleetNumber(json.trailerFleetNumber);
    setTrailer1Year(json.trailerYear);
    setTrailer2RegistrationNumber(json.secondTrailerReg);
    setTrailer2VinNumber(json.secondTrailerVin);
    setTrailer2Make(json.secondTrailerMake);
    setTrailer2Model(json.secondTrailerModel);
    setTrailer2FleetNumber(json.secondTrailerFleetNumber);
    setTrailer2Year(json.secondTrailerYear);
    setVehicleLeaseType(json.vehicleLeaseType);
    setCustomerQuoteNumber(json.customerQuoteNumber);
    setSupplierQuoteNumber(json.supplierQuoteNumber);
    setDealerQuoteNumber(json.dealerQuoteNumber);
    setCompanyName(json.companyName);
    setLatitude(json.latitude);
    setLongitude(json.longitude);
    setDriverName(json.driverAlias);
    setDriverCell(json.driverCell);
    setTruckMileage(json.jobcardNumber);
    setOrderNumber(json.orderNumber);
    setIsTrailerFault(json.isTrailerFault);
    setLoad(json.vehicleLoad);
    setBreakdownType(json.breakdownType);
    setHandoverNotes(json.handoverNotes);
    setContactNumber(json.contactNumber);
    setReportedBy(json.reportedBy);
    setInitiatorCell(json.initiatorCell);
    setInitiatorName(json.initiatorName);
  };

  useEffect(() => {
    //Load the breakdown information

    const getBreakdownCategories = async () => {
      const response = await fetch(
        `${appSettings.apiUrl}/breakdowncategory/getactive `,
        {
          method: "GET",
        }
      );

      const result = await response.json();

      setBreakdownCategoryList(result.categoryList);
    };

    const getBreakdownCauses = async () => {
      const response = await fetch(
        `${appSettings.apiUrl}/breakdowncauses/getallactive `,
        {
          method: "GET",
        }
      );

      const result = await response.json();

      setCauseList(result);
    };

    loadBreakdown();
    getBreakdownCategories();
    getBreakdownCauses();
  }, [breakdownId,refreshData]);

  const handleCategoryChange = async (category) => {
    setCategory(category);
    const response = await fetch(
      `${appSettings.apiUrl}/breakdownsubcategory/getbycategoryname/${category} `,
      {
        method: "GET",
      }
    );

    const result = await response.json();

    setBreakdownSubCategoryList(result);
  };

  const setTruckRegistratationSelectedVehicleInformation = (reg, vehicle) => {
    setTruckRegistrationNumber(reg);
    
    if (vehicle == undefined) {
      setTruckFleetNumber("");
      setTruckVinNumber("");
      setTruckMake("");
      setTruckModel();
      setTruckYear("");
    } else {
      setTruckFleetNumber(vehicle.fleet);
      setTruckVinNumber(vehicle.vin);
      setTruckMake(vehicle.make);
      setTruckModel(vehicle.model);
      setTruckYear(vehicle.year);
    }
  };

  const setTruckFleetSelectedVehicleInformation = (fleet, vehicle) => {
    setTruckFleetNumber(fleet);
    if (vehicle == undefined) {
      setTruckRegistrationNumber();
      setTruckVinNumber();
      setTruckMake();
      setTruckModel();
      setTruckYear();
    } else {
      setTruckRegistrationNumber(vehicle.registration);
      setTruckVinNumber(vehicle.vin);
      setTruckMake(vehicle.make);
      setTruckModel(vehicle.model);
      setTruckYear(vehicle.year);
    }
  };

  const setTrailer1RegistratationSelectedVehicleInformation = (
    reg,
    vehicle
  ) => {
    setTrailer1RegistrationNumber(reg);
    if (vehicle == undefined) {
    } else {
      setTrailer1FleetNumber(vehicle.fleet);
      setTrailer1VinNumber(vehicle.vin);
      setTrailer1Make(vehicle.make);
      setTrailer1Model(vehicle.model);
      setTrailer1Year(vehicle.year);
    }
  };

  const setTrailer1FleetSelectedVehicleInformation = (fleet, vehicle) => {
    setTrailer1FleetNumber(fleet);
    if (vehicle == undefined) {
    } else {
      setTrailer1RegistrationNumber(vehicle.registration);
      setTrailer1VinNumber(vehicle.vin);
      setTrailer1Make(vehicle.make);
      setTrailer1Model(vehicle.model);
      setTrailer1Year(vehicle.year);
    }
  };
  const setTrailer2RegistratationSelectedVehicleInformation = (
    reg,
    vehicle
  ) => {
    setTrailer2RegistrationNumber(reg);
    if (vehicle == undefined) {
    } else {
      setTrailer2VinNumber(vehicle.vin);
      setTrailer2FleetNumber(vehicle.fleet);
      setTrailer2Make(vehicle.make);
      setTrailer2Model(vehicle.model);
      setTrailer2Year(vehicle.year);
    }
  };

  const setTrailer2FleetSelectedVehicleInformation = (fleet, vehicle) => {
    setTrailer2FleetNumber(fleet);
    if (vehicle == undefined) {
    } else {
      setTrailer2RegistrationNumber(vehicle.registration);
      setTrailer2VinNumber(vehicle.vin);
      setTrailer2Make(vehicle.make);
      setTrailer2Model(vehicle.model);
      setTrailer2Year(vehicle.year);
    }
  };


  const breakdownRef = breakdownDisplayInformation.breakdownReference;
  return (
    <div>
      <h4 style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}>Driver Details</h4>
      <div style={viewPanel}>
        <div style={{ textAlign: "right" }}>
          <Link to={`/portal/secure/Breakdowns/breakdownView/${breakdownRef}`}>
            <button className='text-blue-500 hover:text-blue-700 font-semibold'>Breakdown Details Link</button>
          </Link>
          <button className='text-blue-500 hover:text-blue-700 font-semibold' style={{ marginLeft: "8px" }} onClick={showRequestModal}>
            Breakdown Request Link
          </button>
        </div>
        <Row style={viewPanelRowPadding}>
          <Col span={24} style={viewPanelLabels}>
            Reference: <b>{breakdownDisplayInformation.breakdownReference}</b>
          </Col>
        </Row>

        <Row style={viewPanelRowPadding}>
          <Col span={24} style={viewPanelLabels}>
            Company: <b>{breakdownDisplayInformation.companyName}</b>
          </Col>
        </Row>
        <Row style={viewPanelRowPadding}>
          <Col span={12} style={viewPanelLabels}>
            Description: <b>{breakdownDisplayInformation.description}</b>
          </Col>
        </Row>
        <Row style={viewPanelRowPadding}>
          <Col span={12} style={viewPanelLabels}>
            Vehicle Lease Type: <b>{breakdownDisplayInformation.vehicleLeaseType}</b>
          </Col>
        </Row>
        <Row style={viewPanelRowPadding}>
          <Col span={12} style={viewPanelLabels}>
            Fleet: <b>{breakdownDisplayInformation.fleetNumber}</b>
          </Col>
          <Col span={12} style={viewPanelLabels}>
            Registration Number: <b>{breakdownDisplayInformation.registrationNumber}</b>
          </Col>
        </Row>
        <Row style={viewPanelRowPadding}>
          <Col span={12} style={viewPanelLabels}>
            Vehicle: <b>{breakdownDisplayInformation.vehicle}</b>
          </Col>
          <Col span={12} style={viewPanelLabels}>
            Warranty: <b>{breakdownDisplayInformation.warranty}</b>
          </Col>
        </Row>
        <Row style={viewPanelRowPadding}>
          <Col span={12} style={viewPanelLabels}>
            Driver: <b>{breakdownDisplayInformation.driverAlias}</b>
          </Col>
          <Col span={12} style={viewPanelLabels}>
            Driver Number: <b>{breakdownDisplayInformation.driverCell}</b>
          </Col>
        </Row>
        <Row style={viewPanelRowPadding}>
          <Col span={12} style={viewPanelLabels}>
            Controller: <b>{breakdownDisplayInformation.reportedBy}</b>
          </Col>
          <Col span={12} style={viewPanelLabels}>
            Controller Number: <b>{breakdownDisplayInformation.contactNumber}</b>
          </Col>
        </Row>

        <Button
          type='primary'
          style={{ width: "100%" }}
          onClick={() => {
            setIsModalOpen(true);
          }}>
          Edit Breakdown
        </Button>
      </div>

      <Modal
        title='MODIFY BREAKDOWN INFORMATION'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"100%"}
        style={{ top: 0, left: 0, right: 0, bottom: 0 }}
        footer={[
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type='primary' loading={isSaving} onClick={saveBreakdown}>
            SAVE
          </Button>,
        ]}>
        <Row style={sectionHeader}>
          <Col span='24'>COMPANY DETAILS</Col>
        </Row>
        <Row>
          <Col span={4} style={dataInputColumn}>
            <b>COMPANY</b> <br />
            <span>{breakdownDisplayInformation.companyName}</span>
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>DRIVER</b> <br />
            <Input
              style={{ wdith: "100%" }}
              value={driverName}
              onChange={(e) => {
                setDriverName(e.target.value);
              }}></Input>
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>DRIVER NUMBER</b> <br />
            <Input
              style={{ wdith: "100%" }}
              value={driverCell}
              onChange={(e) => {
                setDriverCell(e.target.value);
              }}></Input>
          </Col>
          <Col span={4} style={{ ...dataInputColumn, paddingTop: "8px" }}>
            {currentUser && currentUser.authority === "SUPERVISOR" && <ChangeCompanyDriver breakdownId={breakdownId} reloadFunction={loadBreakdown} />}
          </Col>
          <Row>
            <Col span={4} style={dataInputColumn}>
              <b>INITIATOR NAME</b>
              <Input
                style={{ wdith: "100%" }}
                value={initiatorName}
                onChange={(e) => {
                  setInitiatorName(e.target.value);
                }}></Input>
            </Col>
            <Col span={4} style={dataInputColumn}>
              <b>INITIATOR NUMBER</b>
              <Input
                style={{ wdith: "100%" }}
                value={initiatorCell}
                onChange={(e) => {
                  setInitiatorCell(e.target.value);
                }}></Input>
            </Col>
            <Col span={4} style={dataInputColumn}>
              <b>CONTROLLER NAME</b>
              <Input
                style={{ wdith: "100%" }}
                value={reportedBy}
                onChange={(e) => {
                  setReportedBy(e.target.value);
                }}></Input>
            </Col>
            <Col span={4} style={dataInputColumn}>
              <b>CONTROLLER NUMBER</b>
              <Input
                style={{ wdith: "100%" }}
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}></Input>
            </Col>
          </Row>
        </Row>
        <Row style={sectionHeader}>
          <Col span='24'>BREAKDOWN DETAILS</Col>
        </Row>
        <Row>
          <Col span='8' style={dataInputColumn}>
            <b>ADDRESS</b>
            {/* <Input style={{wdith:'100%'}} value={breakdownAddress} onChange={(e) => {setBreakdownAddress(e.target.value)}} ></Input> */}
            <AutoComplete
              style={{ width: "100%" }}
              options={addressOptions?.map((a) => ({
                value: a.address.label,
                label: a.address.label,
              }))}
              value={breakdownAddress}
              onChange={(e) => {
                setBreakdownAddress(e);
                _lookupAddress(e);
              }}
              onSelect={(e) => {
                setBreakdownAddress(e);
                handleAddressSearch(e);
              }}
            />
          </Col>
          <Col span='4' style={dataInputColumn}>
            <b>CASE NUMBER</b>
            <Input
              style={{ wdith: "100%" }}
              value={caseNumber}
              onChange={(e) => {
                setCaseNumber(e.target.value);
              }}></Input>
          </Col>
          <Col span='3' style={dataInputColumn}>
            <b>FAULT ON TRAILER</b>
            <Input
              style={{ wdith: "100%" }}
              value={breakdownDisplayInformation.isTrailer}
              onChange={(e) => {
                setIsTrailerFault(e.target.value);
              }}></Input>
          </Col>

          <Col span='4' style={dataInputColumn}>
            <b>CATEGORY</b>
            <Select
              style={{ width: "100%" }}
              value={category}
              options={breakdownCategoryList.map((bc) => ({
                key: bc.i,
                value: bc.categoryName,
                label: bc.categoryName,
              }))}
              onChange={(e) => {
                handleCategoryChange(e);
              }}
              showSearch
              placeholder='Select a Category'
            />
          </Col>
          <Col span='4' style={dataInputColumn}>
            <b>SUB CATEGORY</b>
            <Select
              style={{ width: "100%" }}
              value={subcategory}
              showSearch
              options={breakdownSubCategoryList.map((bc) => ({
                key: bc.id,
                value: bc.categoryName,
                label: bc.categoryName,
              }))}
              onChange={(e) => {
                setSubCategory(e);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8} style={dataInputColumn}>
            <b>DESCRIPTION</b>
            <Input
              style={{ textTransform: "uppercase" }}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>CAUSE</b>
            <Select
              style={{ width: "100%" }}
              value={cause}
              showSearch
              options={causeList.map((c) => ({
                value: c.cause,
                label: c.cause,
              }))}
              onChange={(e) => {
                setCause(e);
              }}
            />
          </Col>
          <Col span={8} style={dataInputColumn}>
            <b>CORRECTION</b>
            <Input
              style={{ textTransform: "uppercase" }}
              value={correction}
              onChange={(e) => {
                setCorrection(e.target.value);
              }}
            />
          </Col>
          <Row>
            <Col span={6} style={dataInputColumn}>
              <b>VEHICLE LOAD</b>
              <Select
                showsearch
                style={{ width: "100%" }}
                value={load}
                onChange={(e) => {
                  setLoad(e);
                }}
                options={[
                  { value: "PERISHABLE", label: "PERISHABLE" },
                  { value: "HIGH VALUE", label: "HIGH VALUE" },
                  { value: "HAZARDOUS", label: "HAZARDOUS" },
                  { value: "NO LOAD", label: "NO LOAD" },
                ]}
              />
            </Col>
            <Col span={6} style={dataInputColumn}>
              <b>BREAKDOWN TYPE</b>
              <Select
                showsearch
                style={{ width: "100%" }}
                value={breakdownType}
                onChange={(e) => {
                  setBreakdownType(e);
                }}
                options={[
                  { value: 0, label: "PARTIAL (LEGACY)" },
                  { value: 1, label: "ON ROAD" },
                  { value: 10, label: "IN YARD" },
                ]}
              />{" "}
            </Col>
            <Col span={12} style={dataInputColumn}>
              <b>HANDOVER NOTES</b>
              <Input
                style={{ textTransform: "uppercase" }}
                value={handoverNotes}
                onChange={(e) => {
                  setHandoverNotes(e.target.value);
                }}
              />
            </Col>
            <Col span={6} style={dataInputColumn}>
              <b>TRAILER FAULT</b>
              <Select showSearch style={{ width: "100%" }} value={isTrailerFault} onChange={(value) => setIsTrailerFault(value)}>
                <Select.Option value={null}>Select...</Select.Option>
                <Select.Option value={1}>Yes</Select.Option>
                <Select.Option value={0}>No</Select.Option>
              </Select>
            </Col>
          </Row>
        </Row>
        <Row style={sectionHeader}>
          <Col span='24'>VEHICLE DETAILS</Col>
        </Row>
        <Row>
          <Col span={4} style={dataInputColumn}>
            <b>FLEET</b>
            <VehicleFleetSelect companyId={breakdownDisplayInformation.companyModelId} onSelected={setTruckFleetSelectedVehicleInformation} onChange={handleFleetNotInList} value={truckFleetNumber} />
          </Col>
          <Col span={3} style={dataInputColumn}>
            <b>REGISTRATION</b>
            <VehicleRegistrationSelect
              companyId={breakdownDisplayInformation.companyModelId}
              onSelected={setTruckRegistratationSelectedVehicleInformation}
              onChange={handleTruckNotInList}
              value={truckRegistrationNumber}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>VIN NUMBER</b>
            <Input
              value={truckVinNumber}
              onChange={(e) => {
                setTruckVinNumber(e.target.value);
              }}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>MAKE</b>
            <Input
              value={truckMake}
              onChange={(e) => {
                setTruckMake(e.target.value);
              }}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>MODEL</b>
            <Input
              value={truckModel}
              onChange={(e) => {
                setTruckModel(e.target.value);
              }}
            />
          </Col>
          <Col span={2} style={dataInputColumn}>
            <b>YEAR</b>
            <Input
              value={truckYear}
              onChange={(e) => {
                setTruckYear(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12} style={dataInputColumn}>
            <b>WARRANTY</b>
            <Input
              value={truckWarranty}
              onChange={(e) => {
                setTruckWarranty(e.target.value);
              }}
            />
          </Col>
          <Col span={3} style={dataInputColumn}>
            <b>MILEAGE</b>
            <Input
              value={truckMileage}
              onChange={(e) => {
                setTruckMileage(e.target.value);
              }}
            />
          </Col>
          <Col span={3} style={dataInputColumn}>
            <b>VEHICLE LEASE TYPE</b>
            <Input
              value={vehicleLeaseType}
              onChange={(e) => {
                setVehicleLeaseType(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row style={sectionHeader}>
          <Col span='24'>TRAILER 1 DETAILS</Col>
        </Row>
        <Row>
          <Col span={4} style={dataInputColumn}>
            <b>FLEET</b>
            <VehicleFleetSelect
              companyId={breakdownDisplayInformation.companyModelId}
              onSelected={setTrailer1FleetSelectedVehicleInformation}
              onChange={handleTrailerFleetNotInList}
              value={trailer1FleetNumber}
              isTrailer={1}
            />
          </Col>
          <Col span={3} style={dataInputColumn}>
            <b>REGISTRATION</b>
            <VehicleRegistrationSelect
              companyId={breakdownDisplayInformation.companyModelId}
              onSelected={setTrailer1RegistratationSelectedVehicleInformation}
              onChange={handleTrailerNotInList}
              value={trailer1RegistrationNumber}
              isTrailer={1}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>VIN NUMBER</b>
            <Input
              value={trailer1VinNumber}
              onChange={(e) => {
                setTrailer1VinNumber(e.target.value);
              }}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>MAKE</b>
            <Input
              value={trailer1Make}
              onChange={(e) => {
                setTrailer1Make(e.target.value);
              }}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>MODEL</b>
            <Input
              value={trailer1Model}
              onChange={(e) => {
                setTrailer1Model(e.target.value);
              }}
            />
          </Col>
          <Col span={2} style={dataInputColumn}>
            <b>YEAR</b>
            <Input
              value={trailer1Year}
              onChange={(e) => {
                setTrailer1Year(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row style={sectionHeader}>
          <Col span='24'>TRAILER 2 DETAILS</Col>
        </Row>
        <Row>
          <Col span={4} style={dataInputColumn}>
            <b>FLEET</b>
            <VehicleFleetSelect
              companyId={breakdownDisplayInformation.companyModelId}
              onSelected={setTrailer2FleetSelectedVehicleInformation}
              onChange={handleTrailer2FleetNotInList}
              value={trailer2FleetNumber}
              isTrailer={1}
            />
          </Col>
          <Col span={3} style={dataInputColumn}>
            <b>REGISTRATION</b>
            <VehicleRegistrationSelect
              companyId={breakdownDisplayInformation.companyModelId}
              onSelected={setTrailer2RegistratationSelectedVehicleInformation}
              onChange={handleTrailer2NotInList}
              value={trailer2RegistrationNumber}
              isTrailer={1}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>VIN NUMBER</b>
            <Input
              value={trailer2VinNumber}
              onChange={(e) => {
                setTrailer2VinNumber(e.target.value);
              }}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>MAKE</b>
            <Input
              value={trailer2Make}
              onChange={(e) => {
                setTrailer2Make(e.target.value);
              }}
            />
          </Col>
          <Col span={4} style={dataInputColumn}>
            <b>MODEL</b>
            <Input
              value={trailer2Model}
              onChange={(e) => {
                setTrailer2Model(e.target.value);
              }}
            />
          </Col>
          <Col span={2} style={dataInputColumn}>
            <b>Year</b>
            <Input
              value={trailer2Year}
              onChange={(e) => {
                setTrailer2Year(e.target.value);
              }}
            />
          </Col>
        </Row>

        <Row style={sectionHeader}>
          <Col span='24'>FINANCE DETAILS</Col>
        </Row>
        <Row>
          <Col span='3' style={dataInputColumn}>
            <b>CUSTOMER QUOTE NUMBER</b>
            <Input
              value={customerQuoteNumber}
              onChange={(e) => {
                setCustomerQuoteNumber(e.target.value);
              }}
            />
          </Col>
          <Col span='3' style={dataInputColumn}>
            <b>SUPPLIER QUOTE NUMBER</b>
            <Input
              value={supplierQuoteNumber}
              onChange={(e) => {
                setSupplierQuoteNumber(e.target.value);
              }}
            />
          </Col>
          <Col span='3' style={dataInputColumn}>
            <b>DEALER QUOTE NUMBER</b>
            <Input
              value={dealerQuoteNumber}
              onChange={(e) => {
                setDealerQuoteNumber(e.target.value);
              }}
            />
          </Col>
          <Col span='3' style={dataInputColumn}>
            <b>ORDER NUMBER</b>
            <Input
              value={orderNumber}
              onChange={(e) => {
                setOrderNumber(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Modal>
      {isRequestModalOpen && <BreakdownRequestView modalVisible={isRequestModalOpen} onclose={handleRequestClose} breakdownRef={breakdownRef} />}
    </div>
  );
}

const ChangeCompanyDriver = ({ breakdownId, reloadFunction }) => {
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDriverChange = async () => {
    getCustomers();
    setIsModalOpen(true);
  };

  const getCustomers = async () => {
    const response = await fetch(
      `${appSettings.apiUrl}/company/getcompanylist/0`
    );
    const json = await response.json();

    setCustomers(json);
  };

  const getDrivers = async (customerId) => {
    const response = await fetch(
      `${appSettings.apiUrl}/drivers/getdrivers/getcompanydrivers/${customerId}`
    );
    const json = await response.json();

    setDrivers(json.drivers);
  };

  const handleCustomerSelection = async (value) => {
    setDrivers([]);
    setDriverId("");
    setCustomerId(value);

    await getDrivers(value);
  };

  const handleDriverSelection = async (value) => {
    setDriverId(value);
  };

  const saveNewCompanyDriver = async () => {
    const response = await fetch(
      `${appSettings.apiUrl}/breakdowndrivers/changebreakdowndriver`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer: " + currentUser.jwtToken,
        },
        body: JSON.stringify({
          breakdownId: breakdownId,
          driverId: driverId,
          companyId: customerId,
        }),
      }
    );

    const json = await response.json();

    reloadFunction();
    handleCancel();
  };

  return (
    <div>
      <Button type="primary" onClick={showDriverChange}>
        Change Driver
      </Button>

      <Modal
        title="CHANGE COMPANY DRIVER"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button danger onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            loading={isSaving}
            onClick={saveNewCompanyDriver}
          >
            SET NEW COMPANY DRIVER
          </Button>,
        ]}
      >
        <Row>
          <Col style={dataInputColumn} span={12}>
            Select Company
            <Select
              style={{ width: "100%" }}
              showSearch
              value={customerId}
              options={customers.map((customer) => ({
                value: customer.id,
                label: customer.name,
              }))}
              onChange={handleCustomerSelection}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            ></Select>
          </Col>
          <Col style={dataInputColumn} span={12}>
            Select Driver
            <Select
              style={{ width: "100%" }}
              showSearch
              value={driverId}
              options={drivers.map((driver) => ({
                value: driver.id,
                label: driver.fullName,
              }))}
              onChange={handleDriverSelection}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            ></Select>
          </Col>
        </Row>
      </Modal>
     
    </div>
  );
};

const viewPanel = {
  borderRadius: "0.375rem",
  width: "100%",
  borderWidth: "1px",
  borderStyle: "solid",
  padding: "0.5rem",
  marginBottom: "1rem",
  borderColor: "rgb(209 213 219)",
  backgroundColor: "#fff",
};

const viewPanelLabels = {
  fontFamily: "roboto",
  fontSize: "9pt",
};

const sectionHeader = {
  fontSize: "18pt",
  fontWeight: "bold",
  borderBottom: "solid 1px #000",
  paddingBottom: "8px",
  paddingTop: "16px",
};

const dataInputColumn = {
  paddingRight: "6px",
  paddingLeft: "6px",
  paddingTop: "6px",
};

const viewPanelRowPadding = {
  padding: "6px",
};
