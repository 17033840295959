
import { Navigate } from "react-router";
import MobileLayout from "./Layout";

const MobileProtected = () => {
    var isLoggedIn = false;
    if (sessionStorage.getItem('currentUser') === null) {
        isLoggedIn = false;
    }else{
        isLoggedIn = true;
    }

    if (!isLoggedIn) {
        return <Navigate to="/mobile/auth/" replace />
    }else{
        return (
           <MobileLayout />
        )
    }
}

export default MobileProtected;